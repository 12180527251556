import { ElementType, ReactElement, ReactNode, Ref, useImperativeHandle, useMemo, useRef } from 'react';
import { defaultRangeExtractor, Range, useVirtualizer, VirtualItem, Virtualizer } from '@tanstack/react-virtual';
import { Header as RTHeader, Row as RTRow, Table, TableState } from '@tanstack/react-table';
import { makeStyles } from '@material-ui/core';
import { BidsTableNextItem, CarsTableNextItem } from './types';

// TODO: add key resolvers

type VirtualizedTableProps = {
  row: VirtualRowRender;
  header: VirtualHeaderRender;
  rowSize: number;
  table: Table<CarsTableNextItem | BidsTableNextItem>;
  /**
   * Implicit reference to the table virtualizer instance.
   */
  virtualizer?: Ref<TableVirtualizer>;
  /**
   * Is the data for the table currently loading? If `isLoading` is typeof boolean
   * the virtualized table will populate rows (equal to `table.pageSize`) with no data as fallback,
   * meaning you are able to handle a separate loading state inside the row render fn.
   */
  isLoading?: boolean;
  isPreviousData?: boolean;
  className?: string;
  noDataFallback?: ReactNode;
  containerComponent?: ElementType<any>;
  containerProps?: object;
};

export type TableVirtualizer = {
  row: Virtualizer<HTMLDivElement | null, unknown>;
  column: Virtualizer<HTMLDivElement | null, unknown>;
};

export type VirtualRowRender = (props: {
  virtualRowKey: string | number;
  virtualRowSize: number;
  virtualRowStart: number;
  row: RTRow<CarsTableNextItem | BidsTableNextItem> | undefined;
  table: Table<CarsTableNextItem | BidsTableNextItem>;
  virtualColumnItems: VirtualItem<HTMLDivElement>[];
  tableState: TableState;
  isLoading?: boolean;
  isPreviousData?: boolean;
}) => ReactElement | null;

export type VirtualHeaderRender = (props: RTHeader<CarsTableNextItem | BidsTableNextItem, unknown>) => ReactElement;

const staticColumnRangeExtractor = (range: Range) => {
  const pinnedIndex = [0];
  const next = new Set([...pinnedIndex, ...defaultRangeExtractor(range)]);
  return [...next].sort((a, b) => a - b);
};

const useStyles = makeStyles({
  rowContainer: ({ width, height }: { width: number; height: number }) => ({
    width,
    height,
    position: 'relative',
    zIndex: 0,
  }),
});

export const VirtualizedTable = ({
  row: Row,
  header: Header,
  rowSize,
  table,
  isLoading,
  isPreviousData,
  className,
  virtualizer,
  noDataFallback,
  containerComponent: ContainerComponent = 'div',
  containerProps,
}: VirtualizedTableProps) => {
  const { rows } = table.getRowModel();
  const headerGroups = table.getHeaderGroups();
  const tableRef = useRef<HTMLDivElement | null>(null);
  const tableState = table.getState();
  const columnSizes = table.getVisibleLeafColumns().map((col) => col.getSize());

  const rowVirtualizer = useVirtualizer({
    count: isLoading ? table.getState().pagination.pageSize : rows.length,
    getScrollElement: () => tableRef.current,
    estimateSize: () => rowSize,
    overscan: 5,
    getItemKey: (index) => rows.at(index)?.original.carId ?? index,
  });
  const classes = useStyles({
    width: table.getTotalSize(),
    height: rowVirtualizer.getTotalSize(),
  });

  const columnVirtualizer = useVirtualizer({
    horizontal: true,
    count: columnSizes.length,
    getScrollElement: () => tableRef.current,
    estimateSize: (index) => columnSizes[index],
    overscan: 2,
    rangeExtractor: staticColumnRangeExtractor,
  });

  const virtualColumnItems = columnVirtualizer.getVirtualItems();

  const headerGroupsContent = useMemo(
    () =>
      headerGroups.map((headerGroup) => (
        <div
          // tr
          key={headerGroup.id}
          style={{
            position: 'relative',
            display: 'flex',
          }}
        >
          {headerGroup.headers.map((header) => (
            <Header key={header.id} {...header} />
          ))}
        </div>
      )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [headerGroups, rowSize, Header, tableState],
  );

  useImperativeHandle(virtualizer, () => ({
    row: rowVirtualizer,
    column: columnVirtualizer,
  }));

  return (
    <div
      // table
      ref={tableRef}
      style={{
        contain: 'strict',
        background: '#fff',
        overflowX: 'auto',
        overflowY: 'hidden',
        flex: '1',
        position: 'relative',
        display: 'grid',
        gridTemplateRows: 'auto minmax(0, 1fr)',
        gridTemplateColumns: 'minmax(0, 1fr)',
        overflow: 'auto',
      }}
      className={className}
    >
      <div
        // thead
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 1,
          background: '#fff', // make sure to block content behind sticky header
          width: table.getTotalSize(),
        }}
      >
        {headerGroupsContent}
      </div>

      {rows.length === 0 && !isLoading && noDataFallback}

      <ContainerComponent className={classes.rowContainer} {...containerProps}>
        {rowVirtualizer.getVirtualItems().map((virtualRow) => (
          <Row
            key={virtualRow.key}
            virtualRowKey={virtualRow.key}
            virtualRowStart={virtualRow.start}
            virtualRowSize={virtualRow.size}
            table={table}
            row={rows.at(virtualRow.index)}
            virtualColumnItems={virtualColumnItems}
            tableState={tableState}
            isLoading={isLoading}
            isPreviousData={isPreviousData}
          />
        ))}
      </ContainerComponent>
    </div>
  );
};
