import { Typography, lighten, makeStyles } from '@material-ui/core';
import { Apps } from '@material-ui/icons';
import MUIDataTable, { MUIDataTableColumn } from 'mui-datatables';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../modules/date-helpers';
import { BidRequestCarStatus, CSVBidRequestDTO } from '../modules/generated/api';
import getMUIDataTableDefaultOptions from '../setup/MUIDataTableOptions';
import ActionCarDetailLink from './ActionCarDetailLink';
import ActionExternalDetailLink from './ActionExternalDetailLink';
import ActionExternalPackageLink from './ActionExternalPackageLink';
import UnitValueCell from './UnitValueCell';

const useStyles = makeStyles({
  row: {
    '&--error': {
      backgroundColor: '#f5c6cb',

      '&:hover': {
        backgroundColor: `${lighten('#f5c6cb', 0.5)} !important`,
      },
    },
  },
});

export enum BidsMUIDataTableMode {
  Preview = 'PREVIEW',
}

type BidsMUIDataTableProps = { recordsTotal?: number } & {
  mode: BidsMUIDataTableMode.Preview;
  bids?: CSVBidRequestDTO[];
};

const formatString = 'dd.MM.yy HH:mm:ss.SSS';

const BidsMUIDataTable = ({ mode, bids = [], recordsTotal }: BidsMUIDataTableProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const columns: MUIDataTableColumn[] = useMemo(() => {
    const cols: MUIDataTableColumn[] = [
      {
        name: 'actions',
        label: t('bids.auction_plural'),
        options: {
          customBodyRenderLite: (dataIndex) => {
            const { source, carId, packageId } = bids[dataIndex];

            return (
              <>
                <ActionCarDetailLink source={source} carId={carId} />
                {packageId ? (
                  <ActionExternalPackageLink source={source} packageId={packageId} />
                ) : (
                  <ActionExternalDetailLink source={source} carId={carId} />
                )}
              </>
            );
          },
        },
      },
      {
        name: 'biddingTime',
        label: t('bids.plannedBidSubmission'),
        options: {
          customBodyRenderLite: (dataIndex) =>
            bids[dataIndex].biddingTime && formatDate(bids[dataIndex].biddingTime as string, formatString),
        },
      },
      {
        name: 'carId',
        label: t('car.ID'),
        options: {
          customBodyRenderLite: (dataIndex) => {
            const { carId, packageId } = bids[dataIndex];

            return packageId ? (
              <>
                {packageId}{' '}
                <Apps titleAccess="Paket" fontSize="small" style={{ verticalAlign: 'text-top', fontSize: '1rem' }} />
              </>
            ) : (
              carId
            );
          },
        },
      },
      {
        name: 'bid1',
        label: `1. ${t('common.bid')}`,
        options: {
          customBodyRenderLite: (dataIndex) => <UnitValueCell value={bids[dataIndex].bid1} unit="€" />,
        },
      },
      {
        name: 'bid2',
        label: `2. ${t('common.bid')}`,
        options: {
          customBodyRenderLite: (dataIndex) => <UnitValueCell value={bids[dataIndex].bid2} unit="€" />,
        },
      },
      {
        name: 'bid3',
        label: `3. ${t('common.bid')}`,
        options: {
          customBodyRenderLite: (dataIndex) => <UnitValueCell value={bids[dataIndex].bid3} unit="€" />,
        },
      },
    ];

    return cols;
  }, [bids, t]);

  return (
    <MUIDataTable
      title={
        <Typography variant="h2">
          {t('common.bid_plural')}
          {mode === BidsMUIDataTableMode.Preview ? ` ${t('bids.preview')} ` : ' '}
          {recordsTotal !== undefined && `(${recordsTotal})`}
        </Typography>
      }
      data={bids}
      columns={columns}
      options={{
        ...getMUIDataTableDefaultOptions(),
        search: true,
        sortOrder: {
          name: 'biddingTime',
          direction: 'desc',
        },
        ...(mode === BidsMUIDataTableMode.Preview && { elevation: 0 }),
        setRowProps: (_, dataIndex) => {
          if (
            mode === BidsMUIDataTableMode.Preview &&
            (bids[dataIndex] as CSVBidRequestDTO).status === BidRequestCarStatus.CarUnavailable
          ) {
            return { className: `${classes.row}--error` };
          }

          return {};
        },
      }}
    />
  );
};

export default memo(BidsMUIDataTable);
