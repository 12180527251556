import { makeStyles, Tooltip, TooltipProps, Typography } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import clsx from 'clsx';
import { memo, ReactElement, ReactNode } from 'react';
import HorizontalDivider from './HorizontalDivider';

const useStyles = makeStyles((theme) => ({
  hintIcon: {
    verticalAlign: 'middle',
    margin: theme.spacing(0.22),
  },
  smaller: {
    fontSize: '1rem',
  },
}));

type InfoTooltipProps = {
  title?: string | ReactElement;
  hint?: ReactNode;
  divider?: boolean;
  moreInfo?: ReactNode;
  xsIcon?: boolean;
  color?: 'inherit' | 'primary' | 'secondary' | 'action' | 'disabled' | 'error';
} & Pick<TooltipProps, 'placement'>;

const InfoTooltip = ({
  title,
  hint,
  divider,
  moreInfo,
  xsIcon,
  placement = 'right',
  color = 'inherit',
}: InfoTooltipProps) => {
  const classes = useStyles();

  return (
    <Tooltip
      arrow
      placement={placement}
      title={
        <>
          <Typography variant="caption">
            {title && (
              <>
                <strong>{title}</strong> <br />
              </>
            )}
            {hint}
          </Typography>

          {divider && <HorizontalDivider variant="middle" light />}

          {moreInfo}
        </>
      }
      className={clsx(classes.hintIcon, xsIcon && classes.smaller)}
    >
      <InfoOutlined color={color} fontSize="small" />
    </Tooltip>
  );
};

export default memo(InfoTooltip);
