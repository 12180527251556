import { ReactElement, useEffect, useRef, useState } from 'react';
import { DurationObject } from 'luxon';
import humanizeDuration from 'humanize-duration';
import { useTranslation } from 'react-i18next';
import { nowTillDate } from '../modules/date-helpers';
import { humanizeDict } from '../modules/humanize-duration';
import { useInView } from '../hooks/useInView';

type CarsTableRemainingTimeProps = {
  dateEnd?: string;
  showExpiredLabel: boolean;
};

const isDurationObj = (x: any): x is DurationObject & Required<Pick<DurationObject, 'milliseconds'>> =>
  typeof x === 'object';

const remainingTimeHumanizer = humanizeDuration.humanizer({
  language: 'shortEn',
  languages: humanizeDict,
  spacer: '',
  units: ['d', 'h', 'm'],
  round: true,
});

function CarsTableRemainingTime({ dateEnd, showExpiredLabel }: CarsTableRemainingTimeProps): ReactElement {
  const { t } = useTranslation();
  const rootRef = useRef<HTMLDivElement | null>(null);
  const inView = useInView(rootRef);
  const [timeLeft, setTimeLeft] = useState(dateEnd && nowTillDate(dateEnd));
  const isValid = isDurationObj(timeLeft);
  const isExpired = dateEnd && new Date(dateEnd).getTime() <= Date.now();

  useEffect(() => {
    let timestamp: number;

    if (dateEnd && inView && !isExpired) {
      timestamp = window.setInterval(() => {
        setTimeLeft(nowTillDate(dateEnd));
      }, 1000);
    }

    return () => {
      if (timestamp) {
        window.clearInterval(timestamp);
      }
    };
  }, [dateEnd, inView, isExpired]);

  return (
    <div ref={rootRef}>
      {!isValid && t('common.unavailable')}
      {isValid && isExpired && showExpiredLabel && t('common.expired')}
      {isValid && !isExpired && (
        <>
          {remainingTimeHumanizer((timeLeft as DurationObject).milliseconds!, {
            units: (timeLeft as DurationObject).milliseconds! < 60_000 ? ['d', 'h', 'm', 's'] : ['d', 'h', 'm'],
          })}
        </>
      )}
    </div>
  );
}

export default CarsTableRemainingTime;
