import InfoTooltip from '@components/InfoTooltip';
import { Drawer, DrawerContent, DrawerFooter, DrawerHeader, InputLabel, Stack } from '@components/ui';
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  makeStyles,
  DrawerProps as MuiDrawerProps,
  OutlinedInput,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import clsx from 'clsx';
import { cloneDeep } from 'lodash';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BiddingTimeExtensionRuleDTO, PlatformCredentialsDTO } from 'src/modules/generated/api';
import type { Merge } from 'type-fest';

type FirstCallRulesDrawerProps = {
  title?: string;
  onClose: () => void;
};

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiDrawer-paper': {
      width: 600,
    },
  },
  alignment: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  styledInput: {
    marginBottom: 0,
    fontSize: '1rem',
  },
  infoTooltip: {
    '& p': {
      paddingBottom: '4px',
      margin: 0,
      lineHeight: '1.5',
    },
  },
}));

export const FirstCallRulesDrawer = ({
  onClose,
  className,
  ...drawerProps
}: Merge<MuiDrawerProps, FirstCallRulesDrawerProps>) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { getValues, setValue, watch, reset } = useFormContext<PlatformCredentialsDTO>();

  const form = useForm<BiddingTimeExtensionRuleDTO>({
    defaultValues: {
      postalCodePattern: '',
    },
    mode: 'onChange',
  });
  const {
    control,
    getValues: getPostalFormValues,
    formState: { errors },
    reset: resetRule,
  } = form;

  const extension = watch('metadata.automaticBiddingTimeExtension');

  const onSubmit = (rule: BiddingTimeExtensionRuleDTO) => {
    setValue('metadata.automaticBiddingTimeExtension', { rules: [rule, ...(extension?.rules || [])], enabled: true });
    resetRule();
  };

  const onDelete = (index: number) => {
    if (!extension?.rules) {
      return;
    }
    const ruleSet = cloneDeep(extension?.rules);
    const filteredRules = ruleSet.filter((_, i) => i !== index);
    reset({
      ...getValues(),
      metadata: { automaticBiddingTimeExtension: { rules: filteredRules, enabled: filteredRules.length > 0 } },
    });
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <form id="single-rule">
      <FormControl id="single-rule" {...form}>
        <Drawer anchor="right" onClose={handleClose} className={clsx(classes.root, className)} {...drawerProps}>
          <DrawerHeader>
            <Typography>{t('dealerManagementPage.addAdaptRules')}</Typography>
          </DrawerHeader>
          <DrawerContent>
            <Stack spacing={3}>
              <FormControl size="small" fullWidth variant="outlined">
                <div className={classes.alignment}>
                  <InputLabel className={classes.styledInput}>{t('dealerManagementPage.rule')}</InputLabel>
                  <InfoTooltip
                    title={
                      <div className={classes.infoTooltip}>
                        <p>{t('dealerManagementPage.ruleInfo_1')}</p>
                        <p>{t('dealerManagementPage.ruleInfo_2')}</p>
                        <p>{t('dealerManagementPage.ruleInfo_3')}</p>
                      </div>
                    }
                  />
                </div>
                <Controller
                  name="postalCodePattern"
                  control={control}
                  rules={{
                    pattern: {
                      value: /^[0-9*]+$/,
                      message: t('validation.onlyNumbersOrAsterisk'),
                    },
                  }}
                  render={({ field: { ref, ...field } }) => (
                    <OutlinedInput error={!!errors.postalCodePattern?.message} inputRef={ref} {...field} />
                  )}
                />
                {errors.postalCodePattern?.message ? (
                  <FormHelperText error>{errors.postalCodePattern.message}</FormHelperText>
                ) : null}
              </FormControl>

              <Button
                onClick={() => onSubmit(getPostalFormValues())}
                startIcon={<AddIcon />}
                size="small"
                color="secondary"
                variant="contained"
                disabled={!!errors.postalCodePattern}
              >
                {t('dealerManagementPage.addRule')}
              </Button>
              <Stack spacing={2}>
                {extension?.rules
                  ?.filter((rule) => rule.postalCodePattern !== '')
                  .map((rule, index) => (
                    <Grid container key={rule.postalCodePattern} alignItems="center">
                      <Grid item xs={10}>
                        <Typography variant="body2">{rule.postalCodePattern}</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Button
                          key={`delete-${rule.postalCodePattern}`}
                          onClick={() => onDelete(index)}
                          size="small"
                          color="secondary"
                          variant="outlined"
                        >
                          {t('common.delete')}
                        </Button>
                      </Grid>
                    </Grid>
                  ))}
              </Stack>
            </Stack>
          </DrawerContent>
          <DrawerFooter>
            <Grid container justifyContent="space-between">
              <Button size="small" variant="outlined" color="secondary" onClick={handleClose}>
                {t('common.cancel')}
              </Button>
              <Button
                onClick={handleClose}
                type="submit"
                form="fc-rules-edit-form"
                size="small"
                variant="contained"
                color="primary"
              >
                {t('common.save')}
              </Button>
            </Grid>
          </DrawerFooter>
        </Drawer>
      </FormControl>
    </form>
  );
};
