import { FormControl, FormControlLabel, FormLabel, makeStyles, Radio, RadioGroup } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { memo } from 'react';
import { Control, Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getAccountDealerId } from 'src/modules/bid-buy-helpers';

const useStyles = makeStyles(() => ({
  formGroup: {
    flexDirection: 'row',
    '& .Mui-checked': {
      color: grey[800],
    },
    '& .Mui-checked  + span': {
      color: grey[800],
    },
  },
}));

type BuyingTimeRadiosProps = {
  name: string;
  control: Control;
  activateForm: boolean;
};

const BiddingTimeRadios = ({ name, control, activateForm }: BuyingTimeRadiosProps) => {
  const classes = useStyles();
  const { setValue } = useFormContext();
  const { t } = useTranslation();
  const currentDealer = getAccountDealerId();

  /// reset the value overridingFirstCallCascadeDealer on every change to make sure everything gets rendered correctly
  const resetFirstCallDealer = () => {
    if (typeof currentDealer === 'string') {
      setValue('overridingFirstCallCascadeDealer', currentDealer);
    } else {
      setValue('overridingFirstCallCascadeDealer', '');
    }
  };

  return (
    <FormControl component="fieldset" margin="dense" fullWidth>
      <FormLabel component="legend">{t('bids.buyAtAuctionEnd')}</FormLabel>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <RadioGroup
            className={classes.formGroup}
            value={value}
            onChange={(e) => {
              onChange(e);
              resetFirstCallDealer();
            }}
          >
            {[
              {
                value: 'no',
                label: t('bids.submissionAtAuctionEnd_short'),
              },
              { value: 'yes', label: t('bids.instantly') },
            ].map(({ value: itemValue, label }) => {
              const labelWithHint = `${label} `;
              return (
                <FormControlLabel
                  key={label}
                  value={itemValue}
                  disabled={!activateForm}
                  control={<Radio />}
                  label={labelWithHint}
                />
              );
            })}
          </RadioGroup>
        )}
      />
    </FormControl>
  );
};

export default memo(BiddingTimeRadios);
