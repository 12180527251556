import { Tooltip } from '@material-ui/core';

type VirtualSourceIconType = {
  src: string;
  title: string;
};

export const VirtualSourceIcon = ({ src, title }: VirtualSourceIconType) => (
  <Tooltip title={title} placement="top" arrow>
    <img src={src} alt={title} width={20} />
  </Tooltip>
);
