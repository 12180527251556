import { MouseEventHandler, ReactElement, ReactNode } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: 0,
  },
  dialogContent: {
    padding: theme.spacing(4),
  },
  dialogContentNoPadding: {
    padding: 0,
  },
  dialogContentNoTopPadding: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(1.5),
  },
  actionsTopLeft: {
    position: 'absolute',
    left: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  actionsTopRight: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  fit: {
    maxWidth: 'fit-content',
    margin: '0 auto',
    '& .MuiDialog-container': {
      height: 'auto',
    },
  },
}));

type DialogWithCloseProps = {
  actionsBottom?: ReactElement;
  actionsTopLeft?: ReactElement;
  actionsTopRight?: ReactElement;
  children: ReactNode;
  maxWidth?: DialogProps['maxWidth'];
  open: boolean;
  onClick?: MouseEventHandler<HTMLDivElement> | undefined;
  onClose: () => void;
  paddingType?: 'default' | 'none' | 'noTop';
  title?: ReactNode;
  small?: boolean;
  stopProp?: boolean;
  onlyCloseOnEscape?: boolean; // avoids closing the dialog when using scrollbar
};

const DialogWithClose = ({
  actionsBottom,
  actionsTopLeft,
  actionsTopRight,
  children,
  maxWidth = 'md',
  paddingType = 'default',
  open,
  onClick,
  onClose,
  small = false,
  title,
  stopProp = false,
  onlyCloseOnEscape = false,
}: DialogWithCloseProps) => {
  const classes = useStyles();

  let dialogClass;
  switch (paddingType) {
    case 'noTop':
      dialogClass = classes.dialogContentNoTopPadding;
      break;
    case 'none':
      dialogClass = classes.dialogContentNoPadding;
      break;
    default:
      dialogClass = classes.dialogContent;
  }
  return (
    <Dialog
      fullWidth={!small}
      maxWidth={maxWidth}
      open={open}
      onClose={(event, reason) => {
        if (reason === 'escapeKeyDown' || (!onlyCloseOnEscape && reason === 'backdropClick')) {
          onClose();
        }
      }}
      onClick={onClick}
      scroll="body"
      className={clsx(!maxWidth && classes.fit)}
    >
      <DialogTitle disableTypography className={classes.dialogTitle}>
        {title && <Typography variant="h5">{title}</Typography>}
        {actionsTopLeft && <div className={classes.actionsTopLeft}>{actionsTopLeft}</div>}
        <div className={classes.actionsTopRight}>
          {actionsTopRight}
          <IconButton
            aria-label="close"
            onClick={(e) => {
              if (stopProp) e.stopPropagation();
              onClose();
            }}
          >
            <Close />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent className={dialogClass}>{children}</DialogContent>
      {actionsBottom && <DialogActions>{actionsBottom}</DialogActions>}
    </Dialog>
  );
};

export default DialogWithClose;
