import { ComponentType, useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import TagManager from 'react-gtm-module';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import useQueryParam from '../hooks/useQueryParam';
import { getAuthenticationUrl, redirectToAuthentication, useAuthenticationStore } from '../stores/Authentication';
import { getPageViewTM } from '../modules/tag-manager-helpers';
import { AuthenticationProvider } from '../modules/generated/api';

type ProtectedRouteProps = RouteComponentProps & {
  component: ComponentType<any>;
  gtmPageType?: string;
};

export const ProtectedRoute = ({ component: Component, gtmPageType, ...props }: ProtectedRouteProps) => {
  const { queryParam: accessTokenQueryParam, urlWithoutQueryParams } = useQueryParam('access_token');
  const { queryParam: targetQueryParam } = useQueryParam('Target');
  const {
    i18n: { language },
  } = useTranslation();

  const {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    state: { hasAccess, accessToken, objectToken: { authentication_provider, exp, is_allowed_to_login } = {} },
    dispatch,
  } = useAuthenticationStore();

  useEffect(() => {
    if (gtmPageType) {
      TagManager.dataLayer(getPageViewTM(language, gtmPageType));
    }
  }, [gtmPageType, language]);

  useEffect(() => {
    if (targetQueryParam) {
      window.location.replace(
        `${getAuthenticationUrl(AuthenticationProvider.Grp)}?Target=${encodeURIComponent(targetQueryParam)}`,
      );
    }
  }, [targetQueryParam]);

  useEffect(() => {
    if (accessTokenQueryParam) {
      dispatch({ type: 'authenticationStore/SIGN_IN', payload: accessTokenQueryParam });
      window.location.replace(urlWithoutQueryParams);
    }
  }, [accessTokenQueryParam, dispatch, urlWithoutQueryParams]);

  if (!hasAccess || (exp && exp < DateTime.now().plus({ minute: 1 }).toSeconds())) {
    if (!authentication_provider) {
      redirectToAuthentication();
      return null;
    }
    window.location.replace(getAuthenticationUrl(authentication_provider as AuthenticationProvider));
    return null;
  }

  if (accessToken) {
    if (!is_allowed_to_login) {
      window.location.replace(`${process.env.REACT_APP_API_URL || ''}/forbidden`);
      return null;
    }
  }

  return <Component {...props} />;
};
