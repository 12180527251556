import { isArray, isFunction, mergeWith } from 'lodash';
import React, { createContext, useCallback, useContext, useEffect, useMemo } from 'react';
import { ControllerRenderProps, FormProvider, useForm, useFormContext } from 'react-hook-form';
import { CacheKeys } from 'src/constants';
import useRole from 'src/hooks/useRole';
import { useSources } from 'src/hooks/useSources';
import { useUser } from 'src/hooks/useUser';
import { getPersistedValue, persistValue } from 'src/lib/localStorage';
import UserRole from 'src/types/UserRoles';
import { getDefaultFilter, transformPersistedFilter } from './lib';
import { CarsTableNextFilterData } from './types';

type FilterProviderProps = {
  children: React.ReactNode;
};

type FilterContext = {
  defaultFilter: CarsTableNextFilterData;
};

const filterContext = createContext({} as FilterContext);

export const useFilter = () => {
  const filter = useContext(filterContext);
  const { defaultFilter } = filter;
  const form = useFormContext<CarsTableNextFilterData>();
  const { reset: formReset, getValues, setValue } = form;

  /**
   * Set the filter and additionally merges default values
   */
  const setFilterSafe = (newValue: CarsTableNextFilterData) => {
    const prevFilter = getValues();

    formReset(
      mergeWith(
        {},
        defaultFilter,
        isFunction(newValue) ? newValue(prevFilter) : newValue,
        // Customizer: overwrite with newValue if default Value is an Array.
        // Default behaviour: Concatenation if length of source1 < length of source2
        (objValue, srcValue) => {
          if (isArray(srcValue)) {
            return srcValue;
          }
          return undefined;
        },
      ),
    );
  };

  const setFilter = useCallback(
    (newValue: CarsTableNextFilterData | null) => {
      if (newValue) {
        formReset(newValue);
      }
    },
    [formReset],
  );

  const onFieldChange = useCallback(
    (field: ControllerRenderProps<CarsTableNextFilterData>, newValue: any) => {
      if (
        newValue &&
        typeof newValue === 'object' &&
        'nativeEvent' in newValue &&
        newValue.nativeEvent instanceof Event
      ) {
        // eslint-disable-next-line no-console
        console.error('Filter onChange should not be called with event');
        return;
      }

      setValue(field.name, newValue);
    },
    [setValue],
  );

  /**
   * Reset filter state to the default filter value
   */
  const reset = useCallback(() => {
    formReset(defaultFilter);
  }, [defaultFilter, formReset]);

  return {
    ...filter,
    ...form,
    setFilter,
    setFilterSafe,
    reset,
    onFieldChange,
  };
};

export const FilterProvider = ({ children }: FilterProviderProps) => {
  const { data: sources } = useSources();
  const { data: user } = useUser();
  const { hasRole } = useRole();
  const isAdmin = hasRole(UserRole.ADMIN);
  const defaultFilter = useMemo(() => getDefaultFilter({ sources, user, admin: isAdmin }), [sources, user, isAdmin]);

  const form = useForm({
    defaultValues: getPersistedValue(CacheKeys.carsTableNextFilter, {
      initialValue: defaultFilter,
      transform: transformPersistedFilter,
    }),
  });
  const { watch } = form;

  const filterContextValue = useMemo(() => ({ defaultFilter }), [defaultFilter]);

  // Persist filter to local storage
  useEffect(() => {
    const subscription = watch((filter) => {
      persistValue(CacheKeys.carsTableNextFilter, filter);
    });
    return () => subscription.unsubscribe();
  });

  return (
    <filterContext.Provider value={filterContextValue}>
      <FormProvider {...form}>{children}</FormProvider>
    </filterContext.Provider>
  );
};
