import { memo, useCallback, useState } from 'react';
import {
  Grid,
  InputAdornment,
  makeStyles,
  Slider as MuiSlider,
  TextField,
  TextFieldProps,
  Theme,
} from '@material-ui/core';
import { useFormContext, useWatch } from 'react-hook-form';
import { debounce, isNumber } from 'lodash';
import { scaleLinear } from 'd3';
import { useTranslation } from 'react-i18next';
import { InputLabel } from '@components/ui';
import { grey } from '@material-ui/core/colors';
import AmountInput from './AmountInput';
import { useDidUpdateEffect } from '../hooks/useDidUpdateEffect';
import {
  DEFAULT_CURRENCY,
  makeMonetaryAmount,
  MonetaryAmountStrict,
  SupportedCurrencyCode,
  SUPPORTED_CURRENCIES,
} from '../modules/currency';
import { useCurrency } from '../hooks/useCurrency';

type MonetaryAmountTuple = [MonetaryAmountStrict, MonetaryAmountStrict];

const useStyles = makeStyles<Theme, { marginTop: boolean }>((theme) => ({
  label: {
    display: 'block',
    marginBottom: theme.spacing(-0.5),
    marginTop: ({ marginTop }) => (marginTop ? 8 : 0),
  },
  textField: {
    marginTop: theme.spacing(-0.5),
  },
}));

const formatMarkLabel = (num: number) => {
  if (num > 999 && num < 1000000) {
    return `${(num / 1000).toFixed(0)}K`; // convert to K for number from > 1000 < 1 million
  }
  if (num >= 1000000) {
    return `${(num / 1000000).toFixed(0)}M`; // convert to M for number from > 1 million
  }
  if (num < 900) {
    return num; // if value < 1000, nothing to do
  }
  return '';
};

const serializeValue = (value: [number, number], currencyCode: SupportedCurrencyCode) => [
  isNumber(value[0]) ? makeMonetaryAmount(value[0], currencyCode) : value[0],
  isNumber(value[1]) ? makeMonetaryAmount(value[1], currencyCode) : value[1],
];

const deserializeValue = (
  value: MonetaryAmountTuple,
  callback?: (value: number, currencyCode: SupportedCurrencyCode) => number,
): [number, number] => {
  const [left, right] = value;

  return [
    callback?.(left.amount, left.currency as SupportedCurrencyCode) ?? left.amount,
    callback?.(right.amount, right.currency as SupportedCurrencyCode) ?? right.amount,
  ];
};

const CurrencySliderInput = () => {
  const { t } = useTranslation();
  const name = 'purchasePriceGross';
  const min = 0;
  const max = 1_000_000;
  const classes = useStyles({ marginTop: false });
  const { currency, convert } = useCurrency();
  const marks = [
    {
      value: 0,
      scaledValue: 0,
      label: '0',
    },
    {
      value: 90,
      scaledValue: convert(200_000, { from: DEFAULT_CURRENCY.code }),
      label: formatMarkLabel(convert(200_000, { from: DEFAULT_CURRENCY.code })),
    },
    {
      value: 100,
      scaledValue: convert(1_000_000, { from: DEFAULT_CURRENCY.code }),
      label: formatMarkLabel(convert(1_000_000, { from: DEFAULT_CURRENCY.code })),
    },
  ];
  const scale = scaleLinear()
    .domain(marks.map((mark) => mark.scaledValue))
    .range(marks.map((mark) => mark.value));
  const step = scale(100);
  const minLocalized = convert(min, { from: DEFAULT_CURRENCY.code });
  const maxLocalized = convert(max, { from: DEFAULT_CURRENCY.code });
  const { setValue: setFormValue, getValues: getFormValue, control } = useFormContext();
  const [value, setValue] = useState(deserializeValue(getFormValue(name), (v, c) => scale(convert(v, { from: c }))));
  const formValue = useWatch({ name, control }) as [MonetaryAmountStrict, MonetaryAmountStrict];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setFormValueDebounced = useCallback(debounce(setFormValue, 400), [setFormValue]);

  // populate internal state updates
  useDidUpdateEffect(() => {
    setFormValueDebounced(name, serializeValue([scale.invert(value[0]), scale.invert(value[1])], currency));

    return () => {
      setFormValueDebounced.cancel();
    };
  }, [value, name, setFormValueDebounced]);

  // sync external state updates
  useDidUpdateEffect(() => {
    setValue(deserializeValue(formValue, (v, c) => scale(convert(v, { from: c }))));
  }, [setValue, formValue, convert]);

  return (
    <div>
      <InputLabel
        style={{
          marginBlockEnd: 2,
          fontWeight: 'normal',
          fontSize: 12,
          color: grey[600],
        }}
      >
        {t('car.prices.purchasePriceGross')}
      </InputLabel>
      <MuiSlider
        value={value}
        step={step}
        aria-labelledby={name}
        onChange={(_, changeValue) => {
          setValue(changeValue as [number, number]);
        }}
        color="secondary"
        min={0}
        max={100}
        marks={marks}
      />
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <AmountInput<TextFieldProps>
            className={classes.textField}
            customInput={TextField}
            inputProps={{
              step,
              min: minLocalized,
              max: maxLocalized,
              'aria-labelledby': name,
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">{SUPPORTED_CURRENCIES[currency].symbol}</InputAdornment>,
            }}
            margin="dense"
            value={Math.round(scale.invert(value[0]))}
            variant="outlined"
            onChange={(event) => {
              const parsedValue = parseInt(event.target.value?.replaceAll(/\D/g, ''), 10);
              if (Number.isFinite(parsedValue)) {
                setValue((prev: [number, number]) => [scale(parsedValue), prev[1]]);
              }
            }}
            isAllowed={({ value: unformattedValue }) =>
              !unformattedValue || parseInt(unformattedValue, 10) <= maxLocalized
            }
          />
        </Grid>
        <Grid item xs={6}>
          <AmountInput<TextFieldProps>
            className={classes.textField}
            customInput={TextField}
            inputProps={{
              step,
              min: minLocalized,
              max: maxLocalized,
              'aria-labelledby': name,
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">{SUPPORTED_CURRENCIES[currency].symbol}</InputAdornment>,
            }}
            margin="dense"
            variant="outlined"
            value={Math.round(scale.invert(value[1]))}
            onChange={(event) => {
              const parsedValue = parseInt(event.target.value?.replaceAll(/\D/g, ''), 10);
              if (Number.isFinite(parsedValue)) {
                setValue((prev: [number, number]) => [prev[0], scale(parsedValue)]);
              }
            }}
            isAllowed={({ value: unformattedValue }) =>
              !unformattedValue || parseInt(unformattedValue, 10) <= maxLocalized
            }
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default memo(CurrencySliderInput);
