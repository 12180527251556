import { useTranslation } from 'react-i18next';
import { Icon, makeStyles } from '@material-ui/core';
import IconValue from './IconValue';
import { Co2ApproximationMethod } from '../modules/generated/api';
import CarsTableCO2Cell from './CarsTableCO2Cell';

type CarDetailTileCo2Props = {
  approxCo2?: Co2ApproximationMethod;
  engineCo2?: number;
  engineCo2Nedc?: number;
  engineCo2Wltp?: number;
};

const useStyles = makeStyles((theme) => ({
  co2: {
    ...theme.typography.body1,
    fontWeight: theme.typography.fontWeightBold as number,
  },
}));

const Co2Icon = (props: {}) => (
  <Icon {...props}>
    <img
      src="/images/icons/car_repair.svg"
      alt="Icon for CO2 testing method"
      style={{
        width: '1em',
        height: '1em',
      }}
    />
  </Icon>
);

const CarDetailTileCo2 = ({ approxCo2, engineCo2, engineCo2Nedc, engineCo2Wltp }: CarDetailTileCo2Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <IconValue icon={Co2Icon} label={t('car.co2')} padding>
      <div className={classes.co2}>
        <CarsTableCO2Cell
          approxCo2={approxCo2}
          engineCo2={engineCo2}
          engineCo2Nedc={engineCo2Nedc}
          engineCo2Wltp={engineCo2Wltp}
        />
      </div>
    </IconValue>
  );
};

export default CarDetailTileCo2;
