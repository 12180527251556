import { ReactNode } from 'react';
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  InputLabelProps,
  makeStyles,
  MenuItem,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { grey } from '@material-ui/core/colors';
import clsx from 'clsx';
import ClearIcon from '@material-ui/icons/Clear';
import type { Merge } from 'type-fest';
import { isArray, isString } from 'lodash';
import i18n from 'src/setup/i18n';
import { FieldError } from 'react-hook-form';
import { Option, isOptionObj } from '../../modules/i18n-helpers';

export type SelectProps = {
  options: Readonly<SelectOption[]>;
  name: string;
  label?: ReactNode;
  error?: FieldError;
  loading?: boolean;
  size?: 'small' | 'medium';
  InputLabelProps?: InputLabelProps;
  clearable?: boolean;
  onClear?: () => void;
  // FIXME: value typing for multiple true/false
  onChange: (value: any) => void;
  helperText?: ReactNode;
};

export type SelectOption = string | number | Option<number | string>;

const useStyles = makeStyles((theme) => ({
  root: {
    '&:hover .button_clear': {
      visibility: 'visible',
    },
  },
  adornment: {
    position: 'absolute',
    right: 0,
    marginInlineEnd: theme.spacing(4),
  },
  button_clear: {
    visibility: 'hidden',
  },
  input: {
    background: '#fff',

    '&.Mui-focused .button_clear': {
      visibility: 'visible',
    },
  },
  // shrink: true leads to wrong style computation
  inputPatched: {
    '& fieldset legend': {
      maxWidth: 'none',
    },
  },
}));

// TODO: convert to component type
export const renderOption = (option: SelectOption): JSX.Element => {
  const finalValue = isOptionObj(option) ? option.value : option;
  const finalLabel = isOptionObj(option) ? option.label : option;

  return (
    <MenuItem key={finalValue} value={finalValue} {...(isOptionObj(option) ? option.options : {})}>
      {i18n.t(finalLabel as any)}
    </MenuItem>
  );
};

export const Select = ({
  name,
  label,
  options = [],
  error,
  disabled,
  loading,
  className,
  multiple,
  style,
  size = 'small',
  fullWidth = true,
  InputLabelProps: inputLabelProps,
  clearable,
  onChange,
  value,
  onClear,
  helperText,
  ...rest
}: Merge<MuiSelectProps, SelectProps>): JSX.Element => {
  const { t } = useTranslation();
  const labelId = `${name}-label`;
  const classes = useStyles();

  const handleClickClear = () => {
    if (onClear) {
      onClear();
    } else {
      onChange(multiple ? [] : '');
    }
  };
  return (
    <FormControl
      variant="outlined"
      fullWidth={fullWidth}
      size={size}
      error={!!error}
      disabled={disabled}
      className={clsx(className, classes.root)}
      style={style}
    >
      {label ? (
        <InputLabel
          id={labelId}
          style={{
            color: grey[600],
            ...inputLabelProps?.style,
          }}
          {...inputLabelProps}
        >
          {isString(label) ? t(label as any) : label}
        </InputLabel>
      ) : null}
      <MuiSelect
        value={value}
        error={!!error}
        onChange={(event) => onChange(event.target.value as string)}
        className={clsx(classes.input, {
          [classes.inputPatched]: inputLabelProps?.shrink,
        })}
        labelId={labelId}
        label={isString(label) ? t(label as any) : label}
        endAdornment={
          // eslint-disable-next-line no-nested-ternary
          loading ? (
            <InputAdornment className={classes.adornment} position="end">
              <CircularProgress size={20} />
            </InputAdornment>
          ) : clearable && ((isArray(value) && value.length > 0) || (isString(value) && value)) ? (
            <InputAdornment className={clsx('button_clear', classes.button_clear, classes.adornment)} position="end">
              <IconButton size={size} onClick={handleClickClear}>
                <ClearIcon fontSize={size} />
              </IconButton>
            </InputAdornment>
          ) : null
        }
        multiple={multiple}
        {...rest}
      >
        {options.map((option) => renderOption(option))}
      </MuiSelect>
      {/* eslint-disable-next-line no-nested-ternary */}
      {error ? (
        <FormHelperText>{error.message}</FormHelperText>
      ) : helperText ? (
        <FormHelperText>{helperText}</FormHelperText>
      ) : null}
    </FormControl>
  );
};
