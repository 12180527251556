import { memo, MouseEvent, Suspense, useState } from 'react';
import { Button, Divider, makeStyles, Menu, MenuItem, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ArrowDropDown } from '@material-ui/icons';
import clsx from 'clsx';
import LanguageSwitcher from './LanguageSwitcher';
import ValuationCountrySwitcher from './ValuationCountrySwitcher';
import useRole from '../hooks/useRole';
import UserRole from '../types/UserRoles';
import ValuationCountryIcon from './ValuationCountryIcon';
import { getIcon, IconType } from '../modules/data';
import { SUPPORTED_LANGUAGES } from '../modules/i18n-helpers';
import CurrentValuationButton from './CurrentValuationButton';
import { ValuationCountryCode } from '../modules/generated/api';
import { SUPPORTED_COUNTRIES } from '../modules/valuation-country-helpers';
import { CurrencySwitcher } from './CurrencySwitcher';
import { useValuationSettings } from '../hooks/useValuationSettings';

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: theme.spacing(1),
  },
  btn: {
    width: theme.spacing(20),
    marginRight: theme.spacing(8),
  },
  marginLeft: { marginLeft: theme.spacing(1) },
  menu: {
    '& .MuiAutocomplete-root': {
      width: theme.spacing(25),
    },
    '& li': {
      minWidth: theme.spacing(58),
    },
    '&--admin': {
      '& li': {
        minWidth: theme.spacing(29),
      },
    },
  },
}));

export const REGIONS = SUPPORTED_COUNTRIES.filter((rawCountryCode) => rawCountryCode.includes('_')).reduce(
  (prevValue, regionCountryCode) => {
    const [country] = regionCountryCode.split('_');
    if (prevValue[country]) {
      const modifiedRegions = [...prevValue[country], regionCountryCode as ValuationCountryCode];
      return { ...prevValue, [country]: modifiedRegions };
    }
    return { ...prevValue, [country]: [regionCountryCode as ValuationCountryCode] };
  },
  {} as { [key in string]: ValuationCountryCode[] },
);

const LanguageCountrySwitcher = () => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const classes = useStyles();
  const { hasRole } = useRole();
  const {
    i18n: { language },
  } = useTranslation();
  const { valuationCountry, valuationType, setValuationSettings } = useValuationSettings();

  const handleClick = (event: MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button onClick={handleClick} className={classes.btn}>
        <img src={getIcon(IconType.LANGUAGE, language)} alt={SUPPORTED_LANGUAGES[language]} width={18} />
        <Typography className={classes.marginLeft} variant="caption">
          {language}
        </Typography>
        <ArrowDropDown />
        <ValuationCountryIcon valuationCountry={valuationCountry} />
      </Button>
      <Menu
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        className={clsx(classes.menu, `${classes.menu}--admin`)}
      >
        <MenuItem disableRipple style={{ backgroundColor: 'transparent' }}>
          <div>
            <LanguageSwitcher onClose={handleClose} />
          </div>
          {hasRole(UserRole.ADMIN) ||
          (hasRole(UserRole.COUNTRYMANAGER) && valuationCountry && REGIONS[valuationCountry] !== undefined) ? (
            <>
              <Divider orientation="vertical" color="black" flexItem className={classes.divider} />
              <div>
                <ValuationCountrySwitcher
                  rootButton={
                    <CurrentValuationButton valuationType={valuationType} valuationCountry={valuationCountry} />
                  }
                  onOptionSelect={(updatedValuationCountry, updatedValuationType) =>
                    setValuationSettings(updatedValuationCountry, updatedValuationType || null)
                  }
                  onClose={handleClose}
                  highlightSelectedValuation
                />
              </div>
            </>
          ) : null}
          <Divider orientation="vertical" color="black" flexItem className={classes.divider} />
          <div>
            <Suspense fallback={null}>
              <CurrencySwitcher />
            </Suspense>
          </div>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default memo(LanguageCountrySwitcher);
