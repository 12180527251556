import { ReactNode } from 'react';
import { CardHeader, CardHeaderProps, createStyles, makeStyles, Theme, withStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import type { Merge } from 'type-fest';

type TableHeaderProps = {
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
};

const useStyles = makeStyles({
  root: {
    borderBottom: `1px solid ${grey[200]}`,
    background: '#fff',
    padding: 16,
  },
});

const StyledCardHeader = withStyles(
  createStyles((theme: Theme) => ({
    root: {
      padding: 0,
    },
    title: {
      fontSize: '1.125rem',
      fontWeight: 'bold',
      marginBlockEnd: theme.spacing(0.5),
    },
    subheader: {
      fontSize: '0.875rem',
    },
  })),
)(CardHeader);

export const TableHeader = ({
  endAdornment,
  startAdornment,
  style,
  ...rest
}: Merge<CardHeaderProps, TableHeaderProps>): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.root} style={style}>
      {startAdornment}
      <StyledCardHeader {...rest} />
      {endAdornment}
    </div>
  );
};
