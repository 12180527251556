import { IconButton, Tooltip, makeStyles } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryKeys } from 'src/constants';
import { getAccountDealerId } from 'src/modules/bid-buy-helpers';
import { BidDTO, IndividualBidDTO } from '../modules/generated/api';
import { BidSuccessStatus, ListCarDTO } from '../modules/generated/api/api';
import DeleteBidDialog from './DeleteBidDialog';
import IndividualBidTable from './IndividualBidTable';

const useStyles = makeStyles((theme) => ({
  tableCell: {
    width: theme.spacing(50),
    display: 'flex',
    alignItems: 'flex-start',
    overflow: 'auto',
  },
}));

type CarsTableBidsCellProps = {
  bids?: IndividualBidDTO[];
  bidInfos?: BidDTO & ListCarDTO;
};

const CarsTableBidsCell = ({ bids, bidInfos }: CarsTableBidsCellProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [deleteDialog, setDeleteDialog] = useState<BidDTO | undefined>(undefined);
  const queryClient = useQueryClient();

  const onClose = () => setDeleteDialog(undefined);

  const bidDealderId = bids ? bids[0].dealer?.id : undefined;

  const bidsDeletable =
    bidInfos?.successStatus === BidSuccessStatus.Scheduled &&
    bidDealderId &&
    getAccountDealerId()?.includes(bidDealderId); // bids can only be deleted if the bidder is from the same business (same dealer_id)

  function refetch() {
    queryClient.invalidateQueries([QueryKeys.bidsList]);
  }

  return (
    <div className={classes.tableCell}>
      {bids ? (
        <>
          <IndividualBidTable bids={bids} />
          {bidsDeletable && (
            <Tooltip title={`${t('common.bid_plural')} ${t('common.delete')}`}>
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  setDeleteDialog(bidInfos);
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          )}
          {deleteDialog && <DeleteBidDialog bidForDeletion={bidInfos} onClose={onClose} reload={refetch} />}
        </>
      ) : (
        ''
      )}
    </div>
  );
};

export default CarsTableBidsCell;
