import ActionCarCulatorLink from '@components/ActionCarCulatorLink';
import CarsTableValuationCountryIndicator from '@components/CarsTableValuationCountryIndicator';
import ReferenceVehicleButton from '@components/ReferenceVehicleButton';
import { getRelativeIntValue } from '@components/cars-table-next/columns/lib';
import { Stack } from '@components/ui';
import { Grid } from '@material-ui/core';
import { has } from 'lodash';
import { useUser } from 'src/hooks/useUser';
import { ConditionalFormattingType, CountryCode, ValuationCountryCode } from 'src/modules/generated/api';
import { Potential } from 'src/types/potential';
import type { Get } from 'type-fest';
import useRole from '../../../hooks/useRole';
import UserRole from '../../../types/UserRoles';
import { ContentCell, NumericCell, NumericCellFallback, TextCellFallback } from '../cells';
import { ColumnSize } from '../constants';
import { CarsTableNextItem, StrictColumnDef } from '../types';
import { createPotentialColumn } from './common/templates';

export const externalColumns = (enableSorting: boolean = true): StrictColumnDef<CarsTableNextItem>[] => [
  {
    id: 'referenceVehicles',
    cell: ({ row: { original } }) => {
      const { data: user } = useUser();

      return (
        <ContentCell>
          <Stack direction="row" spacing={1} justifyContent="center">
            {user?.dealer?.country === CountryCode.At && (
              <ActionCarCulatorLink
                firstRegistration={original.firstRegistration}
                mileage={original.mileage}
                vin={original.vin}
                layout="vertical"
              />
            )}
            {original.valuation?.numberOfCars && original.valuation.numberOfCars > 0 && (
              <ReferenceVehicleButton
                refCar={original}
                potential={original.potential}
                offerId={original.valuation.offerId}
                valuationCountry={original.valuation.valuationCountry}
              />
            )}
          </Stack>
        </ContentCell>
      );
    },
    meta: {
      align: 'center',
      export: {
        enabled: false,
      },
      fallback: () => <TextCellFallback textWidth="80%" style={{ textAlign: 'center' }} />,
      isExternalColumn: true,
    },
    enableSorting: false,
  },
  {
    id: 'marketPrice',
    accessorFn: (car) => car.valuation?.price?.mean,
    cell: ({ getValue, row: { original } }) => {
      const { hasRole } = useRole();
      const valuationCountry = original?.potential?.country;
      return (
        <ContentCell>
          {hasRole(UserRole.ADMIN) && valuationCountry && (
            <CarsTableValuationCountryIndicator valuationCountry={valuationCountry} />
          )}
          <NumericCell value={getValue<Get<CarsTableNextItem, 'valuation.price.mean'>>() ?? '-'} />
        </ContentCell>
      );
    },
    meta: {
      isNumeric: true,
      fallback: () => <NumericCellFallback textWidth="80%" />,
      isExternalColumn: true,
      conditionalFormat: ConditionalFormattingType.ValuationMarketPrice,
    },
    enableSorting,
  },
  {
    id: 'marketPriceDE',
    accessorFn: (car) => car.valuations?.DE?.price?.mean,
    cell: ({ getValue }) => {
      const valuationCountry = ValuationCountryCode.De;
      return (
        <ContentCell>
          {valuationCountry && <CarsTableValuationCountryIndicator valuationCountry={valuationCountry} />}
          <NumericCell value={getValue<Get<CarsTableNextItem, 'valuations.DE.price.mean'>>() ?? '-'} />
        </ContentCell>
      );
    },
    meta: {
      isNumeric: true,
      fallback: () => <NumericCellFallback textWidth="80%" />,
      isExternalColumn: true,
      export: {
        enabled: false,
      },
    },
    enableSorting,
  },
  {
    id: 'referenceVehiclesDE',
    cell: ({ row: { original } }) => (
      <ContentCell>
        <CarsTableValuationCountryIndicator valuationCountry={ValuationCountryCode.De} />
        <Grid container justifyContent="center">
          {has(original.valuations?.DE, 'numberOfCars') && original.valuations?.DE.numberOfCars! > 0 && (
            <Grid item>
              <ReferenceVehicleButton
                refCar={original}
                potential={original.potentials?.DE}
                offerId={original.valuations?.DE.offerId}
                valuationCountry={original.valuations?.DE.valuationCountry}
              />
            </Grid>
          )}
        </Grid>
      </ContentCell>
    ),
    meta: {
      align: 'center',
      export: {
        enabled: false,
      },
      fallback: () => <TextCellFallback textWidth="80%" style={{ textAlign: 'center' }} />,
      isExternalColumn: true,
    },
    size: ColumnSize.xs,
    enableSorting: false,
  },
  createPotentialColumn(
    Potential.potentialMeanAbsolute,
    {
      isExternalColumn: true,
      conditionalFormat: ConditionalFormattingType.PotentialInternetPriceAverageAbsolute,
    },
    enableSorting,
  ),
  createPotentialColumn(
    Potential.potentialMeanRelative,
    {
      isExternalColumn: true,
      conditionalFormat: {
        key: ConditionalFormattingType.PotentialInternetPriceAveragePercent,
        getValue: ({ getValue }) =>
          getRelativeIntValue(getValue<Get<CarsTableNextItem, 'potential.potentialMeanRelative'>>()),
      },
    },
    enableSorting,
  ),
  createPotentialColumn(
    Potential.potentialMinAbsolute,
    {
      isExternalColumn: true,
      conditionalFormat: ConditionalFormattingType.PotentialInternetPriceMinAbsolute,
    },
    enableSorting,
  ),
  createPotentialColumn(
    Potential.potentialMinRelative,
    {
      isExternalColumn: true,
      conditionalFormat: {
        key: ConditionalFormattingType.PotentialInternetPriceMinPercent,
        getValue: ({ getValue }) =>
          getRelativeIntValue(getValue<Get<CarsTableNextItem, 'potential.potentialMinRelative'>>()),
      },
    },
    enableSorting,
  ),
  createPotentialColumn(
    Potential.potentialMinMeanAbsolute,
    {
      columnVisible: false,
      isExternalColumn: true,
      conditionalFormat: ConditionalFormattingType.PotentialInternetPriceMinAverageAbsolute,
    },
    enableSorting,
  ),
  createPotentialColumn(
    Potential.potentialMinMeanRelative,
    {
      columnVisible: false,
      isExternalColumn: true,
      conditionalFormat: {
        key: ConditionalFormattingType.PotentialInternetPriceMinAveragePercent,
        getValue: ({ getValue }) =>
          getRelativeIntValue(getValue<Get<CarsTableNextItem, 'potential.potentialMinMeanRelative'>>()),
      },
    },
    enableSorting,
  ),
];
