import { Autocomplete } from '@components/cars-table-next/filter/common/Autocomplete';
import { Drawer, DrawerContent, DrawerFooter, DrawerHeader, InputLabel, Stack } from '@components/ui';
import { Address } from '@components/ui/address/types';
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  DrawerProps as MuiDrawerProps,
  OutlinedInput,
  Typography,
} from '@material-ui/core';
import countries from 'i18n-iso-countries';
import React, { useImperativeHandle } from 'react';
import { Controller, useForm, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DELIVERY_ADDRESS_VALIDATION } from 'src/modules/global-vars';
import type { Merge } from 'type-fest';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { getIcon, IconType, SHIPPING_COUNTRIES_COUNTRY_CODES } from '../../../modules/data';
import i18n from '../../../setup/i18n';

type AddressFormData = Address;

type AddressDrawerProps = {
  title: string;
  onClose: () => void;
  onSubmit: (data: AddressFormData) => void;
  address?: Address;
  form?: React.Ref<FormInstance | undefined>;
};

export type FormInstance = UseFormReturn<AddressFormData>;

export const AddressDrawer = ({
  onClose,
  onSubmit,
  title,
  address: originalAddress = {
    name: '',
    companyName: '',
    street: '',
    zip: '',
    city: '',
    country: '',
    countryCode: '',
    email: '',
    phoneNumber: '',
    faxNumber: '',
  },
  form: formRef,
  ...drawerProps
}: Merge<MuiDrawerProps, AddressDrawerProps>) => {
  const { t } = useTranslation();
  const form = useForm<AddressFormData>({
    defaultValues: originalAddress,
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = form;

  const handleClose = () => {
    onClose();
    reset(originalAddress);
  };

  // sync external state updates
  useDeepCompareEffect(() => {
    reset(originalAddress);
  }, [originalAddress, reset]);

  useImperativeHandle(formRef, () => form);

  const validationValues = DELIVERY_ADDRESS_VALIDATION;

  return (
    <Drawer anchor="right" onClose={handleClose} {...drawerProps}>
      <DrawerHeader>
        <Typography>{title}</Typography>
      </DrawerHeader>
      <DrawerContent>
        <form id="address-form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3}>
            <FormControl size="small" fullWidth variant="outlined">
              <InputLabel required>{t('contact.name')}</InputLabel>
              <Controller
                name="name"
                control={control}
                rules={{
                  required: { value: true, message: t('validation.required') },
                  maxLength: {
                    value: validationValues.name.maxLength,
                    message: t('validation.maxLength', { count: validationValues.name.maxLength }),
                  },
                }}
                render={({ field: { ref, ...field } }) => <OutlinedInput inputRef={ref} {...field} />}
              />
              {errors.name ? <FormHelperText error>{errors.name.message || errors.name.type}</FormHelperText> : null}
            </FormControl>

            <FormControl size="small" fullWidth variant="outlined">
              <InputLabel required>{t('contact.companyName')}</InputLabel>
              <Controller
                name="companyName"
                control={control}
                rules={{
                  required: { value: true, message: t('validation.required') },
                  maxLength: {
                    value: validationValues.companyName.maxLength,
                    message: t('validation.maxLength', { count: validationValues.companyName.maxLength }),
                  },
                }}
                render={({ field: { ref, ...field } }) => <OutlinedInput inputRef={ref} {...field} />}
              />
              {errors.companyName ? (
                <FormHelperText error>{errors.companyName.message || errors.companyName.type}</FormHelperText>
              ) : null}
            </FormControl>

            <FormControl size="small" fullWidth variant="outlined">
              <InputLabel required>{t('contact.street')}</InputLabel>
              <Controller
                name="street"
                control={control}
                rules={{
                  required: { value: true, message: t('validation.required') },
                  maxLength: {
                    value: validationValues.street.maxLength,
                    message: t('validation.maxLength', { count: validationValues.street.maxLength }),
                  },
                }}
                render={({ field: { ref, ...field } }) => <OutlinedInput inputRef={ref} {...field} />}
              />
              {errors.street ? (
                <FormHelperText error>{errors.street.message || errors.street.type}</FormHelperText>
              ) : null}
            </FormControl>

            <FormControl size="small" fullWidth variant="outlined">
              <InputLabel required>{t('contact.city')}</InputLabel>
              <Controller
                name="city"
                control={control}
                rules={{
                  required: { value: true, message: t('validation.required') },
                  maxLength: {
                    value: validationValues.city.maxLength,
                    message: t('validation.maxLength', { count: validationValues.city.maxLength }),
                  },
                }}
                render={({ field: { ref, ...field } }) => <OutlinedInput inputRef={ref} {...field} />}
              />
              {errors.city ? <FormHelperText error>{errors.city.message || errors.city.type}</FormHelperText> : null}
            </FormControl>

            <FormControl size="small" fullWidth variant="outlined">
              <InputLabel required>{t('contact.postal')}</InputLabel>
              <Controller
                name="zip"
                control={control}
                rules={{
                  required: { value: true, message: t('validation.required') },
                  maxLength: {
                    value: validationValues.zip.maxLength,
                    message: t('validation.maxLength', { count: validationValues.zip.maxLength }),
                  },
                }}
                render={({ field: { ref, ...field } }) => <OutlinedInput inputRef={ref} {...field} />}
              />
              {errors.zip ? <FormHelperText error>{errors.zip.message || errors.zip.type}</FormHelperText> : null}
            </FormControl>

            <FormControl size="small" fullWidth variant="outlined">
              <InputLabel required>{t('contact.country')}</InputLabel>
              <Controller
                name="countryCode"
                control={control}
                rules={{
                  required: { value: true, message: t('validation.required') },
                }}
                render={({ field: { ref: _ref, ...field } }) => (
                  <Autocomplete
                    {...field}
                    optionIcon={(option) => getIcon(IconType.COUNTRY, option)}
                    error={!!errors.countryCode}
                    helperText={errors.countryCode && errors.countryCode.message}
                    label={t('contact.country')}
                    options={SHIPPING_COUNTRIES_COUNTRY_CODES}
                    getOptionLabel={(option: string) => (countries.getName(option, i18n.language) as string) || option}
                    disableCloseOnSelect={false}
                    onChange={(value) => {
                      setValue('countryCode', value as any); // Overwrites default behaviour
                      setValue(
                        'country',
                        value && !Array.isArray(value) ? (countries.getName(value, i18n.language) as string) : '',
                      );
                    }}
                  />
                )}
              />
            </FormControl>

            <FormControl size="small" fullWidth variant="outlined">
              <InputLabel required>{t('contact.E-Mail')}</InputLabel>
              <Controller
                name="email"
                control={control}
                rules={{
                  required: { value: true, message: t('validation.required') },
                  maxLength: {
                    value: validationValues.email.maxLength,
                    message: t('validation.maxLength', { count: validationValues.email.maxLength }),
                  },
                }}
                render={({ field: { ref, ...field } }) => <OutlinedInput inputRef={ref} {...field} />}
              />
              {errors.email ? <FormHelperText error>{errors.email.message || errors.email.type}</FormHelperText> : null}
            </FormControl>

            <FormControl size="small" fullWidth variant="outlined">
              <InputLabel required>{t('contact.phone')}</InputLabel>
              <Controller
                name="phoneNumber"
                control={control}
                rules={{
                  required: { value: true, message: t('validation.required') },
                  maxLength: {
                    value: validationValues.phoneNumber.maxLength,
                    message: t('validation.maxLength', { count: validationValues.phoneNumber.maxLength }),
                  },
                }}
                render={({ field: { ref, ...field } }) => <OutlinedInput inputRef={ref} {...field} />}
              />
              {errors.phoneNumber ? (
                <FormHelperText error>{errors.phoneNumber.message || errors.phoneNumber.type}</FormHelperText>
              ) : null}
            </FormControl>

            <FormControl size="small" fullWidth variant="outlined">
              <InputLabel>{t('contact.fax')}</InputLabel>
              <Controller
                name="faxNumber"
                control={control}
                render={({ field: { ref, ...field } }) => <OutlinedInput inputRef={ref} {...field} />}
              />
              {errors.faxNumber ? (
                <FormHelperText error>{errors.faxNumber.message || errors.faxNumber.type}</FormHelperText>
              ) : null}
            </FormControl>
          </Stack>
        </form>
      </DrawerContent>
      <DrawerFooter>
        <Grid container justifyContent="space-between">
          <Button size="small" variant="outlined" color="secondary" onClick={handleClose}>
            {t('common.cancel')}
          </Button>
          <Button type="submit" form="address-form" size="small" variant="contained" color="primary">
            {t('common.save')}
          </Button>
        </Grid>
      </DrawerFooter>
    </Drawer>
  );
};
