import { ReactElement } from 'react';
import { Checkbox, makeStyles } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { IndeterminateCheckBoxOutlined } from '@material-ui/icons';
import { getIcon, IconType } from '../modules/data';

type AutocompleteItemProps = {
  checked?: boolean;
  iconType?: IconType;
  value: string;
  alt?: string;
  IconComponent?: ({ value }: { value: string }) => ReactElement;
  label: string;
  hideCheckbox?: boolean;
  indeterminate?: boolean;
};

const useStyles = makeStyles((theme) => ({
  checkbox: { marginRight: theme.spacing(1), padding: 0 },
  iconImage: { display: 'inline-block', width: 20, height: 'auto', marginRight: theme.spacing(1) },
}));

const AutocompleteItem = ({
  checked = false,
  iconType,
  value,
  alt,
  IconComponent,
  label,
  hideCheckbox,
  indeterminate = false,
}: AutocompleteItemProps) => {
  const classes = useStyles();
  return (
    <>
      {!hideCheckbox && (
        <Checkbox
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          className={classes.checkbox}
          checked={checked}
          indeterminate={indeterminate}
          indeterminateIcon={<IndeterminateCheckBoxOutlined fontSize="small" />}
        />
      )}
      {iconType && <img src={getIcon(iconType, value)} alt={alt} className={classes.iconImage} />}
      {IconComponent && <IconComponent value={value} />}
      {` ${label}`}
    </>
  );
};

export default AutocompleteItem;
