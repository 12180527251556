import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { IconButton, Link, makeStyles, Tooltip, Typography } from '@material-ui/core';
import { Help } from '@material-ui/icons';
import DialogWithClose from '../components/DialogWithClose';
import mail from '../modules/global-vars';

type SupportPopupProps = {
  className: string;
};

const useStyles = makeStyles((theme) => ({
  hover: {
    '&:hover, &.Mui-focusVisible': {
      color: theme.palette.primary.light,
    },
  },
  helpIcon: {
    fontSize: '1.3rem',
  },
}));

const SupportPopup = ({ className }: SupportPopupProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <>
      <IconButton
        onClick={() => setOpen(!open)}
        className={className}
        classes={{
          root: classes.hover,
        }}
      >
        <Tooltip title={t('navigation.support')}>
          <Help className={classes.helpIcon} />
        </Tooltip>
      </IconButton>
      <DialogWithClose
        open={open}
        onClose={() => setOpen(false)}
        title={<Typography variant="h2"> {t('navigation.support')}</Typography>}
        paddingType="noTop"
        small
      >
        <Typography variant="body1">
          <Trans t={t} i18nKey="navigation.supportText" /> <br />
          {t('contact.E-Mail')}: <Link href={`mailto:${mail}`}>irisscout@porsche.co.at</Link>
          <br />
          {t('contact.phone')}: <Link href="tel:+4366246817385">+43 662 468 173 85</Link>
        </Typography>
      </DialogWithClose>
    </>
  );
};

export default SupportPopup;
