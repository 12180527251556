import { IconButton, makeStyles, Popover } from '@material-ui/core';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import FilterListIcon from '@material-ui/icons/FilterList';
import { FilterDef } from '../filter/types';
import { useFilter } from '../filter/useFilter';

type HeaderFilterCellProps = {
  filterDefs: FilterDef[];
};

const useStyles = makeStyles((theme) => ({
  button: {
    position: 'absolute',
    top: '40px',
    left: '0',
  },
  popover: {
    margin: theme.spacing(2),
    width: '250px',
  },
  separator: {
    '&:first-of-type': {
      paddingTop: 0,
    },
    paddingTop: theme.spacing(2),
  },
}));

const HeaderFilterCell = ({ filterDefs }: HeaderFilterCellProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { control, onFieldChange } = useFilter();
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleClick} size="small" className={classes.button}>
        <FilterListIcon />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={classes.popover}>
          {filterDefs.map((filter) => {
            const { filter: Filter, label, name } = filter;
            return (
              <div className={classes.separator} key={name}>
                <Controller
                  control={control}
                  name={name as any}
                  render={({ field }) => (
                    <Filter
                      control={control}
                      field={{
                        ...field,
                        label,
                        ref: () => {},
                        onChange: (newValue) => onFieldChange(field, newValue),
                      }}
                    />
                  )}
                />
              </div>
            );
          })}
        </div>
      </Popover>
    </>
  );
};

export default HeaderFilterCell;
