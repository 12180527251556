import jwtDecode from 'jwt-decode';
import useRole from '../hooks/useRole';
import { getAccessTokenFromStorage, GwscoutJwtPayload } from '../stores/Authentication';
import UserRole from '../types/UserRoles';
import { PriceType, ValuationCountryCode } from './generated/api';

export const getAccountValuationCountry = (mainCountry?: boolean): ValuationCountryCode => {
  const accessToken = getAccessTokenFromStorage();
  if (accessToken) {
    const token = jwtDecode<GwscoutJwtPayload>(accessToken);

    if (token && token.valuation_country !== undefined) {
      if (mainCountry) return token.valuation_country.split('_')[0] as ValuationCountryCode;
      return token.valuation_country;
    }
  }
  return ValuationCountryCode.AtLot;
};

export const SUPPORTED_COUNTRIES = [...Object.values(ValuationCountryCode)];

export const VALUATION_COUNTRIES_PURE = () => {
  const { hasRole } = useRole();
  if (hasRole(UserRole.COUNTRYMANAGER))
    return SUPPORTED_COUNTRIES.filter(
      (valuationCountryCode) => valuationCountryCode === getAccountValuationCountry(),
    ) as string[];
  return SUPPORTED_COUNTRIES.filter((valuationCountryCode) => !valuationCountryCode.includes('_')) as string[];
};

export const REGIONS = SUPPORTED_COUNTRIES.filter((rawCountryCode) => rawCountryCode.includes('_')).reduce(
  (prevValue, regionCountryCode) => {
    const [country] = regionCountryCode.split('_');
    if (prevValue[country]) {
      const modifiedRegions = [...prevValue[country], regionCountryCode as ValuationCountryCode];
      return { ...prevValue, [country]: modifiedRegions };
    }
    return { ...prevValue, [country]: [regionCountryCode as ValuationCountryCode] };
  },
  {} as { [key in string]: ValuationCountryCode[] },
);

export const getBaseCountry = (valuationCountry: ValuationCountryCode) =>
  valuationCountry && valuationCountry.includes('_') ? valuationCountry.split('_')[0] : valuationCountry;

type PriceTypeKey = keyof typeof PriceType;
type PriceTypeContents = keyof { [M in keyof typeof PriceType as `${(typeof PriceType)[M]}`]: M };

export const PRICE_TYPE_INVERTED = new Map<PriceTypeContents, PriceTypeKey>(
  // @ts-ignore
  Object.entries(PriceType).map(([key, value]: [PriceTypeKey, PriceType]) => [value, key]),
);

export const isValuationValid = (
  valuationCountry: ValuationCountryCode | null | undefined,
  valuationType: PriceType | null | undefined,
) => {
  const bothFalsy = !valuationCountry && !valuationType;
  const bothTruthy = valuationCountry && valuationType;
  return !(bothFalsy || bothTruthy);
};
