import { FormControl, FormGroup, FormLabel, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import BooleanCheckbox from './BooleanCheckbox';
import UserRatingFilter from './UserRatingFilter';

const useStyles = makeStyles(() => ({
  formGroup: {
    flexDirection: 'row',
  },
  break: {
    maxWidth: '12vw',
  },
}));
type UserInformationCheckboxesType = {
  formLabel: string;
  type: 'own' | 'any';
};

const UserInformationCheckboxes = ({ type, formLabel }: UserInformationCheckboxesType) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <FormControl component="fieldset" margin="dense" fullWidth>
      <FormLabel component="legend" className={clsx(type === 'own' && classes.break)}>
        {formLabel}
      </FormLabel>
      <FormGroup className={classes.formGroup}>
        <BooleanCheckbox
          label={t('carsTable.comments')}
          name={type === 'own' ? 'hasUserComment' : 'hasAnyOtherUserComment'}
        />
        <UserRatingFilter name={type === 'own' ? 'ratingMin' : 'anyOtherUserRatingMin'} type={type} />
      </FormGroup>
    </FormControl>
  );
};

export default memo(UserInformationCheckboxes);
