import { DependencyList, EffectCallback, useRef } from 'react';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';

export const useDidUpdateEffect = (fn: EffectCallback, inputs: DependencyList) => {
  const didMountRef = useRef(false);

  // eslint-disable-next-line consistent-return
  useDeepCompareEffectNoCheck(() => {
    if (didMountRef.current) {
      return fn();
    }
    didMountRef.current = true;
  }, inputs);
};
