import { ColumnMeta } from '@tanstack/react-table';
import { get } from 'lodash';
import { MonetaryAmount } from 'src/modules/generated/api';
import { Potential } from 'src/types/potential';
import { NumericCell, NumericCellFallback } from '../../cells';
import { ColumnSize } from '../../constants';
import { percentFormat } from '../../format';
import type { CarsTableNextItem, StrictColumnDef } from '../../types';

export const createPotentialColumn = (
  potential: Potential,
  meta?: ColumnMeta<CarsTableNextItem, unknown>,
  enableSorting: boolean = true,
): StrictColumnDef<CarsTableNextItem> => ({
  id: `potential.${potential}`,
  accessorFn: (car) => get(car, ['potential', potential]),
  cell: (props) => (
    <NumericCell
      value={props.getValue<MonetaryAmount | number | undefined>() ?? '-'}
      // format only receives percentage formats as MonetaryAmounts are resolved by NumericCell
      format={percentFormat.format}
    />
  ),
  size: ColumnSize.xs,
  meta: {
    isNumeric: true,
    fallback: () => <NumericCellFallback textWidth="80%" />,
    sortKey: potential,
    ...meta,
  },
  enableSorting,
});
