import { getDefaultFilter, transformForForm, transformForSubmit } from '@components/cars-table-next/filter/lib';
import { useQuery } from '@tanstack/react-query';
import { merge } from 'lodash';
import { QueryKeys } from 'src/constants';
import { useSearchAgent } from 'src/hooks/useSearchAgent';
import ApiService from 'src/modules/api-service';
import { useSources } from './useSources';
import { useUser } from './useUser';

export const useSearchAgentPreview = (id: string | undefined) => {
  const searchAgent = useSearchAgent(id);
  const { data: sources } = useSources();
  const { data: user } = useUser();

  const query = useQuery(
    [QueryKeys.searchAgentPreview, id],
    () =>
      ApiService.listCars
        .listCarsControllerList(
          {
            length: 0,
            ...transformForSubmit(
              merge({}, getDefaultFilter({ sources, user }), searchAgent && transformForForm(searchAgent)),
            ),
          },
          2,
        )
        .then((res) => res.data),
    {
      enabled: Boolean(searchAgent),
    },
  );

  return query;
};
