import LuxonUtils from '@date-io/luxon';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { LocationProvider } from '@reach/router';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { ErrorBoundary } from 'react-error-boundary';
import App from './App';
import { AppErrorFallback, handleAppError } from './components/AppErrorFallback';
import reportWebVitals from './reportWebVitals';
import './setup/i18n';
import theme from './setup/theme';
import AuthenticationProvider from './stores/Authentication';

ReactDOM.render(
  <StrictMode>
    <AuthenticationProvider>
      <MuiPickersUtilsProvider utils={LuxonUtils} locale="de">
        <LocationProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <ErrorBoundary FallbackComponent={AppErrorFallback} onError={handleAppError}>
              <App />
            </ErrorBoundary>
          </ThemeProvider>
        </LocationProvider>
      </MuiPickersUtilsProvider>
    </AuthenticationProvider>
  </StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
