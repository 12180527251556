import DialogWithClose from '@components/DialogWithClose';
import { Grid, Typography } from '@material-ui/core';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import BidsUploadForm from '../components/BidsUploadForm';
import BidsUploadPreview from '../components/BidsUploadPreview';
import { DrawerContent, DrawerHeader, DrawerStatic } from '../components/ui/drawer/Drawer';
import ApiService from '../modules/api-service';
import { CSVBidRequestDTO } from '../modules/generated/api';

type BidsUploadProps = {
  refetchLists: () => void;
};

const BidsUpload = ({ refetchLists }: BidsUploadProps): JSX.Element => {
  const { t } = useTranslation();
  const [previewBidsData, setPreviewBidsData] = useState<
    { data: CSVBidRequestDTO[]; recordsTotal: number } | undefined
  >();
  const [previewOpen, setPreviewOpen] = useState(false);
  const methods = useForm<{ file?: FileList; secondsBeforeEnd: string }>({
    defaultValues: {
      file: undefined,
      secondsBeforeEnd: '60',
    },
  });
  const { handleSubmit: handleSubmitForm, reset } = methods;

  const handleSubmit = (simulate: boolean) =>
    handleSubmitForm(async ({ secondsBeforeEnd, file }) => {
      if (file) {
        const result = await ApiService.newBids.bidNewControllerHandleFileUpload(
          file[0],
          simulate,
          Number.parseInt(secondsBeforeEnd, 10),
        );

        if (simulate) {
          setPreviewBidsData({ data: result.data, recordsTotal: result.data.length });
          setPreviewOpen(true);
        } else {
          setPreviewBidsData(undefined);
          reset();
          refetchLists();
          setPreviewOpen(false);
        }
      }
    });
  const handleClear = () => {
    setPreviewBidsData(undefined);
    setPreviewOpen(false);
  };

  return (
    <DrawerStatic>
      <DrawerHeader>
        <Grid container alignItems="center" justifyContent="space-between" spacing={4}>
          <Grid item>
            <Typography style={{ fontWeight: 'bold' }}>{t('common.upload')}</Typography>
          </Grid>
        </Grid>
      </DrawerHeader>
      <DrawerContent>
        <Grid
          container
          justifyContent="space-between"
          style={{
            marginBlockEnd: 8,
          }}
        >
          <Grid item>
            <FormProvider {...methods}>
              <BidsUploadForm onSubmit={handleSubmit(true)} />

              <DialogWithClose open={previewOpen} onClose={() => setPreviewOpen(false)}>
                <BidsUploadPreview
                  bids={previewBidsData?.data}
                  recordsTotal={previewBidsData?.recordsTotal}
                  onClear={handleClear}
                  onSubmit={handleSubmit(false)}
                />
              </DialogWithClose>
            </FormProvider>
          </Grid>
        </Grid>
      </DrawerContent>
    </DrawerStatic>
  );
};

export default BidsUpload;
