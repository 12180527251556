import {
  createContext,
  createElement,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { useCurrency } from './useCurrency';
import { ListCarDTO, PriceType, SourceType, ValuationCountryCode } from '../modules/generated/api';
import ApiService from '../modules/api-service';
import { setPotentialCalculations } from '../modules/table-helpers';
import { MonetaryAmountStrict } from '../modules/currency';
import { useCarsTableStore } from '../stores/CarsTable';
import { TableCalculatorFormData } from '../types/TableCalculatorFormData';

type TableCalculatorCtx = {
  isEdit: (calcCar: CalculatorCarType) => boolean;
  handleOnEdit: (calcCar: CalculatorCarType) => void;
  resetEditCars: () => void;
  handleOnEditMultiple: (calcCar: CalculatorCarType[]) => void;
  reComputeEveryEditCar: (calcCars: CalculatorCarType[] | null, calculatorPriceStrategy?: PriceType) => void;
  toCalcCar: (car: ListCarDtoSafe) => CalculatorCarType;
  toggleFixed: (calcCar: ListCarDTO) => void;
  isFixed: (car: ListCarDTO) => boolean;
  aggregatedColumnValues: { [key in keyof PriceType]?: MonetaryAmountStrict | number | null };
  calcCarPotential: (
    calcCar: CalculatorCarType,
    calculatorPriceStrategy?: PriceType,
    fixedValue?: number,
    triggerAggregation?: boolean,
  ) => Promise<void>;
  isRelative: (priceType: PriceType) => boolean;
  loading: boolean;
};

type TableCalculatorProviderProps = {
  children: ReactNode;
};

export const tableCalculatorCtx = createContext<TableCalculatorCtx>(undefined!);

export type ListCarDtoSafe = ListCarDTO & { carId: string; source: SourceType };

export type CalculatorCarType = {
  carId: string;
  source: SourceType;
  valuationCountry?: ValuationCountryCode;
};

export const TableCalculatorProvider = ({ children }: TableCalculatorProviderProps): ReactElement => {
  const { currency, convert } = useCurrency();
  const [aggregatedColumnValues, setAggregatedColumnValues] = useState<{
    [key in keyof PriceType]?: MonetaryAmountStrict | number | null;
  }>({});
  const [editCars, setEditCars] = useState<CalculatorCarType[]>([]);
  const [fixedCars, setFixedCars] = useState<Set<string>>(new Set());
  const [loading, setLoading] = useState(false);
  const { packageMode } = useCarsTableStore();
  const { getValues, setValue, reset } = useFormContext<TableCalculatorFormData>();

  const isRelative = (potentialPriceType: PriceType) => potentialPriceType?.includes('RELATIVE');

  const handleAggregationAndStopLoading = useCallback(() => {
    if (!packageMode) {
      setLoading(false);
      return;
    }
    setLoading(true);
    let newAggregatedValues: { [key in keyof PriceType]?: MonetaryAmountStrict | number | null } = {};
    Object.entries(getValues()).forEach(([priceType, carInputs]) => {
      if (!carInputs) return;
      let n = 0;

      const sum = Object.entries(carInputs).reduce((prevValue: number | null, [, value]) => {
        n += 1;
        if (value === undefined || prevValue === null) return null;
        if (typeof value === 'object') return prevValue + (value?.amount || 0);

        return prevValue + value;
      }, 0);

      if (sum === null) {
        newAggregatedValues = {
          ...newAggregatedValues,
          [priceType]: null,
        };
      } else {
        newAggregatedValues = {
          ...newAggregatedValues,
          [priceType]: isRelative(priceType as PriceType)
            ? sum / (n || 1)
            : {
                amount: sum,
                currency,
              },
        };
      }
    });
    setAggregatedColumnValues(newAggregatedValues);
    setLoading(false);
  }, [currency, getValues, packageMode]);

  const calcCarPotential = async (
    calcCar: CalculatorCarType,
    calculatorPriceStrategy: PriceType = PriceType.PotentialMinRelative,
    fixedValue: number = 0,
    triggerAggregation: boolean = false,
  ) => {
    if (triggerAggregation) setLoading(true);
    const calculations = await ApiService.calcPrice.priceCalculationControllerCalculatePriceV2(
      calcCar.source,
      calcCar.carId,
      calculatorPriceStrategy,
      fixedValue,
      currency,
      calcCar.valuationCountry,
    );

    setPotentialCalculations(calculations.data, calcCar.carId, setValue, (val, to) => convert(val, { to }));
    if (triggerAggregation) handleAggregationAndStopLoading();
  };

  const handleOnEdit = (calcCar: CalculatorCarType) => {
    setEditCars((prevState) => [...prevState, calcCar]);
    if (packageMode) setLoading(true);
    calcCarPotential(calcCar).then(handleAggregationAndStopLoading);
  };

  const toCarId = (calcCar: CalculatorCarType) => `${calcCar.source}/${calcCar.carId}`;

  const toCalcCar = (car: ListCarDtoSafe) => ({
    carId: car.carId,
    source: car.source,
    valuationCountry: car.potential?.country,
  });

  const reComputeEveryEditCar = (calcCars: CalculatorCarType[] | null, calculatorPriceStrategy?: PriceType) => {
    if (packageMode) setLoading(true);
    const allPromises = (calcCars || editCars)
      .filter((calcCar) => !fixedCars.has(toCarId(calcCar)))
      .map((calcCar) => calcCarPotential(calcCar, calculatorPriceStrategy));
    Promise.all(allPromises).then(handleAggregationAndStopLoading);
  };

  const handleOnEditMultiple = (calcCars: CalculatorCarType[]) => {
    setEditCars(calcCars);
    setFixedCars(new Set());
    reComputeEveryEditCar(calcCars);
  };

  const toggleFixed = (car: ListCarDTO) => {
    if (!car.source || !car.carId) return;
    const calcCar = toCalcCar(car as ListCarDtoSafe);
    setFixedCars((prevFixedCars) => {
      const newDeselect = new Set(prevFixedCars);
      const id = toCarId(calcCar);
      if (newDeselect.has(id)) newDeselect.delete(id);
      else newDeselect.add(id);
      return newDeselect;
    });
  };

  const isFixed = (car: ListCarDTO) => {
    const calcCar = toCalcCar(car as ListCarDtoSafe);
    return fixedCars.has(toCarId(calcCar));
  };

  const resetEditCars = useCallback(() => {
    setEditCars([]);
    reset();
    setAggregatedColumnValues({});
  }, [reset]);

  const isEdit = (calcCar: CalculatorCarType) =>
    editCars.some((someCalcCar) => calcCar.carId === someCalcCar.carId && calcCar.source === someCalcCar.source);

  useEffect(() => {
    reComputeEveryEditCar(editCars);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency]);

  const value = {
    isEdit,
    handleOnEdit,
    resetEditCars,
    handleOnEditMultiple,
    toggleFixed,
    reComputeEveryEditCar,
    toCalcCar,
    isFixed,
    calcCarPotential,
    aggregatedColumnValues,
    isRelative,
    loading,
  };

  return createElement(tableCalculatorCtx.Provider, { value }, children);
};

export const useTableCalculator = () => useContext(tableCalculatorCtx);
