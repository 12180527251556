import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { validityTypes } from '../modules/labels';
import FilterAutocomplete from './FilterAutocomplete';

const ValiditySelect = () => {
  const { t } = useTranslation();

  return (
    <FilterAutocomplete
      disableClearable
      label={t('filterSearch.offerStatus')}
      name="validity"
      options={validityTypes}
    />
  );
};

export default memo(ValiditySelect);
