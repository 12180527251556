import { IconButton } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import { blue, green, grey, lightGreen, orange, red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import TimelineIcon from '@material-ui/icons/Timeline';
import clsx from 'clsx';
import { isNumber, min, round } from 'lodash';
import { useState } from 'react';
import { Trans } from 'react-i18next';
import { useValuationSettings } from 'src/hooks/useValuationSettings';
import { getCountryCodeFromValuationCountry } from 'src/modules/country-helper';
import { DetailCarPreviousAuctionDTO, MarketingChannel, MonetaryAmount } from 'src/modules/generated/api';
import { TrendChart } from './TrendChart';

export type TrendCellProps = {
  prevAuctions: Array<DetailCarPreviousAuctionDTO>;
  currPrice?: number;
  potential?: Array<MonetaryAmount>;
  marketingChannel: MarketingChannel[];
};

const useStyles = makeStyles({
  styling: {
    position: 'absolute',
    left: '40px',
    bottom: '7.5px',
    '&--blue': {
      color: blue[900],
      backgroundColor: blue[100],
    },
    '&--green': {
      color: green[900],
      backgroundColor: lightGreen[200],
    },
    '&--red': {
      color: red[900],
      backgroundColor: red[100],
    },
  },

  btn: {
    position: 'absolute',
    left: '5px',
    bottom: '5px',
    zIndex: 1,
    color: grey[900],
  },

  btnDiagramOff: {
    color: orange[900],
  },
});

export const TrendCell = ({ prevAuctions, potential, marketingChannel }: TrendCellProps) => {
  const classes = useStyles();
  const { valuationCountry: globalValuationCountry } = useValuationSettings();
  const countryCode = getCountryCodeFromValuationCountry(globalValuationCountry);

  const [showLines, setShowLines] = useState(false);

  const handleClick = () => {
    setShowLines(!showLines);
  };

  const relevantAuctions = prevAuctions.filter(
    (auction) => auction.offerType === 'INDIVIDUAL' && auction.visibleForCountry?.includes(countryCode),
  );
  const auctionPrices = relevantAuctions.map((auction) => auction.price?.base?.amount);
  const currPrice = auctionPrices[0];

  if (auctionPrices.length > 1) auctionPrices.shift();

  const minAuctionPrice = min(auctionPrices);

  const calculateDiff = () => {
    if (currPrice && minAuctionPrice) {
      const percent = ((currPrice - minAuctionPrice) / minAuctionPrice) * 100;
      return round(percent, 1);
    }
    return false;
  };

  const diff = calculateDiff();

  const getIcon = () => {
    if (!isNumber(diff)) return undefined;
    return diff < 0 ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />;
  };

  const getColor = () => {
    if (!isNumber(diff)) return null;
    return diff < 0 ? 'green' : 'red';
  };

  return (
    <div>
      {!auctionPrices.every((item) => item === currPrice) ? (
        <>
          {prevAuctions.length >= 2 ? (
            <IconButton
              style={{
                marginRight: '10px',
              }}
              size="small"
              className={showLines ? clsx(classes.btn) : clsx(classes.btnDiagramOff, classes.btn)}
              onClick={() => {
                handleClick();
              }}
            >
              <TimelineIcon fontSize="small" />
            </IconButton>
          ) : null}

          {showLines ? (
            <TrendChart
              prevAuctions={prevAuctions || []}
              monetary={potential || []}
              diffNegative={isNumber(diff) ? diff < 0 : false}
              marketingChannel={marketingChannel}
            />
          ) : (
            diff !== 0 && (
              <Tooltip title={<Trans i18nKey="car.prices.comparedToLowestBasePrice" />}>
                <Chip
                  size="small"
                  className={clsx(classes.styling, `${classes.styling}--${getColor()} `)}
                  label={`${diff} %`}
                  icon={getIcon()}
                />
              </Tooltip>
            )
          )}
        </>
      ) : null}
    </div>
  );
};
