import { transformForSubmit } from '@components/cars-table-next/filter/lib';
import { useFilter } from '@components/cars-table-next/filter/useFilter';
import { isNil } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { useExtendUsersnap } from 'src/hooks/useExtendUsersnap';
import useRole from 'src/hooks/useRole';
import { useValuationSettings } from 'src/hooks/useValuationSettings';
import { ValuationCountryCode } from 'src/modules/generated/api';
import UserRole from 'src/types/UserRoles';
import { TableNext } from './TableNext';
import { columns, relevantTaxes } from './columns';
import { carsTablePageIndexState, carsTablePaginationState, refetchIntervalState, sortingState } from './state';
import { TableType } from './types';
import { useCars } from './useCars';

export const CarsTableNext = (): JSX.Element => {
  const { hasRole } = useRole();
  const { valuationCountry, valuationType } = useValuationSettings();
  const availableColumns = useMemo(
    () =>
      columns().filter(
        (column) =>
          column.meta?.roles === undefined ||
          hasRole(UserRole.ADMIN) ||
          column.meta.roles.every((role) => hasRole(role as any) || relevantTaxes[valuationCountry!]?.includes(role)),
      ),
    [hasRole, valuationCountry],
  );
  // if the users valuationCountry is De then dont display the columns "marketPriceDE" and "referenceVehiclesDE"
  const filteredColumns = useMemo(
    () =>
      availableColumns.filter(
        (column) =>
          valuationCountry !== ValuationCountryCode.De ||
          (column.id !== 'marketPriceDE' && column.id !== 'referenceVehiclesDE'),
      ),
    [availableColumns, valuationCountry],
  );

  const { reset: resetFilter, watch } = useFilter();
  const filterState = watch();
  const [pagination, setPagination] = useRecoilState(carsTablePaginationState);
  const [sorting] = useRecoilState(sortingState);
  const refetchInterval = useRecoilValue(refetchIntervalState);
  const resetPageIndexSate = useResetRecoilState(carsTablePageIndexState);

  const {
    data: carsList,
    // isFetching,
    isLoading,
    refetch,
    isPreviousData,
  } = useCars({
    enabled: !isNil(filterState),
    filter: filterState,
    pagination,
    sorting,
    refetchInterval: refetchInterval || undefined,
  });

  useExtendUsersnap(
    {
      transformedFilter: filterState && transformForSubmit(filterState),
      pagination,
      sorting,
      valuationCountry,
      valuationType,
    },
    [filterState, pagination, sorting, valuationCountry, valuationType],
  );

  useEffect(() => {
    const subscription = watch(() => {
      resetPageIndexSate();
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [watch, resetPageIndexSate]);

  return (
    <TableNext
      tableColumns={filteredColumns}
      totalRecords={carsList?.recordsTotal}
      data={carsList?.data}
      isLoading={isLoading}
      refetch={refetch}
      isPreviousData={isPreviousData}
      resetFilter={resetFilter}
      tableType={TableType.Cars}
      pagination={pagination}
      setPagination={setPagination}
    />
  );
};
