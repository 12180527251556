import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import FilterAutocomplete from './FilterAutocomplete';
import { EquipmentCodeText } from '../modules/generated/api';

type EquipmentsSelectProps = {
  equipments?: Array<EquipmentCodeText>;
  loading: boolean;
};

const EquipmentsSelect = ({ equipments = [], loading }: EquipmentsSelectProps) => {
  const { t } = useTranslation();
  const options = equipments.map(({ code, text }) => ({
    value: code || '',
    label: `${code} - ${text}`,
  }));

  return (
    <FilterAutocomplete
      name="equipmentCodes"
      label={t('car.equipment')}
      multiple
      options={options}
      isLoading={loading}
      maxOptions
    />
  );
};

export default memo(EquipmentsSelect);
