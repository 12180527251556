import { CircularProgress, IconButton, makeStyles } from '@material-ui/core';
import { CameraAlt, Fullscreen, FullscreenExit } from '@material-ui/icons';
import clsx from 'clsx';
import { createRef, memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { IndividualBidDTO, IndividualBidStateInfoDTO } from '../modules/generated/api';
import GravelBidInfo from './GravelBidInfo';
import ImageLabel from './ImageLabel';

const useStyles = makeStyles(({ spacing, palette: { primary, common, grey } }) => ({
  root: {
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    '&--big': {
      aspectRatio: '4/3',
      minHeight: spacing(37),
      marginBottom: spacing(2),
      display: 'flex',
      maxWidth: '100%',
    },
    '&--small': {
      aspectRatio: '4/3',
      width: spacing(10),
      minHeight: spacing(7),
    },
    '&--medium': {
      aspectRatio: '4/3',
      width: spacing(18),
      display: 'block',
    },
    '& .fullscreen-modal': {
      zIndex: '1005 !important' as any,
    },
    '@media print': {
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
  },
  showOnlyOnPrint: {
    display: 'none',
    '@media print': {
      maxWidth: '250px',
      display: 'block',
    },
  },
  loadingWrapper: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    display: 'block',
    width: '100%',
  },
  iconBig: {
    fontSize: '8rem',
  },
  photoGalleryImage: {
    display: 'flex !important',
    justifyContent: 'center',
    alignItems: 'center',
    verticalAlign: 'middle',
    height: '70vh',
    objectFit: 'cover',
    overflow: 'hidden',
  },
  preview: {
    aspectRatio: '4/3',
    height: 'auto',
  },
  noOpacity: {
    opacity: 0,
  },
  thumbnails: {
    objectFit: 'contain',
  },
  imageGallery: {
    '& .image-gallery-content': {
      backgroundColor: grey[200],
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'wrap',
      '@media print': {
        display: 'none',
      },
    },
    '& .image-gallery-svg': {
      height: '70px',
      '&:hover': {},
      '@media print': {
        display: 'none',
      },
    },
    '& .image-gallery-icon': {
      filter: 'none',
      color: primary.main,
      '@media (hover: hover) and (pointer: fine)': {
        '&:hover': {
          color: primary.dark,
        },
      },
      '@media print': {
        display: 'none',
      },
    },
    '& .image-gallery-fullscreen-button': {
      top: '0',
      height: 'fit-content',
      padding: '5px 10px',
      '& svg': {
        height: '40px',
      },
    },
    '& .image-gallery-index': {
      left: 0,
      width: 'fit-content',
    },
    '& .image-gallery-bullet': {
      marginBottom: spacing(0.4),
      boxShadow: 'none',
      background: primary.light,
      '&.active': {
        background: primary.main,
      },
      '@media print': {
        display: 'none',
      },
      '@media (hover: hover) and (pointer: fine)': {
        '&:hover': {
          background: primary.main,
          borderColor: common.white,
        },
        ' &.active:hover': {
          background: primary.main,
        },
      },
    },
  },
  imageBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    '@media print': {
      display: 'none',
    },
  },
  centerFullscreen: {
    paddingTop: '8vh',
    '& .image-gallery-image': {
      height: '80vh',
    },
  },
  fullscreenIcon: {
    position: 'absolute',
  },
  inlineBidInfo: {
    top: '0.75rem',
    right: '3rem',
  },
  singlePicBidExp: {
    position: 'absolute',
    top: '0.4rem',
    right: '0.5rem',
  },
  singlePicBid: {
    top: '0.4rem',
    right: '0.4rem',
  },
}));

type CarsTableImageCellProps = {
  imageLinks?: string[];
  size?: 'small' | 'medium' | 'big';
  bidStatus?: IndividualBidStateInfoDTO;
  isExpired?: boolean;
  bought?: boolean;
  otherBought?: IndividualBidDTO;
};

const CarsTableImageCell = ({
  imageLinks,
  size = 'small',
  bidStatus,
  isExpired = false,
  bought,
  otherBought,
}: CarsTableImageCellProps) => {
  const classes = useStyles();
  const thumbnail = imageLinks?.[0];
  const [loading, setLoading] = useState(thumbnail !== undefined);
  const [error, setError] = useState(false);
  const [fullScreenState, setFullscreenState] = useState(false);
  const fullscreenBtnRef = createRef<any>();
  const [index, setIndex] = useState(0);
  const { t } = useTranslation();
  const timeout = useRef<number>();

  const handleOnLoad = () => {
    setError(false);

    // Flicker prevention
    timeout.current = window.setTimeout(() => {
      setLoading(false);
    }, 400);
  };

  const handleOnError = () => {
    setError(true);
    setLoading(false);
  };

  const toggleFullScreen = () => {
    if (fullscreenBtnRef?.current?.click) fullscreenBtnRef.current.click();
  };

  // TODO: Removed due to loading state if no thumbnail is available, left to see if problems arise
  // useEffect(() => {
  //   setLoading(true);
  // }, [thumbnail]);

  useEffect(
    () => () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    },
    [],
  );

  return (
    <div className={clsx(`${classes.root}--${size}`, classes.root)}>
      {loading && (
        <div className={classes.loadingWrapper}>
          <CircularProgress size={size === 'small' ? 16 : 64} />
        </div>
      )}

      {(error || !thumbnail) && (
        <div className={classes.loadingWrapper}>
          <ImageLabel
            size="big"
            text={bought || otherBought ? t('referencePopup.sold') : t('bids.expired')}
            disable={!isExpired}
          >
            <CameraAlt fontSize="large" color="disabled" className={clsx(size === 'big' && classes.iconBig)} />
            {bidStatus && <GravelBidInfo bidStatus={bidStatus} className={classes.singlePicBid} />}
          </ImageLabel>
        </div>
      )}

      {imageLinks && imageLinks.length === 1 ? (
        <>
          <div className={classes.imageBox}>
            <ImageLabel
              size="big"
              text={bought || otherBought ? t('referencePopup.sold') : t('bids.expired')}
              disable={!isExpired}
            >
              <img
                className={clsx(classes.image, loading && classes.noOpacity)}
                key={thumbnail}
                src={thumbnail}
                alt=""
                onLoad={handleOnLoad}
                onError={handleOnError}
              />
              {bidStatus && !error && <GravelBidInfo bidStatus={bidStatus} className={classes.singlePicBid} />}
            </ImageLabel>
          </div>
          <img src={thumbnail} alt={`${t('common.car')} ${t('car.picture')}`} className={classes.showOnlyOnPrint} />
        </>
      ) : (
        imageLinks &&
        imageLinks?.length > 1 &&
        !error && (
          <ImageLabel
            size="big"
            text={bought || otherBought ? t('referencePopup.sold') : t('bids.expired')}
            disable={!isExpired}
          >
            <ImageGallery
              additionalClass={clsx(classes.imageGallery, fullScreenState && classes.centerFullscreen)}
              showBullets
              useBrowserFullscreen
              showPlayButton={false}
              showThumbnails={fullScreenState}
              showIndex={fullScreenState}
              onErrorImageURL="/images/CameraAlt.png"
              onImageLoad={handleOnLoad}
              onClick={toggleFullScreen}
              renderFullscreenButton={(onClick, isFullscreen) => (
                <>
                  {bidStatus && !fullScreenState && (
                    <GravelBidInfo bidStatus={bidStatus} className={classes.inlineBidInfo} />
                  )}
                  <IconButton
                    ref={fullscreenBtnRef}
                    onClick={onClick}
                    className={clsx(
                      `image-gallery-fullscreen-button${isFullscreen ? ' active' : ''}`,
                      'image-gallery-icon',
                      classes.fullscreenIcon,
                    )}
                  >
                    {isFullscreen ? (
                      <FullscreenExit className="image-gallery-svg" />
                    ) : (
                      <Fullscreen className="image-gallery-svg" />
                    )}
                  </IconButton>
                </>
              )}
              stopPropagation
              onSlide={(currentIndex) => {
                setIndex(currentIndex);
              }}
              startIndex={index}
              onScreenChange={() => setFullscreenState(!fullScreenState)}
              items={imageLinks?.map(
                (img) =>
                  ({
                    original: img,
                    originalClass: clsx(classes.photoGalleryImage, !fullScreenState && classes.preview),
                    thumbnail: img,
                    thumbnailClass: classes.thumbnails,
                  }) as ReactImageGalleryItem,
              )}
            />
            <img
              src={imageLinks[index]}
              alt={`${t('common.car')} ${t('car.picture')}`}
              className={classes.showOnlyOnPrint}
            />
          </ImageLabel>
        )
      )}
    </div>
  );
};

export default memo(CarsTableImageCell);
