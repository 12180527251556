import { Card, CardContent, styled, Typography } from '@material-ui/core';

export const CalloutContent = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(2),
}));

export const CalloutAdornment = styled('div')({
  alignSelf: 'flex-start',
});

export const CalloutBody = styled('div')({
  flexGrow: 1,
});

export const CalloutTitle = styled(Typography)({
  fontWeight: 'bold',
});
CalloutTitle.defaultProps = {
  variant: 'h6',
  gutterBottom: true,
};

export const CalloutText = styled(Typography)({
  maxWidth: '65ch',
});

export const Callout = Card;
