// @ts-nocheck
import { makeStyles, TableHead } from '@material-ui/core';
import { useState } from 'react';
import { TableHeadCell, TableHeadRow, TableSelectCell } from 'mui-datatables';
import clsx from 'clsx';
import { useTableCalculator } from '../hooks/useTableCalculator';
import { useCarsTableStore } from '../stores/CarsTable';
import { useCurrency } from '../hooks/useCurrency';
import UnitValue from './UnitValue';
import { CARS_BIDS_TABLE_COLUMNS } from '../modules/table-data';
/* eslint-disable */
// From https://github.com/gregnb/mui-datatables/blob/7d3a19062b6eb441bf2a6d4a2bb2f6f6879db592/src/components/TableBody.js

const useStyles = makeStyles(
  (theme) => ({
    main: {
      '& .MuiTableCell-head': {
        zIndex: 2,
      },
    },
    responsiveStacked: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    responsiveSimple: {
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    customRoot: {
      '& tr:nth-child(2)': {
        height: '1.5rem',
        borderBottom: `2px ${theme.palette.primary.main} solid`,
        borderTop: `2px ${theme.palette.primary.main} solid`,
      },
    },
  }),
  { name: 'MUIDataTableHead' },
);
const TableHeadCustom = ({
  columnOrder = null,
  columns,
  components = {},
  count,
  data,
  draggableHeadCellRefs,
  expandedRows,
  options,
  selectedRows,
  selectRowUpdate,
  setCellRef,
  sortOrder = {},
  tableRef,
  tableId,
  timers,
  toggleAllExpandableRows,
  toggleSort,
  updateColumnOrder,
}) => {
  const classes = useStyles();
  const { currency } = useCurrency();
  const { aggregatedColumnValues, isRelative } = useTableCalculator();
  const { packageMode } = useCarsTableStore();

  if (columnOrder === null) {
    columnOrder = columns ? columns.map((item, idx) => idx) : [];
  }

  const [dragging, setDragging] = useState(false);

  const handleToggleColumn = (index) => {
    toggleSort(index);
  };

  const handleRowSelect = () => {
    selectRowUpdate('head', null);
  };

  const numSelected = (selectedRows && selectedRows.data.length) || 0;
  let isIndeterminate = numSelected > 0 && numSelected < count;
  let isChecked = numSelected > 0 && numSelected >= count;

  // When the disableToolbarSelect option is true, there can be
  // selected items that aren't visible, so we need to be more
  // precise when determining if the head checkbox should be checked.
  if (
    options.disableToolbarSelect === true ||
    options.selectToolbarPlacement === 'none' ||
    options.selectToolbarPlacement === 'above'
  ) {
    if (isChecked) {
      for (let ii = 0; ii < data.length; ii++) {
        if (!selectedRows.lookup[data[ii].dataIndex]) {
          isChecked = false;
          isIndeterminate = true;
          break;
        }
      }
    } else if (numSelected > count) {
      isIndeterminate = true;
    }
  }

  const orderedColumns = columnOrder.map((colIndex, idx) => ({
    column: columns[colIndex],
    index: colIndex,
    colPos: idx,
  }));

  return (
    <TableHead
      className={clsx(
        {
          [classes.responsiveStacked]:
            options.responsive === 'vertical' ||
            options.responsive === 'stacked' ||
            options.responsive === 'stackedFullWidth',
          [classes.responsiveStackedAlways]: options.responsive === 'verticalAlways',
          [classes.responsiveSimple]: options.responsive === 'simple',
          [classes.main]: true,
        },
        packageMode && classes.customRoot,
      )}
    >
      <TableHeadRow>
        <TableSelectCell
          setHeadCellRef={setCellRef}
          onChange={handleRowSelect.bind(null)}
          indeterminate={isIndeterminate}
          checked={isChecked}
          isHeaderCell
          expandedRows={expandedRows}
          expandableRowsHeader={options.expandableRowsHeader}
          expandableOn={options.expandableRows}
          selectableOn={options.selectableRows}
          fixedHeader={options.fixedHeader}
          fixedSelectColumn={options.fixedSelectColumn}
          selectableRowsHeader={options.selectableRowsHeader}
          selectableRowsHideCheckboxes={options.selectableRowsHideCheckboxes}
          onExpand={toggleAllExpandableRows}
          isRowSelectable
          components={components}
        />
        {orderedColumns.map(
          ({ column, index, colPos }) =>
            column.display === 'true' &&
            (column.customHeadRender ? (
              column.customHeadRender({ index, ...column }, handleToggleColumn, sortOrder)
            ) : (
              <TableHeadCell
                cellHeaderProps={
                  columns[index].setCellHeaderProps ? columns[index].setCellHeaderProps({ index, ...column }) || {} : {}
                }
                key={index}
                index={index}
                colPosition={colPos}
                type="cell"
                setCellRef={setCellRef}
                sort={column.sort}
                sortDirection={column.name === sortOrder.name ? sortOrder.direction : 'none'}
                toggleSort={handleToggleColumn}
                hint={column.hint}
                print={column.print}
                options={options}
                column={column}
                columns={columns}
                updateColumnOrder={updateColumnOrder}
                columnOrder={columnOrder}
                timers={timers}
                draggingHook={[dragging, setDragging]}
                draggableHeadCellRefs={draggableHeadCellRefs}
                tableRef={tableRef}
                tableId={tableId}
                components={components}
              >
                {column.customHeadLabelRender
                  ? column.customHeadLabelRender({ index, colPos, ...column })
                  : column.label}
              </TableHeadCell>
            )),
        )}
      </TableHeadRow>
      <TableHeadRow>
        {packageMode &&
          orderedColumns.map(({ column }) => {
            const priceType = CARS_BIDS_TABLE_COLUMNS[column.name].priceType;
            const aggregatedValues = aggregatedColumnValues[priceType];

            return (
              column.display === 'true' && (
                <th key={column.name}>
                  {packageMode && priceType && aggregatedValues != null && (
                    <UnitValue
                      /* @ts-ignore eslint-disable-next-line */
                      value={isRelative(priceType) ? aggregatedValues : aggregatedValues?.amount}
                      unit={isRelative(priceType) ? '%' : currency}
                    />
                  )}
                  {priceType && aggregatedValues == null && 'N/A'}
                </th>
              )
            );
          })}
      </TableHeadRow>
    </TableHead>
  );
};

export default TableHeadCustom;
