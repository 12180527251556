import { BidsTableNextFilterData, CarsTableNextFilterData } from '@components/cars-table-next/filter/types';
import { removeFromArray } from './general-helpers';

export function removeItemFromCarsTableNextFilter(
  name: string,
  value: any,
  filterState: CarsTableNextFilterData,
  setFilter: Function,
) {
  if (name === 'search.vins') {
    if (filterState)
      setFilter({
        ...filterState,
        search: { ...filterState.search, vins: removeFromArray(filterState.search.vins, value) || [] },
      });
    return;
  }
  const filterArray = filterState && filterState[name as keyof CarsTableNextFilterData];
  if (filterState && filterArray)
    setFilter({
      ...filterState,
      [name]: removeFromArray(filterArray as any[], value),
    });
}

export function removeItemFromBidsTableNextFilter(
  name: string,
  value: any,
  filterState: BidsTableNextFilterData,
  setFilter: Function,
) {
  const pureName = name.split('.')[1];
  if (pureName) {
    if (name === 'search.vins') {
      if (filterState)
        setFilter({
          ...filterState,
          carsRequest: {
            ...filterState.carsRequest,
            search: {
              ...filterState.carsRequest.search,
              vins: removeFromArray(filterState.carsRequest.search.vins, value) || [],
            },
          },
        });
      return;
    }
    const filterArray = filterState && filterState.carsRequest[pureName as keyof CarsTableNextFilterData];
    if (filterState && filterArray)
      setFilter({
        ...filterState,
        carsRequest: { ...filterState.carsRequest, [pureName]: removeFromArray(filterArray as any[], value) },
      });
  } else {
    const filterArray = filterState && filterState[name as keyof BidsTableNextFilterData];
    if (filterState && filterArray)
      setFilter({
        ...filterState,
        [name]: removeFromArray(filterArray as any[], value),
      });
  }
}

export function resetCarsTableNextFilterValue(name: string, filterState: CarsTableNextFilterData, setFilter: Function) {
  if (filterState)
    setFilter({
      ...filterState,
      [name]: undefined,
    });
}

export function resetBidsTableNextFilterValue(name: string, filterState: BidsTableNextFilterData, setFilter: Function) {
  const pureName = name.split('.')[1];
  if (!filterState) return;
  if (pureName) {
    setFilter({ ...filterState, carsRequest: { ...filterState.carsRequest, [pureName]: undefined } });
  } else {
    setFilter({
      ...filterState,
      [name]: undefined,
    });
  }
}
