import { makeStyles, TablePagination as MuiTablePagination, TablePaginationProps } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

const getLabelDisplayRows = ({ from, to, count }: { from: number; to: number; count: number }): string =>
  `${from} – ${to} von ${count !== -1 ? count : `mehr als ${to}`}`;

const useStyles = makeStyles({
  actions: {
    marginInlineStart: 16,
  },
  toolbar: {
    minHeight: 'auto',
    padding: 0,
  },
});

export const TablePagination = ({ classes: _classes, ...rest }: TablePaginationProps): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    // @ts-expect-error
    <MuiTablePagination
      component="div"
      // TODO: add ALL option
      rowsPerPageOptions={[10, 25, 50, 100]}
      labelDisplayedRows={getLabelDisplayRows}
      align="left"
      classes={{
        toolbar: clsx(classes.toolbar, _classes?.toolbar),
        actions: clsx(classes.actions, _classes?.actions),
      }}
      labelRowsPerPage={t('carsTableNext.rowsPerPage')}
      {...rest}
    />
  );
};
