import { styled, Theme, Typography, TypographyProps } from '@material-ui/core';
import { Dropdown } from '../dropdown';

export const DocViewTrigger = styled('div')(({ theme }) => ({
  width: 0,
  height: 0,
  display: 'inline-block',
  borderStyle: 'solid',
  borderWidth: '0 16px 16px 0', // top right triangle
  borderColor: 'transparent',
  borderRightColor: theme.palette.primary.main,
  cursor: 'pointer',
}));

export const DocViewTitle = styled((props: TypographyProps) => <Typography variant="body1" {...props} />)<
  Theme,
  TypographyProps
>(({ theme, gutterBottom }) => ({
  fontWeight: 'bold',
  marginBlockEnd: gutterBottom ? theme.spacing(2) : undefined,
}));

export const DocView = styled(Dropdown)({
  minWidth: 250,
});
