import { Chip, makeStyles, Tooltip } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { bidSuccessStatus, ExtendedBidSuccessStatus } from '../modules/data';
import { BidSuccessStatus } from '../modules/generated/api';

const useStyles = makeStyles((theme) => ({
  statusChip: {
    color: theme.palette.common.white,
    marginBottom: theme.spacing(0.5),
    '&--primary': {
      backgroundColor: theme.palette.primary.main,
    },
    '&--secondary': {
      backgroundColor: theme.palette.secondary.main,
    },
    '&--error': {
      backgroundColor: theme.palette.error.main,
    },
    '&--success': {
      backgroundColor: theme.palette.success.main,
    },
    '&--failed': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  statusChipIcon: {
    color: theme.palette.common.white,
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

type BidSuccessStatusChipProps = {
  successStatus: BidSuccessStatus;
  errorMessage?: string;
};

const BidSuccessStatusChip = ({ successStatus, errorMessage }: BidSuccessStatusChipProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  let status: ExtendedBidSuccessStatus | undefined = successStatus;
  // the error states from the backend must be shown differently to the user
  // there are special error messages that are not errors for the user
  if (status === BidSuccessStatus.Error && errorMessage) {
    const outbidMessages = ['offer_expired', 'offer_expired_or_sold'];
    if (outbidMessages.includes(errorMessage.toLowerCase())) {
      status = BidSuccessStatus.Outbid;
    } else if (errorMessage.toLowerCase() === 'offer_not_active') {
      status = 'NOT_AVAILABlE';
    } else if (errorMessage.toLowerCase() === 'offer_is_reserved') {
      status = 'RESERVED';
    }
  }

  const getErrorText = () => {
    if (!errorMessage) {
      return '';
    }
    switch (errorMessage.toLowerCase()) {
      case 'offer_not_active':
        return t('carsTable.bidStatus.errorCodes.offer_not_active');
      case 'offer_expired':
        return t('carsTable.bidStatus.errorCodes.offer_expired');
      case 'incorrect_kvps_for_offer':
        return t('carsTable.bidStatus.errorCodes.incorrect_kvps_for_offer');
      case 'offer_expired_or_sold':
        return t('carsTable.bidStatus.errorCodes.offer_expired_or_sold');
      case 'cannot_change_bid_assistant':
        return t('carsTable.bidStatus.errorCodes.cannot_change_bid_assistant');
      case 'bids_smaller_than_old_bids':
        return t('carsTable.bidStatus.errorCodes.bids_smaller_than_old_bids');
      case 'offer_is_reserved':
        return t('carsTable.bidStatus.errorCodes.offer_is_reserved');
      default:
        return errorMessage;
    }
  };

  return (
    <div className={classes.root}>
      {status && (
        <Tooltip
          arrow
          placement="right"
          title={
            (errorMessage && getErrorText()) || (status === BidSuccessStatus.Cancelled && t('bids.hintCancelled')) || ''
          }
        >
          <Chip
            label={t(bidSuccessStatus[status].label as unknown as TemplateStringsArray)}
            icon={
              errorMessage || status === BidSuccessStatus.Cancelled ? (
                <Info className={classes.statusChipIcon} />
              ) : undefined
            }
            className={clsx(classes.statusChip, `${classes.statusChip}--${bidSuccessStatus[status].severity}`)}
            size="small"
          />
        </Tooltip>
      )}
    </div>
  );
};

export default BidSuccessStatusChip;
