import { ChangeEventHandler, createContext, Dispatch, SetStateAction, useContext, useMemo, useState } from 'react';
import { DrawerContent, DrawerFooter, DrawerHeader, DrawerStatic, Select } from '@components/ui';
import { Button, Checkbox, Grid, Typography } from '@material-ui/core';
import { Column } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { DropResult } from 'react-beautiful-dnd';
import type { Merge } from 'type-fest';
import { grey } from '@material-ui/core/colors';
import { replaceItemAtIndex } from 'src/modules/util';
import i18n from 'src/setup/i18n';
import { SubExportDef } from '@components/cars-table-next/types';
import { DraggableList, DraggableListItem, DraggableListItemContentProps, DragHandle, reorder } from '../DraggableList';
import { ExportType } from '../constants';
import { tr } from '../../../modules/i18n-helpers';

// TODO: improve preset select syncing (instead of multiple setStates use effects?)
// TODO: remove lodash set helper

type TableExportProps = {
  columns: Column<any, unknown>[];
  onSubmit: (data: ExportColumn[]) => void;
};

type ExportCtxValue = {
  exportColumns: ExportColumn[];
  setExportColumns: Dispatch<SetStateAction<ExportCtxValue['exportColumns']>>;
  setPreset: Dispatch<SetStateAction<ExportPreset>>;
};

export type ExportColumn = {
  name: string; // exportProperty.name
  label: string;
  column: Column<any, any>;
  checked: boolean;
  exportType?: ExportType;
  exports?: {
    name: string;
    checked: boolean;
    subExportDef: SubExportDef;
    label: string;
  }[];
};

type ExportListItemProps = ExportColumn & {
  disabled?: boolean;
};

enum ExportPreset {
  table,
  minimal,
  individual,
}

// TODO: pass preset via props
const exportPresets = [
  { value: ExportPreset.table, label: tr('carsTableNext.export.presets.table') },
  { value: ExportPreset.minimal, label: tr('carsTableNext.export.presets.minimal') },
  { value: ExportPreset.individual, label: tr('carsTableNext.export.presets.individual') },
];

const minimalPreset = ['car', 'dateStart', 'source', 'promotions'];

const translateEng = i18n.getFixedT('en'); // Force english for potential parsing stability

const updateExportColumn = (
  exportColumns: ExportColumn[],
  targetId: string,
  newExportColumn: ExportColumn | ((prev: ExportColumn) => ExportColumn),
): ExportColumn[] => {
  const prevExportColumn = exportColumns.find((exportColumn) => exportColumn.column.id === targetId);

  if (!prevExportColumn) {
    return exportColumns;
  }

  return replaceItemAtIndex(
    exportColumns,
    exportColumns.indexOf(prevExportColumn),
    typeof newExportColumn === 'function' ? newExportColumn(prevExportColumn) : newExportColumn,
  );
};

const getExportColumn = (column: Column<any, any>): ExportColumn => ({
  name: column.columnDef.meta?.export?.key ?? column.id,
  column,
  checked: column.getIsVisible(),
  exportType: column.columnDef.meta?.export?.typeOptions?.[0]?.value,
  // eslint-disable-next-line no-nested-ternary
  label: i18n.exists(`carsTableNext.columns.${column.id}-supportText` as any)
    ? `${translateEng(`carsTableNext.columns.${column.id}`)} ${translateEng(
        `carsTableNext.columns.${column.id}-supportText`,
      )}`
    : column.id.includes('taxes')
      ? `${translateEng(`carsTableNext.columns.${column.id}`)} (${column.id.split('.')[1]})`
      : translateEng(`carsTableNext.columns.${column.id}`),
  exports: column.columnDef.meta?.export?.exports?.map((subExport) => ({
    name: subExport.key,
    checked: column.getIsVisible(),
    subExportDef: subExport,
    label: translateEng(subExport.label),
  })),
});

const getExportColumnGroups = (columns: Column<any, any>[], preset?: string[]): ExportColumn[] => {
  if (!preset) {
    return columns.map((column) => getExportColumn(column));
  }
  return columns.map((column) => {
    const exportColumn = getExportColumn(column);
    const checked = preset.includes(column.id);
    return {
      ...exportColumn,
      checked,
      exports: exportColumn.exports?.map((subExport) => ({ ...subExport, checked })),
    };
  });
};

const ExportCtx = createContext<ExportCtxValue>(undefined!);
ExportCtx.displayName = 'TableExportCtx';

const ExportListItem = ({
  name,
  disabled,
  dragHandleProps,
  column,
  checked,
  exportType,
  exports,
}: Merge<DraggableListItemContentProps, ExportListItemProps>) => {
  const { t } = useTranslation();
  const { setExportColumns, setPreset } = useContext(ExportCtx);
  const typeOptions = column.columnDef.meta?.export?.typeOptions ?? [];
  const hasTypeOptions = typeOptions.length > 0;

  const update = (newExportColumn: ExportColumn | ((prev: ExportColumn) => ExportColumn)) => {
    setExportColumns((prevExportColumns) => updateExportColumn(prevExportColumns, column.id, newExportColumn));
  };

  const handleChangeVisibility: ChangeEventHandler<HTMLInputElement> = (event) => {
    update((prev) => ({ ...prev, checked: event.target.checked }));
    setPreset(ExportPreset.individual);
  };

  const handleChangeExportType = (newExportType: ExportType) => {
    update((prev) => ({ ...prev, exportType: newExportType }));
  };

  const handleChangeVisibilitySub: ChangeEventHandler<HTMLInputElement> = (event) => {
    update((prev) => ({
      ...prev,
      exports: prev.exports?.map((exportItem) => {
        if (exportItem.name === event.target.name) {
          return {
            ...exportItem,
            checked: event.target.checked,
          };
        }
        return exportItem;
      }),
    }));
    setPreset(ExportPreset.individual);
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        paddingInline: 8,
        paddingBlock: 4,
        border: '1px solid',
        borderColor: grey[400],
        background: '#fff',
        borderRadius: 4,
        gap: 8,
      }}
    >
      <div style={{ flexGrow: 1, minWidth: 0 }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 8,
          }}
        >
          <Checkbox
            name={name}
            size="small"
            style={{
              margin: -9,
            }}
            checked={checked}
            onChange={handleChangeVisibility}
            disabled={disabled}
          />
          <Typography
            style={{
              flex: '1 0',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
            title={column.id}
          >
            {t(`carsTableNext.columns.${column.id}` as any)}
            {i18n.exists(`carsTableNext.columns.${column.id}-supportText` as any) &&
              ` ${t(`carsTableNext.columns.${column.id}-supportText` as any)}`}
            {column.id.includes('taxes') && ` (${column.id.split('.')[1]})`}
          </Typography>
          {hasTypeOptions && (
            <Select
              name={`${name}.exportType`}
              options={typeOptions}
              fullWidth={false}
              variant="standard"
              onChange={handleChangeExportType}
              value={exportType}
            />
          )}
        </div>
        {exports?.length ? (
          <div
            style={{
              marginInlineStart: 10,
              paddingInlineStart: 18,
              borderLeft: '1px dashed black',
            }}
          >
            {exports.map((subExport) => (
              <div key={subExport.name}>
                <Checkbox
                  name={subExport.name}
                  size="small"
                  style={{
                    margin: -6,
                    marginInlineEnd: 0,
                  }}
                  checked={subExport.checked}
                  onChange={handleChangeVisibilitySub}
                />
                {t(subExport.subExportDef.label as any)}
              </div>
            ))}
          </div>
        ) : null}
      </div>
      <DragHandle
        style={{
          paddingBlock: 4,
          alignSelf: 'flex-start',
        }}
        disabled={disabled}
        {...dragHandleProps}
      />
    </div>
  );
};

const ExportList = (): JSX.Element => {
  const { exportColumns, setExportColumns, setPreset } = useContext(ExportCtx);

  const draggableItems = useMemo<DraggableListItem[]>(
    () =>
      exportColumns.map((exportColumn) => ({
        id: exportColumn.column.id,
        content: (props) => <ExportListItem {...exportColumn} {...props} />,
      })),
    [exportColumns],
  );

  const handleDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    setExportColumns((prev) => reorder(prev, result.source.index, result.destination!.index));
    setPreset(ExportPreset.individual);
  };

  return <DraggableList droppableId="table-export" onDragEnd={handleDragEnd} items={draggableItems} />;
};

export const TableExport = ({ columns, onSubmit }: TableExportProps): JSX.Element => {
  const { t } = useTranslation();
  const [exportColumns, setExportColumns] = useState<ExportColumn[]>(() => getExportColumnGroups(columns));
  const [selectedPreset, setPreset] = useState(ExportPreset.table);

  const ctxValue = useMemo(
    () => ({
      exportColumns,
      setExportColumns,
      setPreset,
    }),
    [exportColumns],
  );

  const toggleAll = (visible: boolean) => {
    setExportColumns((prev) =>
      prev.map((exportColumn) => ({
        ...exportColumn,
        checked: visible,
        exports: exportColumn.exports?.map((subExport) => ({ ...subExport, checked: visible })),
      })),
    );
    setPreset(ExportPreset.individual);
  };

  const handleChangePreset = (newPreset: ExportPreset) => {
    switch (newPreset) {
      case ExportPreset.table:
        setExportColumns(getExportColumnGroups(columns));
        break;
      case ExportPreset.minimal:
        setExportColumns(getExportColumnGroups(columns, minimalPreset));
        break;
      // no default
    }

    setPreset(newPreset);
  };

  const handleClickSubmit = () => {
    onSubmit(exportColumns);
  };

  return (
    <DrawerStatic>
      <DrawerHeader>
        <Grid container alignItems="center" justifyContent="space-between" spacing={4}>
          <Grid item>
            <Typography style={{ fontWeight: 'bold' }}>Export</Typography>
          </Grid>
          <Grid
            item
            style={{
              flexGrow: 1,
              maxWidth: 200,
            }}
          >
            <Select
              name="exportPreset"
              value={selectedPreset}
              label={t('carsTableNext.export.presets.preset')}
              options={exportPresets}
              onChange={handleChangePreset}
            />
          </Grid>
        </Grid>
      </DrawerHeader>
      <DrawerContent>
        {/* toggle all */}
        <Grid
          container
          justifyContent="space-between"
          style={{
            marginBlockEnd: 8,
          }}
        >
          <Button color="primary" size="small" onClick={() => toggleAll(true)}>
            {t('carsTableNext.columnDisplaySettings.showAll')}
          </Button>
          <Button color="primary" size="small" onClick={() => toggleAll(false)}>
            {t('carsTableNext.columnDisplaySettings.hideAll')}
          </Button>
        </Grid>

        <ExportCtx.Provider value={ctxValue}>
          <ExportList />
        </ExportCtx.Provider>
      </DrawerContent>
      <DrawerFooter>
        <Grid container justifyContent="flex-end">
          <Button size="small" variant="contained" color="primary" onClick={handleClickSubmit}>
            {t('action.export')}
          </Button>
        </Grid>
      </DrawerFooter>
    </DrawerStatic>
  );
};
