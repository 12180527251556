import { memo, ReactElement } from 'react';
import { Link, useMatch } from '@reach/router';
import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import theme from '../setup/theme';

export const useStyles = makeStyles(() => ({
  root: {
    fontWeight: 'bold',
    textDecorationColor: theme.palette.primary.main,
    textDecorationThickness: `${theme.spacing(0.25)}px`,
  },
  active: {
    color: theme.palette.primary.main,
  },
}));

type NavigationLinkProps = {
  children: ReactElement;
  text: string;
  to: string;
  className?: string;
};

const NavigationLink = ({ children, text, to, className }: NavigationLinkProps) => {
  const classes = useStyles();
  const match = useMatch(`${to}/*`);

  return (
    <IconButton
      color="inherit"
      className={clsx(classes.root, match && classes.active, className)}
      component={Link}
      to={to}
    >
      <Tooltip title={text}>{children}</Tooltip>
    </IconButton>
  );
};

export default memo(NavigationLink);
