import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { QueryKeys } from 'src/constants';
import ApiService from 'src/modules/api-service';
import { ExternalSearchAgentWithNotificationDTO, SourceType } from 'src/modules/generated/api';
import useCustomSnackbarGlobal from './useSnackbarGlobal';

// Currently only supporting AudiGws
export const useExternalSearchAgentUpdate = () => {
  const queryClient = useQueryClient();
  const queryKey = [QueryKeys.externalSearchAgents, SourceType.AudiGws];
  const { showError } = useCustomSnackbarGlobal();
  const { t } = useTranslation();

  const mutation = useMutation(
    (searchAgent: ExternalSearchAgentWithNotificationDTO) =>
      ApiService.searchAgent.searchAgentControllerExternalUpsertSearchAgent(searchAgent),
    {
      onMutate: async (updatedSearchAgent) => {
        await queryClient.cancelQueries(queryKey);

        const snapshot = queryClient.getQueryData<ExternalSearchAgentWithNotificationDTO[]>(queryKey);

        queryClient.setQueryData<ExternalSearchAgentWithNotificationDTO[]>(queryKey, (prev = []) =>
          prev.map((searchAgent) => {
            if (searchAgent.id === updatedSearchAgent.id) {
              return updatedSearchAgent;
            }
            return searchAgent;
          }),
        );

        return snapshot;
      },
      onError: (_err, _updateSearchAgent, snapshot) => {
        showError(t('searchAgentsPage.messages.error'));
        queryClient.setQueryData<ExternalSearchAgentWithNotificationDTO[] | undefined>(queryKey, snapshot);
      },
      onSettled: () => {
        queryClient.invalidateQueries(queryKey);
      },
    },
  );

  return mutation;
};
