import { useContext, useState, Dispatch, SetStateAction, createContext, ReactNode } from 'react';
import TagManager from 'react-gtm-module';
import { useTranslation } from 'react-i18next';
import { DataTablesOutputListCarDTO, ListCarDTO, OnlineCarsRequestDTO } from '../modules/generated/api';
import { transformForSubmit } from '../modules/cars-filter';
import { useCurrency } from '../hooks/useCurrency';
import { useLocalStorage } from '../hooks/useLocalStorage';
import useApi from '../hooks/useApi';
import useCustomSnackbarGlobal from '../hooks/useSnackbarGlobal';
import { useValuationSettings } from '../hooks/useValuationSettings';
import ApiService from '../modules/api-service';
import { useExtendUsersnap } from '../hooks/useExtendUsersnap';
import { getGlobalFilterAndCarDataGTM } from '../modules/tag-manager-helpers';
import { CarsFilterSettings } from '../types/CarsFilterSettings';
import getMUIDataTableDefaultOptions from '../setup/MUIDataTableOptions';
import { CARS_BIDS_TABLE_COLUMNS, CarTableColumn } from '../modules/table-data';

type CarsTableContext = {
  updateCar: (updatedCar: ListCarDTO) => void;
  fetch: (filter: any) => Promise<DataTablesOutputListCarDTO | undefined>;
  cars: ListCarDTO[];
  loading: boolean | undefined;
  rowsPerPage: number;
  setRowsPerPage: Dispatch<SetStateAction<number>>;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  sortOn: SortOn;
  setSortOn: Dispatch<SetStateAction<SortOn>>;
  totalCount?: number;
  packageMode: boolean;
  setAndValidatePackageMode: (flag: boolean) => boolean;
  mutate: Dispatch<SetStateAction<DataTablesOutputListCarDTO | undefined>>;
};

type CarsTableProviderProps = { children: ReactNode };

export type SortDirection = 'asc' | 'desc';

export type SortOn = {
  column: CarTableColumn;
  dir: SortDirection;
};

const CarsTableStore = createContext<CarsTableContext | undefined>(undefined);

const carsDefault: ListCarDTO[] = [];

const CarsTableProvider = ({ children }: CarsTableProviderProps) => {
  const { t } = useTranslation();
  const { currency } = useCurrency();
  const { valuationCountry, valuationType } = useValuationSettings();
  const { fetch, data, loading, mutate } = useApi<DataTablesOutputListCarDTO>('/api/cars/list', {
    onError: handleError,
  });
  const { rowsPerPageOptions } = getMUIDataTableDefaultOptions();
  const [sortOn, setSortOn] = useLocalStorage<SortOn>('gwscout/CarsTable.sortOn', {
    column: CarTableColumn.startDateAndId,
    dir: 'asc',
  });
  const [packageMode, setPackageMode] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useLocalStorage('gwscout/CarsTable.rowsPerPage', 10);
  const snackbar = useCustomSnackbarGlobal();
  const cars = data?.data || carsDefault;
  const totalCount = data?.recordsTotal;
  const [trackingData, setTrackingData] = useState<any>();

  const fetchCars = async (filter?: CarsFilterSettings) => {
    const filterPayload = filter && transformForSubmit(filter);

    const requestPayload: OnlineCarsRequestDTO = {
      length: rowsPerPage,
      start: page * rowsPerPage,
      order: [{ column: 0, dir: sortOn.dir }],
      columns: [{ data: CARS_BIDS_TABLE_COLUMNS[sortOn.column].sortOn } as any],
      draw: 1,
      valuationCountry: valuationCountry || undefined,
      valuationType: valuationType || undefined,
      targetCurrency: currency,
      ...filterPayload,
    };

    const res = await fetch(ApiService.listCars.listCarsControllerList(requestPayload, 2));

    setTrackingData(requestPayload);

    if (res?.data.data) {
      TagManager.dataLayer(
        getGlobalFilterAndCarDataGTM('list-update', res.data.data, page, rowsPerPage, sortOn, filter),
      );
    }

    return res?.data;
  };

  const updateCar = (updatedCar: ListCarDTO) => {
    const carIdxToUpdate = cars.findIndex((car) => car.carId === updatedCar.carId);

    if (carIdxToUpdate === -1) {
      return;
    }

    const updatedCarsList = [...cars];
    updatedCarsList[carIdxToUpdate] = updatedCar;
    mutate((prevData) => ({ ...prevData, data: updatedCarsList }));
  };

  function handleError() {
    snackbar.showError(t('alerts.errorRaised'));
  }

  const setAndValidatePackageMode = (mode: boolean) => {
    if (mode) {
      const packageId = cars?.[0]?.packageId;
      if (!packageId) return false;
      const allCarsSamePackage = cars.every((car) => car.packageId === packageId);
      if (!allCarsSamePackage) return false;
      if (totalCount && rowsPerPageOptions && rowsPerPage < totalCount) {
        const newRowsPerPage = rowsPerPageOptions.find((rowsPerPageOption) => rowsPerPageOption > totalCount);
        setRowsPerPage(newRowsPerPage !== undefined ? newRowsPerPage : totalCount);
      }
    }
    setPackageMode(mode);
    return true;
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    updateCar,
    fetch: fetchCars,
    cars,
    setRowsPerPage,
    rowsPerPage,
    page,
    setPage,
    totalCount,
    loading: data && loading,
    sortOn,
    setSortOn,
    packageMode,
    setAndValidatePackageMode,
    mutate,
  };

  useExtendUsersnap({ listRequestPayload: trackingData }, [trackingData]);

  return <CarsTableStore.Provider value={value}>{children}</CarsTableStore.Provider>;
};

export const useCarsTableStore = <T extends boolean = false>(
  legacyFallback?: T,
): T extends false ? CarsTableContext : CarsTableContext | undefined => {
  const context = useContext(CarsTableStore);

  if (!context && !legacyFallback)
    throw new Error(`${useCarsTableStore.name} must be used within a ${CarsTableProvider.name}`);

  return context!;
};

export default CarsTableProvider;
