import { CardHeader, createStyles, Theme, withStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

export const TableHeader = withStyles(
  createStyles((theme: Theme) => ({
    root: {
      borderBottom: `1px solid ${grey[300]}`,
      background: '#fff',
    },
    title: {
      fontSize: '1.125rem',
      fontWeight: 'bold',
      marginBlockEnd: theme.spacing(0.5),
    },
    subheader: {
      fontSize: '0.875rem',
    },
  })),
)(CardHeader);
