import { isEqual } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useRecoilState } from 'recoil';
import { kvpsLocalStorageKey } from 'src/modules/global-vars';
import { getBidDefaults } from '../modules/bid-buy-helpers';
import { MonetaryAmountStrict } from '../modules/currency';
import { DetailCarDTO, PriceType, SourceType } from '../modules/generated/api';
import { setToArray } from '../modules/util';
import { chosenValuationCountryState } from '../stores/state';
import BidFormData from '../types/BidFormData';
import { useCalculation } from './useCalculation';
import { useCurrency } from './useCurrency';
import useRole from './useRole';

export type BidBuyFormType = {
  kvps: string;
  deliveryAddress?: string;
  bid: BidFormData;
  overrideFirstCallCascade?: 'yes' | 'no';
  overridingFirstCallCascadeDealer?: string;
  autoSetBids?: boolean;
  selectedTransportOption?: string;
};
export const noKVPSSources = [SourceType.Ucs, SourceType.SpotdealsAllmobil];

export const useBidBuyForm = (initialCar: DetailCarDTO) => {
  const { hasRole } = useRole();
  const { convert } = useCurrency();
  const [car, setCar] = useState(initialCar);
  const [chosenValuationCountry] = useRecoilState(chosenValuationCountryState);
  const { calculations, fetchCalculation } = useCalculation(car.source as SourceType, car.carId as string);
  const { carId, bid, deliveryAddressSelection, kvps = [] } = car;

  const isNoKVPSCar = noKVPSSources.includes(car.source!);
  const kvpsAddressReferenceBid = bid?.bids ? bid.bids[0] : undefined;

  const kvpsLocalStorage = localStorage.getItem(kvpsLocalStorageKey) || '';
  const defaultKvps =
    kvpsAddressReferenceBid?.kvps ||
    (kvps.every((kvpsDTO) => kvpsDTO.kvps !== kvpsLocalStorage) ? '' : kvpsLocalStorage);

  const formDefaults: BidBuyFormType = useMemo(
    () => ({
      kvps: defaultKvps,
      deliveryAddress:
        kvpsAddressReferenceBid?.deliveryAddress?.id ||
        deliveryAddressSelection?.selectedAddressByKVPS?.[defaultKvps]?.[0] ||
        (isNoKVPSCar &&
          setToArray(deliveryAddressSelection?.deliveryAddresses).find((address) => address.global)?.id) ||
        '',
      bid: {
        ...getBidDefaults({
          car: car || {},
          hasRole,
          convert,
        }),
      },
      overrideFirstCallCascade: 'no',
      overridingFirstCallCascadeDealer: undefined,
      autoSetBids: car.bidStateInfo?.autoRepeatBidOnSubsequentOffer ?? false,
      selectedTransportOption: car.bid?.selectedTransportOption?.id || '',
    }),
    [
      car,
      convert,
      defaultKvps,
      deliveryAddressSelection,
      hasRole,
      isNoKVPSCar,
      kvpsAddressReferenceBid?.deliveryAddress?.id,
    ],
  );

  const form = useForm<BidBuyFormType>({ mode: 'all', defaultValues: formDefaults });
  const { getValues, setValue, watch, trigger, reset } = form;
  const watchKvps = watch('kvps');

  const resetAndRefetch = useCallback(() => {
    reset(formDefaults);
    [1, 2, 3].forEach((bidNr) => {
      const bidNumber = bidNr as 1 | 2 | 3;
      const existingResponse = calculations[bidNumber];
      const inputValue = formDefaults.bid[`bid${bidNumber}`];
      if (isEqual(existingResponse?.base, inputValue) && chosenValuationCountry === existingResponse?.country) {
        setValue(`bid.bid-gross-${bidNumber}`, calculations[bidNumber]?.purchaseGross as MonetaryAmountStrict);
      }
      fetchCalculation({
        fixedVariable: PriceType.OfferPrice,
        value: inputValue,
        valuationCountry: chosenValuationCountry,
        bidNumber,
      }).then((value) => {
        if (value?.purchaseGross) setValue(`bid.bid-gross-${bidNumber}`, value?.purchaseGross as MonetaryAmountStrict);
      });
    });
    // trigger after bid submitted or when paginated
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kvpsAddressReferenceBid, carId, reset, chosenValuationCountry]);

  useEffect(() => {
    const possibleDeliveryAddresses = isNoKVPSCar
      ? setToArray(deliveryAddressSelection?.deliveryAddresses).map((address) => address.id)
      : deliveryAddressSelection?.selectedAddressByKVPS?.[watchKvps];
    const deliveryAddressUsedInBid = kvpsAddressReferenceBid?.deliveryAddress?.id;
    // We need that when we edit a bid (meaning deliveryAddressUsedInBid is defined) and we change the KVPS.
    // It is possible that the delivery address from the bid is not available with the newly chosen KVPS
    const deliveryAddressUsedInBidSafe =
      deliveryAddressUsedInBid &&
      possibleDeliveryAddresses?.includes(deliveryAddressUsedInBid) &&
      deliveryAddressUsedInBid;

    setValue('deliveryAddress', deliveryAddressUsedInBidSafe || possibleDeliveryAddresses?.[0] || '', {
      shouldValidate: true,
    });
  }, [
    setValue,
    deliveryAddressSelection?.selectedAddressByKVPS,
    getValues,
    kvpsAddressReferenceBid,
    watchKvps,
    trigger,
    isNoKVPSCar,
    deliveryAddressSelection?.deliveryAddresses,
  ]);

  useEffect(() => {
    if (watchKvps && !kvpsAddressReferenceBid?.kvps) window.localStorage.setItem(kvpsLocalStorageKey, watchKvps);
  }, [setValue, kvps, watchKvps, kvpsAddressReferenceBid?.kvps]);

  // On load validate default values
  useEffect(() => {
    if (car) trigger(['kvps', 'deliveryAddress']);
  }, [car, trigger]);

  useEffect(() => {
    resetAndRefetch();
  }, [resetAndRefetch]);

  useEffect(() => {
    setCar(initialCar);
  }, [initialCar]);

  return {
    form,
    resetAndRefetch,
    fetchCalculation,
  };
};
