import { IconButton, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { memo, MouseEvent } from 'react';
import EditIcon from '@material-ui/icons/Edit';

type CarTableEditButtonProps = {
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
  hide?: boolean;
};

const useStyles = makeStyles((theme) => ({
  editIcon: {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    marginRight: 'auto',
    visibility: 'hidden',

    '& .MuiSvgIcon-root': { fontSize: '1rem' },
  },
}));

const CarTableEditButton = ({ onClick, hide }: CarTableEditButtonProps) => {
  const classes = useStyles();

  return (
    <IconButton className={clsx(classes.editIcon, !hide && 'car-row-edit-button')} onClick={onClick} size="small">
      <EditIcon />
    </IconButton>
  );
};

export default memo(CarTableEditButton);
