import { memo, useCallback, useState } from 'react';
import { Grid, InputAdornment, makeStyles, Slider, TextField, TextFieldProps, Theme } from '@material-ui/core';
import { useFormContext, useWatch } from 'react-hook-form';
import { debounce, isNumber } from 'lodash';
import { InputLabel } from '@components/ui';
import { grey } from '@material-ui/core/colors';
import { useTranslation } from 'react-i18next';
import AmountInput from './AmountInput';
import { useDidUpdateEffect } from '../hooks/useDidUpdateEffect';
import {
  DEFAULT_CURRENCY,
  makeMonetaryAmount,
  MonetaryAmountStrict,
  SupportedCurrencyCode,
  SUPPORTED_CURRENCIES,
} from '../modules/currency';
import { useCurrency } from '../hooks/useCurrency';

type SliderInputProps = {
  name: string;
  label: string;
  step: number;
  min: number;
  max: number;
  marginTop?: boolean;
};

type MonetaryAmountTuple = [MonetaryAmountStrict, MonetaryAmountStrict];

const useStyles = makeStyles<Theme, { marginTop: boolean }>((theme) => ({
  label: {
    display: 'block',
    marginBottom: theme.spacing(-0.5),
    marginTop: ({ marginTop }) => (marginTop ? 8 : 0),
  },
  textField: {
    marginTop: theme.spacing(-0.5),
    marginBottom: theme.spacing(2),
  },
}));

const serializeValue = (value: [number, number], currencyCode: SupportedCurrencyCode) => [
  isNumber(value[0]) ? makeMonetaryAmount(value[0], currencyCode) : value[0],
  isNumber(value[1]) ? makeMonetaryAmount(value[1], currencyCode) : value[1],
];

const deserializeValue = (
  value: MonetaryAmountTuple,
  currencyCb?: (value: number, currencyCode: SupportedCurrencyCode) => number,
): [number, number] => {
  const [left, right] = value;

  return [
    currencyCb?.(left.amount, left.currency as SupportedCurrencyCode) ?? left.amount,
    currencyCb?.(right.amount, right.currency as SupportedCurrencyCode) ?? right.amount,
  ];
};

const CurrencySliderInput = ({ name, label, step, min, max, marginTop = false }: SliderInputProps) => {
  const { t } = useTranslation();
  const classes = useStyles({ marginTop });
  const { currency, convert } = useCurrency();
  const minLocalized = convert(min, { from: DEFAULT_CURRENCY.code });
  const maxLocalized = convert(max, { from: DEFAULT_CURRENCY.code });
  const stepLocalized = convert(step, { from: DEFAULT_CURRENCY.code });
  const { setValue: setFormValue, getValues: getFormValue, control } = useFormContext();
  const [value, setValue] = useState(deserializeValue(getFormValue(name), (v, c) => convert(v, { from: c })));
  const formValue = useWatch({ name, control }) as [MonetaryAmountStrict, MonetaryAmountStrict];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setFormValueDebounced = useCallback(debounce(setFormValue, 400), [setFormValue]);

  // populate internal state updates
  useDidUpdateEffect(() => {
    setFormValueDebounced(name, serializeValue(value, currency), { shouldTouch: true });

    return () => {
      setFormValueDebounced.cancel();
    };
  }, [value, name, setFormValueDebounced]);

  // sync external state updates
  useDidUpdateEffect(() => {
    setValue(deserializeValue(formValue, (v, c) => convert(v, { from: c })));
  }, [setValue, formValue, convert]);

  return (
    <div>
      <InputLabel
        style={{
          marginBlockEnd: 2,
          fontWeight: 'normal',
          fontSize: 12,
          color: grey[600],
        }}
      >
        {t(label as any)}
      </InputLabel>
      <Slider
        value={value}
        min={minLocalized}
        max={maxLocalized}
        step={stepLocalized}
        aria-labelledby={name}
        onChange={(_, changeValue) => {
          setValue(changeValue as [number, number]);
        }}
        color="secondary"
      />
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <AmountInput<TextFieldProps>
            className={classes.textField}
            customInput={TextField}
            inputProps={{
              step: stepLocalized,
              min: minLocalized,
              max: maxLocalized,
              'aria-labelledby': name,
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">{SUPPORTED_CURRENCIES[currency].symbol}</InputAdornment>,
            }}
            margin="dense"
            value={value[0]}
            variant="outlined"
            onValueChange={({ value: unformattedValue }) => {
              setValue((prev: [number, number]) => [parseInt(unformattedValue, 10), prev[1]]);
            }}
            isAllowed={({ value: unformattedValue }) => !unformattedValue || parseInt(unformattedValue, 10) <= max}
            onBlur={(event) => {
              if (event.target.value === '') {
                setValue((prev) => [min, prev[1]]);
              }
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <AmountInput<TextFieldProps>
            className={classes.textField}
            customInput={TextField}
            inputProps={{
              step: stepLocalized,
              min: minLocalized,
              max: maxLocalized,
              'aria-labelledby': name,
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">{SUPPORTED_CURRENCIES[currency].symbol}</InputAdornment>,
            }}
            margin="dense"
            variant="outlined"
            value={value[1]}
            onValueChange={({ value: unformattedValue }) => {
              setValue((prev: [number, number]) => [prev[0], parseInt(unformattedValue, 10)]);
            }}
            isAllowed={({ value: unformattedValue }) => !unformattedValue || parseInt(unformattedValue, 10) <= max}
            onBlur={(event) => {
              if (event.target.value === '') {
                setValue((prev) => [prev[0], max]);
              }
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default memo(CurrencySliderInput);
