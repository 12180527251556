import { Card, Table as MuiTable, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { TableCell } from '@components/ui';
import { flexRender, Table as ReactTable } from '@tanstack/react-table';
import { TableHeader } from '@components/ui/TableHeader';

export type TableProps = {
  table: ReactTable<any>;
  className?: string;
  title?: string;
  subheader?: string;
  id?: string;
};

export const Table = ({ table, className, title, subheader, id }: TableProps): JSX.Element => (
  <TableContainer component={Card} className={className} id={id}>
    {title || subheader ? <TableHeader title={title} subheader={subheader} /> : null}
    <MuiTable>
      <TableHead>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <TableCell component="th" key={header.id} colSpan={header.colSpan}>
                {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody>
        {table.getRowModel().rows.map((row) => (
          <TableRow key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </MuiTable>
  </TableContainer>
);
