import { getImageDimensions, SanityImageAsset } from '@sanity/asset-utils';
import urlBuilder from '@sanity/image-url';
import { client } from '@iris/content';
import { PortableTextTypeComponent } from '@portabletext/react';
import { Media } from './Media';

export const Image: PortableTextTypeComponent<SanityImageAsset> = ({ value }) => {
  const { width, height } = getImageDimensions(value);

  return (
    <Media>
      <img
        src={urlBuilder(client).image(value).width(1600).fit('max').auto('format').url()}
        alt=""
        loading="lazy"
        style={{
          aspectRatio: `${width} / ${height}`,
        }}
      />
    </Media>
  );
};
