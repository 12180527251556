import { useLocation } from '@reach/router';
import { useEffect } from 'react';

export const useTitle = (title?: string) => {
  const location = useLocation();

  useEffect(() => {
    document.title = title ? `ALLUC - ${title}` : 'ALLUC';
  }, [title, location]);
};
