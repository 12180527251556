import { BidVisibility } from 'src/modules/generated/api';
import { tr } from 'src/modules/i18n-helpers';
import { bidVisibilityTypes, getLabel } from 'src/modules/labels';
import FilterChip from './FilterChip';
import { useBidsFilter } from './useBidsFilter';

type RadioFilterChipProps = {
  value: any;
  name: string;
  label: string;
};

const RadioFilterChip = ({ value, name, label }: RadioFilterChipProps) => {
  const { setValue } = useBidsFilter();
  return (
    <FilterChip
      resetEffect="undefined"
      name={name}
      label={getLabel(bidVisibilityTypes, value)}
      tooltip={tr(label)}
      onDelete={() => setValue('bidVisibility', BidVisibility.All)}
    />
  );
};

export default RadioFilterChip;
