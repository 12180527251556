import { FormControl, FormControlLabel, FormLabel, makeStyles, Radio, RadioGroup, Tooltip } from '@material-ui/core';
import { memo } from 'react';
import { Control, Controller } from 'react-hook-form';
import { InfoOutlined } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles(() => ({
  formGroup: {
    flexDirection: 'row',
    '& .Mui-checked': {
      color: grey[800],
    },
    '& .Mui-checked  + span': {
      color: grey[800],
    },
  },
  infoIcon: {
    verticalAlign: 'middle',
    fontSize: '1.2rem',
  },
}));

type BiddingTimeRadiosProps = {
  name: string;
  control: Control;
  activateForm: boolean;
};

const BiddingTimeRadios = ({ name, control, activateForm }: BiddingTimeRadiosProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <FormControl component="fieldset" margin="dense" fullWidth>
      <FormLabel component="legend">{t('bids.submissionAtAuctionEnd_long')}</FormLabel>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <RadioGroup className={classes.formGroup} {...field}>
            {[
              {
                value: '60',
                label: t('bids.submissionAtAuctionEnd_short'),
                hint: t('bids.hintSubmissionAtAuctionEnd'),
              },
              { value: '0', label: t('bids.instantly') },
            ].map(({ value: itemValue, label, hint }) => {
              const labelWithHint = (
                <>
                  {`${label} `}
                  {hint && (
                    <Tooltip title={hint}>
                      <InfoOutlined className={classes.infoIcon} />
                    </Tooltip>
                  )}
                </>
              );
              return (
                <FormControlLabel
                  key={label}
                  value={itemValue}
                  disabled={!activateForm}
                  control={<Radio />}
                  label={labelWithHint}
                />
              );
            })}
          </RadioGroup>
        )}
      />
    </FormControl>
  );
};

export default memo(BiddingTimeRadios);
