import { ChangeEvent, useState } from 'react';
import { FormControl, makeStyles, MenuItem, Select } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useAuthenticationStore } from '../stores/Authentication';
import useRole from '../hooks/useRole';
import UserRole from '../types/UserRoles';

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(2),
    backgroundColor: theme.palette.secondary.light,
    '& .MuiFilledInput-input': {
      padding: '5px 25px 5px 5px',
      color: theme.palette.secondary.contrastText,
      border: 'none',
    },
    '& svg': {
      right: '0px',
      color: theme.palette.secondary.contrastText,
    },
  },
  admin: {
    backgroundColor: theme.palette.primary.light,
  },
}));

const RoleSimulationChip = () => {
  const classes = useStyles();
  const { dispatch } = useAuthenticationStore();
  const { hasRole } = useRole();
  const { t } = useTranslation();
  const [role, simulateRole] = useState<UserRole>(UserRole.ADMIN);

  const handleChange = (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    const {
      target: { value: newRole },
    } = event;

    simulateRole(newRole as UserRole);
    switch (newRole) {
      case UserRole.ADMIN:
        dispatch({ type: 'authenticationStore/TOGGLE_SIMULATION_OFF' });
        break;
      case UserRole.DEALER:
        dispatch({ type: 'authenticationStore/TOGGLE_SIMULATION_DEALER' });
        break;
      case UserRole.DEALERADMIN:
        dispatch({ type: 'authenticationStore/TOGGLE_SIMULATION_DEALERADMIN' });
        break;
      case UserRole.COUNTRYMANAGER:
        dispatch({ type: 'authenticationStore/TOGGLE_SIMULATION_COUNTRYMANAGER' });
        break;
      default:
        dispatch({ type: 'authenticationStore/TOGGLE_SIMULATION_OFF' });
    }
  };

  return hasRole(UserRole.ADMIN, true) ? (
    <FormControl variant="filled" className={clsx(classes.root, role === UserRole.ADMIN && classes.admin)}>
      <Select value={role} onChange={handleChange}>
        <MenuItem value={UserRole.ADMIN}>{t('navigation.admin')}</MenuItem>
        <MenuItem value={UserRole.DEALER}>{t('navigation.dealer')}</MenuItem>
        <MenuItem value={UserRole.DEALERADMIN}>{t('navigation.dealerAdmin')}</MenuItem>
        <MenuItem value={UserRole.COUNTRYMANAGER}>{t('navigation.countrymanager')}</MenuItem>
      </Select>
    </FormControl>
  ) : null;
};

export default RoleSimulationChip;
