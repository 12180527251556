import { Address } from '@components/ui/address/types';
import flatten from 'flat';
import { DeepMap, FieldError, FieldValues } from 'react-hook-form';
import { DeliveryAddressDTO } from './modules/generated/api';

const flattenErrors = (errors: DeepMap<FieldValues, FieldError>): { [key: string]: string } => flatten(errors);

export const transformDeliveryAddressDtoToAddress = (deliveryAddress?: DeliveryAddressDTO): Address => ({
  name: deliveryAddress?.contactPersonName || '',
  companyName: deliveryAddress?.companyName || '',
  street: deliveryAddress?.street || '',
  city: deliveryAddress?.city || '',
  zip: deliveryAddress?.zip || '',
  country: deliveryAddress?.country || '',
  countryCode: deliveryAddress?.countryCode || '',
  email: deliveryAddress?.email || '',
  phoneNumber: deliveryAddress?.phoneNumber || '',
  faxNumber: deliveryAddress?.faxNumber || '',
});

export default flattenErrors;
