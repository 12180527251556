import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { Add, RotateLeft, Save } from '@material-ui/icons';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ConditionalFormattingResetDialog } from '../components/ConditionalFormattingResetDialog';
import ConfigurationFormattingFieldRow, {
  ConfigurationFormattingFieldValues,
} from '../components/ConfigurationFormattingFieldRow';
import FilterAutocomplete from '../components/FilterAutocomplete';
import useApi from '../hooks/useApi';
import useRole from '../hooks/useRole';
import useCustomSnackbarGlobal from '../hooks/useSnackbarGlobal';
import { useWizard } from '../hooks/useWizard';
import ApiService from '../modules/api-service';
import { ConditionalFormattingDTO, ConditionalFormattingType, WizardStep } from '../modules/generated/api';
import { Option } from '../modules/i18n-helpers';
import { conditionalFormattingTypes, taxes, taxesPerCountry } from '../modules/labels';
import { getAccountValuationCountry } from '../modules/valuation-country-helpers';
import { Tax } from '../types/tax';
import UserRole from '../types/UserRoles';

const isTaxOption = (option: Option): option is Option<Tax> => taxes.some((tax) => tax.value === option.value);

const useStyles = makeStyles((theme) => ({
  divider: {
    borderTop: `1px solid ${theme.palette.secondary.main}`,
    marginTop: theme.spacing(4),
    paddingTop: theme.spacing(4),
  },
  headline: {
    marginBottom: theme.spacing(2),
  },
  addFieldWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  addField: {
    width: theme.spacing(30),
    marginRight: theme.spacing(2),
  },
  btnGroup: {
    gap: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    '@media screen and (max-width: 1350px)': {
      marginRight: theme.spacing(4),
    },
  },
}));
type ConfigurationFormattingFormProps = {
  showInWizard?: boolean;
};

const ConfigurationFormattingForm = ({ showInWizard }: ConfigurationFormattingFormProps) => {
  const { fetch, data: configurations } = useApi<ConditionalFormattingDTO[]>();
  const { fetch: fetchUpdate } = useApi<ConditionalFormattingDTO[]>();
  const methods = useForm<ConfigurationFormattingFieldValues>({
    defaultValues: {
      addField: '' as ConditionalFormattingType,
      values: [],
    },
  });
  const { setValue, control, getValues, watch } = methods;
  const { fields, append, remove } = useFieldArray({ control, name: 'values' as 'values' });
  const classes = useStyles();
  const addField = watch('addField');
  const snackbar = useCustomSnackbarGlobal();
  const { t } = useTranslation();
  const { hasRole } = useRole();
  const [showResetDialog, setShowResetDialog] = useState(false);
  const { useTrackWizardConfirmationOnPageView } = useWizard();
  const currentValuationCountry = getAccountValuationCountry(true);
  const currentValuationCountryTaxes = taxesPerCountry[currentValuationCountry];

  const setFormValues = useCallback(
    (fromConfiguration: ConditionalFormattingDTO[]) => {
      setValue('values', fromConfiguration as Required<ConditionalFormattingDTO>[]);
    },
    [setValue],
  );

  const isUnusedField = (item: Option) => fields.every((field) => field.field !== item.value);

  const isAllowedTaxField = (item: Option) => {
    const isTax = isTaxOption(item);

    if (!isTax) {
      return true;
    }

    return hasRole(UserRole.ADMIN) || currentValuationCountryTaxes.includes(item.value as Tax);
  };

  const handleCloseResetDialog = (confirmed: boolean) => {
    setShowResetDialog(false);

    if (confirmed) {
      ApiService.config.configControllerResetConditionalFormatting().then(({ data }) => {
        setFormValues(data);
        snackbar.showSuccess(t('alerts.successfulReset'));
      });
    }
  };

  useEffect(() => {
    fetch(ApiService.config.configControllerGetConditionalFormatting());
  }, [fetch]);

  useEffect(() => {
    if (configurations) {
      setFormValues(configurations);
    }
  }, [configurations, setFormValues]);

  useTrackWizardConfirmationOnPageView(WizardStep.ConditionalFormatting, showInWizard);

  return (
    <>
      <ConditionalFormattingResetDialog open={showResetDialog} handleClose={handleCloseResetDialog} />
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(async (data) => {
            const result = await fetchUpdate(ApiService.config.configControllerSaveConditionalFormatting(data.values));
            if (result && result.data) {
              setFormValues(result.data);
              snackbar.showSuccess(t('alerts.successSaved'));
            } else {
              snackbar.showError(t('alerts.errorRaised'));
            }
          })}
        >
          <Typography variant="h1" className={classes.headline}>
            {t('conditionalFormatting.formattingConfigHeader')}
          </Typography>

          <div>
            {fields.map((field, index) => (
              <ConfigurationFormattingFieldRow
                key={field.id}
                parentField={field}
                index={index}
                onRemove={() => remove(index)}
              />
            ))}
          </div>

          <Grid container spacing={2} justifyContent="space-between">
            <Grid item>
              <div className={classes.addFieldWrapper}>
                <div className={classes.addField}>
                  <FilterAutocomplete
                    disableClearable
                    label={t('conditionalFormatting.field')}
                    name="addField"
                    options={conditionalFormattingTypes.filter(isAllowedTaxField).filter(isUnusedField)}
                    hideCheckbox
                  />
                </div>
                <Button
                  endIcon={<Add />}
                  color="secondary"
                  variant="contained"
                  disabled={(addField as string) === ''}
                  onClick={() => {
                    const field = getValues('addField');

                    if (field) {
                      append({ field, conditions: [{}] }, { shouldFocus: false });
                      setValue('addField', '' as ConditionalFormattingType);
                    }
                  }}
                >
                  {t('conditionalFormatting.addField')}
                </Button>
              </div>
            </Grid>
            <Grid item className={classes.btnGroup}>
              <Button
                endIcon={<RotateLeft />}
                color="secondary"
                variant="contained"
                onClick={() => setShowResetDialog(true)}
              >
                {t('common.reset')}
              </Button>
              <Button endIcon={<Save />} color="primary" variant="contained" type="submit">
                {t('common.save')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
};

export default ConfigurationFormattingForm;
