import { ConditionalFormattingDTO, ConditionalFormattingType } from './generated/api';
import { ConditionalFormattingScope } from './generated/api/api';

export const findConditionalFormattingDetail = (
  searchField: ConditionalFormattingType,
  fields: ConditionalFormattingDTO[],
) => fields.find(({ field }) => searchField === field);

export const findConditionalFormattingRow = (
  fields: ConditionalFormattingDTO[], // # return all fields that have the conditions with the scope row
) => {
  const withRow = fields.filter(({ conditions }) =>
    conditions?.some(({ scope }) => scope === ConditionalFormattingScope.Row),
  );
  withRow.forEach((field) => {
    if (field.conditions) {
      // eslint-disable-next-line no-param-reassign
      field.conditions = field.conditions.filter(({ scope }) => scope === ConditionalFormattingScope.Row);
    }
  });
  return withRow;
};

export const findConditionalFormattingFieldName = (searchField: string) => {
  for (const [key, value] of Object.entries(ConditionalFormattingType)) {
    if (value === searchField) {
      // make the first letter lowercase to find the right translation
      return key.charAt(0).toLowerCase() + key.slice(1);
    }
  }
  return searchField;
};

export const conditionalFormattingCreateBackground = (colors: string[], fallback?: string) =>
  colors.length > 0
    ? `repeating-linear-gradient(45deg, ${colors
        .map((color, index) => `${color} ${index * 24}px, ${color} ${(index + 1) * 24}px`)
        .join(', ')})`
    : fallback || 'transparent';

export const conditionalFormattingNumber = (
  value: number | undefined,
  searchField: ConditionalFormattingType,
  fields: ConditionalFormattingDTO[],
) => {
  const colors = [];

  if (value !== undefined) {
    const conditionalFormattingDetail = findConditionalFormattingDetail(searchField, fields);

    if (conditionalFormattingDetail && conditionalFormattingDetail.conditions) {
      // eslint-disable-next-line no-restricted-syntax
      for (const condition of conditionalFormattingDetail.conditions) {
        if (condition.backgroundColor) {
          if (condition.gt !== undefined && condition.lt !== undefined) {
            if (value > condition.gt && value < condition.lt) {
              colors.push(condition.backgroundColor);
            }
          } else if (condition.gt !== undefined) {
            if (value > condition.gt) colors.push(condition.backgroundColor);
          } else if (condition.lt !== undefined) {
            if (value < condition.lt) colors.push(condition.backgroundColor);
          }
        }
      }
    }
  }
  return colors;
};

export const conditionalFormattingString = (
  value: string | undefined,
  searchField: ConditionalFormattingType,
  fields: ConditionalFormattingDTO[],
): string[] => {
  const colors = [];

  if (value !== undefined) {
    const conditionalFormattingDetail = findConditionalFormattingDetail(searchField, fields);

    if (conditionalFormattingDetail && conditionalFormattingDetail.conditions) {
      // eslint-disable-next-line no-restricted-syntax
      for (const condition of conditionalFormattingDetail.conditions) {
        if (condition.backgroundColor) {
          if (condition.eq && value.toLowerCase() === condition.eq.toLowerCase()) {
            colors.push(condition.backgroundColor);
          } else if (condition.contains) {
            const conditions = Array.isArray(condition.contains) ? condition.contains : [condition.contains];
            if (conditions.some((c) => value.toLowerCase().includes(c.toLowerCase()))) {
              colors.push(condition.backgroundColor);
            }
          }
        }
      }
    }
  }
  return colors;
};

export const conditionalFormattingEnumArray = (
  value: string[] | undefined,
  searchField: ConditionalFormattingType,
  fields: ConditionalFormattingDTO[],
) => {
  const colors = [];

  if (value !== undefined) {
    const conditionalFormattingDetail = findConditionalFormattingDetail(searchField, fields);

    if (conditionalFormattingDetail && conditionalFormattingDetail.conditions) {
      // eslint-disable-next-line no-restricted-syntax
      for (const condition of conditionalFormattingDetail.conditions) {
        if (
          condition.backgroundColor &&
          condition.contains &&
          (Array.isArray(condition.contains)
            ? condition.contains.some((c) => value.includes(c))
            : value.includes(condition.contains))
        ) {
          colors.push(condition.backgroundColor);
        }
      }
    }
  }

  return colors;
};
