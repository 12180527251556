import { memo, MouseEvent, useEffect, useRef, useState } from 'react';
import { CardActionArea, Divider, Grid, makeStyles, Popover, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { AddComment } from '@material-ui/icons';
import { ListCarDTO, SourceType } from '../modules/generated/api';
import CarDetailUserInformation from '../containers/CarDetailUserInformation';
import UserRating from './UserRating';
import handleStopPropagation from '../modules/jsx-helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: theme.spacing(25),
    maxHeight: theme.spacing(15),
    width: theme.spacing(16),
    overflow: 'auto',
    whiteSpace: 'pre-line',
  },
  showComment: {
    fontSize: '0.8rem',
    padding: theme.spacing(0.8),
  },
  addCommentBtn: {
    marginTop: theme.spacing(1.2),
    marginBottom: theme.spacing(1),
  },
}));

type CarsTableCellMyUserInformationProps = { car: ListCarDTO; refetch?: () => Promise<void> };

const CarsTableCellMyUserInformation = ({ car, refetch }: CarsTableCellMyUserInformationProps) => {
  const classes = useStyles();
  const [edit, setEdit] = useState(false);
  const anchorEl = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setEdit(false);
  }, [car.carId]);

  const handleOnClick = (event: MouseEvent) => {
    event.stopPropagation();
    setEdit(true);
  };

  const handleSubmit = async () => {
    setEdit(false);
    if (refetch) refetch();
  };

  return (
    <div
      className={clsx(classes.root)}
      onKeyDown={handleStopPropagation}
      role="button"
      tabIndex={0}
      onClick={handleStopPropagation}
      ref={anchorEl}
    >
      <CardActionArea onClick={(e) => handleOnClick(e)}>
        <Grid container justifyContent="center">
          <Grid item>
            <UserRating car={car} namePrefix="carsTable" refresh={handleSubmit} />
          </Grid>
        </Grid>
        <Divider />
        <Grid container justifyContent={car.userComment ? 'flex-start' : 'center'}>
          <Grid item>
            {car.userComment ? (
              <Typography className={classes.showComment} paragraph>
                {car.userComment}
              </Typography>
            ) : (
              <AddComment className={classes.addCommentBtn} color="action" />
            )}
          </Grid>
        </Grid>
      </CardActionArea>

      {edit && (
        <Popover
          open
          onClose={handleSubmit}
          anchorEl={anchorEl.current}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          <CarDetailUserInformation
            car={car}
            carId={car.carId as string}
            source={car.source as SourceType}
            userComment={car.userComment}
            userRatingNamePrefix="carsTable"
            editDefault
            setEdit={setEdit}
            refresh={refetch}
          />
        </Popover>
      )}
    </div>
  );
};
export default memo(CarsTableCellMyUserInformation);
