import { groupBy } from 'lodash';
import { Stack, Teaser, TeaserContent, TeaserLinkChevron, TeaserLinkEndAdornment, TeaserTitle } from '@components/ui';
import { Typography } from '@material-ui/core';
import { Link } from '@reach/router';
import { Spinner } from '@components/spinner';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from 'src/constants';
import { fetchFaqBySlug } from '@components/help-center/useFaq';
import { useTranslation } from 'react-i18next';
import useRole from 'src/hooks/useRole';
import UserRole from 'src/types/UserRoles';
import { useAuthenticationStore } from 'src/stores/Authentication';
import { AllFaqByCategory, useAllFaqByCategory } from './useAllFaqByCategory';

type FaqListProps = {
  categorySlug: string;
};

const groupFaqBySubCategory = (faqList: AllFaqByCategory) => groupBy(faqList, 'subCategory.title');

export const FaqList = ({ categorySlug }: FaqListProps) => {
  const { state: authState } = useAuthenticationStore();
  const { hasRole } = useRole();
  const { i18n } = useTranslation();
  const queryClient = useQueryClient();
  const { data: faqList } = useAllFaqByCategory({ category: categorySlug });
  const allowedFaqList = hasRole(UserRole.ADMIN)
    ? faqList
    : faqList?.filter(
        (faq) =>
          faq.countries.length === 0 ||
          (authState.objectToken?.country && faq.countries.includes(authState.objectToken.country)),
      );
  const faqBySubCategory = allowedFaqList && groupFaqBySubCategory(allowedFaqList);

  const prefetchFaq = (slug: string) => {
    // prefetch faq answer content
    const faqVariables = { slug, lang: i18n.language };
    queryClient.prefetchQuery([QueryKeys.faq, faqVariables], () => fetchFaqBySlug(faqVariables));
  };

  return (
    <Stack spacing={3}>
      {faqBySubCategory &&
        Object.keys(faqBySubCategory).map((subCategory) => (
          <div key={subCategory}>
            {subCategory !== 'undefined' && (
              <Typography variant="overline" gutterBottom>
                {subCategory}
              </Typography>
            )}

            <Stack spacing={1}>
              {faqBySubCategory[subCategory]?.map(({ _id, title, slug }) => (
                <Teaser
                  key={_id}
                  // @ts-expect-error: Invalid lib typings
                  component={Link}
                  to={`/help/${categorySlug}/${slug}`}
                  onPointerEnter={() => prefetchFaq(slug)}
                >
                  <TeaserContent>
                    <TeaserTitle>{title}</TeaserTitle>
                  </TeaserContent>
                  <TeaserLinkEndAdornment>
                    <TeaserLinkChevron />
                  </TeaserLinkEndAdornment>
                </Teaser>
              ))}
            </Stack>
          </div>
        ))}
    </Stack>
  );
};

export const FaqListFallback = () => <Spinner isLoading busyDelayMs={200} size="medium" />;
