import { InputAdornment, TextField } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { memo, useCallback, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import BooleanCheckbox from './BooleanCheckbox';
import { VIN_PREFIXES } from '../modules/data';
import { useDidUpdateEffect } from '../hooks/useDidUpdateEffect';

const VIN_LENGTH = 17;

const isVIN = (value: string) => {
  const trimmed = value.trim().toUpperCase();
  return trimmed.length === VIN_LENGTH && VIN_PREFIXES.some((prefix) => trimmed.startsWith(prefix));
};

const SearchInput = () => {
  const { register, setValue: setFormValue, getValues: getFormValues, control } = useFormContext();
  const { t } = useTranslation();
  const name = 'search.value';
  const checkBoxName = 'searchExact';
  const vinValue: string[] = getFormValues('vinSearch');
  const formValue = useWatch({ name, control });
  const [value, setValue] = useState(formValue);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setFormValueDebounced = useCallback(debounce(setFormValue, 400), [setFormValue]);

  // populate internal state updates
  useDidUpdateEffect(() => {
    setFormValueDebounced(name, value);

    return () => {
      setFormValueDebounced.cancel();
    };
  }, [setFormValueDebounced, value, name]);

  // sync external state updates
  useDidUpdateEffect(() => {
    setValue(formValue);
  }, [setValue, formValue]);

  return (
    <>
      <TextField
        name={name}
        value={value}
        onChange={(event) => {
          const vin = isVIN(event.target.value);
          if (vin) setFormValue(checkBoxName, true);
          setValue(event.target.value);
        }}
        label={t('filterSearch.search')}
        variant="outlined"
        margin="dense"
        disabled={!!vinValue}
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <BooleanCheckbox label={t('filterSearch.exact')} name={checkBoxName} disabled={!!vinValue} />
            </InputAdornment>
          ),
        }}
      />
      <input {...register('search.regex')} type="hidden" />
    </>
  );
};

export default memo(SearchInput);
