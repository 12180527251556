import ImprintContent from '@components/ImprintContent';
import { PageMeta } from '@components/page-meta';
import PageContainer from '@components/PageContainer';
import { RouteComponentProps } from '@reach/router';

const ImprintPage = (_props: RouteComponentProps) => (
  <>
    <PageMeta title="navigation.imprint" />
    <PageContainer>
      <ImprintContent />
    </PageContainer>
  </>
);

export default ImprintPage;
