import { Avatar, Chip, Grid, makeStyles } from '@material-ui/core';
import { PriorityHigh } from '@material-ui/icons';
import { isArray } from 'lodash';
import { useRef } from 'react';
import { OverlayContainer, useOverlayPosition, useOverlayTrigger } from 'react-aria';
import { useTranslation } from 'react-i18next';
import { useOverlayTriggerState } from 'react-stately';
import { tr } from 'src/modules/i18n-helpers';
import { getLabel } from 'src/modules/labels';
import { Option } from '../../../modules/i18n-helpers';
import { DocView, DocViewTitle } from '../../ui/doc-view/DocView';
import FilterChip from './FilterChip';

type ArrayFilterChipProps = {
  value: string[];
  name: string;
  tooltip: string;
  labelObj?: readonly Option[];
  formatFunction?: (model: string) => string;
  labelFunction?: (entry: unknown) => string;
};

const useStyles = makeStyles(() => ({
  chip: {
    '& span': { alignSelf: 'flex-end' },
  },
  gridSpacing: {
    '& div': { padding: '2px' },
  },
}));

const ArrayFilterChip = ({ name, value, tooltip, labelObj, formatFunction, labelFunction }: ArrayFilterChipProps) => {
  const classes = useStyles();
  const formatLabel = (rawLabel: string): string => {
    if (labelObj) {
      return getLabel(labelObj, rawLabel);
    }
    if (formatFunction) {
      return formatFunction(rawLabel);
    }
    return rawLabel;
  };

  const overlayState = useOverlayTriggerState({});
  const triggerRef = useRef<HTMLDivElement>(null);
  const overlayRef = useRef(null);
  const { t } = useTranslation();
  const { overlayProps } = useOverlayTrigger({ type: 'dialog' }, overlayState, triggerRef);
  const { overlayProps: positionProps } = useOverlayPosition({
    targetRef: triggerRef,
    overlayRef,
    placement: 'right top',
    offset: 2,
    isOpen: overlayState.isOpen,
  });

  if (isArray(value))
    return (
      <>
        {[...value.slice(0, 3)].map(
          (
            entry: unknown, // only show top 3 Array items like in Filter
          ) => (
            <FilterChip
              name={name}
              key={`${name}-${entry}`}
              tooltip={entry === 'AUDI_GWS' ? t('alerts.audi') : tr(tooltip)}
              label={labelFunction ? labelFunction(entry) : formatLabel(entry as string)}
              resetEffect="remove"
              value={entry}
              avatar={entry === 'AUDI_GWS' ? <PriorityHigh /> : '' || undefined}
            />
          ),
        )}
        {value.length > 3 && ( // group other items in one chip with overlay
          <Grid item>
            <Chip
              avatar={<Avatar>{`+${value.length - 3}`}</Avatar>}
              size="small"
              className={classes.chip}
              label={tr(tooltip)}
              onClick={() => overlayState.setOpen(true)}
              ref={triggerRef}
            />
          </Grid>
        )}
        {overlayState.isOpen && value.length > 3 && (
          <OverlayContainer>
            <DocView
              {...overlayProps}
              {...positionProps}
              title={() => <DocViewTitle>{tr(tooltip)}</DocViewTitle>}
              ref={overlayRef}
              onClose={overlayState.close}
              isOpen={overlayState.isOpen}
            >
              <Grid container className={classes.gridSpacing} alignItems="center">
                {[...value.slice(3, value.length)].map((entry: unknown) => (
                  <FilterChip
                    name={name}
                    key={`${name}-${entry}`}
                    tooltip={tr(tooltip)}
                    label={labelFunction ? labelFunction(entry) : formatLabel(entry as string)}
                    resetEffect="remove"
                    value={entry}
                  />
                ))}
              </Grid>
            </DocView>
          </OverlayContainer>
        )}
      </>
    );

  return null;
};
export default ArrayFilterChip;
