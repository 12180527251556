import { memo } from 'react';
import { makeStyles } from '@material-ui/core';
import UnitValue, { UnitValueProps } from './UnitValue';

const useStyles = makeStyles({
  root: {
    textAlign: 'right',
    whiteSpace: 'nowrap',
    letterSpacing: 0,
    height: '1rem',
    lineHeight: '1rem',
  },
});

const UnitValueCell = (props: UnitValueProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <UnitValue {...props} />
    </div>
  );
};

export default memo(UnitValueCell);
