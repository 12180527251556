type GetPersistedValueOptions<T> = {
  initialValue: T;
  validator?: (value: unknown) => void;
  transform?: (value: T) => T;
};

export const getPersistedValue = <T>(key: string, { initialValue, validator }: GetPersistedValueOptions<T>): T => {
  try {
    const item = window.localStorage.getItem(key) as string | null;
    if (item) validator?.(JSON.parse(item));
    return item ? JSON.parse(item) : initialValue;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(key, error);
    return initialValue;
  }
};

export const persistValue = (key: string, value: any) => {
  try {
    const valueToStore = JSON.stringify(value);
    localStorage.setItem(key, valueToStore);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn('Unable to persist filter:\n', error);
  }
};
