import {
  Button,
  FormControlLabel,
  Grid,
  Link,
  Paper,
  Switch,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  makeStyles,
  styled,
} from '@material-ui/core';
import { Apps } from '@material-ui/icons';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import LaunchIcon from '@material-ui/icons/Launch';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { memo, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import useApi from 'src/hooks/useApi';
import useCustomSnackbarGlobal from 'src/hooks/useSnackbarGlobal';
import { capitalizeThreeOrLessChars, capitalizeWords, formatModelNames } from 'src/modules/string-helpers';
import { useCurrency } from '../hooks/useCurrency';
import useRole from '../hooks/useRole';
import { useValuationSettings } from '../hooks/useValuationSettings';
import ApiService from '../modules/api-service';
import {
  BidSuccessStatus,
  DetailCarDTO,
  IndividualBidDTO,
  PriceType,
  SourceRegisterPotentialDTO,
  SourceType,
  TaxType,
  ValidityType,
  ValuationCountryCode,
} from '../modules/generated/api';
import { MAX_NUMBER_CHECKABLE } from '../modules/global-vars';
import { TAXTYPES, getLabel, sourceOriginFilter, sources } from '../modules/labels';
import UserRole from '../types/UserRoles';
import ActionExternalDetailLink from './ActionExternalDetailLink';
import ActionExternalPackageLink from './ActionExternalPackageLink';
import CarDetailCarPotentials from './CarDetailCarPotentials';
import CarDetailTable from './CarDetailTable';
import CarDetailTableHeadCell from './CarDetailTableHeadCell';
import CarDetailTableUnitValueCell from './CarDetailTableUnitValueCell';
import FilterMap from './FilterMap';
import InfoTooltip from './InfoTooltip';
import Promotions from './Promotions';
import PurchaseGrossHint from './PurchaseGrossHint';
import UnitValue from './UnitValue';

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: '62px',
  height: '34px',
  padding: '7px',
  '& .MuiSwitch-switchBase': {
    margin: '1px',
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url(/images/icons/table.svg)`,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.primary.light,
    width: '32px',
    height: '32px',
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '60%',
      height: '60%',
      left: '7px',
      top: '6px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url(/images/icons/weltkarte-gefaltet.svg)`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    borderRadius: 20 / 2,
    backgroundColor: '#93999C',
  },
}));

const useStyles = makeStyles((theme) => ({
  printHide: {
    '@media print': {
      display: 'none',
    },
  },
  table: {
    boxShadow: 'none',
    '& th': {
      width: '25%',
    },
  },
  header: {
    padding: '6px 24px 6px 16px',
  },
  fakeTableCell: {
    maxWidth: '600px',
    '& span': {
      padding: '6px 0px 0px 16px',
    },
    '& div:first-child': {
      paddingBottom: '5px',
    },
  },
  iconValue: {
    display: 'inline-flex',
    gap: '5px',
    alignItems: 'center',
    '& svg': {
      filter: ' invert(46%) sepia(0%) saturate(15%) hue-rotate(249deg) brightness(98%) contrast(97%);',
    },
  },
  cellFlex: {
    display: 'flex',
    alignItems: 'center',
  },
  moreCellItems: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  crossText: {
    textDecoration: 'line-through',
  },
  externalLink: {
    '& svg': {
      marginLeft: theme.spacing(0.5),
    },
  },
  fullWidth: {
    width: '100%',
  },
  deleteText: {
    marginLeft: '5px',
  },
  deleteButton: {
    color: theme.palette.error.main,
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
}));

type CarDetailCarBasicsProps = {
  car: DetailCarDTO;
  disableHeader?: boolean;
  disableElevation?: boolean;
  isBought?: boolean;
  otherBought?: IndividualBidDTO;
  refresh: () => Promise<void>;
};

const CarDetailCarBasics = ({
  car,
  isBought,
  otherBought,
  disableHeader = false,
  disableElevation = false,
  refresh,
}: CarDetailCarBasicsProps) => {
  const classes = useStyles();
  const boughtBid = car.bid?.bids?.find((bid) => bid.status === BidSuccessStatus.Bought) || otherBought;
  const { currency } = useCurrency();
  const snackbar = useCustomSnackbarGlobal();
  const { fetch } = useApi();

  const [countryPotentials, setCountryPotentials] = useState<{ [key: string]: SourceRegisterPotentialDTO } | undefined>(
    boughtBid ? undefined : car.potentials,
  );
  const { hasRole } = useRole();
  const { t } = useTranslation();
  const [showMap, setShowMap] = useState(false);
  const isAdmin = hasRole(UserRole.ADMIN);
  const potential = boughtBid?.potential || car.potential;
  const showPurchasePrice = boughtBid && boughtBid?.potential?.base?.amount !== car.potential?.base?.amount;

  const { valuationCountry: globalValuationCountry } = useValuationSettings();
  const currentValuationCountry = car.potential?.country;

  // May contain duplicates.
  const initialComparisonCountries = [
    ...new Set(
      [
        currentValuationCountry,
        globalValuationCountry,
        ...(Object.values(ValuationCountryCode) as ValuationCountryCode[]),
      ].filter((countryCode) => countryCode !== undefined && countryCode !== null),
    ),
  ].slice(0, MAX_NUMBER_CHECKABLE) as ValuationCountryCode[];

  const storedValuationCountries = localStorage.getItem(`gwscout/comparingValuationCountries`);

  const [comparingValuationCountries, setComparingValuationCountries] = useState<Set<ValuationCountryCode>>(
    new Set(
      storedValuationCountries
        ? (JSON.parse(storedValuationCountries) as unknown as ValuationCountryCode[])
        : initialComparisonCountries,
    ),
  );

  // We have the adapted potential in the bid, but have to re-compute the country-dependent potentials
  useEffect(() => {
    const getAdaptedCountryPotentials = async (country: ValuationCountryCode) => {
      const response = await ApiService.calcPrice.priceCalculationControllerCalculatePriceV2(
        car.source as SourceType,
        car.carId as string,
        PriceType.OfferPrice,
        boughtBid?.potential?.base?.amount || 0,
        currency,
        country,
      );
      setCountryPotentials((prevCountryPotential) => ({ ...(prevCountryPotential || {}), [country]: response.data }));
    };

    if (boughtBid?.potential?.base?.amount) {
      comparingValuationCountries.forEach((country) => getAdaptedCountryPotentials(country));
    }
  }, [car.carId, car.source, comparingValuationCountries, boughtBid, currency]);

  const handleComparingCountryChange = (countries: Set<ValuationCountryCode>) => {
    localStorage.setItem('gwscout/comparingValuationCountries', JSON.stringify([...countries]));
    setComparingValuationCountries(countries);
  };

  const handleChange = () => {
    setShowMap(!showMap);
  };

  useEffect(() => {
    if (!boughtBid) setCountryPotentials(car.potentials);
  }, [boughtBid, car]);

  const handleSetOffline = async (source: SourceType, offerId: string, carId: string) => {
    const response = await fetch(
      ApiService.importController.manualImportControllerInactivateOffer(source, offerId, carId),
    );
    if (response && response.status === 200) {
      snackbar.showSuccess(t('snackBar.success.inaktivate'));
      refresh();
    } else {
      snackbar.showError(t('snackBar.error.inaktivate'));
    }
  };

  return (
    <Paper>
      <Typography variant="h2" className={classes.header}>
        {t('common.car')}
      </Typography>
      <CarDetailTable
        title=""
        disableHeader={disableHeader}
        disableElevation={disableElevation}
        className={classes.table}
      >
        <TableRow>
          <CarDetailTableHeadCell>
            <div className={classes.printHide}>{`${t('common.car')}-ID`}</div>
            {car.source && (
              <div>
                {t('common.source')}
                <span className={classes.noWrap}>
                  {car.originSource && isAdmin && ` | ${t('carsTableNext.filter.originSource')}`}
                </span>
              </div>
            )}
          </CarDetailTableHeadCell>
          <TableCell className={classes.cellFlex}>
            <div className={classes.printHide}>
              {car.packageId ? (
                <ActionExternalPackageLink source={car.source} packageId={car.packageId} />
              ) : (
                <ActionExternalDetailLink source={car.source} carId={car.carId} offerId={car.offerId} />
              )}
            </div>

            <Grid container className={classes.fullWidth}>
              <div className={classes.printHide}>{car.carId}</div>
              {car.source && (
                <Grid item container justifyContent="space-between">
                  <Grid item>
                    {getLabel(sources, car.source ?? '')}
                    {car.originSource && isAdmin && ` | ${getLabel(sourceOriginFilter, car.originSource)}`}
                  </Grid>
                  <Grid item>
                    {car.source === SourceType.SpotdealsAllmobil &&
                      isAdmin &&
                      car.offerId &&
                      car.bidSuccessStatus !== BidSuccessStatus.Bought &&
                      car.validity === ValidityType.Current && (
                        <Button
                          size="small"
                          variant="contained"
                          className={classes.deleteButton}
                          onClick={() => handleSetOffline(car.source!, car.offerId!, car.carId!)}
                        >
                          <HighlightOffIcon />
                          <Typography variant="button" component="span" className={classes.deleteText}>
                            {t('car.takeOffline')}
                          </Typography>
                        </Button>
                      )}
                  </Grid>
                </Grid>
              )}
            </Grid>

            {car.packageId && (
              <>
                {' / '}
                <div className={classes.iconValue}>
                  <Tooltip title={`${t('common.package')}-${t('carsTable.ID')}`}>
                    <Apps />
                  </Tooltip>
                  {car.packageId}
                </div>
              </>
            )}
          </TableCell>
        </TableRow>
        {(isAdmin || car.imagesExportLink) && car.vin ? (
          <TableRow className={classes.printHide}>
            <CarDetailTableHeadCell>{t('car.services.title')}</CarDetailTableHeadCell>
            <TableCell>
              <Link href={`/services/image-export/${car.vin}`} className={classes.externalLink} target="blank">
                {t('car.services.imageExport')}
                <LaunchIcon fontSize="inherit" />
              </Link>
            </TableCell>
          </TableRow>
        ) : null}
        <TableRow>
          <CarDetailTableHeadCell>{t('car.brand')}</CarDetailTableHeadCell>
          <TableCell>{capitalizeThreeOrLessChars(capitalizeWords(car.normalized?.brand ?? '').split(' '))}</TableCell>
        </TableRow>
        <TableRow>
          <CarDetailTableHeadCell>{t('car.model')}</CarDetailTableHeadCell>
          <TableCell>{formatModelNames(car.normalized?.model ?? '')}</TableCell>
        </TableRow>
        <TableRow>
          <CarDetailTableHeadCell>{t('car.vin')}</CarDetailTableHeadCell>
          <TableCell>{car.vin}</TableCell>
        </TableRow>
        <TableRow>
          <CarDetailTableHeadCell>{t('car.modelCode')}</CarDetailTableHeadCell>
          <TableCell>{car.modelcode}</TableCell>
        </TableRow>
        {car.promotions && !isEmpty(car.promotions) && (
          <TableRow>
            <CarDetailTableHeadCell>{t('filterSearch.promotions')}</CarDetailTableHeadCell>
            <TableCell>
              <Promotions car={car} />
            </TableCell>
          </TableRow>
        )}
        {car.zip ? (
          <TableRow className={classes.printHide}>
            <CarDetailTableHeadCell>{t('contact.postal')}</CarDetailTableHeadCell>
            <TableCell>
              <Link
                href={`https://www.google.com/maps?q=${car?.zip}`}
                title="Google Maps"
                className={classes.externalLink}
                target="blank"
              >
                {car.zip}
                <LaunchIcon fontSize="inherit" />
              </Link>
            </TableCell>
          </TableRow>
        ) : null}
        <TableRow className={classes.printHide}>
          <CarDetailTableHeadCell>
            {isBought ? t('car.prices.purchasePrice') : t('car.prices.basePrice')}
          </CarDetailTableHeadCell>
          <TableCell className={classes.moreCellItems}>
            {car.taxType === TaxType.Differenzbesteuert ? (
              <mark>{getLabel(TAXTYPES, car.taxType || '')}</mark>
            ) : (
              <span>{getLabel(TAXTYPES, car.taxType || '')}</span>
            )}
            {car.packageId && (
              <div className={classes.iconValue}>
                <Tooltip title={`${t('common.package')} ${t('car.totalPrice')}:`} placement="left">
                  <Apps />
                </Tooltip>
                <UnitValue value={car.pricePackage?.value} unit="EUR" />
              </div>
            )}
            <span style={{ fontWeight: 'bold' }}>
              <span className={clsx(showPurchasePrice && classes.crossText)}>
                <UnitValue value={car.potential?.base?.amount} unit={car.potential?.base?.currency} />
              </span>
              {showPurchasePrice && (
                <span>
                  {' '}
                  <UnitValue value={boughtBid?.potential?.base?.amount} unit={boughtBid?.potential?.base?.currency} />
                </span>
              )}
            </span>
          </TableCell>
        </TableRow>
        <TableRow className={classes.printHide}>
          <CarDetailTableHeadCell>{t('car.prices.purchaseNetWholesaleUnrepaired')}</CarDetailTableHeadCell>
          <CarDetailTableUnitValueCell>
            <UnitValue
              value={potential?.purchaseNetWholesaleUnrepaired?.amount}
              unit={potential?.purchaseNetWholesaleUnrepaired?.currency}
            />
          </CarDetailTableUnitValueCell>
        </TableRow>
        <TableRow className={classes.printHide}>
          <CarDetailTableHeadCell>
            <Trans t={t} i18nKey="car.prices.purchaseNetRetailRepaired_br" />
            <InfoTooltip hint={<PurchaseGrossHint />} xsIcon />
          </CarDetailTableHeadCell>
          <CarDetailTableUnitValueCell>
            <UnitValue
              value={potential?.purchaseNetRetailRepaired?.amount}
              unit={potential?.purchaseNetRetailRepaired?.currency}
            />
          </CarDetailTableUnitValueCell>
        </TableRow>
        {car?.recommendedRetailPrice && globalValuationCountry === ValuationCountryCode.De && (
          <TableRow className={classes.printHide}>
            <CarDetailTableHeadCell>{t('car.prices.recommendedRetailPrice')}</CarDetailTableHeadCell>
            <CarDetailTableUnitValueCell>
              <UnitValue value={car?.recommendedRetailPrice?.amount} unit={car?.recommendedRetailPrice?.currency} />
            </CarDetailTableUnitValueCell>
          </TableRow>
        )}
        {car?.recommendedRetailPriceDeductionAbsolute && globalValuationCountry === ValuationCountryCode.De && (
          <TableRow className={classes.printHide}>
            <CarDetailTableHeadCell>{t('car.prices.recommendedRetailPriceDeduction')}</CarDetailTableHeadCell>
            <CarDetailTableUnitValueCell>
              <UnitValue
                value={car?.recommendedRetailPriceDeductionAbsolute?.amount}
                unit={car?.recommendedRetailPriceDeductionAbsolute?.currency}
              />{' '}
              (<UnitValue value={car?.recommendedRetailPriceDeductionRelative} unit="%" />)
            </CarDetailTableUnitValueCell>
          </TableRow>
        )}
        {countryPotentials && isAdmin ? (
          <TableRow className={classes.printHide}>
            <CarDetailTableHeadCell>
              <div style={{ marginTop: '5px' }}>
                <FormControlLabel
                  control={<MaterialUISwitch defaultChecked onChange={handleChange} name="showMap" />}
                  label={showMap ? t('cardDetailPage.showMap') : t('cardDetailPage.showTable')}
                />
              </div>
            </CarDetailTableHeadCell>
            <TableCell />
          </TableRow>
        ) : (
          ''
        )}
        {!showMap && (
          <CarDetailCarPotentials
            car={car}
            comparisonCountries={comparingValuationCountries}
            setCheckedCountries={handleComparingCountryChange}
            countryPotentials={countryPotentials}
          />
        )}
      </CarDetailTable>
      {showMap && countryPotentials && (
        <div className={classes.fakeTableCell}>
          <FilterMap potentials={countryPotentials} />
        </div>
      )}
    </Paper>
  );
};

export default memo(CarDetailCarBasics);
