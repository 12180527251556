import { TextCell } from '@components/ui/TextCell';
import humanizeDuration from 'humanize-duration';
import { useTranslation } from 'react-i18next';

export type TimestampCellProps = {
  timestamp: string | number | Date | undefined;
};

const diffToNow = (date: number | Date | string): string => {
  const { i18n } = useTranslation();
  const parsedDate = +new Date(date);
  return humanizeDuration(Date.now() - parsedDate, { round: true, language: i18n.language, largest: 1 });
};

export const TimestampCell = ({ timestamp }: TimestampCellProps): JSX.Element => (
  <TextCell text={timestamp ? diffToNow(timestamp) : null} />
);
