import { Address } from '@components/ui/address/types';
import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  makeStyles,
  OutlinedInput,
  TextField,
  Typography,
} from '@material-ui/core';
import { FolderOpen, Publish } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import countries from 'i18n-iso-countries';
import { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { usePdfExtract, useSetSourceAddress } from 'src/hooks/useTransportOptions';
import { getIcon, IconType, SHIPPING_COUNTRIES_COUNTRY_CODES } from 'src/modules/data';
import { CountryCode } from 'src/modules/generated/api';
import { DELIVERY_ADDRESS_VALIDATION } from 'src/modules/global-vars';
import i18n from 'src/setup/i18n';
import { Autocomplete } from './cars-table-next/filter/common/Autocomplete';
import { InputLabel, Stack } from './ui';

type SourceAddressFormProps = {
  sourceAddress?: Address;
  bidId: string;
  refetch: () => void;
  vin?: string;
};

type AddressWithAttorney = Address & {
  attorney?: FileList;
};

const useStyles = makeStyles((theme) => ({
  textFieldInput: {
    '& .MuiInputBase-input': {
      color: theme.palette.text.primary,
    },

    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.error.main,
    },
  },
}));

export const SourceAddressForm = ({ sourceAddress, bidId, refetch, vin }: SourceAddressFormProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [fileApproved, setFileApproved] = useState(false);
  const [errorInfo, setErrorInfo] = useState<string | null>(null);
  const [dataMismatch, setDataMismatch] = useState(false);
  const [fileSaver, setFileSaver] = useState<File | null>(null);

  const methods = useForm<AddressWithAttorney>({
    defaultValues: { ...sourceAddress, attorney: undefined },
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    register,
    watch,
    reset,
  } = methods;

  const validationValues = DELIVERY_ADDRESS_VALIDATION;

  const { mutate: postTransportId, isLoading } = useSetSourceAddress(bidId);
  const { mutate: uploadFile, data: address, isLoading: isFileLoading, error } = usePdfExtract(vin);
  const file = watch('attorney');
  const fileName = file && file[0] ? file[0].name.replace('C:\\fakepath\\', '') : '';

  const onSubmit = (data: Address) => {
    if (!fileSaver) return;
    postTransportId({
      address: { ...data, countryCode: data.countryCode as CountryCode, contactPersonName: data.name },
      file: fileSaver,
    });
    refetch();
    document.getElementById('delivery-and-transport')?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleUpload = () => {
    if (!file) return;
    setErrorInfo(null);
    setDataMismatch(false);
    setFileSaver(file[0]);
    uploadFile(file[0]);
  };

  useEffect(() => {
    if (address && address.carLocation && fileSaver) {
      if (sourceAddress?.city && sourceAddress?.zip !== address.carLocation.zip) {
        setErrorInfo(t('configuration.transportAttorneyDataMismatch'));
        setDataMismatch(true);
      } else {
        setFileApproved(true);
        // city and zip get not set on purpose
        setValue('street', address.carLocation.street || '');
        setValue('companyName', address.carLocation.companyName || '');
        setValue('name', address.carLocation.contactPersonName || '');
        setValue('email', address.carLocation.email || '');
        setValue('phoneNumber', address.carLocation.phoneNumber || '');
      }
    }
  }, [address, sourceAddress, setValue, t, fileSaver]);

  useEffect(() => {
    if (error) {
      if (error.response && error.response.status) {
        const statusCode = error.response.status;
        if (statusCode === 404) {
          setErrorInfo(t('alerts.uploadErrorAttorneyWrongVin'));
        } else if (statusCode === 422) {
          setErrorInfo(t('alerts.uploadErrorAttorneyWrongFile'));
        } else {
          setErrorInfo(t('alerts.uploadError'));
        }
      } else {
        setErrorInfo(t('alerts.uploadError'));
      }
    }
  }, [error, setErrorInfo, t]);

  return (
    <form id="address-form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <FormProvider {...methods}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={12}>
            <Alert severity="info">{t('configuration.uploadAttorneyDocumentHint')}</Alert>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              <strong>{t('configuration.firstStep')}:</strong> {t('configuration.uploadAttorneyDocument')}
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <input
              id="file"
              accept="text/xlsx"
              type="file"
              {...register('attorney', { required: true })}
              hidden
              disabled={fileApproved}
            />
            <TextField
              fullWidth
              placeholder={t('common.pdfFile')}
              margin="dense"
              variant="outlined"
              value={fileName || fileSaver?.name}
              disabled
              error={!!errors.attorney}
              InputLabelProps={{
                disabled: false,
              }}
              InputProps={{
                className: classes.textFieldInput,
                endAdornment: (
                  <InputAdornment position="end">
                    {!fileApproved && (
                      <Button component="label" htmlFor="file" endIcon={<FolderOpen />}>
                        {t('common.chooseFile')}
                      </Button>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              endIcon={isFileLoading ? <CircularProgress size={20} /> : <Publish />}
              disabled={fileApproved || isFileLoading || !file}
              onClick={handleUpload}
              variant="contained"
              color="primary"
            >
              {t('common.upload')}
            </Button>
          </Grid>
          {errorInfo && (
            <Grid item>
              <FormHelperText error>{errorInfo}</FormHelperText>
              {dataMismatch && (
                <p>
                  <div>
                    <Typography variant="caption" color="textSecondary">
                      {t('configuration.offerData')}: {sourceAddress?.countryCode}-{sourceAddress?.zip}
                    </Typography>
                  </div>
                  <div>
                    <Typography variant="caption" color="textSecondary">
                      {t('configuration.attorneyData')}: {address?.carLocation?.zip}
                    </Typography>
                  </div>
                </p>
              )}
            </Grid>
          )}
        </Grid>

        {fileApproved && !errorInfo && (
          <Stack spacing={1}>
            <div style={{ marginTop: '8px' }}>
              <Typography variant="subtitle2">
                <strong>{t('configuration.secondStep')}:</strong> {t('configuration.addAndCheckData')}
              </Typography>
            </div>
            <FormControl size="small" fullWidth variant="outlined">
              <InputLabel required>{t('contact.companyName')}</InputLabel>
              <Controller
                name="companyName"
                control={control}
                rules={{
                  required: { value: true, message: t('validation.required') },
                  maxLength: {
                    value: validationValues.companyName.maxLength,
                    message: t('validation.maxLength', { count: validationValues.companyName.maxLength }),
                  },
                }}
                render={({ field: { ref, ...field } }) => <OutlinedInput inputRef={ref} {...field} />}
              />
              {errors.companyName ? (
                <FormHelperText error>{errors.companyName.message || errors.companyName.type}</FormHelperText>
              ) : null}
            </FormControl>

            <FormControl size="small" fullWidth variant="outlined">
              <InputLabel required>{t('configuration.contactPerson')}</InputLabel>
              <Controller
                name="name"
                control={control}
                rules={{
                  required: { value: true, message: t('validation.required') },
                  maxLength: {
                    value: validationValues.name.maxLength,
                    message: t('validation.maxLength', { count: validationValues.name.maxLength }),
                  },
                }}
                render={({ field: { ref, ...field } }) => <OutlinedInput inputRef={ref} {...field} />}
              />
              {errors.name ? <FormHelperText error>{errors.name.message || errors.name.type}</FormHelperText> : null}
            </FormControl>

            <FormControl size="small" fullWidth variant="outlined">
              <InputLabel required>{t('contact.street')}</InputLabel>
              <Controller
                name="street"
                control={control}
                rules={{
                  required: { value: true, message: t('validation.required') },
                  maxLength: {
                    value: validationValues.street.maxLength,
                    message: t('validation.maxLength', { count: validationValues.street.maxLength }),
                  },
                }}
                render={({ field: { ref, ...field } }) => <OutlinedInput inputRef={ref} {...field} />}
              />
              {errors.street ? (
                <FormHelperText error>{errors.street.message || errors.street.type}</FormHelperText>
              ) : null}
            </FormControl>

            <FormControl size="small" fullWidth variant="outlined">
              <InputLabel required>{t('contact.city')}</InputLabel>
              <Controller
                name="city"
                control={control}
                rules={{
                  required: { value: true, message: t('validation.required') },
                  maxLength: {
                    value: validationValues.city.maxLength,
                    message: t('validation.maxLength', { count: validationValues.city.maxLength }),
                  },
                }}
                render={({ field: { ref, ...field } }) => <OutlinedInput inputRef={ref} {...field} />}
              />
              {errors.city ? <FormHelperText error>{errors.city.message || errors.city.type}</FormHelperText> : null}
            </FormControl>

            <FormControl size="small" fullWidth variant="outlined">
              <InputLabel required>{t('contact.postal')}</InputLabel>
              <Controller
                name="zip"
                control={control}
                rules={{
                  required: { value: true, message: t('validation.required') },
                  maxLength: {
                    value: validationValues.zip.maxLength,
                    message: t('validation.maxLength', { count: validationValues.zip.maxLength }),
                  },
                }}
                render={({ field: { ref, ...field } }) => <OutlinedInput disabled inputRef={ref} {...field} />}
              />
              {errors.zip ? <FormHelperText error>{errors.zip.message || errors.zip.type}</FormHelperText> : null}
            </FormControl>

            <FormControl size="small" fullWidth variant="outlined">
              <InputLabel required>{t('contact.country')}</InputLabel>
              <Controller
                name="countryCode"
                control={control}
                rules={{
                  required: { value: true, message: t('validation.required') },
                }}
                render={({ field: { ref: _ref, ...field } }) => (
                  <Autocomplete
                    {...field}
                    disabled
                    optionIcon={(option) => getIcon(IconType.COUNTRY, option)}
                    error={!!errors.countryCode}
                    helperText={errors.countryCode && errors.countryCode.message}
                    label=""
                    options={SHIPPING_COUNTRIES_COUNTRY_CODES}
                    getOptionLabel={(option: string) => (countries.getName(option, i18n.language) as string) || option}
                    disableCloseOnSelect={false}
                    onChange={(value) => {
                      setValue('countryCode', value as any); // Overwrites default behaviour
                      setValue(
                        'country',
                        value && !Array.isArray(value) ? (countries.getName(value, i18n.language) as string) : '',
                      );
                    }}
                  />
                )}
              />
            </FormControl>

            <FormControl size="small" fullWidth variant="outlined">
              <InputLabel required>{t('contact.E-Mail')}</InputLabel>
              <Controller
                name="email"
                control={control}
                rules={{
                  required: { value: true, message: t('validation.required') },
                  maxLength: {
                    value: validationValues.email.maxLength,
                    message: t('validation.maxLength', { count: validationValues.email.maxLength }),
                  },
                }}
                render={({ field: { ref, ...field } }) => <OutlinedInput inputRef={ref} {...field} />}
              />
              {errors.email ? <FormHelperText error>{errors.email.message || errors.email.type}</FormHelperText> : null}
            </FormControl>

            <FormControl size="small" fullWidth variant="outlined">
              <InputLabel required>{t('contact.phone')}</InputLabel>
              <Controller
                name="phoneNumber"
                control={control}
                rules={{
                  required: { value: true, message: t('validation.required') },
                  maxLength: {
                    value: validationValues.phoneNumber.maxLength,
                    message: t('validation.maxLength', { count: validationValues.phoneNumber.maxLength }),
                  },
                }}
                render={({ field: { ref, ...field } }) => <OutlinedInput inputRef={ref} {...field} />}
              />
              {errors.phoneNumber ? (
                <FormHelperText error>{errors.phoneNumber.message || errors.phoneNumber.type}</FormHelperText>
              ) : null}
            </FormControl>

            <Grid container style={{ marginTop: '16px' }} justifyContent="space-between">
              <Button
                size="small"
                variant="outlined"
                color="secondary"
                onClick={() => {
                  setFileApproved(false);
                  setFileSaver(null);
                  reset({ ...sourceAddress, attorney: undefined });
                  document.getElementById('delivery-and-transport')?.scrollIntoView({ behavior: 'smooth' });
                }}
              >
                {t('common.cancel')}
              </Button>
              <Button
                type="submit"
                form="address-form"
                size="small"
                variant="contained"
                color="primary"
                disabled={isLoading}
              >
                {t('configuration.commissionTransport')}
                {isLoading ? <CircularProgress size={20} /> : null}
              </Button>
            </Grid>
          </Stack>
        )}
      </FormProvider>
    </form>
  );
};
