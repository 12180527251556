import { DateTime, DurationObjectUnits } from 'luxon';
import { useTranslation } from 'react-i18next';
import humanizeDuration from 'humanize-duration';
import useRole from '../hooks/useRole';
import UserRole from '../types/UserRoles';

export type DateLike = string | number | Date;

export const BID_DATE_TIME_FORMAT = 'dd.MM.yy HH:mm:ss';
export const DATE_TIME_FORMAT_NO_TIME = 'dd.MM.yy';
export const DATE_TIME_FORMAT = 'dd.MM.yy HH:mm';
export const DATE_TIME_EXACT_FORMAT = 'dd.MM.yy HH:mm:ss';
export const FILE_DATE_TIME_FORMAT = 'dd-MM-yy_HH-mm-ss';
export const REGISTRATION_DATE_FORMAT = 'MM/yyyy';
export const DATE_TIME_FORMAT_JUST_TIME = 'HH:mm';

export const formatDate = (isoDate: string, formatString: string = DATE_TIME_FORMAT) => {
  if (typeof isoDate === 'string' && isoDate.length > 0) return DateTime.fromISO(isoDate).toFormat(formatString);
  return 'N/A';
};

export const sheduledOrPromt = (created: string | undefined, sheduled: string | undefined) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { hasRole } = useRole();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation();

  if (created && sheduled) {
    const time = DateTime.fromISO(sheduled).diff(DateTime.fromISO(created), 'seconds').toObject();
    if (time.seconds && time.seconds <= 5) {
      if (hasRole(UserRole.ADMIN)) return `${t('bids.status.prompt')} (${formatDate(sheduled, BID_DATE_TIME_FORMAT)})`;
      return `${t('bids.status.prompt')}`;
    }
    return formatDate(sheduled, BID_DATE_TIME_FORMAT);
  }
  return '';
};

export const dateInFuture = (date: string) => {
  if (date === '') return false;
  return DateTime.now() < DateTime.fromISO(date);
};

export const nowTillDate = (date: string, durations: (keyof DurationObjectUnits)[] = ['milliseconds']) => {
  if (!date) return false;
  return DateTime.now().until(DateTime.fromISO(date)).toDuration(durations).toObject();
};

export const dateTillNow = (date: string, durations: (keyof DurationObjectUnits)[]) => {
  if (!date) return false;
  return DateTime.fromISO(date).until(DateTime.now()).toDuration(durations).toObject();
};

export const getIsValidDate = (date: DateLike | undefined): date is DateLike =>
  date !== undefined && !Number.isNaN(new Date(date)?.getTime());

export const msToMinutes = (ms: number): number => ms / 60 / 1000;

export const minutesToMs = (minutes: number): number => minutes * 60 * 1000;

export const diffToNow = (date: DateLike): number => new Date(date).getTime() - Date.now();

export const formatDiffToNow = (date: DateLike, language: string): string => {
  const parsedDate = new Date(date);
  return humanizeDuration(Math.abs(Date.now() - +parsedDate), { round: true, language, largest: 1 });
};
