import { Container } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';

export const ErrorFallback = () => {
  const { t } = useTranslation();

  return (
    <Container maxWidth="sm">
      <Alert severity="error">{t('helpCenter.errorFallback.headline')}</Alert>
    </Container>
  );
};
