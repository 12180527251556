import SpotdealsAllmobilUploadForm from '@components/SpotdealsAllmobilUploadForm';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useApi from 'src/hooks/useApi';
import useCustomSnackbarGlobal from 'src/hooks/useSnackbarGlobal';
import theme from 'src/setup/theme';
import { DrawerContent, DrawerHeader, DrawerStatic } from '../components/ui/drawer/Drawer';
import ApiService from '../modules/api-service';
import { SourceType } from '../modules/generated/api';

type BidsUploadProps = {
  refetchCars: () => void;
};

const useStyles = makeStyles({
  errorBox: {
    border: '1px solid red',
    borderRadius: 4,
    padding: theme.spacing(1),
    margin: theme.spacing(2),
  },
  errorText: {
    fontSize: 14,
  },
});

const SpotdealsAllmobilUpload = ({ refetchCars }: BidsUploadProps): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { fetch } = useApi<string[]>();
  const snackbar = useCustomSnackbarGlobal();
  const [failedCars, setFailedCars] = useState<Array<string>>([]);
  const methods = useForm<{ file?: FileList }>({
    defaultValues: {
      file: undefined,
    },
  });
  const { handleSubmit: handleSubmitForm, reset } = methods;
  const handleSubmit = () =>
    handleSubmitForm(async ({ file }) => {
      setFailedCars([]);
      if (file) {
        const result = await fetch(
          ApiService.importController.manualImportControllerImportCars(SourceType.SpotdealsAllmobil, file[0]),
        );
        reset();
        if (result?.status !== 200) {
          snackbar.showError(t('alerts.uploadError'));
          return;
        }
        if (result?.data.length > 0) {
          snackbar.showInfo(t('alerts.uploadPartial'));
          setFailedCars(result?.data);
        } else {
          snackbar.showSuccess(t('alerts.uploadSuccess'));
        }
        refetchCars();
      }
    });

  return (
    <DrawerStatic>
      <DrawerHeader>
        <Grid container alignItems="center" justifyContent="space-between" spacing={4}>
          <Grid item>
            <Typography style={{ fontWeight: 'bold' }}>{t('common.upload')}</Typography>
          </Grid>
        </Grid>
      </DrawerHeader>
      <DrawerContent>
        <Typography className={classes.errorText}>{t('carsTableNext.upload.description')}</Typography>
        <Grid container justifyContent="space-between" spacing={4}>
          <Grid item>
            <FormProvider {...methods}>
              <SpotdealsAllmobilUploadForm onSubmit={handleSubmit()} />
            </FormProvider>
          </Grid>
          {!isEmpty(failedCars) && (
            <Grid item className={classes.errorBox}>
              <Typography className={classes.errorText}>{t('carsTableNext.upload.errorCars')}</Typography>
              <ul>
                {failedCars.map((car) => (
                  <li key={car}>
                    <Typography className={classes.errorText}>{car}</Typography>
                  </li>
                ))}
              </ul>
            </Grid>
          )}
        </Grid>
      </DrawerContent>
    </DrawerStatic>
  );
};

export default SpotdealsAllmobilUpload;
