import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import useCustomSnackbarGlobal from 'src/hooks/useSnackbarGlobal';
import { getAccessTokenFromStorage } from 'src/stores/Authentication';

const contentApiClient = axios.create({
  headers: {
    Authorization: `Bearer ${getAccessTokenFromStorage()}`,
  },
});

// TODO: export from content-functions package
export enum VoteDirection {
  up = 'UP',
  down = 'DOWN',
}

const vote = ({ documentId, direction }: { documentId: string; direction: VoteDirection }) =>
  contentApiClient
    .post(`${process.env.REACT_APP_CONTENT_API_URL}/vote`, { documentId, direction })
    .then((res) => res.data);

export const useVoteMutation = () => {
  const { t } = useTranslation();
  const { showInfo, showError } = useCustomSnackbarGlobal();

  const mutation = useMutation(vote, {
    onSuccess: () => {
      showInfo(t('helpCenter.actions.vote.success'));
    },
    onError: () => {
      showError(t('helpCenter.actions.vote.error'));
    },
  });

  return mutation;
};
