import { styled } from '@material-ui/core';
import { grey, orange } from '@material-ui/core/colors';

/**
 * TableCell is the root of the cell and vertically centers the content.
 */
export const TableCell = styled('div')(
  {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderBottom: '1px solid',
    borderBottomColor: grey[200],
    borderLeft: '1px solid',
    borderLeftColor: grey[200],
    background: 'var(--row-background, #fff)',

    '&:focus': {
      outline: '2px auto',
      outlineOffset: -1,
      outlineColor: orange[500],
    },
  },
  {
    name: 'TableCell',
  },
);
