import CarsTableBidSuccessStatus from '@components/CarsTableBidSuccessStatus';
import CarsTableBidsBuyer from '@components/CarsTableBidsBuyer';
import CarsTableBidsCell from '@components/CarsTableBidsCell';
import CarsTableBoughtKVPS from '@components/CarsTableBoughtKVPS';
import { DateTime } from 'luxon';
import { BID_DATE_TIME_FORMAT, formatDate } from 'src/modules/date-helpers';
import CarsTableDeliveryAddress from '@components/CarsTableDeliveryAddress';
import UserRoles from 'src/types/UserRoles';
import { TextCell, TextCellFallback } from '../cells';
import { ColumnSize } from '../constants';
import { BidsTableNextItem, StrictColumnDef } from '../types';

export const bidColumns: StrictColumnDef<BidsTableNextItem>[] = [
  {
    id: 'bidUserDealer',
    cell: ({ row: { original } }) => <CarsTableBidsCell bids={original.bids} bidInfos={original} />,
    meta: {
      align: 'center',
      export: {
        enabled: true,
        exports: [
          { key: 'bids.bid', label: 'carsTableNext.filter.groups.bids' },
          { key: 'bids.status', label: 'carsTableNext.columns.bidStatus' },
          { key: 'bids.biddingTime', label: 'carsTableNext.columns.biddingTime' },
          { key: 'bids.user.name', label: 'navigation.dealer' },
          { key: 'bids.dealer.id', label: 'carsTableNext.filter.bidCreatorDealerId' },
        ],
      },
      fallback: () => <TextCellFallback textWidth="80%" style={{ textAlign: 'center' }} />,
    },
    size: ColumnSize.lg,
    enableSorting: false,
  },
  {
    id: 'successStatus',
    accessorFn: (bid) => bid,
    cell: ({ row: { original } }) => <CarsTableBidSuccessStatus bid={original} />,
    meta: {
      export: {
        enabled: true,
      },
      fallback: () => <TextCellFallback textWidth="80%" />,
    },
    enableSorting: false,
  },
  {
    id: 'buyer',
    accessorFn: (bid) => bid,
    cell: ({ row: { original } }) => <CarsTableBidsBuyer bids={original.bids} />,
    meta: {
      columnVisible: false,
      export: {
        enabled: true,
      },
      roles: [UserRoles.ADMIN],
      onlyForTable: 'orders',
      fallback: () => <TextCellFallback textWidth="80%" />,
    },
    enableSorting: false,
  },
  {
    id: 'buyingKVPS',
    accessorFn: (bid) => bid,
    cell: ({ row: { original } }) => <CarsTableBoughtKVPS bids={original.bids} />,
    meta: {
      columnVisible: false,
      export: {
        enabled: true,
      },
      roles: [UserRoles.ADMIN],
      onlyForTable: 'orders',
      fallback: () => <TextCellFallback textWidth="80%" />,
    },
    enableSorting: false,
  },
  {
    id: 'deliveryAddress',
    accessorFn: (bid) => bid.deliveryAddress,
    cell: ({ row: { original } }) => <CarsTableDeliveryAddress address={original.deliveryAddress} />,
    meta: {
      columnVisible: false,
      export: {
        enabled: true,
        exports: [
          { key: 'deliveryAddress.companyName', label: 'contact.companyName' },
          { key: 'deliveryAddress.street', label: 'contact.street' },
          { key: 'deliveryAddress.zip', label: 'contact.postal' },
          { key: 'deliveryAddress.city', label: 'contact.city' },
          { key: 'deliveryAddress.contactPersonName', label: 'contact.contactPerson' },
          { key: 'deliveryAddress.email', label: 'contact.E-Mail' },
          { key: 'deliveryAddress.phoneNumber', label: 'contact.phone' },
        ],
      },
      onlyForTable: 'orders',
      fallback: () => <TextCellFallback textWidth="80%" />,
    },
    enableSorting: false,
    size: ColumnSize.md,
  },
  {
    id: 'created',
    accessorFn: (bid) => bid,
    cell: ({ row: { original } }) => {
      const date = original.created;
      const formattedDate = date ? DateTime.fromISO(date).toString() : undefined;
      return (
        <TextCell
          style={{ textAlign: 'center' }}
          text={formattedDate ? formatDate(formattedDate, BID_DATE_TIME_FORMAT) : ''}
        />
      );
    },
    meta: {
      columnVisible: false,
      export: {
        enabled: true,
      },
      fallback: () => <TextCellFallback textWidth="80%" />,
    },
    enableSorting: false,
  },
  {
    id: 'biddingTime',
    accessorFn: (bid) => bid,
    cell: ({ row: { original } }) => {
      const date = original.biddingTime;
      const formattedDate = date ? DateTime.fromISO(date).toString() : undefined;
      return (
        <TextCell
          style={{ textAlign: 'center' }}
          text={formattedDate ? formatDate(formattedDate, BID_DATE_TIME_FORMAT) : ''}
        />
      );
    },
    meta: {
      columnVisible: false,
      export: {
        enabled: true,
      },
      fallback: () => <TextCellFallback textWidth="80%" />,
    },
  },
  {
    id: 'sentTime',
    accessorFn: (bid) => bid,
    cell: ({ row: { original } }) => {
      const date = original.sentTime;
      const formattedDate = date ? DateTime.fromISO(date).toString() : undefined;
      return (
        <TextCell
          style={{ textAlign: 'center' }}
          text={formattedDate ? formatDate(formattedDate, BID_DATE_TIME_FORMAT) : ''}
        />
      );
    },
    meta: {
      columnVisible: false,
      export: {
        enabled: true,
      },
      fallback: () => <TextCellFallback textWidth="80%" />,
    },
  },
  {
    id: 'boughtTime',
    accessorFn: (bid) => bid,
    cell: ({ row: { original } }) => {
      const date = original.boughtTime;
      const formattedDate = date ? DateTime.fromISO(date).toString() : undefined;
      return (
        <TextCell
          style={{ textAlign: 'center' }}
          text={formattedDate ? formatDate(formattedDate, BID_DATE_TIME_FORMAT) : ''}
        />
      );
    },
    meta: {
      columnVisible: false,
      export: {
        enabled: true,
      },
      onlyForTable: 'orders',
      fallback: () => <TextCellFallback textWidth="80%" />,
    },
  },
];
