import { Suspense } from 'react';
import { Breadcrumbs, Container, Divider, Link as MuiLink, makeStyles, Typography } from '@material-ui/core';
import { Link, navigate, RouteComponentProps, useParams } from '@reach/router';
import { SupportCallout, useCategory, FaqList, FaqListFallback } from '@components/help-center';
import { useTranslation } from 'react-i18next';

type PageParams = {
  category: string;
};

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#fff',
  },
  headline: {
    fontWeight: 'bold',
  },
  breadcrumbs: {
    paddingBlock: theme.spacing(2),
  },
  container: {
    paddingBlock: theme.spacing(6),
  },
  header: {
    marginBlockEnd: theme.spacing(3),
  },
  feedback: {
    marginBlockEnd: theme.spacing(6),
  },
}));

export const CategoryPage = (_props: RouteComponentProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { category: categorySlug } = useParams<PageParams>();
  const { data: category } = useCategory({ slug: categorySlug });

  if (!category) {
    navigate('/help');
    return null;
  }

  return (
    <div className={classes.root}>
      <Container className={classes.breadcrumbs}>
        <Breadcrumbs>
          <MuiLink component={Link} color="inherit" to="/help">
            {t('navigation.help')}
          </MuiLink>
          <Typography color="primary">{category.title}</Typography>
        </Breadcrumbs>
      </Container>

      <Divider />

      <Container className={classes.container} maxWidth="md">
        <div className={classes.header}>
          <Typography variant="h4" className={classes.headline} gutterBottom>
            {category.title}
          </Typography>
          <Typography color="textSecondary">{category.description}</Typography>
        </div>

        <Suspense fallback={<FaqListFallback />}>
          <FaqList categorySlug={categorySlug} />
        </Suspense>

        <Divider style={{ marginBlockStart: 40, marginBlockEnd: 24 }} />

        <SupportCallout />
      </Container>
    </div>
  );
};
