import { makeStyles, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

type CustomSnackbarProps = {
  message?: string;
  SnackbarProps?: object;
  customParameters?: any;
};

const useStyles = makeStyles((theme) => ({
  snackbar: {
    marginBottom: theme.spacing(3),
  },
}));

const CustomSnackbarGlobal = ({ message, SnackbarProps, customParameters }: CustomSnackbarProps) => {
  const classes = useStyles();
  return (
    <Snackbar
      transitionDuration={400}
      autoHideDuration={(message && (message.length / 5) * 1000) || 6000}
      {...SnackbarProps}
    >
      <Alert severity={customParameters?.type} elevation={6} variant="filled" className={classes.snackbar}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackbarGlobal;
