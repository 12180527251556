import { refetchIntervalState } from '@components/cars-table-next/state';
import { Badge } from '@material-ui/core';
import CachedIcon from '@material-ui/icons/Cached';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import FilterListIcon from '@material-ui/icons/FilterList';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import { isNil } from 'lodash';
import { useRecoilValue } from 'recoil';
import { tr } from 'src/modules/i18n-helpers';
import UserRoles from 'src/types/UserRoles';
import { ReactComponent as PersonSearchIcon } from '../../assets/icons/muiV5/person-search.svg';
import { CarsTableNextAction, SidebarRoutePaths } from './constants';

export const CarsTableNavItems = [
  {
    id: CarsTableNextAction.showFilter,
    path: SidebarRoutePaths.filter,
    title: tr('carsTableNext.actions.filter'),
    icon: FilterListIcon,
    roles: [],
  },
  {
    id: CarsTableNextAction.showColumns,
    path: SidebarRoutePaths.columnSettings,
    title: tr('carsTableNext.actions.columns'),
    icon: ViewColumnIcon,
    roles: [],
  },
  {
    id: CarsTableNextAction.showViews,
    path: SidebarRoutePaths.views,
    title: tr('carsTableNext.actions.searchagents'),
    icon: PersonSearchIcon,
    roles: [],
  },
  {
    id: CarsTableNextAction.showAutoRefresh,
    path: SidebarRoutePaths.autoRefresh,
    title: tr('carsTableNext.actions.autoRefresh'),
    icon: () => {
      const refetchInterval = useRecoilValue(refetchIntervalState);
      return (
        <Badge variant="dot" color="primary" invisible={isNil(refetchInterval)} overlap="circular">
          <CachedIcon />
        </Badge>
      );
    },
    roles: [],
  },
  {
    id: CarsTableNextAction.showExport,
    path: SidebarRoutePaths.export,
    title: tr('carsTableNext.actions.export'),
    icon: CloudDownloadIcon,
    roles: [],
  },
  {
    id: CarsTableNextAction.showUpload,
    path: SidebarRoutePaths.upload,
    title: tr('carsTableNext.actions.upload'),
    roles: [UserRoles.ADMIN],
    icon: CloudUploadIcon,
  },
];

export const PendingBidsTableNavItems = [
  {
    id: CarsTableNextAction.showFilter,
    path: SidebarRoutePaths.pendingFilter,
    title: tr('carsTableNext.actions.filter'),
    icon: FilterListIcon,
    roles: [],
  },
  {
    id: CarsTableNextAction.showColumns,
    path: SidebarRoutePaths.pendingColumnSettings,
    title: tr('carsTableNext.actions.columns'),
    icon: ViewColumnIcon,
    roles: [],
  },
  {
    id: CarsTableNextAction.showExport,
    path: SidebarRoutePaths.pendingExport,
    title: tr('carsTableNext.actions.export'),
    roles: [],
    icon: CloudDownloadIcon,
  },
  // TODO: Add again when BE is updated for that
  // {
  //   id: CarsTableNextAction.showUpload,
  //   path: SidebarRoutePaths.pendingUpload,
  //   title: tr('carsTableNext.actions.upload'),
  //   icon: CloudUploadIcon,
  //   roles: [UserRoles.ADMIN]
  // },
];

export const PastBidsTableNavItems = [
  {
    id: CarsTableNextAction.showFilter,
    path: SidebarRoutePaths.pastFilter,
    title: tr('carsTableNext.actions.filter'),
    icon: FilterListIcon,
    roles: [],
  },
  {
    id: CarsTableNextAction.showColumns,
    path: SidebarRoutePaths.pastColumnSettings,
    title: tr('carsTableNext.actions.columns'),
    icon: ViewColumnIcon,
    roles: [],
  },
  {
    id: CarsTableNextAction.showExport,
    path: SidebarRoutePaths.pastExport,
    title: tr('carsTableNext.actions.export'),
    icon: CloudDownloadIcon,
    roles: [],
  },
];

export const OrderBidsTableNavItems = [
  {
    id: CarsTableNextAction.showFilter,
    path: SidebarRoutePaths.ordersFilter,
    title: tr('carsTableNext.actions.filter'),
    icon: FilterListIcon,
    roles: [],
  },
  {
    id: CarsTableNextAction.showColumns,
    path: SidebarRoutePaths.ordersColumnSettings,
    title: tr('carsTableNext.actions.columns'),
    icon: ViewColumnIcon,
    roles: [],
  },
  {
    id: CarsTableNextAction.showExport,
    path: SidebarRoutePaths.ordersExport,
    title: tr('carsTableNext.actions.export'),
    icon: CloudDownloadIcon,
    roles: [],
  },
];
