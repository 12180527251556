import { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core';
import { Eco } from '@material-ui/icons';
import { isNumber } from 'lodash';
import { useTranslation } from 'react-i18next';
import { TaxContainer, ValuationCountryCode } from '../modules/generated/api';
import IconValue from './IconValue';
import UnitValue from './UnitValue';
import i18n from '../setup/i18n';
import { createCalculationParamList, createTaxList } from '../modules/tax';
import { CarsTaxList } from './CarsTaxList';
import { DEFAULT_VALUATION_COUNTRY } from '../modules/data';
import { formatUnitValue } from '../modules/unit-helpers';
import { useCurrency } from '../hooks/useCurrency';

type CarDetailTileTaxesProps = {
  tax: TaxContainer;
  valuationCountry?: ValuationCountryCode;
};

const useStyles = makeStyles((theme) => ({
  split: {
    display: 'flex',
  },
  list: {
    padding: 0,
    margin: 0,
    listStyle: 'none',
  },
  taxes: {
    ...theme.typography.body1,
    fontWeight: theme.typography.fontWeightBold as number,
  },
}));

const CarDetailTileTaxes = ({
  tax,
  valuationCountry = DEFAULT_VALUATION_COUNTRY,
}: CarDetailTileTaxesProps): ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { convert, format } = useCurrency();
  const taxes = createTaxList(tax, valuationCountry || undefined, {
    percent: (v) => formatUnitValue(v, '%'),
    currency: (v) => format(convert(v, {}), {}),
  });
  const calculationParams = createCalculationParamList(tax);

  const tSafe = (key: any, fallback?: any) => (i18n.exists(key) ? t(key) : fallback ?? key);

  return (
    <IconValue
      icon={Eco}
      label={t('carsTable.tax_plural')}
      padding
      color="#4caf50"
      hint={
        calculationParams.length > 0 && (
          <ul className={classes.list}>
            {calculationParams.map(({ key, value, unit }) => (
              <li key={key}>
                {tSafe(`car.${key}`)}:{' '}
                {unit && isNumber(value) ? <UnitValue value={value} unit={unit} /> : tSafe(value)}
              </li>
            ))}
          </ul>
        )
      }
    >
      <div className={classes.split}>
        <CarsTaxList taxes={taxes} className={classes.taxes} />
      </div>
    </IconValue>
  );
};

export default CarDetailTileTaxes;
