import React, { MouseEventHandler, useRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles, PropTypes } from '@material-ui/core';
import { Option } from '../modules/i18n-helpers';
import { getLabel } from '../modules/labels';

const useStyles = makeStyles((theme) => ({
  dropdown: {
    zIndex: 1,
  },
  small: {
    fontSize: '0.7rem',
  },
  buttonGroup: {
    '& .MuiButton-containedSizeSmall': {
      fontSize: '0.7rem',
    },
    '& .MuiButtonGroup-grouped': {
      minWidth: 0,
    },
  },
  dropdownIcon: {
    padding: theme.spacing(0.5),
  },
}));

type SplitButtonProps = {
  options: Option[];
  onClick: (value: any) => void;
  size?: 'small' | 'large' | 'medium';
  color?: PropTypes.Color;
  defaultIndex?: number;
  disabled?: boolean;
};

export default function SplitButton({
  options,
  onClick,
  size = 'small',
  color = 'secondary',
  defaultIndex,
  disabled,
}: SplitButtonProps) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(defaultIndex || 0);

  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    onClick(options[selectedIndex].value);
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
    event.stopPropagation();
    setSelectedIndex(index);
    setOpen(false);
    onClick(options[index].value);
  };

  const handleToggle: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    event.stopPropagation();
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <div>
      <ButtonGroup
        variant="contained"
        color={color}
        ref={anchorRef}
        aria-label="split button"
        size={size}
        className={classes.buttonGroup}
      >
        <Button onClick={handleClick} size={size} disabled={disabled}>
          {getLabel(options, options[selectedIndex].value)}
        </Button>
        <Button
          color={color}
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="menu"
          onClick={handleToggle}
          className={classes.dropdownIcon}
          disabled={disabled}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      {open && (
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition className={classes.dropdown}>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose} mouseEvent="onMouseDown">
                  <MenuList id="split-button-menu">
                    {options.map((option, index) => (
                      <MenuItem
                        key={option.value}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                        className={size === 'small' ? classes.small : undefined}
                      >
                        {getLabel(options, option.value)}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
    </div>
  );
}
