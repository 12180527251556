import { Grid, IconButton, InputAdornment, makeStyles, TextField, Tooltip } from '@material-ui/core';
import ClearSharpIcon from '@material-ui/icons/ClearSharp';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import { Alert, AlertTitle } from '@material-ui/lab';
import { memo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { green } from '@material-ui/core/colors';
import theme from '../setup/theme';
import { splitOnDelimiter } from '../modules/string-helpers';
import { useCarsFilter } from '../hooks/useCarsFilter';

const useStyles = makeStyles(() => ({
  alert: {
    width: '100%',
    '& .MuiAlert-message': {
      width: '100%',
      position: 'relative',
    },
  },
  alertTitle: {
    marginBottom: 0,
  },
  textarea: {
    backgroundColor: theme.palette.common.white,
    width: theme.spacing(34),
  },
  check: {
    color: green[400],
  },
  clear: {
    padding: theme.spacing(0.7),
  },
}));

const SearchVinAdder = () => {
  const classes = useStyles();
  const {
    form: { register },
    defaultValues,
  } = useCarsFilter();
  const { ref: vinSearchRef, ...vinSearchField } = register('vinSearch', { required: false });
  const { setValue, getValues } = useFormContext();
  const { t } = useTranslation();
  const vinValue: string[] = getValues('vinSearch');

  return (
    <form>
      <Alert severity="info" iconMapping={{ info: <SearchIcon /> }} elevation={3} className={classes.alert}>
        <Grid container alignItems="center" justifyContent="space-between" spacing={4}>
          <Grid item>
            <AlertTitle className={classes.alertTitle}>{t('filterSearch.searchForVins')}</AlertTitle>
          </Grid>
          <IconButton type="submit">{vinValue ? <CheckIcon className={classes.check} /> : <ClearIcon />}</IconButton>
        </Grid>

        <TextField
          {...vinSearchField}
          onBlur={() => {
            setValue('vinSearch', splitOnDelimiter(getValues('vinSearch')).join('\n'));
          }}
          inputRef={vinSearchRef}
          variant="outlined"
          margin="dense"
          fullWidth
          multiline
          autoFocus
          className={classes.textarea}
          rows={4}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  className={classes.clear}
                  onClick={() => {
                    setValue('carIds', defaultValues.carIds);
                    setValue('vinSearch', '');
                  }}
                >
                  <Tooltip title={t('filterSearch.clearSearch')}>
                    <ClearSharpIcon fontSize="small" color="action" />
                  </Tooltip>
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Alert>
    </form>
  );
};

export default memo(SearchVinAdder);
