import { useTranslation } from 'react-i18next';
import { MenuItem, Select } from '@material-ui/core';
import { Public } from '@material-ui/icons';
import ApiService from 'src/modules/api-service';
import useCustomSnackbarGlobal from 'src/hooks/useSnackbarGlobal';
import { formatDeliveryAddress } from '../../modules/labels';
import { DeliveryAddressDTO } from '../../modules/generated/api';
import { DetailedDealerDTO } from '../../modules/generated/api/api';

type DeliveryClassSelectorProps = {
  deliveryAddresses?: DeliveryAddressDTO[];
  dealer: DetailedDealerDTO;
  dealerID?: string;
};
const DeliveryAddressSelector = ({ deliveryAddresses, dealerID, dealer }: DeliveryClassSelectorProps) => {
  const { t } = useTranslation();
  const { showError, showSuccess } = useCustomSnackbarGlobal();

  const saveAddress = async (deliveryAddressId: string) => {
    const response = await ApiService.dealer.dealerControllerUpdateDefaultDeliveryAddressForDealer(
      dealerID as string,
      deliveryAddressId,
    );
    if (response.status !== 200) {
      showError(t('alerts.errorRaised'));
      return;
    }
    showSuccess(t('alerts.successSaved'));
  };

  const handleSelect = (newAddress: string) => {
    saveAddress(newAddress);
  };

  return (
    <Select onChange={(event) => handleSelect(event.target.value as string)} value={dealer.defaultDeliveryAddress}>
      {deliveryAddresses?.map((deliveryAddress) => (
        <MenuItem key={deliveryAddress.id} value={deliveryAddress.id}>
          <div style={{ display: 'flex', width: '100%', gap: 5, alignItems: 'center' }}>
            {formatDeliveryAddress(deliveryAddress)}
            {deliveryAddress.global && <Public color="primary" style={{ marginLeft: 'auto' }} fontSize="small" />}
          </div>
        </MenuItem>
      ))}
    </Select>
  );
};

export default DeliveryAddressSelector;
