import { Button, Grid, IconButton, InputAdornment, makeStyles, TextField, Typography } from '@material-ui/core';
import { Add, Clear } from '@material-ui/icons';
import { get } from 'lodash';
import { FieldArrayWithId, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IconType } from '../modules/data';
import {
  ConditionalFormattingDTO,
  ConditionalFormattingScope,
  ConditionalFormattingType,
} from '../modules/generated/api';
import {
  conditionalFormattingColors,
  conditionalFormattingTypes,
  damageTypes,
  engineFuelDetails,
  equipments,
  formattingScopes,
  getLabel,
  promotions,
  taxationTypes,
} from '../modules/labels';
import ConditionalFormattingColor from './ConditionalFormattingColor';
import ConditionSelect from './ConditionSelect';
import FilterAutocomplete from './FilterAutocomplete';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(4),
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    marginBottom: theme.spacing(4),

    '&:first-child': {
      marginTop: theme.spacing(4),
      borderTop: `1px solid ${theme.palette.secondary.main}`,
      paddingTop: theme.spacing(4),
    },
  },
  heading: {
    textDecoration: 'none',
  },
  conditions: {
    paddingLeft: theme.spacing(6),
  },
  colorSelectWrapper: {
    display: 'flex',
    justifyContent: 'stretch',
    alignItems: 'center',
  },
  colorSelect: {
    flex: '1 0 auto',
  },
}));

export type ConfigurationFormattingFieldValues = {
  values: Required<ConditionalFormattingDTO>[];
  addField: ConditionalFormattingType;
};

type ConfigurationFormattingFieldRowProps = {
  parentField: FieldArrayWithId<ConfigurationFormattingFieldValues, 'values', 'id'>;
  index: number;
  onRemove: () => void;
};

const ConfigurationFormattingFieldRow = ({
  parentField,
  index: nestedIndex,
  onRemove,
}: ConfigurationFormattingFieldRowProps) => {
  const classes = useStyles();
  const { control, register, watch } = useFormContext<ConfigurationFormattingFieldValues>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `values.${nestedIndex}.conditions` as const,
  });
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <IconButton size="small" onClick={onRemove}>
            <Clear />
          </IconButton>
        </Grid>
        <Grid item xs>
          <Typography variant="h3" className={classes.heading}>
            {getLabel(conditionalFormattingTypes, parentField.field as ConditionalFormattingType)}
          </Typography>
        </Grid>
        <Grid item>
          <Button endIcon={<Add />} color="secondary" variant="contained" size="small" onClick={() => append({})}>
            {t('conditionalFormatting.addCondition')}
          </Button>
        </Grid>
      </Grid>

      <div className={classes.conditions}>
        {fields.map((item, innerIndex) => {
          // FIXME: watching the background directly somehow evaluates to undefined
          // const color = watch(`values.${nestedIndex}.conditions.${innerIndex}.backgroundColor`);
          const formData = watch();
          const color = get(formData, `values.${nestedIndex}.conditions.${innerIndex}.backgroundColor`);
          let conditionInputs;

          if (ConditionalFormattingType.DamageType === parentField.field) {
            const { ref: conditionRef, ...conditionField } = register(
              `values.${nestedIndex}.conditions.${innerIndex}.condition` as const,
            );
            conditionInputs = (
              <>
                <Grid xs item>
                  <ConditionSelect
                    conditionRef={conditionRef}
                    conditionField={conditionField}
                    defaultValue={item.condition}
                  />
                </Grid>
                <Grid xs item>
                  <FilterAutocomplete
                    disableClearable
                    label={t('car.damageType')}
                    name={`values.${nestedIndex}.conditions.${innerIndex}.contains`}
                    options={damageTypes}
                    iconType={IconType.DAMAGE_TYPE}
                    defaultValue={item.contains || []}
                    multiple
                    noLabel
                    shownTags={4}
                    smallInput
                  />
                </Grid>
              </>
            );
          } else if (
            [ConditionalFormattingType.Model, ConditionalFormattingType.NormalizedFullModelName].includes(
              parentField.field,
            )
          ) {
            const { ref: eqRef, ...eqField } = register(`values.${nestedIndex}.conditions.${innerIndex}.eq` as const);
            const { ref: containsRef, ...containsField } = register(
              `values.${nestedIndex}.conditions.${innerIndex}.contains` as const,
            );

            conditionInputs = (
              <>
                <Grid xs item>
                  <TextField
                    inputRef={eqRef}
                    {...eqField}
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{ required: false, shrink: true }}
                    label={t('conditionalFormatting.equal')}
                    margin="dense"
                    defaultValue={item.eq}
                  />
                </Grid>
                <Grid xs item>
                  <TextField
                    inputRef={containsRef}
                    {...containsField}
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{ required: false, shrink: true }}
                    label={t('conditionalFormatting.contains')}
                    margin="dense"
                    defaultValue={item.contains}
                  />
                </Grid>
              </>
            );
          } else if (ConditionalFormattingType.TaxType === parentField.field) {
            const { ref: conditionRef, ...conditionField } = register(
              `values.${nestedIndex}.conditions.${innerIndex}.condition` as const,
            );
            conditionInputs = (
              <>
                <Grid xs item>
                  <ConditionSelect
                    conditionRef={conditionRef}
                    conditionField={conditionField}
                    defaultValue={item.condition}
                  />
                </Grid>
                <Grid xs item>
                  <FilterAutocomplete
                    disableClearable
                    label={t('carsTable.taxationType')}
                    name={`values.${nestedIndex}.conditions.${innerIndex}.contains`}
                    options={taxationTypes}
                    defaultValue={item.contains || []}
                    hideCheckbox
                    multiple
                  />
                </Grid>
              </>
            );
          } else if (ConditionalFormattingType.Promotions === parentField.field) {
            const { ref: conditionRef, ...conditionField } = register(
              `values.${nestedIndex}.conditions.${innerIndex}.condition` as const,
            );
            conditionInputs = (
              <>
                <Grid xs item>
                  <ConditionSelect
                    conditionRef={conditionRef}
                    conditionField={conditionField}
                    defaultValue={item.condition}
                  />
                </Grid>
                <Grid xs item>
                  <FilterAutocomplete
                    disableClearable
                    label={t('filterSearch.promotions')}
                    name={`values.${nestedIndex}.conditions.${innerIndex}.contains`}
                    options={promotions}
                    defaultValue={item.contains || []}
                    iconType={IconType.PROMOTION}
                    multiple
                    noLabel
                    shownTags={4}
                    smallInput
                  />
                </Grid>
              </>
            );
          } else if (ConditionalFormattingType.NormalizedFuelDetail === parentField.field) {
            const { ref: conditionRef, ...conditionField } = register(
              `values.${nestedIndex}.conditions.${innerIndex}.condition` as const,
            );
            conditionInputs = (
              <>
                <Grid xs item>
                  <ConditionSelect
                    conditionRef={conditionRef}
                    conditionField={conditionField}
                    defaultValue={item.condition}
                  />
                </Grid>
                <Grid xs item>
                  <FilterAutocomplete
                    disableClearable
                    label={t('carsTableNext.columns.normalized.fuelDetail')}
                    name={`values.${nestedIndex}.conditions.${innerIndex}.contains`}
                    options={engineFuelDetails}
                    defaultValue={item.contains || []}
                    multiple
                    shownTags={4}
                    smallInput
                  />
                </Grid>
              </>
            );
          } else if (ConditionalFormattingType.Highlights === parentField.field) {
            const { ref: conditionRef, ...conditionField } = register(
              `values.${nestedIndex}.conditions.${innerIndex}.condition` as const,
            );
            conditionInputs = (
              <>
                <Grid xs item>
                  <ConditionSelect
                    conditionRef={conditionRef}
                    conditionField={conditionField}
                    defaultValue={item.condition}
                  />
                </Grid>
                <Grid xs item>
                  <FilterAutocomplete
                    disableClearable
                    label={t('filterSearch.highlight')}
                    name={`values.${nestedIndex}.conditions.${innerIndex}.contains`}
                    options={equipments}
                    defaultValue={item.contains || []}
                    iconType={IconType.EQUIPMENT}
                    multiple
                    noLabel
                    shownTags={4}
                    smallInput
                  />
                </Grid>
              </>
            );
          } else {
            const { ref: gtRef, ...gtField } = register(`values.${nestedIndex}.conditions.${innerIndex}.gt` as const, {
              valueAsNumber: true,
            });
            const { ref: ltRef, ...ltField } = register(`values.${nestedIndex}.conditions.${innerIndex}.lt` as const, {
              valueAsNumber: true,
            });

            conditionInputs = (
              <>
                <Grid xs item>
                  <TextField
                    inputRef={gtRef}
                    {...gtField}
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{ required: false }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">{`${t('conditionalFormatting.value')} >`}</InputAdornment>
                      ),
                    }}
                    label={t('conditionalFormatting.gt')}
                    margin="dense"
                    type="number"
                    defaultValue={item.gt}
                  />
                </Grid>
                <Grid xs item>
                  <TextField
                    inputRef={ltRef}
                    {...ltField}
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{ required: false }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">{`${t('conditionalFormatting.value')} <`}</InputAdornment>
                      ),
                    }}
                    label={t('conditionalFormatting.st')}
                    margin="dense"
                    type="number"
                    defaultValue={item.lt}
                  />
                </Grid>
              </>
            );
          }

          return (
            <Grid key={item.id} container spacing={2} alignItems="center">
              <Grid item>
                <IconButton
                  size="small"
                  onClick={() => {
                    if (fields.length === 1) {
                      onRemove();
                    } else {
                      remove(innerIndex);
                    }
                  }}
                >
                  <Clear />
                </IconButton>
              </Grid>
              <Grid item xs className={classes.colorSelectWrapper}>
                <ConditionalFormattingColor size={24} value={color} />
                <div className={classes.colorSelect}>
                  <FilterAutocomplete
                    defaultValue={item.backgroundColor}
                    disableClearable
                    label={t('colors.color')}
                    name={`values.${nestedIndex}.conditions.${innerIndex}.backgroundColor`}
                    options={conditionalFormattingColors}
                    IconComponent={ConditionalFormattingColor}
                    hideCheckbox
                  />
                </div>
              </Grid>

              {conditionInputs}

              <Grid xs item>
                <FilterAutocomplete
                  name={`values.${nestedIndex}.conditions.${innerIndex}.scope`}
                  label={t('conditionalFormatting.limitation')}
                  options={formattingScopes}
                  defaultValue={item.scope ?? ConditionalFormattingScope.Cell}
                  disableClearable
                  hideCheckbox
                />
              </Grid>
            </Grid>
          );
        })}
      </div>
    </div>
  );
};

export default ConfigurationFormattingFieldRow;
