import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ListItemIcon, ListItemText, makeStyles, Menu, MenuItem } from '@material-ui/core';
import i18n from 'i18next';
import { ArrowDropDown } from '@material-ui/icons';
import { SUPPORTED_LANGUAGES } from '../modules/i18n-helpers';
import { getIcon, IconType } from '../modules/data';

const useStyles = makeStyles((theme) => ({
  listItemIcon: {
    minWidth: theme.spacing(4),
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
}));

type LanguageSwitcherProps = {
  onClose?: () => void;
};

const LanguageSwitcher = ({ onClose }: LanguageSwitcherProps) => {
  const classes = useStyles();
  const {
    i18n: { language },
  } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const changeLanguageHandler = (lang: string) => {
    setAnchorEl(null);
    if (onClose) onClose();

    i18n.changeLanguage(lang).then(() => window.location.reload());
  };

  return (
    <>
      <Button onClick={(event) => setAnchorEl(event.currentTarget)} size="small">
        <img
          src={getIcon(IconType.LANGUAGE, language)}
          alt={SUPPORTED_LANGUAGES[language]}
          width={18}
          className={classes.marginRight}
        />
        {SUPPORTED_LANGUAGES[language.split('-')[0]]} <ArrowDropDown />
      </Button>
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={() => {
          setAnchorEl(null);
          if (onClose) onClose();
        }}
      >
        {Object.keys(SUPPORTED_LANGUAGES).map((lang) => (
          <MenuItem onClick={() => changeLanguageHandler(lang)} key={lang}>
            <ListItemIcon className={classes.listItemIcon}>
              <img src={getIcon(IconType.LANGUAGE, lang)} alt={SUPPORTED_LANGUAGES[lang]} width={18} />
            </ListItemIcon>
            <ListItemText>{SUPPORTED_LANGUAGES[lang]}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default LanguageSwitcher;
