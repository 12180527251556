import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from 'src/constants';
import ApiService from 'src/modules/api-service';
import { createStrictPacket } from './lib';

export const fetchPacket = (packetId: string) =>
  ApiService.packet.packetControllerGetPacket(packetId).then((res) => res.data);

export const usePacket = (packetId: string | undefined) =>
  useQuery(
    [QueryKeys.servicePacket, packetId],
    () => fetchPacket(packetId!).then(createStrictPacket), // won't get called until packetId exists
    {
      enabled: Boolean(packetId),
    },
  );
