import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from '../locales/en/translation.json';
import translationDE from '../locales/de/translation.json';
import translationFR from '../locales/fr/translation.json';
import translationES from '../locales/es/translation.json';
import translationIT from '../locales/it/translation.json';

import { SUPPORTED_LANGUAGES } from '../modules/i18n-helpers';

export const resources = {
  en: { translation: translationEN },
  de: { translation: translationDE },
  fr: { translation: translationFR },
  es: { translation: translationES },
  it: { translation: translationIT },
} as const;

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    debug: false,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    supportedLngs: Object.keys(SUPPORTED_LANGUAGES),
  });

export default i18n;
