import { useQuery } from '@tanstack/react-query';
import { PaginationState, SortingState } from '@tanstack/react-table';
import { QueryKeys } from 'src/constants';
import { useCurrency } from 'src/hooks/useCurrency';
import { useValuationSettings } from 'src/hooks/useValuationSettings';
import ApiService from 'src/modules/api-service';
import {
  BidAutomationStatusResponseDTO,
  BidDataTablesOutputListBidDTO,
  Column,
  CurrencyCode,
  DataTablesOutputListCarDTO,
  ListCarDTO,
  PriceType,
  SourceType,
  ValuationCountryCode,
} from 'src/modules/generated/api';
import { columnsWithBids } from './columns';
import { transformForSubmit } from './filter/lib';
import { BidsTableNextFilterData } from './filter/types';
import { getColumnSortKey } from './lib';

type UseCarsProps = {
  filter: BidsTableNextFilterData;
  pagination: PaginationState;
  sorting: SortingState;
};

export type DataTablesOutputListCarDTOExtended = DataTablesOutputListCarDTO & {
  data?: ListCarDTO &
    {
      isNew?: boolean;
    }[];
};

export const fetchBidCars = (
  filter: BidsTableNextFilterData,
  pagination: PaginationState,
  sorting: SortingState,
  settings?: {
    valuationCountry?: ValuationCountryCode;
    valuationType?: PriceType;
    targetCurrency?: CurrencyCode;
  },
) =>
  ApiService.newBids
    .bidNewControllerList({
      ...filter,
      carsRequest: {
        ...transformForSubmit({ ...filter.carsRequest, ...settings }),
      },
      length: pagination.pageSize,
      start: pagination.pageIndex * pagination.pageSize,
      order: sorting.map((columnSort, index) => ({
        column: index,
        dir: columnSort.desc ? 'desc' : 'asc',
      })),
      columns: sorting.map(
        (columnSort) => ({ data: getColumnSortKey(columnSort.id, columnsWithBids) }) as Column, // Only send minimal information for simple directional sorting
      ),
    })
    .then((res) => res.data);

export const useBids = ({ filter, pagination, sorting }: UseCarsProps) => {
  const { valuationCountry, valuationType } = useValuationSettings();
  const { currency } = useCurrency();
  const settings = {
    valuationCountry: valuationCountry || undefined,
    valuationType: valuationType || undefined,
    targetCurrency: currency,
  };
  const options = [QueryKeys.bidsList, { filter, pagination, sorting, settings }] as const;
  return useQuery<BidDataTablesOutputListBidDTO>(options, () => fetchBidCars(filter, pagination, sorting, settings), {
    keepPreviousData: true,
  });
};

type RequestAutomationStatusByCarProps = {
  sourceType: SourceType;
  carId: string;
};

export const useBidsRequestAutomationStatusByCar = ({ sourceType, carId }: RequestAutomationStatusByCarProps) => {
  const options = [QueryKeys.bidRequestAutomationStatusByCar, { sourceType, carId }] as const;
  return useQuery<BidAutomationStatusResponseDTO>(options, () =>
    ApiService.newBids.bidNewControllerBidAutomationRequestStatus(carId, sourceType).then((res) => res.data),
  );
};
