import { Button, Card, Grid, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    padding: theme.spacing(8),
    textAlign: 'center',
  },
  headline: {
    marginBlockEnd: theme.spacing(4),
  },
  supportText: {
    marginBlockEnd: theme.spacing(2),
  },
  button: {
    width: theme.spacing(18),
    display: 'grid',
  },
}));

export const handleAppError = (error: unknown): void => {
  // TODO: log error in external service
  // eslint-disable-next-line no-console
  console.log(error);
};

export const AppErrorFallback = (): JSX.Element => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleClickReload = () => {
    window.location.reload();
  };

  const handleClickReset = () => {
    localStorage.clear();
    handleClickReload();
  };

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <Typography variant="h1" className={classes.headline}>
          {t('appErrorFallback.headline')}
        </Typography>
        <Typography className={classes.supportText}>{t('appErrorFallback.supportText')}</Typography>
        <Grid container spacing={1}>
          <Grid item xs={6} className={classes.button}>
            <Button color="secondary" onClick={handleClickReset}>
              {t('appErrorFallback.reset')}
            </Button>
          </Grid>
          <Grid item xs={6} className={classes.button}>
            <Button color="primary" variant="contained" onClick={handleClickReload}>
              {t('appErrorFallback.reload')}
            </Button>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};
