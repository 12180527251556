import { IconButton, InputAdornment, makeStyles, TextField } from '@material-ui/core';
import { KeyboardEvent, memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import TagManager from 'react-gtm-module';
import { InputAttributes } from 'react-number-format';
import CheckIcon from '@material-ui/icons/Check';
import { green } from '@material-ui/core/colors';
import clsx from 'clsx';
import { isNil } from 'lodash';
import { ListCarDTO, PriceType } from '../modules/generated/api';
import { getInputUri } from '../modules/map-calculations-helper';
import { getCalculationCarDetailsTM } from '../modules/tag-manager-helpers';
import { getPropertyLabel, PRICE_STRATEGY_LABEL, recordsToOptions } from '../modules/labels';
import AmountInput from './AmountInput';
import { useCurrency } from '../hooks/useCurrency';
import { makeMonetaryAmount, SUPPORTED_CURRENCIES } from '../modules/currency';
import { ListCarDtoSafe, useTableCalculator } from '../hooks/useTableCalculator';
import { useCarsTableStore } from '../stores/CarsTable';
import SplitButton from './SplitButton';
import { CarProperty } from '../modules/table-data';

type TableInputProps = {
  disabled?: boolean;
  autoFocus?: boolean;
  car: ListCarDTO;
  fixedVariable: PriceType;
  targetKey: string;
  idx: number;
};

const useStyles = makeStyles((theme) => ({
  input: {
    border: 'none',
    fontFamily: 'Arial, sans-serif',
    height: '1.5rem',
    width: '100%',
    '& .MuiInputBase-root': {
      fontSize: '0.875rem',
      textAlign: 'right',
    },
    '& .MuiInputBase-input': {
      textAlign: 'right',
      padding: 0,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.primary.light,
    },
    '& .Mui-error.MuiInput-underline:after': {
      borderBottomColor: theme.palette.error.dark,
    },
  },
  fixInputButton: {
    marginRight: 0,
    fontSize: '0.5rem',
    '& .MuiSvgIcon-root': { fontSize: '1rem' },
    '& .MuiIconButton-root': {
      padding: 0,
    },
    '& .MuiInputAdornment-root': {
      paddingTop: theme.spacing(1),
    },
  },
  fixedIcon: {
    color: green[400],
  },
  fixedInput: {
    '& .MuiInput-underline:before': {
      borderBottomColor: green[400],
    },
  },
}));

const TableInput = ({ disabled, autoFocus, car, fixedVariable, targetKey, idx }: TableInputProps) => {
  const { currency } = useCurrency();
  const classes = useStyles();
  const { setValue, getValues } = useFormContext();
  const { toggleFixed, isFixed, calcCarPotential, toCalcCar } = useTableCalculator();
  const { packageMode } = useCarsTableStore();
  const { carId, source } = car;
  const name = getInputUri(carId as string, fixedVariable, idx);
  const isRelativeValue = name.toLowerCase().includes('relative');
  const fixed = isFixed(car);

  const fetchCalculation = async () => {
    const value = getValues(name);
    const parsedValue = typeof value === 'number' ? value * 100 : value.amount;
    if (carId && source && value !== undefined && value !== '') {
      TagManager.dataLayer(
        getCalculationCarDetailsTM(getPropertyLabel(targetKey as CarProperty, 'en'), parsedValue, car),
      );
      calcCarPotential(toCalcCar(car as ListCarDtoSafe), fixedVariable, parsedValue, true);
    }
  };

  const handleOnKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      fetchCalculation();
    }
  };

  return (
    <>
      <Controller
        key={name}
        name={name}
        render={({ field: { name: fieldName, value: fieldValue } }) => (
          <AmountInput<InputAttributes>
            name={fieldName}
            value={isRelativeValue ? Math.round(fieldValue * 100) : fieldValue?.amount && fieldValue?.amount}
            onValueChange={({ value: newValue }) => {
              const amount = newValue ? parseInt(newValue, 10) : undefined;

              if (isRelativeValue) {
                setValue(name, amount && amount / 100);
                return;
              }
              setValue(name, amount && makeMonetaryAmount(amount, currency));
            }}
            className={clsx(classes.input, fixed && classes.fixedInput)}
            disabled={disabled}
            onKeyDown={(event) => handleOnKeyDown(event)}
            onClick={(event) => {
              event.stopPropagation();
            }}
            suffix={isRelativeValue ? ' %' : ` ${SUPPORTED_CURRENCIES[currency].symbol}`}
            emptyInputAllowed
            autoFocus={autoFocus}
            error={packageMode && isNil(fieldValue)}
            // @ts-ignore
            customInput={TextField}
            InputProps={
              packageMode
                ? {
                    startAdornment: (
                      <InputAdornment position="start" className={classes.fixInputButton}>
                        <IconButton onClick={() => toggleFixed(car)} className={fixed ? classes.fixedIcon : undefined}>
                          <CheckIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }
                : undefined
            }
          />
        )}
      />
      {packageMode && fixedVariable === PriceType.OfferPrice && (
        <SplitButton
          options={recordsToOptions(PRICE_STRATEGY_LABEL)}
          onClick={(value: PriceType) => {
            if (!car.source || !car.carId) return;
            calcCarPotential(toCalcCar(car as ListCarDtoSafe), value, 0, true);
          }}
          defaultIndex={1}
          disabled={fixed}
        />
      )}
    </>
  );
};

export default memo(TableInput);
