import { isObject } from 'lodash';
import { DateTime } from 'luxon';
import { formatDate, DATE_TIME_FORMAT } from 'src/modules/date-helpers';
import { tr } from 'src/modules/i18n-helpers';
import { getLabel } from 'src/modules/labels';
import { DateFilter } from '../../../modules/generated/api/api';
import { dateFilters } from '../legacy/DateTimeFilterSelect';
import FilterChip from './FilterChip';
import { useFilter } from './useFilter';

type DateFilterChipProps = {
  value: any;
  name: string;
  dateType: string;
};

const DateFilterChip = ({ value, name, dateType }: DateFilterChipProps) => {
  const { setFilter, watch } = useFilter();
  const filterState = watch();
  // dates are always set but are only active if the filter is individual
  if (value === DateFilter.Individual) {
    const from = filterState && filterState[dateType === 'end' ? 'endDateFrom' : 'startDateFrom'];
    const to = filterState && filterState[dateType === 'end' ? 'endDateTo' : 'startDateTo'];

    return (
      <>
        <FilterChip
          name={name}
          key={`${name}-${value}`}
          tooltip={tr(`carsTableNext.filter.${name}`)}
          label={getLabel(dateFilters, value)}
          resetEffect="undefined"
          value={value}
        />
        {from && (
          <FilterChip
            name={`${dateType}DateFrom`}
            key={`${dateType}DateFrom`}
            tooltip={tr(`carsTableNext.filter.${dateType === 'end' ? 'endDateFrom' : 'startDateFrom'}`)}
            label={
              isObject(from)
                ? formatDate((from as unknown as DateTime).toISO()) // after first setting the value its a date object
                : formatDate(from as string, DATE_TIME_FORMAT)
            }
            onDelete={() =>
              filterState &&
              setFilter({
                ...filterState,
                [dateType === 'end' ? 'endDateFrom' : 'startDateFrom']: undefined,
              })
            }
          />
        )}
        {to && (
          <FilterChip
            key={`${dateType}DateTo`}
            tooltip={tr(`carsTableNext.filter.${dateType === 'end' ? 'endDateTo' : 'startDateTo'}`)}
            label={
              isObject(to)
                ? formatDate((to as unknown as DateTime).toISO()) // after first setting the value its a date object
                : formatDate(to as string, DATE_TIME_FORMAT)
            }
            name={`${dateType}DateTo`}
            onDelete={() =>
              filterState &&
              setFilter({
                ...filterState,
                [dateType === 'end' ? 'endDateTo' : 'startDateTo']: undefined,
              })
            }
          />
        )}
      </>
    );
  }
  return (
    <FilterChip
      name={name}
      key={`${name}-${value}`}
      tooltip={tr(`carsTableNext.filter.${name}`)}
      label={getLabel(dateFilters, value)}
      resetEffect="undefined"
      value={value}
    />
  );
};

export default DateFilterChip;
