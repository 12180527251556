import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { offerTypes } from '../modules/labels';
import FilterAutocomplete from './FilterAutocomplete';

const OfferTypeSelect = () => {
  const { t } = useTranslation();

  return (
    <FilterAutocomplete disableClearable label={t('filterSearch.offerType')} name="offerType" options={offerTypes} />
  );
};

export default memo(OfferTypeSelect);
