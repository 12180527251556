import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { transmissions } from '../modules/labels';
import FilterAutocomplete from './FilterAutocomplete';

const EngineTransmissionSelect = () => {
  const { t } = useTranslation();
  return (
    <FilterAutocomplete name="normalizedTransmission" multiple label={t('car.transmission')} options={transmissions} />
  );
};

export default memo(EngineTransmissionSelect);
