import DeliveryAddressForm from '@components/DeliveryAddressForm';
import { Grid, IconButton, makeStyles, Typography } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { transformDeliveryAddressDtoToAddress } from 'src/hook-form-helper';
import useSnackbarGlobal from 'src/hooks/useSnackbarGlobal';
import { useGetSelectedTransportOption, useSetTransportInfo } from 'src/hooks/useTransportOptions';
import { dateInFuture } from 'src/modules/date-helpers';
import { getLabel, transportOptionProvider, transportOptionStatus } from 'src/modules/labels';
import {
  BiddingDeliveryAddressSelectionDTO,
  DeliveryAddressDTO,
  SetTransportationDTO,
  TransportationState,
} from '../modules/generated/api';
import { setToArray } from '../modules/util';
import { SourceAddressForm } from './SourceAddressForm';
import UnitValue from './UnitValue';

type DeliveryAddressProps = {
  deliveryAddressSelection?: BiddingDeliveryAddressSelectionDTO;
  bidId?: string;
  vin?: string;
};

const useStyles = makeStyles((theme) => ({
  displayAddress: {
    '& p': {
      margin: theme.spacing(0.5),
    },
  },
  heading: {
    display: 'flex',
    alignItems: 'center',
    '& button': {
      padding: '10px',
    },
    paddingBottom: theme.spacing(2),
  },
  subHeading: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
    fontVariant: 'small-caps',
  },
  tiles: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(2),
  },
  editDeliveryAddress: {
    marginTop: theme.spacing(3),
  },
  SourceAddressForm: {
    marginTop: theme.spacing(1),
  },
  noTransportDiv: {
    marginTop: theme.spacing(1),
  },
}));

export type BoughtCarDeliveryAddressForm = {
  deliveryAddressId: string | undefined;
  transportOptionId: string | undefined;
};

const BoughtCarDeliveryAddressTransportation = ({ deliveryAddressSelection, bidId, vin }: DeliveryAddressProps) => {
  const { showError, showSuccess } = useSnackbarGlobal();
  const { mutate: postTransportId, isLoading } = useSetTransportInfo(bidId || '');
  const { data: transportInfo, refetch, isLoading: loadingTransportInfo } = useGetSelectedTransportOption(bidId || '');
  const {
    deliveryAddress,
    status,
    option,
    deliveryAddressChangeUntil: changeUntil,
    sourceAddress,
  } = transportInfo || {};

  const { t } = useTranslation();
  const classes = useStyles();

  const [editDelivery, setEditDelivery] = useState(false);

  const methods = useForm<BoughtCarDeliveryAddressForm>({
    defaultValues: { deliveryAddressId: deliveryAddress?.id, transportOptionId: option?.id },
  });
  const { reset, getValues } = methods;
  const editable = dateInFuture(changeUntil || '');
  const sourceAddressEditable = status === TransportationState.WaitingForValidSourceAddress;

  const selectableDeliveryAddresses = setToArray(
    deliveryAddressSelection?.deliveryAddresses || (new Set() as Set<DeliveryAddressDTO>),
  );

  const handleCancel = () => {
    reset();
    setEditDelivery(false);
  };

  const handleSubmit = () => {
    const optionId = getValues('transportOptionId');
    const transportation: SetTransportationDTO = {
      transportOptionId: optionId,
    };
    postTransportId(transportation, {
      onSuccess: () => {
        setEditDelivery(false);
        showSuccess(t('snackBar.success.update'));
        refetch();
      },
      onError: () => {
        showError(t('snackBar.error.update'));
      },
    });
  };

  useEffect(() => {
    reset({ deliveryAddressId: deliveryAddress?.id, transportOptionId: option?.id });
  }, [reset, deliveryAddress, option]);

  if (!bidId)
    return (
      <div className={classes.tiles}>
        <Typography variant="h2">{t('configuration.deliveryAndTransport')}</Typography>
        <Typography>{t('configuration.noInfoAvailable')}</Typography>
      </div>
    );

  return (
    <div className={classes.tiles}>
      <div className={classes.heading}>
        <Typography variant="h2" id="delivery-and-transport">
          {t('configuration.deliveryAndTransport')}{' '}
        </Typography>
      </div>

      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <Typography className={classes.subHeading}>
            {t('configuration.deliveryAddress')} & {t('configuration.transportOption')}
          </Typography>
        </Grid>
        <Grid item>
          {editable && !editDelivery && (
            <IconButton size="small" onClick={() => setEditDelivery(true)}>
              <Edit />
            </IconButton>
          )}
        </Grid>
      </Grid>
      {!editDelivery && deliveryAddress && (
        <div className={classes.displayAddress}>
          <p>{deliveryAddress.companyName}</p>
          <p>{deliveryAddress.street}</p>
          <p>
            {deliveryAddress.zip} {deliveryAddress.city}
          </p>
          <p>{deliveryAddress.country}</p>

          <p>
            <strong>{t('configuration.contactPerson')}: </strong>
            {deliveryAddress.contactPersonName}
          </p>
          <p>
            <strong>{t('contact.phone')}: </strong>
            {deliveryAddress.phoneNumber}
          </p>
          <p>
            <strong>{t('contact.E-Mail')}: </strong>
            {deliveryAddress.email}
          </p>
        </div>
      )}
      {editDelivery && (
        <DeliveryAddressForm
          deliveryAddresses={selectableDeliveryAddresses}
          methods={methods}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          isLoading={isLoading}
          showLink
          originTransportationId={option?.id}
          bidId={bidId}
        />
      )}
      {!editDelivery && (
        <div className={classes.displayAddress}>
          {option ? (
            <>
              <br />
              <p>
                <strong>{t('configuration.transportOption')}: </strong>
                {getLabel(transportOptionProvider, option?.provider || '')}
              </p>
              {option.cost && (
                <p>
                  <strong>{t('configuration.costs')}: </strong>
                  <UnitValue
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    value={option.cost.amount}
                    unit={option.cost.currency}
                  />
                </p>
              )}
              <p>
                <strong>{t('configuration.status')}: </strong>
                {getLabel(transportOptionStatus, status || '')}
              </p>
            </>
          ) : (
            <div className={classes.noTransportDiv}>
              <strong>{t('configuration.transportOption')}: </strong>
              <p>{t('configuration.noInfoAvailable')}</p>
            </div>
          )}
        </div>
      )}
      <br />
      <Grid container alignItems="center" spacing={1} id="sourceAddress">
        <Grid item>
          <Typography className={classes.subHeading}>{t('configuration.sourceAddress')}</Typography>
        </Grid>
      </Grid>
      {!sourceAddressEditable && (
        <div className={classes.displayAddress}>
          {sourceAddress ? (
            <>
              <p>{sourceAddress.companyName}</p>
              <p>{sourceAddress.street}</p>
              <p>
                {sourceAddress.zip} {sourceAddress.city}
              </p>
              <p>{sourceAddress.country}</p>

              <p>
                <strong>{t('configuration.contactPerson')}: </strong>
                {sourceAddress.contactPersonName}
              </p>
              <p>
                <strong>{t('contact.phone')}: </strong>
                {sourceAddress.phoneNumber}
              </p>
              <p>
                <strong>{t('contact.E-Mail')}: </strong>
                {sourceAddress.email}
              </p>
            </>
          ) : (
            <p>{t('configuration.noInfoAvailable')}</p>
          )}
        </div>
      )}
      {sourceAddressEditable && !loadingTransportInfo && (
        <div className={classes.SourceAddressForm}>
          <SourceAddressForm
            bidId={bidId}
            sourceAddress={transformDeliveryAddressDtoToAddress(sourceAddress)}
            refetch={refetch}
            vin={vin}
          />
        </div>
      )}
    </div>
  );
};

export default BoughtCarDeliveryAddressTransportation;
