import { MUIDataTableOptions } from 'mui-datatables';
import { tr } from '../modules/i18n-helpers';

const getMUIDataTableDefaultOptions = (): MUIDataTableOptions => ({
  print: false,
  filter: false,
  search: false,
  selectableRows: 'none',
  draggableColumns: {
    enabled: true,
    transitionTime: 1000,
  },
  fixedHeader: true,
  elevation: 6,
  tableBodyMaxHeight: 'calc(100vh - 53px)',
  rowsPerPageOptions: [10, 25, 50, 100],
  textLabels: {
    body: {
      noMatch: tr('carsTable.noEntriesFound'),
      toolTip: '',
    },
    filter: {
      all: tr('common.all'),
      title: tr('common.filter'),
      reset: tr('common.reset'),
    },
    pagination: {
      next: tr('carsTable.nextPage'),
      previous: tr('carsTable.prevPage'),
      rowsPerPage: tr('carsTable.rowsPerPage'),
      displayRows: tr('common.of'),
    },
    toolbar: {
      search: tr('filterSearch.search'),
      viewColumns: tr('carsTable.shownCols'),
      downloadCsv: tr('carsTable.downloadXLSX'),
    },
    viewColumns: {
      title: tr('carsTable.shownCols'),
      titleAria: tr('carsTable.showHideCols'),
    },
  },
});

export default getMUIDataTableDefaultOptions;
