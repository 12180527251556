import { DrawerContent, DrawerFooter, DrawerHeader, DrawerStatic, Select } from '@components/ui';
import { Badge, Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { tr } from 'src/modules/i18n-helpers';

type AutoRefreshProps = {
  onSubmit: (data: AutoRefreshData) => void;
  onPause: () => void;
  isActive?: boolean;
  intervalMs?: number | null;
  onChange?: (data: AutoRefreshData) => void;
};

export type AutoRefreshData = {
  intervalMs: number;
};

// TODO: offload to web worker
const useStyles = makeStyles((theme) => ({
  header: {
    fontWeight: 'bold',
  },
  description: {
    marginBlockEnd: theme.spacing(4),
  },
}));

const makeIntervalOption = (intervalMs: number) => {
  const seconds = intervalMs / 1000;
  return {
    value: intervalMs,
    label: `${seconds} ${tr('common.timeUnits', { context: 'xsecond', count: seconds })}`,
  };
};

const intervalOptions = [
  makeIntervalOption(5_000),
  makeIntervalOption(10_000),
  makeIntervalOption(30_000),
  makeIntervalOption(60_000),
];

export const AutoRefresh = ({
  onSubmit,
  onPause,
  isActive,
  intervalMs: initialIntervalMs,
  onChange,
}: AutoRefreshProps): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm<AutoRefreshData>({
    defaultValues: {
      // @ts-expect-error
      intervalMs: initialIntervalMs ?? '',
    },
  });
  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;

  useEffect(() => {
    const subscription = watch((data) => {
      onChangeRef.current?.(data);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [watch]);

  return (
    <DrawerStatic>
      <DrawerHeader>
        <Badge variant="dot" color="primary" invisible={!isActive} overlap="rectangular">
          <Typography className={classes.header}>{t('autoRefresh.title')}</Typography>
        </Badge>
      </DrawerHeader>
      <DrawerContent>
        <Typography className={classes.description} variant="body2">
          {t('autoRefresh.description')}
        </Typography>

        <form id="auto-refresh" autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="intervalMs"
            control={control}
            rules={{
              required: {
                value: true,
                message: t('autoRefresh.form.fields.interval.errors.required'),
              },
            }}
            render={({ field: { ref, ...field } }) => (
              <Select
                inputRef={ref}
                {...field}
                options={intervalOptions}
                label={t('autoRefresh.form.fields.interval.label')}
                helperText={t('autoRefresh.form.fields.interval.description')}
                error={errors.intervalMs}
              />
            )}
          />
        </form>
      </DrawerContent>
      <DrawerFooter>
        <Grid container justifyContent="flex-end">
          {isActive && (
            <Button size="small" variant="contained" color="primary" onClick={onPause}>
              {t('autoRefresh.stopBtn')}
            </Button>
          )}
          {!isActive && (
            <Button size="small" variant="contained" color="primary" type="submit" form="auto-refresh">
              {t('autoRefresh.startBtn')}
            </Button>
          )}
        </Grid>
      </DrawerFooter>
    </DrawerStatic>
  );
};
