import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  ButtonProps,
  Chip,
  CircularProgress,
  Typography,
  makeStyles,
} from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ExpandMore from '@material-ui/icons/ExpandMore';
import axios from 'axios';
import { partialRight } from 'lodash';
import { nanoid } from 'nanoid';
import { MouseEventHandler, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ImageGallery, { ReactImageGalleryItem, ReactImageGalleryProps } from 'react-image-gallery';
import { trackPromise } from 'react-promise-tracker';
import useApi from 'src/hooks/useApi';
import useRole from 'src/hooks/useRole';
import useCustomSnackbarGlobal from 'src/hooks/useSnackbarGlobal';
import ApiService from 'src/modules/api-service';
import { GalleryDTO } from 'src/modules/generated/api';
import UserRole from 'src/types/UserRoles';

type Image = {
  blob: Blob;
  name: string;
  extension: string;
};

const useStyles = makeStyles((theme) => ({
  accordionSummary: {
    '& .MuiAccordionSummary-content': {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  gallery: {
    minWidth: 0,
    flexGrow: 1,

    '& .image-gallery-content .image-gallery-slide .image-gallery-image': {
      maxHeight: '50vh',
      minHeight: 256,
    },
  },
  galleryItemDownloadButton: {
    position: 'absolute',
    right: 36,
    zIndex: 1,
  },
  separator: {
    marginTop: 24,
  },
  line: {
    width: '100%',
    marginTop: 24,
    marginBottom: 24,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
    borderWidth: 1,
  },
}));

const isValidFileName = (fileName: string) => /^[^<>:"/\\|?*\0-\31]+\.[^<>:"/\\|?*\0-\31]+$/.test(fileName);

const getFileName = (url: string) => {
  const parsedUrl = new URL(url);
  const fileName = parsedUrl.pathname.split('/').pop();

  if (!fileName || !isValidFileName(fileName)) {
    throw new Error('Invalid filename');
  }

  return fileName;
};

const fetchImage = async (imageUrl: string): Promise<Image> => {
  const res = await axios.get<Blob>(`${imageUrl}?`, { responseType: 'blob' }); // ? helps to avoid caching
  let [, extension] = res.headers['content-type'].split('/');

  const validExtensionTypes = ['jpg', 'jpeg', 'png', 'gif', 'webp'];
  if (!validExtensionTypes.includes(extension)) {
    extension = 'jpg'; // avoid problems with content type application/octet-stream
  }

  let fileName = '';
  try {
    fileName = getFileName(imageUrl);
  } catch (_error) {
    fileName = `${nanoid()}.${extension}`;
  }

  return {
    name: fileName,
    blob: res.data,
    extension,
  };
};

const download = async (imageUrl: string, config?: { getFileName?: (image: Image) => string | undefined }) => {
  const { default: saveAs } = await import('file-saver');
  const image = await fetchImage(imageUrl);
  return saveAs(image.blob, config?.getFileName?.(image) ?? image.name);
};

const downloadAll = async (
  imageUrls: string[],
  config?: {
    getName?: () => string;
    getFolderName?: () => string;
    getFileName?: (image: Image, index: number) => string;
  },
) => {
  const { default: JSZip } = await import('jszip');
  const zip = new JSZip();
  const imageFolder = zip.folder(config?.getFolderName?.() ?? 'images');

  if (!imageFolder) {
    throw new Error('folderCreationFailed');
  }

  const imagePromises = imageUrls.map((imageUrl) => fetchImage(imageUrl));
  const imageResults = await trackPromise(Promise.allSettled(imagePromises));
  const successfulImages = imageResults.filter(
    (result): result is PromiseFulfilledResult<Image> => result.status === 'fulfilled',
  );
  const failedImages = imageResults.filter((result): result is PromiseRejectedResult => result.status === 'rejected');

  successfulImages.forEach((result, index) =>
    imageFolder.file(config?.getFileName?.(result.value, index) ?? result.value.name, result.value.blob),
  );

  const { default: saveAs } = await import('file-saver');
  saveAs(await zip.generateAsync({ type: 'blob' }), config?.getName?.() ?? 'images.zip');

  return {
    success: successfulImages.length,
    failed: failedImages.length,
  };
};

const createGalleryItem = (imageUrl: string): ReactImageGalleryItem => ({
  original: imageUrl,
  thumbnail: imageUrl,
});

const GalleryFallback = () => {
  const { t } = useTranslation();

  return (
    <Typography variant="body1" color="textSecondary">
      {t('imageExport.gallery.fallback')}
    </Typography>
  );
};

const GalleryDownloadAllButton = ({
  gallery,
  config,
  ...rest
}: ButtonProps & {
  gallery: { original: string }[];
  config?: {
    getName?: () => string;
    getFolderName?: () => string;
    getFileName?: (image: Image, index: number) => string;
  };
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { showInfo, showSuccess, showError } = useCustomSnackbarGlobal();
  const showSuccessDelayed = partialRight(showSuccess, 'success', undefined, { autoHideDuration: 10000 });

  const handleClick: MouseEventHandler<HTMLButtonElement> = async (event) => {
    event.stopPropagation();

    showInfo(t('imageExport.actions.downloadAll.loading', { count: gallery.length }));
    setIsLoading(true);

    try {
      const stats = await downloadAll(
        gallery.map((item) => item.original),
        config,
      );
      const templateVars = { successCount: stats.success, failedCount: stats.failed };

      if (stats.failed) {
        showSuccessDelayed(t('imageExport.actions.downloadAll.success_partial', templateVars));
      } else {
        showSuccessDelayed(t('imageExport.actions.downloadAll.success', templateVars));
      }
    } catch (error) {
      if (error instanceof Error && error.message === 'folderCreationFailed') {
        showError(t('imageExport.actions.downloadAll.error.folderCreationFailed'));
      } else {
        showError(t('imageExport.actions.downloadAll.error.unexpected'));
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button
      variant="outlined"
      color="primary"
      endIcon={isLoading ? <CircularProgress size={20} /> : <CloudDownloadIcon />}
      onClick={handleClick}
      disabled={isLoading || gallery.length === 0}
      {...rest}
    >
      {t('imageExport.gallery.downloadAllButton')}
    </Button>
  );
};

const GalleryCountBadge = ({ count, isLoading }: { count: number; isLoading?: boolean }) => {
  const { t } = useTranslation();

  return (
    <Chip
      color="primary"
      label={isLoading ? t('common.isLoading') : t('imageExport.gallery.count', { count })}
      size="small"
    />
  );
};

const Gallery = (
  props: ReactImageGalleryProps & {
    downloadConfig?: { getFileName: (image: Image, index: number) => string };
  },
) => {
  const { items, downloadConfig } = props;
  const { t } = useTranslation();
  const galleryRef = useRef<ImageGallery>();
  const classes = useStyles();
  const { showSuccess, showError } = useCustomSnackbarGlobal();

  const handleClickDownload = async () => {
    try {
      const index = galleryRef.current?.getCurrentIndex() ?? 0;
      await download(items[index]?.original, {
        getFileName: (image) => downloadConfig?.getFileName(image, index),
      });
      showSuccess(t('imageExport.actions.download.success'));
    } catch (error) {
      showError(t('imageExport.actions.download.error.unexpected'));
    }
  };

  const imageGalleryProps: Omit<ReactImageGalleryProps, 'items'> = {
    lazyLoad: true,
    renderCustomControls: () => (
      <Button
        onClick={handleClickDownload}
        variant="contained"
        className={classes.galleryItemDownloadButton}
        size="small"
        color="primary"
        aria-label={t('common.download')}
        endIcon={<CloudDownloadIcon />}
      >
        {t('common.download')}
      </Button>
    ),
  };

  return (
    <ImageGallery
      {...props}
      {...imageGalleryProps}
      // @ts-expect-error
      ref={galleryRef}
      additionalClass={classes.gallery}
    />
  );
};

type CarImagesProps = {
  vin: string;
};

export const CarImages = ({ vin }: CarImagesProps) => {
  const { hasRole } = useRole();
  const { t } = useTranslation();
  const classes = useStyles();
  const { showError } = useCustomSnackbarGlobal();
  const { fetch, data, loading, error } = useApi<GalleryDTO>();

  const gallery360 = {
    exterior: data?.images360Degree?.exterior?.map((imageUrl) => createGalleryItem(imageUrl)) ?? [],
    interior: data?.images360Degree?.interior?.map((imageUrl) => createGalleryItem(imageUrl)) ?? [],
  };
  const galleryDetail = data?.imagesDetail?.map((imageUrl) => createGalleryItem(imageUrl)) ?? [];
  const galleryConfigurator =
    data?.imagesCarConfigurator?.map((imageUrl) => createGalleryItem(`${imageUrl}?size=XL`)) ?? [];

  useEffect(() => {
    fetch(ApiService.imageExport.exportImagesControllerGetGalleryData(vin));
  }, [fetch, vin]);

  useEffect(() => {
    if (error && error.response?.status === 404) {
      showError(t('imageExport.error.404'));
    } else if (error) {
      showError(t('alerts.errorRaised'));
    }
  }, [error, showError, t]);

  const getDownloadAllConfig = (type: string) => ({
    getName: () => `${vin}_${type}.zip`,
    getFolderName: () => `${vin}_${type}`,
    getFileName: (image: Image, index: number) => `${vin}_${type}_${index + 1}.${image.extension}`,
  });

  const getDownloadConfig = (type: string) => ({
    getFileName: (image: Image, index: number) => `${vin}_${type}_${index + 1}.${image.extension}`,
  });

  return (
    <div>
      <Accordion defaultExpanded>
        <AccordionSummary
          className={classes.accordionSummary}
          expandIcon={<ExpandMore />}
          aria-controls="exterior-content"
          id="exterior-header"
        >
          <Typography variant="h6">
            {t('imageExport.gallery.360images.exterior')}{' '}
            <GalleryCountBadge isLoading={loading} count={gallery360.exterior.length} />
          </Typography>
          <GalleryDownloadAllButton gallery={gallery360.exterior} config={getDownloadAllConfig('360-exterior')} />
        </AccordionSummary>
        <AccordionDetails>
          {gallery360.exterior.length > 0 ? (
            <Gallery items={gallery360.exterior} downloadConfig={getDownloadConfig('360-exterior')} />
          ) : (
            <GalleryFallback />
          )}
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          className={classes.accordionSummary}
          expandIcon={<ExpandMore />}
          aria-controls="detail-content"
          id="detail-header"
        >
          <Typography variant="h6">
            {t('imageExport.gallery.detailImages')}{' '}
            <GalleryCountBadge isLoading={loading} count={galleryDetail.length} />
          </Typography>
          <GalleryDownloadAllButton gallery={galleryDetail} config={getDownloadAllConfig('detail')} />
        </AccordionSummary>
        <AccordionDetails>
          {galleryDetail.length > 0 ? (
            <Gallery items={galleryDetail} downloadConfig={getDownloadConfig('detail')} />
          ) : (
            <GalleryFallback />
          )}
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          className={classes.accordionSummary}
          expandIcon={<ExpandMore />}
          aria-controls="interior-content"
          id="interior-header"
        >
          <Typography variant="h6">
            {t('imageExport.gallery.360images.interior')}{' '}
            <GalleryCountBadge isLoading={loading} count={gallery360.interior.length} />
          </Typography>
          <GalleryDownloadAllButton gallery={gallery360.interior} config={getDownloadAllConfig('360-interior')} />
        </AccordionSummary>
        <AccordionDetails>
          {gallery360.interior.length > 0 ? (
            <Gallery items={gallery360.interior} downloadConfig={getDownloadConfig('360-interior')} />
          ) : (
            <GalleryFallback />
          )}
        </AccordionDetails>
      </Accordion>

      {hasRole(UserRole.ADMIN) ? (
        <div className={classes.separator}>
          <hr className={classes.line} />
          <Typography variant="h1">{t('imageExport.gallery.carConfiguratorImages')}</Typography>
          <Accordion defaultExpanded>
            <AccordionSummary
              className={classes.accordionSummary}
              expandIcon={<ExpandMore />}
              aria-controls="configurator-content"
              id="configurator-header"
            >
              <Typography variant="h6">
                {t('imageExport.gallery.renderedVehicleImages')}{' '}
                <GalleryCountBadge isLoading={loading} count={galleryConfigurator.length} />
              </Typography>
              <GalleryDownloadAllButton gallery={galleryConfigurator} config={getDownloadAllConfig('configurator')} />
            </AccordionSummary>
            <AccordionDetails>
              {galleryConfigurator.length > 0 ? (
                <Gallery items={galleryConfigurator} downloadConfig={getDownloadConfig('configurator')} />
              ) : (
                <GalleryFallback />
              )}
            </AccordionDetails>
          </Accordion>
        </div>
      ) : null}
    </div>
  );
};

export default CarImages;
