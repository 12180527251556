import { Button, makeStyles, Paper, Typography } from '@material-ui/core';
import { Refresh } from '@material-ui/icons';
import { isUndefined } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useApi from 'src/hooks/useApi';
import { UserPermissions, useUserPermissions } from 'src/hooks/useUserPermissions';
import ApiService from 'src/modules/api-service';
import { BidDataTablesOutputListBidDTO, BidSuccessStatus, DetailCarDTO } from '../modules/generated/api';
import BoughtCarDeliveryAddress from './BoughtCarDeliveryAddress';
import BoughtCarDeliveryAddressTransportation from './BoughtCarDeliveryAddressTransportation';

const useStyles = makeStyles((theme) => ({
  tiles: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(2),
  },
  center: {
    textAlign: 'center',
  },
}));

type CarDetailBoughtCarDeliveryAddressProps = {
  car: DetailCarDTO;
};
const CarDetailBoughtCarDeliveryAddress = ({ car }: CarDetailBoughtCarDeliveryAddressProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const allowedUserForTransportation = useUserPermissions(UserPermissions.CAN_USE_TRANSPORTATION);
  const { fetch } = useApi<BidDataTablesOutputListBidDTO>();

  const [bid, setBid] = useState(car.bid);

  const handleSearchBid = async () => {
    if (isUndefined(car.vin)) return;
    const response = await fetch(
      ApiService.newBids.bidNewControllerList({
        carsRequest: { carIds: [car.vin] },
      }),
    );
    const queriedBid = response?.data?.data?.[0];
    if (queriedBid) setBid(queriedBid);
  };

  useEffect(() => {
    setBid(car.bid);
  }, [car]);

  const bidId = car.bid?.id;

  return (
    <Paper elevation={3} className={classes.tiles}>
      {bid?.id && (
        <div>
          {allowedUserForTransportation ? (
            <BoughtCarDeliveryAddressTransportation
              deliveryAddressSelection={car.deliveryAddressSelection}
              bidId={bidId}
              vin={car.vin}
            />
          ) : (
            <BoughtCarDeliveryAddress
              bidId={bid.id}
              changeDeliveryAddressUntil={bid.deliveryAddressChangeUntil}
              deliveryAddress={bid.deliveryAddress}
              deliveryAddressSelection={car.deliveryAddressSelection}
              winningBid={bid.bids?.find((detailedBid) => detailedBid.status === BidSuccessStatus.Bought)}
            />
          )}
        </div>
      )}
      {!bid && (
        <div>
          <Typography variant="h2">{t('navigation.delivery')} </Typography>
          <p>{t('alerts.loadDeliveryAddress')}</p>
          <div className={classes.center}>
            <Button onClick={handleSearchBid}>
              <Refresh /> {t('common.loadMore')}
            </Button>
          </div>
        </div>
      )}
    </Paper>
  );
};

export default CarDetailBoughtCarDeliveryAddress;
