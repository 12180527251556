import { makeStyles } from '@material-ui/core';
import { isNil } from 'lodash';
import UnitValueCell from './UnitValueCell';
import useRole from '../hooks/useRole';
import UserRole from '../types/UserRoles';
import { MonetaryAmount, ValuationCountryCode } from '../modules/generated/api';
import CarsTableValuationCountryIndicator from './CarsTableValuationCountryIndicator';
import { makeMonetaryAmount, SupportedCurrencyCode } from '../modules/currency';

type CarsTableMarketPriceProps = {
  value?: MonetaryAmount;
  valuationCountry?: ValuationCountryCode;
};

const useStyles = makeStyles((theme) => ({
  root: { position: 'relative' },
  valueDiv: {
    marginInlineStart: theme.spacing(2),
  },
}));

const CarsTableMarketPrice = ({ value, valuationCountry }: CarsTableMarketPriceProps) => {
  const classes = useStyles();
  const { hasRole } = useRole();
  const money = !isNil(value) ? makeMonetaryAmount(value, value.currency as SupportedCurrencyCode) : undefined;

  return (
    <div className={hasRole(UserRole.ADMIN) ? classes.root : ''}>
      {hasRole(UserRole.ADMIN) && valuationCountry && (
        <CarsTableValuationCountryIndicator valuationCountry={valuationCountry} />
      )}
      <div className={classes.valueDiv}>
        <UnitValueCell unit={money?.currency} value={money?.amount} />
      </div>
    </div>
  );
};

export default CarsTableMarketPrice;
