import React, { ChangeEvent } from 'react';
import { InputLabel, Stack } from '@components/ui';
import { InputAdornment, makeStyles, Slider, SliderProps, TextField, TextFieldProps } from '@material-ui/core';
import type { Merge } from 'type-fest';
import { useTranslation } from 'react-i18next';
import { replaceItemAtIndex } from 'src/modules/util';
import NumberFormat from 'react-number-format';

export type RangeSliderProps = {
  label: string;
  onChange: (value: [min: number | '', max: number | '']) => void;
  value: (number | '')[] | undefined;
  defaultValue?: number[];
  unit?: React.ReactNode;
};

type NumberFormatCustomProps = {
  inputRef: (instance: NumberFormat<any> | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
};

type RangeSliderInputProps = TextFieldProps & {
  unit?: React.ReactNode;
};

const useClasses = makeStyles((theme) => ({
  inputLabel: {
    marginBlockEnd: theme.spacing(0.5),
    fontWeight: 'normal',
    fontSize: 12,
    color: theme.palette.grey[600],
  },
}));

const NumberFormatCustom = ({ inputRef, onChange, name, ...rest }: NumberFormatCustomProps) => (
  <NumberFormat
    {...rest}
    name={name}
    getInputRef={inputRef}
    onValueChange={(values) => {
      onChange({
        target: {
          name,
          value: values.value,
        },
      });
    }}
    isNumericString
    decimalSeparator=","
    thousandSeparator="."
  />
);

const RangeSliderInput = ({ unit, ...props }: RangeSliderInputProps) => (
  <TextField
    {...props}
    variant="outlined"
    size="small"
    InputProps={{
      endAdornment: unit ? <InputAdornment position="end">{unit}</InputAdornment> : undefined,
      inputComponent: NumberFormatCustom as any,
    }}
  />
);

export const RangeSlider = ({
  label,
  min = 0,
  max = 100,
  defaultValue: initialDefaultValue,
  onChange,
  value,
  name,
  step,
  unit,
  ...rest
}: Merge<Omit<SliderProps<'span'>, 'value'>, RangeSliderProps>): JSX.Element => {
  const classes = useClasses();
  const { t } = useTranslation();
  const defaultValue = initialDefaultValue ?? (min !== undefined && max !== undefined ? [min, max] : undefined);
  const [startValue, endValue] = value ?? defaultValue ?? [];

  const handleSliderChange = (_event: ChangeEvent<{}>, newValue: number | number[]) => {
    if (Array.isArray(newValue)) {
      onChange(newValue as [number, number]);
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: 0 | 1) => {
    onChange(
      replaceItemAtIndex(
        [startValue, endValue],
        index,
        event.target.value === '' ? '' : Number(event.target.value),
      ) as [number, number],
    );
  };

  return (
    <div>
      <InputLabel className={classes.inputLabel}>{t(label as any)}</InputLabel>
      <Slider
        name={name}
        min={min}
        max={max}
        step={step}
        defaultValue={defaultValue}
        color="secondary"
        style={{
          display: 'block',
        }}
        valueLabelDisplay="off"
        onChange={handleSliderChange}
        value={[Number(startValue), Number(endValue)]}
        {...rest}
      />
      <Stack direction="row" spacing={1}>
        <RangeSliderInput value={startValue ?? ''} onChange={(event) => handleInputChange(event, 0)} unit={unit} />
        <RangeSliderInput value={endValue ?? ''} onChange={(event) => handleInputChange(event, 1)} unit={unit} />
      </Stack>
    </div>
  );
};
