import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    fontWeight: 'bold',
    fontSize: '1.5rem',
    lineHeight: '2rem',
  },
});

type CarDetailHeadlineProps = {
  title: string;
};

const CarDetailHeadline = ({ title }: CarDetailHeadlineProps) => {
  const classes = useStyles();

  return (
    <Typography variant="h2" className={classes.root}>
      {title}
    </Typography>
  );
};

export default CarDetailHeadline;
