import { isMonetaryAmount, isUnitEntry } from '@components/cars-table-next/lib';
import { Tooltip } from '@material-ui/core';
import { isNil, isNumber } from 'lodash';
import { ComponentProps } from 'react';
import { useCurrency } from 'src/hooks/useCurrency';
import { DEFAULT_CURRENCY, isSupportedCurrencyCode } from 'src/modules/currency';
import { MonetaryAmount, UnitEntry } from 'src/modules/generated/api';
import type { Merge } from 'type-fest';
import { TextCell, TextCellFallback, TextCellFallbackProps, TextCellProps } from './TextCell';

export type NumericCellProps = {
  value: NumericCellValue;
  format?: (value: number) => string;
};

type NumericCellValue = number | MonetaryAmount | UnitEntry | string | undefined;

/**
 * Display numeric data with optional customized formatting.
 */
export const NumericCell = ({
  value,
  format,
  style,
  ...rest
}: Merge<ComponentProps<'div'>, Merge<Partial<TextCellProps>, NumericCellProps>>): JSX.Element => {
  const { format: formatCurrency, convert } = useCurrency();

  // TODO: convert to pure function
  const getText = () => {
    if (isNumber(value)) {
      return format?.(value) ?? String(value);
    }

    if (isMonetaryAmount(value)) {
      if (isNil(value.amount) || isNil(value.currency)) {
        return 'ERROR';
      }

      const converted = convert(value, {});

      return (
        <Tooltip
          title={
            converted.currency !== DEFAULT_CURRENCY.code
              ? formatCurrency(convert(converted, { to: DEFAULT_CURRENCY.code }), {})
              : ''
          }
        >
          <span>{formatCurrency(converted, {})}</span>
        </Tooltip>
      );
    }

    // TODO: remove UnitEntry for currencies from BE
    if (isUnitEntry(value) && value.unit === '€') {
      if (isNil(value.value) || !isSupportedCurrencyCode(value.unit)) {
        return 'ERROR';
      }

      const converted = convert({ amount: value.value, currency: value.unit }, {});

      return (
        <Tooltip
          title={
            converted.currency !== DEFAULT_CURRENCY.code
              ? formatCurrency(convert(converted, { to: DEFAULT_CURRENCY.code }), {})
              : ''
          }
        >
          <span>{formatCurrency(converted, {})}</span>
        </Tooltip>
      );
    }

    return String(value);
  };

  return (
    <TextCell
      style={{
        textAlign: 'right',
        ...style,
      }}
      text={getText()}
      {...rest}
    />
  );
};

export const NumericCellFallback = (props: TextCellFallbackProps): JSX.Element => (
  <TextCellFallback {...props} style={{ textAlign: 'right' }} />
);
