import { Tooltip } from '@material-ui/core';
import { ReactElement, useState } from 'react';
import { getIcon, IconType, fallbackIcon } from '../modules/data';
import { getLabel, promotions } from '../modules/labels';

type PromotionIconProps = {
  promotion: string;
};

export const PromotionIcon = ({ promotion }: PromotionIconProps): ReactElement => {
  const icon = getIcon(IconType.PROMOTION, promotion);

  const [imgSrc, setImgSrc] = useState(icon);
  const onError = () => setImgSrc(fallbackIcon);

  return (
    <Tooltip title={getLabel(promotions, promotion)}>
      <img src={imgSrc} alt={getLabel(promotions, promotion)} width={24} onError={onError} />
    </Tooltip>
  );
};
