import { isNumber } from 'lodash';
import { ValuationCountryCode } from 'src/modules/generated/api';
import { Tax } from 'src/types/tax';

export const getTaxId = (country: ValuationCountryCode, tax: Tax) => `taxes.${country}.${tax}`;

// untangle the different percentage formats (floats 0-1; integers 0-100) in order to properly compare values
// necessary for conditional formats
export const getRelativeIntValue = (value: number | undefined): number | undefined =>
  isNumber(value) ? value * 100 : value;
