import { isArray } from 'lodash';
import { tr } from 'src/modules/i18n-helpers';
import { formatNumber } from 'src/modules/math-helpers';
import FilterChip from './FilterChip';
import { BidsTableNextFilterData, CarsTableNextFilterData } from './types';
import { useBidsFilter } from './useBidsFilter';
import { useFilter } from './useFilter';

type SliderFilterChipProps = {
  value: any;
  name: string;
  label: string;
  number?: boolean;
  unit?: string;
  format?: (value: any) => string;
  prefix?: string;
};

const SliderFilterChip = ({ value, name, label, number, unit, format, prefix }: SliderFilterChipProps) => {
  const { defaultFilter: carsDefaultFilter, setValue: setCarsFilterValue } = useFilter();
  const { defaultFilter: bidsDefaultFilter, setValue: setBidsFilterValue } = useBidsFilter();

  const getLabel = () => {
    if (typeof format === 'function') {
      return format(value);
    }
    if (number) {
      if (value[0] === '') return `<= ${formatNumber(value[1], unit)}`;
      if (value[1] === '') return `>= ${formatNumber(value[0], unit)}`;
      return `${formatNumber(value[0])} - ${formatNumber(value[1], unit)}`;
    }
    if (value[0] === '') return `<= ${value[1]}${unit ? ` ${unit}` : ''}`;
    if (value[1] === '') return `>= ${value[0]}${unit ? ` ${unit}` : ''}`;
    return `${value[0] || 0} - ${value[1]}${unit ? ` ${unit}` : ''}`;
  };

  if (isArray(value) && !(value[0] === '' && value[1] === '') && !(value[0] === 0 && value[1] === ''))
    return (
      <FilterChip
        name={name}
        key={`${name}-${value}`}
        tooltip={tr(`${label}`)}
        label={getLabel()}
        onDelete={() =>
          prefix
            ? setBidsFilterValue(name as any, bidsDefaultFilter[name as keyof BidsTableNextFilterData])
            : setCarsFilterValue(name as any, carsDefaultFilter[name as keyof CarsTableNextFilterData])
        }
      />
    );
  return null;
};

export default SliderFilterChip;
