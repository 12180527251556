import { useState } from 'react';
import {
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  Step,
  StepIconProps,
  StepLabel,
  Stepper,
  Theme,
  withStyles,
} from '@material-ui/core';
import { EditLocation, Euro, FormatColorFill, PlaylistAddCheck } from '@material-ui/icons';
import clsx from 'clsx';
import GavelIcon from '@material-ui/icons/Gavel';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { DealerTable } from '@components/dealer-table/DealerTable';
import TermsAndConditionsForm from './TermsAndConditionsForm';
import ConfigurationFormattingForm from './ConfigurationFormattingForm';
import { WizardStep } from '../modules/generated/api';
import ConfigurationCostsForm from './ConfigurationCostsForm';
import { useWizard } from '../hooks/useWizard';
import DeliveryAddressContainer from './DeliveryAddressContainer';

const useColorlibStepIconStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage: `linear-gradient( 136deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 50%, ${theme.palette.primary.dark} 100%)`,
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage: `linear-gradient( 136deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 50%, ${theme.palette.primary.dark} 100%)`,
  },
}));

function ColorlibStepIcon(props: StepIconProps) {
  const classes = useColorlibStepIconStyles();
  const { active, completed, icon } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icon}
    </div>
  );
}

const ColorlibConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage: `linear-gradient( 95deg,${theme.palette.primary.light} 0%,${theme.palette.primary.main} 50%,${theme.palette.primary.dark} 100%)`,
    },
  },
  completed: {
    '& $line': {
      backgroundImage: `linear-gradient( 95deg,${theme.palette.primary.light} 0%,${theme.palette.primary.main} 50%,${theme.palette.primary.dark} 100%)`,
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
}))(StepConnector);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    content: {
      paddingTop: theme.spacing(3),
    },
    dialogPaper: {
      minHeight: '70vh',
      maxHeight: '70vh',
    },
  }),
);

const WIZARD_STEPS = [
  {
    id: WizardStep.TermsConditions,
    label: 'termsAndConditions.termsAndConditions',
    icon: <PlaylistAddCheck />,
  },
  {
    id: WizardStep.DealerAdministration,
    label: 'navigation.dealerManagement',
    icon: <GavelIcon />,
  },
  { id: WizardStep.DealerAddress, label: 'navigation.delivery', icon: <EditLocation /> },
  { id: WizardStep.Costs, label: 'configuration.costConfig', icon: <Euro /> },
  {
    id: WizardStep.ConditionalFormatting,
    label: 'conditionalFormatting.conditionalFormatting',
    icon: <FormatColorFill />,
  },
];

const WizardPopup = () => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const { t } = useTranslation();
  const { wizard, hasWizardStepsToShow, refetchWizard, onConfirmCallback } = useWizard();

  const stepOrder = WIZARD_STEPS.filter((wizardStep) => wizard?.steps?.[wizardStep.id].show);

  const handleSkip = () => {
    // On finish re-fetch to disable badges
    if (activeStep === stepOrder.length - 1) {
      refetchWizard();
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleNext = async () => {
    await onConfirmCallback(stepOrder[activeStep]?.id, true);
    // On finish re-fetch to disable badges
    if (activeStep === stepOrder.length - 1) {
      refetchWizard();
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const currentWizardStep = stepOrder[activeStep];
  const currentWizardDetail = wizard?.steps?.[currentWizardStep?.id];
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {hasWizardStepsToShow() && currentWizardStep && (
        <Dialog
          open={activeStep < stepOrder.length && !!wizard}
          maxWidth="lg"
          fullWidth
          classes={{ paper: classes.dialogPaper }}
        >
          <DialogContent>
            {stepOrder.length > 1 && (
              <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                {stepOrder.map((wizardStep) => (
                  <Step key={wizardStep.id}>
                    <StepLabel StepIconProps={{ icon: wizardStep.icon }} StepIconComponent={ColorlibStepIcon}>
                      {t(wizardStep.label as any)}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            )}
            <div className={stepOrder.length > 1 ? classes.content : undefined}>
              {currentWizardStep.id === WizardStep.TermsConditions && <TermsAndConditionsForm showInWizard />}
              {currentWizardStep.id === WizardStep.DealerAdministration && <DealerTable showInWizard />}
              {currentWizardStep.id === WizardStep.DealerAddress && <DeliveryAddressContainer showInWizard />}
              {currentWizardStep.id === WizardStep.Costs && <ConfigurationCostsForm showInWizard />}
              {currentWizardStep.id === WizardStep.ConditionalFormatting && (
                <ConfigurationFormattingForm showInWizard />
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <>
              <Button disabled={currentWizardDetail?.required} onClick={handleSkip}>
                {t('common.skip')}
              </Button>
              <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                {t('common.back')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                className={classes.button}
                disabled={currentWizardDetail?.required && !currentWizardDetail?.confirmed}
              >
                {activeStep === stepOrder.length - 1 ? t('common.finish') : t('common.next')}
              </Button>
            </>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default WizardPopup;
