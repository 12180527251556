import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from 'src/constants';
import ApiService from 'src/modules/api-service';
import { SearchAgentDTO, SearchAgentRequestDTO } from 'src/modules/generated/api';

export const useCreateSearchAgent = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (newSearchAgent: SearchAgentRequestDTO) =>
      ApiService.searchAgent.searchAgentControllerCreateSearchAgent(newSearchAgent),
    {
      onMutate: async (newSearchAgent) => {
        await queryClient.cancelQueries([QueryKeys.searchAgents]);

        const snapshot = queryClient.getQueryData<SearchAgentDTO[]>([QueryKeys.searchAgents]);

        queryClient.setQueryData<SearchAgentDTO[]>([QueryKeys.searchAgents], (prev = []) => [...prev, newSearchAgent]);

        return snapshot;
      },
      onError: (_error, _newSearchAgent, snapshot) => {
        queryClient.setQueryData<SearchAgentDTO[] | undefined>([QueryKeys.searchAgents], snapshot);
      },
      onSettled: () => {
        queryClient.invalidateQueries([QueryKeys.searchAgents]);
      },
    },
  );

  return mutation;
};
