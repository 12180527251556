import { grey } from '@material-ui/core/colors';
import { CSSProperties, FC } from 'react';
import {
  DragDropContext,
  DragDropContextProps,
  Draggable,
  DraggableProvidedDragHandleProps,
  Droppable,
} from 'react-beautiful-dnd';
import type { Merge } from 'type-fest';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';

export type DraggableListProps = {
  droppableId: string;
  items: DraggableListItem[];
};

export type DraggableListItem = {
  id: string;
  content: FC<DraggableListItemContentProps>;
};

export type DraggableListItemContentProps = {
  dragHandleProps?: DraggableProvidedDragHandleProps;
  isDragging?: boolean;
};

type DragHandleProps = {
  disabled?: boolean;
  className?: string;
  style?: CSSProperties;
};

export const reorder = <T extends unknown>(list: T[], startIndex: number, endIndex: number): T[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const DragHandle = ({
  disabled,
  style,
  ...rest
}: Merge<Partial<DraggableProvidedDragHandleProps>, DragHandleProps>) => (
  <button
    type="button"
    style={{
      display: 'flex',
      appearance: 'none',
      padding: 0,
      background: 'none',
      border: 'none',
      color: grey[600],
      ...(disabled && {
        pointerEvents: 'none',
        opacity: 0.4,
      }),
      ...style,
    }}
    {...rest}
  >
    <DragIndicatorIcon fontSize="small" />
  </button>
);

export const DraggableList = ({
  droppableId,
  items,
  ...dragDropCtxProps
}: Merge<Omit<DragDropContextProps, 'children'>, DraggableListProps>): JSX.Element => (
  <DragDropContext {...dragDropCtxProps}>
    <Droppable droppableId={droppableId}>
      {(droppableProvided, droppableSnapshot) => (
        <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
          {items.map((item, index) => (
            <Draggable key={item.id} draggableId={item.id} index={index}>
              {(draggableProvided, draggableSnapshot) => (
                <div
                  ref={draggableProvided.innerRef}
                  {...draggableProvided.draggableProps}
                  style={{
                    ...draggableProvided.draggableProps.style,
                    marginBlockEnd: 6,
                    pointerEvents: droppableSnapshot.isDraggingOver ? 'none' : 'initial',
                  }}
                >
                  {item.content({
                    dragHandleProps: draggableProvided.dragHandleProps,
                    isDragging: draggableSnapshot.isDragging,
                  })}
                </div>
              )}
            </Draggable>
          ))}
          {droppableProvided.placeholder}
        </div>
      )}
    </Droppable>
  </DragDropContext>
);
