/* eslint-disable no-nested-ternary */
import { useId, Fragment } from 'react';
import { Chip, makeStyles } from '@material-ui/core';
import useMeasure from 'react-use-measure';
import { useTranslation } from 'react-i18next';
import * as d3 from 'd3';
import { red, green, grey } from '@material-ui/core/colors';
import Tooltip from '@material-ui/core/Tooltip';
import { useCurrency } from 'src/hooks/useCurrency';
import { DateTime } from 'luxon';
import { clsx } from 'clsx';
import { DetailCarPreviousAuctionDTO, MarketingChannel, MonetaryAmount } from '../../../modules/generated/api/api';

type TrendChartProps = {
  prevAuctions: Array<DetailCarPreviousAuctionDTO>;
  monetary: Array<MonetaryAmount>;
  diffNegative?: boolean;
  marketingChannel: MarketingChannel[];
};

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    background: 'white',
  },
  point: {
    zIndex: 1,
  },
  chip: {
    margin: '5px',
    fontSize: '0.99em',
    '&--green': {
      backgroundColor: green[500],
    },
    '&--grey': {
      backgroundColor: grey[700],
    },
    '&--outlined': {
      color: green[500],
      backgroundColor: grey[700],
    },
  },
});

export const TrendChart = ({ prevAuctions, diffNegative, monetary, marketingChannel }: TrendChartProps) => {
  const margin = {
    top: 6,
    right: 6,
    bottom: 6,
    left: 6,
  };

  const { format, convert } = useCurrency();

  const auctionPrices = prevAuctions.map((amount) => amount.price?.base?.amount);

  const auctions = auctionPrices.filter((price) => price !== undefined).map((price) => price as number);

  const timeStamps = prevAuctions.map((time) => time.dateStart);

  const [ref, bounds] = useMeasure();
  const classes = useStyles();
  const max = Math.max(...auctions);
  const [minDate, maxDate] = d3.extent(timeStamps, (item) => item);

  const xScale = d3
    .scaleTime<number>()
    .domain([new Date(minDate || DateTime.now().toISO()), new Date(maxDate || DateTime.now().toISO())])
    .range([0, bounds.width - margin.left - margin.right]);

  const yScale = d3
    .scaleLinear<number>()
    .domain([0, max * 1.5])
    .range([bounds.height - margin.top - margin.bottom, 0]);

  const lineGenerator = d3.line().curve(d3.curveBasis);
  const areaGenerator = d3
    .area()
    .x(([x]) => x)
    .y0(([, y]) => y)
    .y1(() => yScale(0))
    .curve(d3.curveBasis);

  const points =
    prevAuctions.map<[number, number]>((item) => [
      xScale(new Date(item.dateStart || DateTime.now().toISO())),
      yScale(item.price?.base?.amount as number),
    ]) || [];

  const ID = useId();

  const { t } = useTranslation();
  const bidText = t('carsTableNext.filter.marketingChannels.BID');
  const fixText = t('carsTableNext.filter.marketingChannels.FIXED');

  const bidding = marketingChannel.includes(MarketingChannel.Bid);
  const buying = marketingChannel.includes(MarketingChannel.Fixed);
  const biddingAndBuying =
    marketingChannel.includes(MarketingChannel.Bid) && marketingChannel.includes(MarketingChannel.Fixed);

  const chipColor = () => {
    switch (true) {
      case biddingAndBuying:
        return 'outlined';
      case bidding:
        return 'grey';
      case buying:
        return 'green';
      default:
        return '';
    }
  };

  return (
    <div ref={ref} className={classes.root}>
      <svg width={bounds.width} height={bounds.height}>
        <defs>
          <linearGradient id={`${ID}`} x1="0" x2="0" y1="0" y2="1">
            <stop stopColor={diffNegative ? green[100] : red[100]} stopOpacity="1" offset="0%" />
            <stop stopColor={diffNegative ? green[100] : red[100]} stopOpacity="0" offset="100%" />
          </linearGradient>
        </defs>
        <g transform={`translate(${margin.left}, ${margin.top})`}>
          <path d={areaGenerator(points) as string} fill={`url(#${ID})`} />
          <path
            d={lineGenerator(points) as string}
            stroke={diffNegative ? green[900] : red[900]}
            strokeWidth={1}
            fill="none"
          />
          {points.map((item, index) => (
            <Fragment key={item[0]}>
              <circle
                cx={item[0]}
                cy={item[1]}
                r="3.5"
                fill={biddingAndBuying ? grey[700] : bidding ? grey[700] : green[500]}
                stroke={biddingAndBuying ? green[500] : bidding ? grey[700] : green[500]}
              />
              <Tooltip
                title={
                  <>
                    {`${format(convert(monetary[index], {}), {})}`}{' '}
                    <Chip
                      color="primary"
                      className={clsx(classes.chip, `${classes.chip}--${chipColor()} `)}
                      label={
                        biddingAndBuying
                          ? `${bidText}, ${fixText}`
                          : bidding
                            ? `${bidText}`
                            : buying
                              ? `${fixText}`
                              : ''
                      }
                      size="small"
                    />
                  </>
                }
                placement="right"
              >
                <circle fillOpacity="0.0" r="10" cx={item[0]} cy={item[1]} />
              </Tooltip>
            </Fragment>
          ))}
        </g>
      </svg>
    </div>
  );
};
