import { Card, IconButton, InputAdornment, makeStyles, TextField } from '@material-ui/core';
import { ChangeEventHandler } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import NumberFormat from 'react-number-format';
import { Stack } from '@components/ui';
import { toNumber } from 'lodash';
import { MonetaryAmount } from 'src/modules/generated/api';
import { useTranslation } from 'react-i18next';
import { Position } from './types';

type ServicePositionItemProps = {
  name: string;
  description: string;
  price: MonetaryAmount;
  onChange: (data: Position) => void;
  onClickDelete: () => void;
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  inputName: {
    flexGrow: 1,
  },
  inputPrice: {
    flexGrow: 0,
    flexBasis: '12ch',
  },
  buttonDelete: {
    color: theme.palette.error.main,
  },
}));

const PriceInput = (props: any) => {
  const { inputRef, onChange, name, ...other } = props;

  return (
    <NumberFormat
      {...other}
      name={name}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name,
            value: values.value,
          },
        });
      }}
      // IMPROVE: i18n?
      thousandSeparator="."
      decimalSeparator=","
      isNumericString
    />
  );
};

export const ServicePositionItem = ({
  name,
  description,
  price,
  onChange,
  onClickDelete,
}: ServicePositionItemProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleChangeName: ChangeEventHandler<HTMLInputElement> = (event) => {
    onChange({
      name: event.target.value ?? '',
      description,
      price,
    });
  };

  const handleChangeDescription: ChangeEventHandler<HTMLInputElement> = (event) => {
    onChange({
      name,
      description: event.target.value,
      price,
    });
  };

  const handleChangePrice: ChangeEventHandler<HTMLInputElement> = (event) => {
    onChange({
      name,
      description,
      price: {
        ...price,
        amount:
          event.target.value !== '' // prevent converting empty strings to 0
            ? toNumber(event.target.value)
            : undefined,
      },
    });
  };

  return (
    <Card className={classes.root}>
      <Stack spacing={2}>
        <Stack direction="row" spacing={2}>
          <TextField
            className={classes.inputName}
            size="small"
            placeholder={t('servicePositionItem.form.name.label')}
            value={name}
            required
            onChange={handleChangeName}
          />
          <TextField
            className={classes.inputPrice}
            size="small"
            placeholder={t('servicePositionItem.form.price.label')}
            InputProps={{
              inputComponent: PriceInput as any,
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
            required
            value={price.amount}
            onChange={handleChangePrice}
          />
          <IconButton size="small" onClick={onClickDelete} color="inherit" className={classes.buttonDelete}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Stack>
        <TextField
          size="small"
          placeholder={t('servicePositionItem.form.description.label')}
          value={description}
          required
          minRows={3}
          multiline
          onChange={handleChangeDescription}
        />
      </Stack>
    </Card>
  );
};
