import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import useRole from '../hooks/useRole';
import { BidFilterStatus } from '../modules/generated/api';
import { bidStatusTypesExpired } from '../modules/labels';
import UserRole from '../types/UserRoles';
import FilterAutocomplete from './FilterAutocomplete';

const BidStateFilterExpired = () => {
  const { hasRole } = useRole();
  const { t } = useTranslation();

  const getOptions = () => {
    if (hasRole(UserRole.ADMIN)) return bidStatusTypesExpired;
    return bidStatusTypesExpired.filter(
      (type) => type.value !== BidFilterStatus.AnybodyBidden && type.value !== BidFilterStatus.NobodyBidden,
    );
  };

  return (
    <FilterAutocomplete
      disableClearable
      label={`${t('bids.bidStatus')} ${t('common.expired')}`}
      name="expiredBidState"
      options={getOptions()}
    />
  );
};

export default memo(BidStateFilterExpired);
