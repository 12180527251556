import { isNil } from 'lodash';
import { IndividualizedCarDTO } from 'src/modules/generated/api';

type Activity = {
  id: string;
  type: ActivityType;
  userName: string;
  createdAt?: string;
  value: unknown;
};

export enum ActivityType {
  comment = 'comment',
  rating = 'rating',
}

const getActivityValue = (individualization: IndividualizedCarDTO, type: ActivityType) => {
  switch (type) {
    case ActivityType.comment:
      return individualization.userComment;
    case ActivityType.rating:
      return individualization.rating;
    default:
      return null;
  }
};

const getActivity = (individualization: IndividualizedCarDTO, type: ActivityType): Activity | null => {
  const value = getActivityValue(individualization, type);

  if (isNil(value)) {
    return null;
  }

  return {
    id: `${individualization.carId}_${individualization.user?.id}_${type}`, // TODO: create unique activity ID @BE
    type,
    userName: individualization.user?.name ?? 'Anonymous',
    createdAt: individualization.created,
    value,
  };
};

const isActivity = (a: Activity | null): a is Activity => !isNil(a);

// Unfortunately all activities are grouped per user as `individualization`. In order
// to simplify the render process, we chunk `individualizations` into fake `activities`
export const getActivities = (individualizations: IndividualizedCarDTO[]) =>
  individualizations.reduce<Activity[]>((result, individualization) => {
    const activities = [
      getActivity(individualization, ActivityType.comment),
      getActivity(individualization, ActivityType.rating),
    ];

    return [...result, ...activities.filter(isActivity)];
  }, []);
