import { isUndefined } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ApiService from '../modules/api-service';
import { PlatformCredentialsMetadataDTO, SourceType, UsernameStatusBasic } from '../modules/generated/api';
import { addItemAtIndex, removeItemAtIndex, replaceItemAtIndex } from '../modules/util';

export type DealerCredentialsWithStatus = {
  id: string;
  username: string;
  metadata: PlatformCredentialsMetadataDTO;
  status: UsernameStatusBasic;
  validated?: boolean;
  kvps: string[];
  source: SourceType;
};

// Util for creating credential ids in order to simplify CRUD operations
export const getCredId = (cred: { username: string; source: string }) => `${cred.username}-${cred.source}`;

const createCredTemplate = (
  username: string,
  source = SourceType.Vwfs,
  metadata: PlatformCredentialsMetadataDTO = {
    displayName: '',
    postalCode: '',
    automaticBiddingTimeExtension: { rules: [], enabled: false },
  },
  status: UsernameStatusBasic = UsernameStatusBasic.Pending,
  validated = false,
  kvps: string[] = [],
): DealerCredentialsWithStatus => ({
  id: getCredId({ username, source }),
  username,
  metadata,
  status,
  validated,
  kvps,
  source,
});

const getCredList = () =>
  ApiService.dealer
    .dealerControllerGetPlatformCredentials()
    .then((res) =>
      res.data
        .filter((cred) => !isUndefined(cred.status) && !isUndefined(cred.username))
        .map(({ username, status, kvps, metadata, source }) =>
          createCredTemplate(username, source as unknown as SourceType, metadata, status, true, kvps),
        ),
    );

export const useDealerCreds = () => {
  const { t } = useTranslation();
  const [credList, setCredList] = useState<DealerCredentialsWithStatus[]>([]);

  const update = async (id: string, username: string, password: string, metadata: PlatformCredentialsMetadataDTO) => {
    const target = credList.find((cred) => cred.id === id);

    if (!target) {
      return;
    }

    const index = credList.indexOf(target);

    setCredList((prev) =>
      replaceItemAtIndex(prev, index, {
        ...target,
        metadata,
        validated: false,
        status: UsernameStatusBasic.Pending,
      }),
    );

    try {
      await ApiService.dealer.dealerControllerUpdatePlatformCredentials({
        username,
        password,
        metadata,
        source: target.source,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      throw Error(t('dealerTable.errors.edit', { username }));
    } finally {
      setCredList((prev) =>
        replaceItemAtIndex(prev, index, { ...target, metadata, validated: true, status: UsernameStatusBasic.Pending }),
      );
    }
  };

  const add = async (
    username: string,
    password: string,
    metadata: PlatformCredentialsMetadataDTO,
    source: SourceType,
    // disabled due to promise chaining
    // eslint-disable-next-line consistent-return
  ) => {
    const newCred = createCredTemplate(username, source, metadata);
    const isUnique = credList.every((cred) => cred.id !== newCred.id);

    if (!isUnique) {
      return update(getCredId({ username, source }), username, password, metadata);
    }

    setCredList((prev) => [...prev, newCred]);

    try {
      await ApiService.dealer.dealerControllerCreatePlatformCredentials({ username, password, metadata, source });
      setCredList((prev) => replaceItemAtIndex(prev, prev.indexOf(newCred), { ...newCred, validated: true }));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      setCredList((prev) => removeItemAtIndex(prev, prev.indexOf(newCred)));
      throw Error(t('dealerTable.errors.create', { username }));
    }
  };

  const remove = async (id: string) => {
    const target = credList.find((cred) => cred.id === id);

    if (!target) {
      return undefined;
    }

    const index = credList.indexOf(target);
    setCredList(removeItemAtIndex(credList, index));

    try {
      await ApiService.dealer.dealerControllerDeletePlatformCredentials(target.source, { username: target.username });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      setCredList((prev) => addItemAtIndex(prev, index, target));
      throw Error(t('dealerTable.errors.delete', { username: target.username }));
    }

    return target;
  };

  useEffect(() => {
    getCredList().then((creds) => setCredList(creds));
  }, []);

  return {
    credentialList: credList,
    add,
    remove,
    update,
  };
};
