import { Container, Drawer as MuiDrawer, styled, withStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

export const DrawerWrapper = styled('div')(({ theme }) => ({
  overflowY: 'scroll',
  gridRow: 'span 2',
  background: '#fff',
  boxShadow: theme.shadows[5],
  zIndex: 2,
}));

export const Drawer = withStyles({
  paper: {
    width: 300,
  },
})(MuiDrawer);

export const DrawerStatic = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minHeight: 'fill-available',
});

export const DrawerContent = styled(Container)(({ theme }) => ({
  flexGrow: 1,
  paddingBlock: theme.spacing(2),
  background: grey[50],
}));

export const DrawerHeader = styled(Container)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  left: 0,
  paddingBlock: theme.spacing(2),
  borderBottom: `1px solid ${grey[200]}`,
  background: '#fff',
  zIndex: 2,
}));

export const DrawerFooter = styled(Container)(({ theme }) => ({
  position: 'sticky',
  left: 0,
  bottom: 0,
  paddingBlock: theme.spacing(2),
  borderTop: `1px solid ${grey[200]}`,
  background: '#fff',
  zIndex: 2,
}));
