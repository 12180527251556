import DeliveryAddressForm from '@components/DeliveryAddressForm';
import { IconButton, makeStyles, Tooltip, Typography } from '@material-ui/core';
import { Cancel, Edit } from '@material-ui/icons';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useBidDeliveryAddress } from '../hooks/useDeliveryAddress';
import { dateInFuture, formatDate } from '../modules/date-helpers';
import { BiddingDeliveryAddressSelectionDTO, DeliveryAddressDTO, IndividualBidDTO } from '../modules/generated/api';
import { setToArray } from '../modules/util';

type DeliveryAdressProps = {
  bidId: string;
  deliveryAddresses?: DeliveryAddressDTO[];
  changeDeliveryAddressUntil?: string;
  deliveryAddress?: DeliveryAddressDTO;
  deliveryAddressSelection?: BiddingDeliveryAddressSelectionDTO;
  winningBid?: IndividualBidDTO;
};

const useStyles = makeStyles((theme) => ({
  displayAdress: {
    '& p': {
      margin: theme.spacing(0.5),
    },
  },
  heading: {
    display: 'flex',
    alignItems: 'center',
    '& button': {
      padding: '10px',
    },
  },
  tiles: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(2),
  },
  editDeliveryAddress: {
    marginTop: theme.spacing(3),
  },
}));

const BoughtCarDeliveryAddress = ({
  bidId,
  changeDeliveryAddressUntil,
  deliveryAddressSelection,
  winningBid,
  deliveryAddress,
}: DeliveryAdressProps) => {
  const { mutate: postDeliveryAddress, isLoading } = useBidDeliveryAddress(bidId);

  const classes = useStyles();
  const [edit, setEdit] = useState(false);
  const { t } = useTranslation();
  const methods = useForm<{ deliveryAddressId: string | undefined; transportOptionId: string | undefined }>({
    defaultValues: { deliveryAddressId: deliveryAddress?.id, transportOptionId: undefined },
  });
  const { reset, getValues } = methods;
  const editable = dateInFuture(changeDeliveryAddressUntil || '');
  const allowedDeliveryAddressIds =
    (winningBid?.kvps && deliveryAddressSelection?.selectedAddressByKVPS?.[winningBid.kvps]) || [];
  const deliveryAddresses =
    setToArray(deliveryAddressSelection?.deliveryAddresses).filter(
      (address) => address.id && allowedDeliveryAddressIds?.includes(address.id),
    ) || [];

  const handleCancel = () => {
    reset();
    setEdit(false);
  };

  const handleSubmit = () => {
    const deliveryAddressId = getValues('deliveryAddressId');
    if (deliveryAddressId)
      postDeliveryAddress(deliveryAddressId, {
        onSuccess: () => {
          setEdit(false);
        },
      });
  };

  return (
    <div className={classes.tiles}>
      <div className={classes.heading}>
        <Typography variant="h2">{t('navigation.delivery')} </Typography>
        {editable && !edit && (
          <IconButton onClick={() => setEdit(true)}>
            <Tooltip
              placement="right"
              title={t('bids.changeDeliveryAdress', { date: formatDate(changeDeliveryAddressUntil!) })}
            >
              <Edit />
            </Tooltip>
          </IconButton>
        )}
        {editable && edit && (
          <IconButton onClick={handleCancel}>
            <Cancel />
          </IconButton>
        )}
      </div>

      {edit && (
        <DeliveryAddressForm
          deliveryAddresses={deliveryAddresses}
          methods={methods}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          isLoading={isLoading}
          showLink
          onlyDelivery
          bidId={bidId}
        />
      )}
      {!edit && !!deliveryAddress && (
        <div className={classes.displayAdress}>
          <p>{deliveryAddress.companyName}</p>
          <p>{deliveryAddress.street}</p>
          <p>
            {deliveryAddress.zip} {deliveryAddress.city}
          </p>
          <p>{deliveryAddress.country}</p>
          <br />
          <p>
            <strong>{t('configuration.contactPerson')}: </strong>
            {deliveryAddress.contactPersonName}
          </p>
          <p>
            <strong>{t('contact.phone')}: </strong>
            {deliveryAddress.phoneNumber}
          </p>
          <p>
            <strong>{t('contact.E-Mail')}: </strong>
            {deliveryAddress.email}
          </p>
        </div>
      )}
    </div>
  );
};

export default BoughtCarDeliveryAddress;
