import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { buyableStatus } from '../modules/labels';
import FilterAutocomplete from './FilterAutocomplete';

const BuyableStatusSelect = () => {
  const { t } = useTranslation();

  return (
    <FilterAutocomplete
      disableClearable
      label={t('filterSearch.buyableStatus')}
      name="buyableStatus"
      options={buyableStatus}
    />
  );
};

export default memo(BuyableStatusSelect);
