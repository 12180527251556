import { useEffect, useMemo } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import useRole from 'src/hooks/useRole';
import { useValuationSettings } from 'src/hooks/useValuationSettings';
import { ValuationCountryCode } from 'src/modules/generated/api';
import UserRole from 'src/types/UserRoles';
import { TableNext } from './TableNext';
import { columns, relevantTaxes } from './columns';
import { bidColumns } from './columns/bid-columns';
import { useBidsFilter } from './filter/useBidsFilter';
import {
  bidsOrderedTablePageIndexState,
  bidsOrderedTablePaginationState,
  bidsPastTablePageIndexState,
  bidsPastTablePaginationState,
  bidsPendingTablePageIndexState,
  bidsPendingTablePaginationState,
  bidsSortingState,
  carsTablePageIndexState,
  carsTablePaginationState,
  sortingState,
} from './state';
import { BidTypes, BidsTableNextItem, CarsTableNextItem, StrictColumnDef, TableType } from './types';
import { useBids } from './useBids';

type BidsTableNextProps = {
  bidType: BidTypes;
};

export const BidsTableNext = ({ bidType }: BidsTableNextProps): JSX.Element => {
  const { hasRole } = useRole();
  const { valuationCountry } = useValuationSettings();
  const availableColumns: StrictColumnDef<CarsTableNextItem>[] = useMemo(
    () =>
      columns(false).filter(
        (column: StrictColumnDef<CarsTableNextItem>) =>
          column.meta?.roles === undefined ||
          hasRole(UserRole.ADMIN) ||
          column.meta.roles.every(
            (role) => hasRole(role as UserRole) || relevantTaxes[valuationCountry!]?.includes(role),
          ),
      ),
    [hasRole, valuationCountry],
  );
  // if the users valuationCountry is De then dont display the columns "marketPriceDE" and "referenceVehiclesDE"
  const filteredColumns = useMemo(
    () =>
      availableColumns.filter(
        (column) =>
          valuationCountry !== ValuationCountryCode.De ||
          (column.id !== 'marketPriceDE' && column.id !== 'referenceVehiclesDE'),
      ),
    [availableColumns, valuationCountry],
  );
  const availableBidColumns: StrictColumnDef<BidsTableNextItem>[] = useMemo(
    () =>
      bidColumns
        .filter(
          (column: StrictColumnDef<BidsTableNextItem>) =>
            column.meta?.roles === undefined ||
            hasRole(UserRole.ADMIN) ||
            column.meta.roles.every(
              (role) => hasRole(role as UserRole) || relevantTaxes[valuationCountry!]?.includes(role),
            ),
        )
        .filter((column) => column.meta?.onlyForTable === undefined || column.meta.onlyForTable === bidType),
    [bidType, hasRole, valuationCountry],
  );
  const filteredColumnsWithBids = [...filteredColumns.slice(0, 4), ...availableBidColumns, ...filteredColumns.slice(4)];

  function getPaginationState() {
    switch (bidType) {
      case BidTypes.Pending:
        return bidsPendingTablePaginationState;
      case BidTypes.Past:
        return bidsPastTablePaginationState;
      case BidTypes.Orders:
        return bidsOrderedTablePaginationState;
      default:
        return carsTablePaginationState;
    }
  }

  function getIndex() {
    switch (bidType) {
      case BidTypes.Pending:
        return bidsPendingTablePageIndexState;
      case BidTypes.Past:
        return bidsPastTablePageIndexState;
      case BidTypes.Orders:
        return bidsOrderedTablePageIndexState;
      default:
        return carsTablePageIndexState;
    }
  }

  function getSortingState() {
    switch (bidType) {
      case BidTypes.Pending:
        return bidsSortingState;
      case BidTypes.Past:
        return bidsSortingState;
      case BidTypes.Orders:
        return bidsSortingState;
      default:
        return sortingState;
    }
  }

  const { reset: resetFilter, watch } = useBidsFilter();
  const filterState = watch();
  const [pagination, setPagination] = useRecoilState(getPaginationState());
  const [sorting] = useRecoilState(getSortingState());
  const resetPageIndexSate = useResetRecoilState(getIndex());

  useEffect(() => {
    const subscription = watch(() => {
      resetPageIndexSate();
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [watch, resetPageIndexSate]);

  const {
    data: carsList,
    isLoading,
    refetch,
    isPreviousData,
  } = useBids({
    filter: filterState,
    pagination,
    sorting,
  });

  // transform the data from the api into cars to display in the table
  const carsListData = carsList?.data?.reduce((acc: BidsTableNextItem[], curr) => {
    const cars = curr.cars!.map((car) => ({
      ...car,
      ...curr,
    }));
    return [...acc, ...cars];
  }, []);

  return (
    <TableNext
      tableColumns={filteredColumnsWithBids}
      totalRecords={carsList?.recordsTotal}
      data={carsListData}
      isLoading={isLoading}
      refetch={refetch}
      isPreviousData={isPreviousData}
      resetFilter={resetFilter}
      tableType={TableType.Bids}
      bidType={bidType}
      pagination={pagination}
      setPagination={setPagination}
      numbBids={carsList?.recordsTotal}
      numbCars={carsList?.carsRecordsTotal}
    />
  );
};
