import { makeStyles, styled, Tooltip } from '@material-ui/core';
import { SvgIconComponent } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  backgroundBlackAndWhite: {
    filter: 'grayscale(100%)',
  },
}));

const LeadImg = styled('img')({
  objectFit: 'cover',
  background: 'white',
  aspectRatio: '4/3',
  borderRadius: 4,
  transform: 'translateZ(0)',
  clipPath: 'inset(0% 0% 0% 0% round 4px)',
});

const Overlay = styled('div')({
  zIndex: 2,
  height: '100%',
  width: '100%',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

type ImageOverlayProps = {
  src: string;
  height: number;
  disableOverlay?: boolean;
  tooltipText?: string;
  icon: SvgIconComponent;
  imageClassName?: string;
  iconClassName?: string;
};

const ImageOverlay = ({
  src,
  height,
  tooltipText = '',
  icon: Icon,
  disableOverlay,
  imageClassName,
}: ImageOverlayProps) => {
  const classes = useStyles({ src, height });
  return (
    <>
      {!disableOverlay && (
        <Overlay>
          <Tooltip title={tooltipText}>
            {/* Tooltip child needs to hold ref */}
            <div>
              <Icon />
            </div>
          </Tooltip>
        </Overlay>
      )}
      <LeadImg
        src={src}
        alt=""
        height={height}
        loading="eager"
        className={(!disableOverlay && (imageClassName || classes.backgroundBlackAndWhite)) || undefined}
      />
    </>
  );
};

export default ImageOverlay;
