import PageContainer from '@components/PageContainer';
import { AddressCard, NewAddressButton } from '@components/ui/address';
import { Address } from '@components/ui/address/types';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import {
  useCreateDeliveryAddress,
  useDeleteDeliveryAddress,
  useGetDeliveryAddresses,
  useSetDefaultAddress,
} from 'src/hooks/useDeliveryAddress';
import { useWizard } from 'src/hooks/useWizard';
import { CountryCode, DeliveryAddressType, WizardStep } from 'src/modules/generated/api';

const useStyles = makeStyles((theme) => ({
  header: {
    maxWidth: theme.breakpoints.values.sm,
    marginBlockEnd: theme.spacing(4.5),
  },
  headline: {
    marginBlockEnd: theme.spacing(2.5),
  },
  addressCard: {
    display: 'grid', // stretch button to match address cards
  },
}));

type DeliveryAddressContainerProps = {
  showInWizard?: boolean;
};

const DeliveryAddressContainer = ({ showInWizard }: DeliveryAddressContainerProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { showWizardBadge, useTrackWizardConfirmationOnPageView } = useWizard();
  const { data: deliveryAddresses = [] } = useGetDeliveryAddresses();
  const { mutate: createDeliveryAddress } = useCreateDeliveryAddress();
  const { mutate: deleteDeliveryAddress } = useDeleteDeliveryAddress();
  const { mutate: setDefaultAddress } = useSetDefaultAddress();

  const handleEdit = (id: string | undefined, updatedAddress: Address) => {
    if (!id) return; // otherwise we create a new one
    createDeliveryAddress({
      ...updatedAddress,
      id,
      contactPersonName: updatedAddress.name,
      countryCode: updatedAddress.countryCode as CountryCode,
    });
  };

  useTrackWizardConfirmationOnPageView(WizardStep.DealerAddress, showInWizard);

  return (
    <PageContainer>
      {showWizardBadge(WizardStep.DealerAddress) && (
        <Alert severity="info" elevation={3}>
          {t('bids.mustVerifyDeliveryAddress')}
        </Alert>
      )}
      <div className={classes.header}>
        <Typography variant="h1" className={classes.headline}>
          {t('navigation.delivery')}
        </Typography>
      </div>
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} className={classes.addressCard}>
            <NewAddressButton
              onSubmit={(newAddress) =>
                createDeliveryAddress({
                  contactPersonName: newAddress.name,
                  ...newAddress,
                  countryCode: newAddress.countryCode as CountryCode,
                })
              }
            />
          </Grid>
          {deliveryAddresses.map((deliveryAddress) => {
            const isPlatformStandard = deliveryAddress.type === DeliveryAddressType.PlatformStandard;

            return (
              <Grid item xs={12} md={4} className={classes.addressCard} key={deliveryAddress.id}>
                <AddressCard
                  title={t('action.action_noun', {
                    action: t('action.edit'),
                    noun: t('contact.address'),
                  })}
                  name={
                    isPlatformStandard ? t('addressCard.platformStandard.title') : deliveryAddress.contactPersonName
                  }
                  companyName={deliveryAddress.companyName}
                  city={deliveryAddress.city}
                  street={deliveryAddress.street}
                  zip={deliveryAddress.zip}
                  country={deliveryAddress.country}
                  countryCode={deliveryAddress.countryCode}
                  email={deliveryAddress.email}
                  phoneNumber={deliveryAddress.phoneNumber}
                  faxNumber={deliveryAddress.faxNumber}
                  isGlobal={deliveryAddress.global}
                  onEdit={isPlatformStandard ? undefined : (address) => handleEdit(deliveryAddress.id, address)}
                  onDelete={isPlatformStandard ? undefined : () => deleteDeliveryAddress(deliveryAddress.id as string)}
                  onSetDefault={
                    deliveryAddress.id !== undefined ? () => setDefaultAddress(deliveryAddress.id as string) : undefined
                  }
                  content={
                    isPlatformStandard ? (
                      <Alert severity="info">
                        <Typography variant="body2">{t('addressCard.platformStandard.hint')}</Typography>
                      </Alert>
                    ) : null
                  }
                />
              </Grid>
            );
          })}
        </Grid>
      </div>
    </PageContainer>
  );
};

export default DeliveryAddressContainer;
