import { nanoid } from 'nanoid';
import { DEFAULT_CURRENCY, MonetaryAmountStrict } from 'src/modules/currency';
import { PacketDTO, PositionDTO } from 'src/modules/generated/api';
import { Position, ServicePacket } from './types';

/**
 * Create strict version of a position with fallback default values.
 */
export const createStrictPosition = (position: PositionDTO): Position => ({
  name: position.name ?? '',
  price: position.price ?? { amount: 0, currency: DEFAULT_CURRENCY.code },
  description: position.description ?? '',
});

/**
 * Create strict version of a packet with fallback default values.
 */
export const createStrictPacket = (packet: PacketDTO): ServicePacket => ({
  id: packet.packetId,
  clientId: packet.clientId ?? nanoid(),
  name: packet.name,
  country: packet.country,
  description: packet.description,
  positions: packet.positions?.map((position) => createStrictPosition(position)) ?? [],
  source: packet.source,
});

// IMPROVE: handle currency miss match (currently EUR only)
export const getPositionsTotal = (positions: Position[]) =>
  positions
    .map((position) => position.price)
    .filter((price): price is MonetaryAmountStrict => price.amount !== undefined && price.currency !== undefined)
    .reduce((acc, position) => acc + position.amount, 0);

/**
 * Patch loose typing from MonetaryAmount as it currently breaks the BE
 */
export const patchPacket = (packet: ServicePacket): ServicePacket => ({
  ...packet,
  positions: packet.positions.map((position) => ({
    ...position,
    price: {
      ...position.price,
      amount: position.price.amount ?? 0,
    },
  })),
});
