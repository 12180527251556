import React, { useEffect, useMemo, useRef, useState } from 'react';
import algoliasearch from 'algoliasearch/lite';
import { AutocompleteOptions, AutocompleteState, createAutocomplete } from '@algolia/autocomplete-core';
import { getAlgoliaResults } from '@algolia/autocomplete-preset-algolia';
import { Card, CardContent, TextFieldProps } from '@material-ui/core';
import type { SearchOptions } from '@algolia/client-search';
import { useOverlayPosition } from 'react-aria';
import { ScreenState } from '@components/faq-search/ScreenState';
import { useTouchEvents } from '@components/faq-search/useTouchEvents';
import { navigate } from '@reach/router';
import { AlgoliaClientKey, AlgoliaProjectId } from 'src/constants';
import { SearchBar } from './SearchBar';
import { InternalDocSearchHit } from './types';
import { navigator as defaultNavigator } from './lib';

export type FaqSearchProps = {
  initialQuery?: string;
  placeholder?: string;
  navigator?: AutocompleteOptions<InternalDocSearchHit>['navigator'];
  textFieldProps?: Partial<TextFieldProps>;
  indexName: string;
  searchParameters?: SearchOptions;
  hitComponent?: (props: { hit: InternalDocSearchHit; children: React.ReactNode }) => JSX.Element;
  resultsFooterComponent?: (props: { state: AutocompleteState<InternalDocSearchHit> }) => JSX.Element | null;
  getMissingResultsUrl?: (props: { query: string }) => string;
};

const searchClient = algoliasearch(AlgoliaProjectId, AlgoliaClientKey);

const getItemUrl = (item: InternalDocSearchHit) => `/help/${item.categories[0].slug.current}/${item.slug.current}`;

export const FaqSearch = (props: FaqSearchProps) => {
  const {
    indexName,
    initialQuery: initialQueryFromProp = '',
    placeholder = 'Search docs',
    navigator,
    textFieldProps,
    hitComponent,
    resultsFooterComponent,
    getMissingResultsUrl,
  } = props;
  const [autocompleteState, setAutocompleteState] = useState<AutocompleteState<InternalDocSearchHit>>({
    query: '',
    collections: [],
    completion: null,
    context: {},
    isOpen: false,
    activeItemId: null,
    status: 'idle',
  });
  const inputRef = useRef<HTMLInputElement | null>(null);
  const initialQuery = useRef(initialQueryFromProp).current;
  const searchBarRef = useRef<HTMLDivElement | null>(null);
  const formRef = useRef(null);

  const overlayRef = useRef<HTMLDivElement | null>(null);
  const { overlayProps: positionProps } = useOverlayPosition({
    targetRef: inputRef,
    overlayRef,
    placement: 'bottom',
    offset: 6,
    isOpen: true,
    containerPadding: 6,
  });

  const autocomplete = useMemo(
    () =>
      createAutocomplete<InternalDocSearchHit, React.FormEvent<HTMLFormElement>, React.MouseEvent, React.KeyboardEvent>(
        {
          id: 'faqsearch',
          defaultActiveItemId: 0,
          placeholder,
          initialState: {
            query: initialQuery,
            context: {
              searchSuggestions: [],
            },
          },
          navigator: navigator ?? defaultNavigator,
          onStateChange({ state: nextState }) {
            setAutocompleteState(nextState);
          },
          getSources: () => [
            {
              sourceId: 'faqs',
              getItemInputValue: ({ item }) => item.query,
              getItems: ({ query }) =>
                getAlgoliaResults({
                  searchClient,
                  queries: [
                    {
                      indexName,
                      query,
                      params: {
                        hitsPerPage: 4,
                        highlightPreTag: '<mark>',
                        highlightPostTag: '</mark>',
                      },
                    },
                  ],
                }),
              getItemUrl: ({ item }) => getItemUrl(item),
            },
          ],
        },
      ),
    [indexName, initialQuery, navigator, placeholder],
  );

  const { getRootProps, refresh, getEnvironmentProps } = autocomplete;

  useTouchEvents({
    getEnvironmentProps,
    panelElement: overlayRef.current,
    formElement: formRef.current,
    inputElement: inputRef.current,
  });

  // We don't focus the input when there's an initial query (i.e. Selection
  // Search) because users rather want to see the results directly, without the
  // keyboard appearing.
  // We therefore need to refresh the autocomplete instance to load all the
  // results, which is usually triggered on focus.
  useEffect(() => {
    if (initialQuery.length > 0) {
      refresh();

      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  }, [initialQuery, refresh]);

  return (
    <div {...getRootProps({})}>
      <SearchBar
        {...autocomplete}
        state={autocompleteState}
        autoFocus={false}
        inputRef={inputRef}
        formRef={formRef}
        textFieldProps={{
          ...textFieldProps,
          InputProps: {
            ref: searchBarRef,
          },
        }}
      />
      {autocompleteState.isOpen && (
        <Card ref={overlayRef} {...positionProps}>
          <CardContent style={{ width: 500 }}>
            <ScreenState
              {...autocomplete}
              indexName={indexName}
              state={autocompleteState}
              hitComponent={hitComponent}
              resultsFooterComponent={resultsFooterComponent}
              inputRef={inputRef}
              getMissingResultsUrl={getMissingResultsUrl}
              onItemClick={(item) => {
                autocomplete.setIsOpen(false);
                navigate(getItemUrl(item));
              }}
            />
          </CardContent>
        </Card>
      )}
    </div>
  );
};
