import { useMemo, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Grid, IconButton, makeStyles } from '@material-ui/core';
import { NavigateBefore, NavigateNext } from '@material-ui/icons';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
type TermsAndConditionsPdfProps = {
  url: string;
  accessToken?: string;
};

const useStyles = makeStyles(() => ({
  controlBar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& button': {
      padding: 0,
    },
  },
}));

const TermsAndConditionsPdf = ({ url, accessToken }: TermsAndConditionsPdfProps) => {
  const classes = useStyles();
  const [pageCount, setPageCount] = useState<null | number>(null);
  const [pageNumber, setPageNumber] = useState(1);

  // Don't ask me why I need this: https://github.com/wojtekmaj/react-pdf/issues/353
  const file = useMemo(
    () => ({ url, httpHeaders: accessToken ? { authorization: `Bearer ${accessToken}` } : {} }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setPageCount(numPages);
    setPageNumber(1);
  }

  function changePage(offset: number) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-2);
  }

  function nextPage() {
    changePage(2);
  }

  return (
    <Grid container alignItems="center" justifyContent="center" direction="column">
      {pageCount && (
        <Grid item className={classes.controlBar}>
          <IconButton disabled={pageNumber <= 1} onClick={previousPage}>
            <NavigateBefore />
          </IconButton>
          <span>
            {pageNumber}
            {pageCount && pageNumber < pageCount && `-${pageNumber + 1}`} / {pageCount}
          </span>

          <IconButton disabled={pageNumber >= pageCount - 1} onClick={nextPage}>
            <NavigateNext />
          </IconButton>
        </Grid>
      )}
      <Grid>
        <Document
          file={file}
          onLoadSuccess={onDocumentLoadSuccess}
          error={
            <span>
              Loading PDF failed:{' '}
              <a href={url} target="_blank" rel="noreferrer">
                Click here
              </a>
            </span>
          }
        >
          <Grid container wrap="nowrap">
            <Grid item>
              <Page pageNumber={pageNumber} />
            </Grid>
            <Grid item>
              {pageCount && pageNumber + 1 <= pageCount && <Page pageNumber={pageNumber + 1} noData="" />}
            </Grid>
          </Grid>
        </Document>
      </Grid>
    </Grid>
  );
};

export default TermsAndConditionsPdf;
