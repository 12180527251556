import { makeStyles, Table, TableBody, TableCell, TableRow, Tooltip } from '@material-ui/core';
import { CallMade as CallMadeIcon, Check as CheckIcon } from '@material-ui/icons';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import useRole from '../hooks/useRole';
import { BID_DATE_TIME_FORMAT, formatDate } from '../modules/date-helpers';
import { BidSuccessStatus, IndividualBidDTO } from '../modules/generated/api';
import UserRole from '../types/UserRoles';
import UnitValue from './UnitValue';

const useStyles = makeStyles((theme) => ({
  bidsTableHeadCell: {
    fontWeight: 'bold',
    borderBottom: `1px solid ${theme.palette.secondary.main} !important`,
  },
  bidsTableCell: {
    paddingLeft: theme.spacing(0.5),
    paddingTop: theme.spacing(0.25),
    paddingRight: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.25),
    borderBottom: 'none',
  },
  emphasizedBid: {
    color: theme.palette.success.main,
    padding: 0,
    fontSize: '1rem',
    fontWeight: 'bold',
  },
  submittedBidsRow: {
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
      borderCollapse: 'collapse',
    },
  },
  submittedBidsTooltip: {
    backgroundColor: theme.palette.primary.light,
  },
  tableBorder: {
    borderRight: `2px solid ${theme.palette.secondary.main}`,
  },
  overflow: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '150px',
  },
}));

type IndividualBidTableProps = {
  bids?: IndividualBidDTO[];
};

const IndividualBidTable = ({ bids = [] }: IndividualBidTableProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { hasRole } = useRole();

  const showDealer = hasRole(UserRole.ADMIN) || hasRole(UserRole.COUNTRYMANAGER);
  const moreThanThreeBids = bids.length > 3;

  const winningBid = bids.find(
    (bid) => bid.status === BidSuccessStatus.Highest || bid.status === BidSuccessStatus.Bought,
  );

  // Sort the remaining bids by value
  const sortedBids = bids.filter((bid) => bid !== winningBid).sort((a, b) => (b.bid?.value || 0) - (a.bid?.value || 0));

  return (
    <Table size="small" className={clsx(moreThanThreeBids && classes.tableBorder)}>
      <TableBody>
        {winningBid && (
          <Tooltip title={winningBid.created ? formatDate(winningBid.created, BID_DATE_TIME_FORMAT) : ''}>
            <TableRow
              key={`${winningBid.user?.id}-${winningBid.created}-${winningBid.bid?.value}`}
              className={classes.submittedBidsRow}
            >
              <TableCell align="right" className={classes.bidsTableCell} width="60px">
                <UnitValue value={winningBid.bid?.value} unit={winningBid.bid?.unit} />
              </TableCell>
              <TableCell className={clsx(classes.bidsTableCell, classes.overflow)} width="150px">
                {winningBid.user?.name}
              </TableCell>
              {showDealer && (
                <TableCell className={classes.bidsTableCell} width="100px">
                  {winningBid?.dealer?.id}
                </TableCell>
              )}
              <TableCell className={classes.bidsTableCell}>
                {winningBid.status === BidSuccessStatus.Highest && (
                  <Tooltip title={t('bids.status.highest') || ''}>
                    <CallMadeIcon className={classes.emphasizedBid} fontSize="small" />
                  </Tooltip>
                )}
                {winningBid.status === BidSuccessStatus.Bought && (
                  <Tooltip title={t('bids.status.bought') || ''}>
                    <CheckIcon className={classes.emphasizedBid} fontSize="small" />
                  </Tooltip>
                )}
              </TableCell>
            </TableRow>
          </Tooltip>
        )}
        {sortedBids
          ? sortedBids.map((bidSubmitted, index) => (
              <Tooltip
                placement="left"
                classes={{ tooltip: classes.submittedBidsTooltip }}
                /* eslint-disable-next-line react/no-array-index-key */
                key={index}
                PopperProps={{
                  popperOptions: {
                    keepMounted: true,
                    modifiers: {
                      flip: { enabled: false },
                      offset: {
                        enabled: true,
                        offset: '0px, -14px, 0px ',
                      },
                    },
                  },
                }}
                title={bidSubmitted.created ? formatDate(bidSubmitted.created, BID_DATE_TIME_FORMAT) : ''}
              >
                <TableRow
                  key={`${bidSubmitted.user?.id}-${bidSubmitted.created}-${bidSubmitted.bid?.value}`}
                  className={classes.submittedBidsRow}
                >
                  <TableCell align="right" className={classes.bidsTableCell} width="60px">
                    <UnitValue value={bidSubmitted.bid?.value} unit={bidSubmitted.bid?.unit} />
                  </TableCell>
                  <TableCell className={clsx(classes.bidsTableCell, classes.overflow)} width="150px">
                    {bidSubmitted.user?.name ?? ''}
                  </TableCell>
                  {showDealer && (
                    <TableCell className={classes.bidsTableCell} width="100px">
                      {bidSubmitted?.dealer?.id}
                    </TableCell>
                  )}
                </TableRow>
              </Tooltip>
            ))
          : null}
      </TableBody>
    </Table>
  );
};

export default IndividualBidTable;
