import { ReactElement, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { Grid, TextField, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { get, pickBy } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { getLabel, getRegionOptions } from '../modules/labels';
import { AddressFields } from './AddressFields';
import InfoTooltip from './InfoTooltip';

type DetailingAddressLocalizedFieldsProps = {
  source: string;
};

const useStyles = makeStyles((theme) => ({
  fields: {
    padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: 4,
  },
  autocomplete: {
    width: '100%',
    maxWidth: 300,
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}));

export const DetailingAddressLocalizedFields = ({ source }: DetailingAddressLocalizedFieldsProps): ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [activeCountry, setActiveCountry] = useState('default');
  const { getValues } = useFormContext();
  const regionValues = getRegionOptions().map(({ value }) => value);

  const getDiff = (option: string) => {
    const ignoredKeys = ['lastChanged', 'country'];
    const rootValues = getValues('default');
    const sourceRootValues = getValues(`${source}.default`);
    const values = pickBy(getValues(`${source}.${option}`) || {}, (v) => !!v);

    const isIgnored = (f: string) => ignoredKeys.includes(f);

    return Object.entries(values).reduce((diff, [field, val]) => {
      const compareVals = option === 'default' ? rootValues : sourceRootValues;

      if (!isIgnored(field) && get(compareVals, field) !== val) {
        return diff + 1;
      }
      return diff;
    }, 0);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div className={classes.controls}>
          <Autocomplete
            className={classes.autocomplete}
            onChange={(_, value) => {
              setActiveCountry(value);
            }}
            defaultValue="default"
            disableClearable
            options={regionValues}
            getOptionLabel={(option: string) => getLabel(getRegionOptions(), option)}
            noOptionsText={t('carsTable.noEntriesFound')}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={t('regions.region')}
                margin="dense"
                InputLabelProps={{ shrink: true }}
              />
            )}
            renderOption={(option) => (
              <div>
                <Typography variant="body1">{getLabel(getRegionOptions(), option)}</Typography>
                {getDiff(option) > 0 && (
                  <Typography variant="body2" color="textSecondary">
                    {t('common.numDiff', { count: getDiff(option) })}
                  </Typography>
                )}
              </div>
            )}
          />
          {activeCountry !== 'default' && (
            <InfoTooltip title={t('detailingAddressFields.hint', { country: activeCountry })} />
          )}
        </div>
      </Grid>

      <Grid item xs={12}>
        <AddressFields className={classes.fields} source={source} country={activeCountry} />
      </Grid>
    </Grid>
  );
};
