import jwtDecode from 'jwt-decode';
import { isNil } from 'lodash';
import { GwscoutJwtPayload, useAuthenticationStore } from '../stores/Authentication';
import { CountryCode, ValuationCountryCode } from './generated/api';

export const getAccountCountry = (): CountryCode => {
  const { state: authState } = useAuthenticationStore();
  const { accessToken } = authState;
  let country = authState.objectToken?.country as CountryCode;

  if (accessToken) {
    const token = jwtDecode<GwscoutJwtPayload>(accessToken);
    if (!isNil) country = token.country as CountryCode;
  }

  return country;
};

export const getCountryCodeFromValuationCountry = (
  valuationCountry: ValuationCountryCode | ValuationCountryCode | null,
): CountryCode => {
  if (isNil(valuationCountry)) return CountryCode.At;
  switch (valuationCountry) {
    case ValuationCountryCode.De:
      return CountryCode.De;
    case ValuationCountryCode.At:
    case ValuationCountryCode.AtLot:
      return CountryCode.At;
    case ValuationCountryCode.Fr:
      return CountryCode.Fr;
    case ValuationCountryCode.Es:
      return CountryCode.Es;
    case ValuationCountryCode.It:
      return CountryCode.It;
    case ValuationCountryCode.Cz:
      return CountryCode.Cz;
    case ValuationCountryCode.Pl:
      return CountryCode.Pl;
    case ValuationCountryCode.Ro:
      return CountryCode.Ro;
    case ValuationCountryCode.Hu:
      return CountryCode.Hu;
    case ValuationCountryCode.Si:
      return CountryCode.Si;
    case ValuationCountryCode.Sk:
      return CountryCode.Sk;
    case ValuationCountryCode.Hr:
      return CountryCode.Hr;
    case ValuationCountryCode.Pt:
      return CountryCode.Pt;
    case ValuationCountryCode.Se:
      return CountryCode.Se;
    case ValuationCountryCode.Bg:
      return CountryCode.Bg;
    case ValuationCountryCode.Mk:
      return CountryCode.Mk;
    case ValuationCountryCode.Al:
      return CountryCode.Al;
    case ValuationCountryCode.Xk:
      return CountryCode.Xk;
    default:
      return CountryCode.At;
  }
};
