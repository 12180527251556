import { forwardRef } from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const GavelClockIcon = forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => (
  <SvgIcon viewBox="0 0 28.621 23.953" ref={ref} {...props}>
    <defs />
    <path d="M 4.249 8.069 L 7.079 5.242 L 21.214 19.392 L 18.384 22.219 Z M 8.486 3.827 L 11.314 0.998 L 16.971 6.654 L 14.143 9.483 Z M -0.001 12.315 L 2.827 9.486 L 8.484 15.143 L 5.656 17.972 Z M 0 21 L 12 21 L 12 23 L 0 23 Z" />
    <path d="M 22.001 0.136 C 18.692 0.136 16.013 2.821 16.013 6.13 C 16.013 9.437 18.692 12.123 22.001 12.123 C 25.315 12.123 28 9.437 28 6.13 C 28 2.821 25.315 0.136 22.001 0.136 Z M 22.006 10.923 C 19.358 10.923 17.213 8.778 17.213 6.13 C 17.213 3.481 19.358 1.334 22.006 1.334 C 24.657 1.334 26.802 3.481 26.802 6.13 C 26.802 8.778 24.657 10.923 22.006 10.923 Z" />
    <path d="M 21.964 3.392 L 21.064 3.392 L 21.064 6.99 L 24.211 8.877 L 24.663 8.141 L 21.964 6.54 Z" />
  </SvgIcon>
));

export default GavelClockIcon;
