import {
  createContext,
  createElement,
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  useContext,
  useRef,
  useState,
} from 'react';
import { omit } from 'lodash';
import useDeepCompareEffect from 'use-deep-compare-effect';

type ExtendUsersnapCtx = {
  custom: ExtendUsersnapData;
  setCustom: Dispatch<SetStateAction<ExtendUsersnapData>>;
};

type ExtendUsersnapData = Record<string, unknown>;

type ExtendedUserSnapProviderProps = {
  children: ReactNode;
};

export const extendUsersnapCtx = createContext<ExtendUsersnapCtx>(undefined!);

export const ExtendedUserSnapProvider = ({ children }: ExtendedUserSnapProviderProps): ReactElement => {
  const [custom, setCustom] = useState<ExtendUsersnapData>({});

  return createElement(extendUsersnapCtx.Provider, { value: { setCustom, custom } }, children);
};

/**
 * Conditionally extend usersnap `custom` data prop
 */
export const useExtendUsersnap = (data: ExtendUsersnapData, deps: any[] = []): void => {
  const { setCustom } = useContext(extendUsersnapCtx);
  const dataRef = useRef<typeof data>();
  dataRef.current = data;

  useDeepCompareEffect(
    function extendUsersnap() {
      setCustom((prev) => ({ ...prev, ...dataRef.current }));

      return function cleanExtension() {
        setCustom((prev) => omit(prev, Object.keys(dataRef.current!)));
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setCustom, ...deps],
  );
};
