import { Breadcrumbs, Container, Divider, Link as MuiLink, makeStyles, Typography } from '@material-ui/core';
import { RouteComponentProps, useParams, Link, navigate } from '@reach/router';
import { Feedback, useFaq, useCategory, SupportCallout } from '@components/help-center';
import { useTranslation } from 'react-i18next';
import { formatDiffToNow } from 'src/modules/date-helpers';
import { PortableText } from '@portabletext/react';
import { Image, Video } from '@components/portable-text';

type PageParams = {
  category: string;
  answer: string;
};

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#fff',
  },
  headline: {
    fontWeight: 'bold',
  },
  breadcrumbs: {
    paddingBlock: theme.spacing(2),
  },
  container: {
    paddingBlock: theme.spacing(6),
  },
  header: {
    marginBlockEnd: theme.spacing(3),
  },
  feedback: {
    marginBlockEnd: theme.spacing(6),
  },
}));

export const AnswerPage = (_props: RouteComponentProps) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const { category: categorySlug, answer } = useParams<PageParams>();
  const { data: category } = useCategory({ slug: categorySlug });
  const { data: faq } = useFaq({ slug: answer });

  if (!category || !faq) {
    navigate('/help');
    return null;
  }

  return (
    <div className={classes.root}>
      <Container className={classes.breadcrumbs}>
        <Breadcrumbs>
          <MuiLink component={Link} color="inherit" to="/help">
            {t('navigation.help')}
          </MuiLink>
          <MuiLink component={Link} color="inherit" to={`/help/${categorySlug}`}>
            {category.title}
          </MuiLink>
          <Typography color="primary">{faq.title}</Typography>
        </Breadcrumbs>
      </Container>

      <Divider />

      <Container className={classes.container} maxWidth="md">
        {/* answer header */}
        <div className={classes.header}>
          <Typography variant="h4" className={classes.headline}>
            {faq.title}
          </Typography>
          <Typography color="textSecondary">
            {t('carsTableNext.cells.timestamp.past', {
              // eslint-disable-next-line no-underscore-dangle
              time: formatDiffToNow(faq._updatedAt, i18n.language),
            })}
          </Typography>
        </div>

        {faq.description && (
          <Typography component="div">
            <PortableText
              value={faq.description}
              components={{
                types: {
                  image: Image,
                  video: Video,
                },
              }}
            />
          </Typography>
        )}

        <Divider style={{ marginBlockStart: 40, marginBlockEnd: 24 }} />

        <Feedback
          className={classes.feedback}
          // eslint-disable-next-line no-underscore-dangle
          documentId={faq._id}
        />

        <SupportCallout />
      </Container>
    </div>
  );
};
