import { Button, makeStyles, Typography } from '@material-ui/core';
import { Link, RouteComponentProps } from '@reach/router';
import { useTranslation } from 'react-i18next';
import FullPageCenterContainer from '@components/FullPageCenterContainer';

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: theme.spacing(4),
  },
}));

const NotFoundPage = (_props: RouteComponentProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <FullPageCenterContainer>
      <Typography variant="h1" className={classes.heading}>
        {t('car.carNotFound')}
      </Typography>
      <Button variant="contained" color="primary" component={Link} to="/cars">
        {t('car.navigateToCars')}
      </Button>
    </FullPageCenterContainer>
  );
};

export default NotFoundPage;
