import { BidsTableNext } from '@components/cars-table-next/BidsTableNext';
import { BidsFilterProvider } from '@components/cars-table-next/filter/useBidsFilter';
import { BidTypes } from '@components/cars-table-next/types';
import { PageMeta } from '@components/page-meta';
import { RouteComponentProps } from '@reach/router';

const BidsPendingPage = (_props: RouteComponentProps) => (
  <>
    <PageMeta title="bids.pendingBids_plural" />
    <BidsFilterProvider bidsTableType="PendingBidsTable">
      <BidsTableNext bidType={BidTypes.Pending} />
    </BidsFilterProvider>
  </>
);

export default BidsPendingPage;
