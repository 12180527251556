import { Grid } from '@material-ui/core';
import { Fragment, useMemo } from 'react';
import useRole from 'src/hooks/useRole';
import { BidVisibility } from 'src/modules/generated/api';
import { isDefaultFilter } from 'src/modules/table-helpers';
import { BidTypes } from '../types';
import { getBidsFilter } from './filter';

type ActiveBidsFilterChipsProps = {
  defaultFilter: any;
  watch: any;
  setFilter: any;
  bidType?: BidTypes;
};

const ActiveBidsFilterChips = ({ defaultFilter, watch, setFilter, bidType }: ActiveBidsFilterChipsProps) => {
  const filterState = watch();
  const { hasRole } = useRole();
  const filterName = bidType === BidTypes.Orders ? 'boughtTimeFilter' : 'biddingTimeFilter';
  const bidsFilter = useMemo(() => [...getBidsFilter(filterName)], [filterName]);

  const keysToDelete = ['buyableStatus', 'sentTimeFilter'];
  if (filterState.bidVisibility === BidVisibility.All) {
    keysToDelete.push('bidCreatorDealerId', 'bidCreatorUserId', 'bidCreatorDealerCountry');
  } else if (filterState.bidVisibility === BidVisibility.Dealer) {
    keysToDelete.push('bidCreatorUserId', 'bidCreatorDealerCountry');
  } else if (filterState.bidVisibility === BidVisibility.User) {
    keysToDelete.push('bidCreatorDealerId', 'bidCreatorDealerCountry');
  } else if (filterState.bidVisibility === BidVisibility.Country) {
    keysToDelete.push('bidCreatorDealerId', 'bidCreatorUserId');
  }
  const active = new Set(
    Object.keys(filterState).filter(
      (name) => !keysToDelete.includes(name) && isDefaultFilter(defaultFilter, name as any, filterState),
    ),
  );

  return (
    <Grid container>
      {bidsFilter.map((filterGroup) => (
        <Fragment key={filterGroup.groupKey}>
          {filterGroup.filter && filterGroup.filter.length > 0
            ? filterGroup.filter
                .filter((filterObject) => active.has(filterObject.name))
                .filter(({ roles }) => roles === undefined || roles.every((role) => hasRole(role))) // default filters shouldn't be displayed if the user can't set this filter
                .map(({ label, name, renderFilterChip: Chip }) => (
                  <Chip
                    key={name}
                    field={{ name: name as any, label, value: filterState[name] }}
                    onDelete={setFilter}
                  />
                ))
            : null}
        </Fragment>
      ))}
    </Grid>
  );
};

export default ActiveBidsFilterChips;
