import { ReactNode } from 'react';
import { Box, BoxProps, styled, Theme } from '@material-ui/core';
import type { Merge } from 'type-fest';

export type StackProps = {
  spacing: number;
  children?: ReactNode;
  direction?: 'column' | 'row';
};

export const Stack = styled(({ spacing: _spacing, direction: _direction, ...props }: Merge<BoxProps, StackProps>) => (
  // eslint-disable-next-line no-restricted-syntax
  <Box {...props} />
))<Theme, StackProps>(
  ({ theme, spacing, direction = 'column' }) => ({
    display: 'flex',
    flexDirection: direction,
    gap: theme.spacing(spacing),
  }),
  { name: 'Stack' },
);
