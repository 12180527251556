import countryToCurrency from 'country-to-currency';
import { isString } from 'lodash';
import { getAccountCountry } from './country-helper';
import { CurrencyCode, MonetaryAmount } from './generated/api';

export type MonetaryAmountStrict = Required<MonetaryAmount>;

export type Currency = {
  symbol: string;
  label: string;
  code: SupportedCurrencyCode;
};

export type SupportedCurrencyCode =
  | CurrencyCode.Eur
  | CurrencyCode.Czk
  | CurrencyCode.Huf
  | CurrencyCode.Pln
  | CurrencyCode.Ron
  | CurrencyCode.Sek;

export const SUPPORTED_CURRENCIES: Record<SupportedCurrencyCode, Currency> = {
  [CurrencyCode.Eur]: {
    label: 'currency.EUR',
    symbol: '€',
    code: CurrencyCode.Eur,
  },
  [CurrencyCode.Czk]: {
    label: 'currency.CZK',
    symbol: 'CZK',
    code: CurrencyCode.Czk,
  },
  [CurrencyCode.Huf]: {
    label: 'currency.HUF',
    symbol: 'HUF',
    code: CurrencyCode.Huf,
  },
  [CurrencyCode.Pln]: {
    label: 'currency.PLN',
    symbol: 'PLN',
    code: CurrencyCode.Pln,
  },
  [CurrencyCode.Ron]: {
    label: 'currency.RON',
    symbol: 'RON',
    code: CurrencyCode.Ron,
  },
  [CurrencyCode.Sek]: {
    label: 'currency.SEK',
    symbol: 'SEK',
    code: CurrencyCode.Sek,
  },
};

// mostly used for internal configuration stuff
// e.g:
// - RangeSlider min/max
// - CarsFilter default values
export const DEFAULT_CURRENCY = SUPPORTED_CURRENCIES[CurrencyCode.Eur];

export const isSupportedCurrencyCode = (x: unknown): x is SupportedCurrencyCode =>
  isString(x) && x in SUPPORTED_CURRENCIES;

export const getAccountCurrency = (): Currency => {
  const country = getAccountCountry() as keyof typeof countryToCurrency;
  const currency = countryToCurrency[country] as SupportedCurrencyCode;

  return SUPPORTED_CURRENCIES[currency] ?? DEFAULT_CURRENCY;
};

/**
 * @deprecated
 */
export const makeMonetaryAmount = (
  amount: number | MonetaryAmount,
  currency: SupportedCurrencyCode | Currency = DEFAULT_CURRENCY.code,
): MonetaryAmountStrict => {
  const currencyCode = typeof currency === 'string' ? currency : currency.code;

  if (typeof amount === 'number') {
    return {
      amount,
      currency: currencyCode,
    };
  }

  return {
    amount: amount.amount ?? 0,
    currency: amount.currency ?? currencyCode,
  };
};
