import { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DealerTable } from '@components/dealer-table/DealerTable';
import PageContainer from '@components/PageContainer';
import { RouteComponentProps } from '@reach/router';
import { PageMeta } from '@components/page-meta';
import { PageHeader } from '@components/page-header';

const useStyles = makeStyles((theme) => ({
  pageHeader: {
    marginBlockEnd: theme.spacing(4),
  },
  pageContainer: {
    paddingBlockEnd: theme.spacing(6),
  },
  card: {
    padding: theme.spacing(2),
  },
}));

const CredentialsPage = (_props: RouteComponentProps): ReactElement => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <PageMeta title="navigation.dealerManagement" />
      <PageContainer className={classes.pageContainer}>
        <PageHeader
          className={classes.pageHeader}
          headline={t('navigation.dealerManagement')}
          body={t('dealerManagementPage.subHeadline')}
        />
        <DealerTable />
      </PageContainer>
    </>
  );
};

export default CredentialsPage;
