import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import useCustomSnackbarGlobal from './useSnackbarGlobal';
import useApi from './useApi';
import { FILE_DATE_TIME_FORMAT } from '../modules/date-helpers';
import { useCarsTableStore } from '../stores/CarsTable';

const useCarsFileExport = () => {
  const {
    data: carsFile,
    error: carsFileExportError,
    fetch: fetchCarsFileExport,
    loading: carsFileExportLoading,
  } = useApi<any>();
  const snackbar = useCustomSnackbarGlobal();
  const { t } = useTranslation();
  const { totalCount } = useCarsTableStore();

  useEffect(() => {
    if (!carsFileExportError && carsFileExportLoading === undefined) return;
    if (carsFileExportError) {
      snackbar.showError(t('alerts.exportFailed'));
    } else if (carsFileExportLoading) {
      snackbar.showInfo(t('alerts.exportInProcess', { carCount: totalCount }));
    }
  }, [carsFileExportError, carsFileExportLoading, snackbar, t, totalCount]);

  useEffect(() => {
    if (!carsFileExportError && carsFileExportLoading === undefined) return;
    if (carsFileExportError) {
      snackbar.showError(t('alerts.exportFailed'));
    } else if (carsFileExportLoading) {
      snackbar.showInfo(t('alerts.exportInProcess', { carCount: totalCount }));
    }
  }, [carsFileExportError, carsFileExportLoading, snackbar, t, totalCount]);

  useEffect(() => {
    if (!carsFile) return;
    const file = new Blob([carsFile]);
    const link = document.createElement('a');
    link.href = URL.createObjectURL(file);
    const fileName = `export_ALLUC_${DateTime.now().toFormat(FILE_DATE_TIME_FORMAT)}.xlsx`;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  }, [carsFile]);

  return { fetchCarsFileExport, carsFileExportLoading, carsFile };
};

export default useCarsFileExport;
