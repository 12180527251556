import { useState } from 'react';
import TagManager from 'react-gtm-module';
import { DetailCarDTO, ListCarDTO } from '../modules/generated/api';
import { getCarDetailsOpenTM } from '../modules/tag-manager-helpers';

const useCarDetailPopup = () => {
  const [carDetail, setCarDetail] = useState<DetailCarDTO | undefined>();
  const [index, setIndex] = useState<number | undefined>();

  const paginateCarDetail = (cars: ListCarDTO[], offset: number) => {
    if (index !== undefined) {
      const newIndex = index + offset;
      const car = cars[newIndex];
      setCarDetail(car);
      TagManager.dataLayer(getCarDetailsOpenTM(car));
      setIndex(newIndex);
    }
  };
  const onRowClick = (cars: ListCarDTO[]) => (rowData: string[], rowMeta: { dataIndex: number }) => {
    const { dataIndex } = rowMeta;
    setIndex(dataIndex);
    setCarDetail(cars[dataIndex]);
    const car = cars[dataIndex];
    TagManager.dataLayer(getCarDetailsOpenTM(car));
  };

  const closeCarDetail = () => {
    setCarDetail(undefined);
    TagManager.dataLayer({
      dataLayer: {
        event: 'car-details-close',
        car: null,
      },
    });
  };

  return { carDetail, closeCarDetail, index, paginateCarDetail, onRowClick };
};

export default useCarDetailPopup;
