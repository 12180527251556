import { Chip, Grid, makeStyles } from '@material-ui/core';
import { Gavel } from '@material-ui/icons';
import clsx from 'clsx';
import { isUndefined } from 'lodash';
import { DateTime, Interval } from 'luxon';
import { CustomHeadLabelRenderOptions, MUIDataTableColumn } from 'mui-datatables';
import { useTranslation } from 'react-i18next';
import { getLabel, sourceOriginFilter } from 'src/modules/labels';
import AuctionsHintHover from '../components/AuctionsHintHover';
import { BaseFixedOrientationPriceCell } from '../components/BaseFixedOrientationPriceCell';
import CarCulatorHeaderCell from '../components/CarCulatorHeaderCell';
import CarsPotentialValues from '../components/CarsPotentialValues';
import CarsTableAnnotatedHeadLabelCell from '../components/CarsTableAnnotatedHeadLabelCell';
import CarsTableBidSuccessStatus from '../components/CarsTableBidSuccessStatus';
import CarsTableBidsBuyer from '../components/CarsTableBidsBuyer';
import CarsTableBidsCell from '../components/CarsTableBidsCell';
import CarsTableCO2Cell from '../components/CarsTableCO2Cell';
import CarsTableCarCulatorLinkCell from '../components/CarsTableCarCulatorLinkCell';
import CarsTableDamageCell from '../components/CarsTableDamageCell';
import CarsTableImageCell from '../components/CarsTableImageCell';
import CarsTableMarketPrice from '../components/CarsTableMarketPrice';
import CarsTableNormalizedModelCell from '../components/CarsTableNormalizedModelCell';
import CarsTableOnlineTimeCell from '../components/CarsTableOnlineTimeCell';
import CarsTableRemainingTime from '../components/CarsTableRemainingTimeCell';
import CarsTableStartDateAndId from '../components/CarsTableStartDateAndId';
import CarsTableUserInformationCell from '../components/CarsTableUserInformationCell';
import { CarsTaxList } from '../components/CarsTaxList';
import Highlights from '../components/Highlights';
import ImageLabel from '../components/ImageLabel';
import Promotions from '../components/Promotions';
import { PurchaseGrossFallback } from '../components/PurchaseGrossFallback';
import PurchaseGrossHint from '../components/PurchaseGrossHint';
import UnitValueCell from '../components/UnitValueCell';
import {
  conditionalFormattingCreateBackground,
  conditionalFormattingEnumArray,
  conditionalFormattingNumber,
  conditionalFormattingString,
  findConditionalFormattingDetail,
} from '../modules/conditional-formatting';
import { SupportedCurrencyCode, makeMonetaryAmount } from '../modules/currency';
import { DEFAULT_VALUATION_COUNTRY } from '../modules/data';
import {
  BID_DATE_TIME_FORMAT,
  DATE_TIME_FORMAT_NO_TIME,
  REGISTRATION_DATE_FORMAT,
  formatDate,
  sheduledOrPromt,
} from '../modules/date-helpers';
import {
  BidDTO,
  BidSuccessStatus,
  ConditionalFormattingDTO,
  ConditionalFormattingScope,
  ConditionalFormattingType,
  IndividualBidStateInfoDTO,
  ListCarDTO,
  MonetaryAmount,
  PriceType,
  ValidityType,
  ValuationCountryCode,
} from '../modules/generated/api';
import { BidTableColumnTypes, BidsListCarDTO, CarTableColumn, getColumnDeclaration } from '../modules/table-data';
import { createTaxList, relevantTaxes } from '../modules/tax';
import { formatUnitValue } from '../modules/unit-helpers';
import { BidTableType } from '../setup/bids-filter-settings';
import { useAuthenticationStore } from '../stores/Authentication';
import { CarsFilterSettings } from '../types/CarsFilterSettings';
import UserRole from '../types/UserRoles';
import { useCurrency } from './useCurrency';
import useRole from './useRole';
import { useValuationSettings } from './useValuationSettings';

const getBidStatus = (bidStateInfo: IndividualBidStateInfoDTO, userId: string | number) => {
  const ownPrevBids = bidStateInfo.allHistoricBids?.filter((bid) => bid.user?.id === userId);
  const ownBids = [...(ownPrevBids || []), ...(bidStateInfo.ownCurrentBids || [])];
  const highest = bidStateInfo.ownCurrentBids?.some((bid) => bid.status === BidSuccessStatus.Highest);
  const prevHighest = ownPrevBids?.some((bid) => bid.status === BidSuccessStatus.Highest);
  const outbid = ownBids.some((bid) => bid.status === BidSuccessStatus.Outbid);

  switch (true) {
    case highest:
      return {
        background: 'green',
        color: 'white',
        label: 'carsTable.bidStatus.highest',
      };
    case prevHighest:
      return {
        background: 'yellow',
        color: 'black',
        label: 'carsTable.bidStatus.prevHighest',
      };
    case outbid:
      return {
        background: 'red',
        color: 'white',
        label: 'carsTable.bidStatus.outbid',
      };
    default:
      return undefined;
  }
};

const useStyles = makeStyles((theme) => ({
  cellDivider: {
    'th&, td&': {
      borderLeft: `${theme.spacing(0.5)}px solid ${theme.palette.primary.main}`,
    },
  },
  cellHeader: {
    '&--small': {
      paddingLeft: 8,
      paddingRight: 8,
    },
  },
  cellHighlightTop: {
    'th&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      height: theme.spacing(0.5),
      backgroundColor: theme.palette.primary.main,
    },
  },
  cellBoldValues: {
    fontWeight: 'bold',
    '& input': { fontWeight: 'bold' },
  },
  numberCell: {
    marginRight: theme.spacing(3.5),
  },
  auctionStatus: {
    marginTop: theme.spacing(1),
  },
}));

const useCarsTableColumns = () => {
  const classes = useStyles();
  const { hasRole } = useRole();
  const { t } = useTranslation();
  const { format, convert } = useCurrency();
  const { valuationCountry: globalValuationCountry } = useValuationSettings();
  const {
    state: { objectToken },
  } = useAuthenticationStore();

  const getColumnBgColors = (
    rowIdx: number,
    column: CarTableColumn,
    tableData: BidsListCarDTO[],
    conditionalFormattings: ConditionalFormattingDTO[],
    scope?: ConditionalFormattingScope,
  ): string[] => {
    const data = tableData[rowIdx];

    const scopedConditionalFormattings =
      scope !== undefined
        ? conditionalFormattings.map((formatting) => ({
            ...formatting,
            conditions: formatting.conditions?.filter((condition) => condition.scope === scope),
          }))
        : conditionalFormattings;

    const monthsSinceFirstRegistration = Interval.fromDateTimes(
      DateTime.fromISO(data.firstRegistration as string),
      DateTime.now(),
    ).length('months');

    const columnBgReducer = () => {
      switch (column) {
        case CarTableColumn.Model:
          return [
            ...conditionalFormattingString(data.model, ConditionalFormattingType.Model, scopedConditionalFormattings),
          ];
        case CarTableColumn.NormalizedModel:
          return [
            ...conditionalFormattingString(
              data.normalized?.fullModelName || '',
              ConditionalFormattingType.NormalizedFullModelName,
              scopedConditionalFormattings,
            ),
          ];
        case CarTableColumn.FirstRegistration:
          return [
            ...conditionalFormattingNumber(
              monthsSinceFirstRegistration,
              ConditionalFormattingType.FirstRegistration,
              scopedConditionalFormattings,
            ),
          ];
        case CarTableColumn.Mileage:
          return [
            ...conditionalFormattingNumber(
              data.mileage,
              ConditionalFormattingType.Mileage,
              scopedConditionalFormattings,
            ),
          ];
        case CarTableColumn.promotions:
          return [
            ...conditionalFormattingEnumArray(
              data.promotions,
              ConditionalFormattingType.Promotions,
              scopedConditionalFormattings,
            ),
          ];
        case CarTableColumn.Damage:
          return [
            ...conditionalFormattingNumber(
              data.priceDamage?.value,
              ConditionalFormattingType.PriceDamage,
              scopedConditionalFormattings,
            ),
            ...conditionalFormattingEnumArray(
              data.damageType,
              ConditionalFormattingType.DamageType,
              scopedConditionalFormattings,
            ),
          ];
        case CarTableColumn.Co2:
          return [
            ...conditionalFormattingNumber(data.engineCo2, ConditionalFormattingType.Co2, scopedConditionalFormattings),
          ];
        case CarTableColumn.DateOnlineTimeInMinutes:
          return [
            ...conditionalFormattingNumber(
              data.onlineTimeInMinutes,
              ConditionalFormattingType.DateOnlineTimeInMinutes,
              scopedConditionalFormattings,
            ),
          ];
        case CarTableColumn.NumAuctions:
          return [
            ...conditionalFormattingNumber(
              data.numAuctions,
              ConditionalFormattingType.NumAuctions,
              scopedConditionalFormattings,
            ),
          ];
        case CarTableColumn.BaseFixedOrientationPrice:
          return [
            ...conditionalFormattingNumber(
              data.potential?.base?.amount,
              ConditionalFormattingType.PotentialBase,
              scopedConditionalFormattings,
            ),
            ...conditionalFormattingString(
              data.taxType,
              ConditionalFormattingType.TaxType,
              scopedConditionalFormattings,
            ),
            ...(scope !== ConditionalFormattingScope.Row ? ['#F0F0F0'] : []),
          ];
        case CarTableColumn.PurchaseNetWholesaleUnrepaired:
          return [
            ...conditionalFormattingNumber(
              data.potential?.purchaseNetWholesaleUnrepaired?.amount,
              ConditionalFormattingType.PurchaseNetUnrepaired,
              scopedConditionalFormattings,
            ),
          ];
        case CarTableColumn.PurchaseNetRetailRepaired:
          return [
            ...conditionalFormattingNumber(
              data.potential?.purchaseNetRetailRepaired?.amount,
              ConditionalFormattingType.PurchaseNetRepaired,
              scopedConditionalFormattings,
            ),
          ];
        case CarTableColumn.PurchaseGross:
          return [
            ...conditionalFormattingNumber(
              data.potential?.purchaseGross?.amount,
              ConditionalFormattingType.PurchaseGross,
              scopedConditionalFormattings,
            ),
            ...(scope !== ConditionalFormattingScope.Row ? ['#F0F0F0'] : []),
          ];
        case CarTableColumn.MarketPrice:
          return [
            ...conditionalFormattingNumber(
              data.valuation?.price?.mean?.amount,
              ConditionalFormattingType.ValuationMarketPrice,
              scopedConditionalFormattings,
            ),
          ];
        case CarTableColumn.PotentialMeanAbsolute:
          return [
            ...conditionalFormattingNumber(
              data.potential?.potentialMeanAbsolute?.amount,
              ConditionalFormattingType.PotentialInternetPriceAverageAbsolute,
              scopedConditionalFormattings,
            ),
          ];
        case CarTableColumn.PotentialMinAbsolute:
          return [
            ...conditionalFormattingNumber(
              data.potential?.potentialMinAbsolute?.amount,
              ConditionalFormattingType.PotentialInternetPriceMinAbsolute,
              scopedConditionalFormattings,
            ),
          ];
        case CarTableColumn.PotentialMeanRelative:
          return [
            ...conditionalFormattingNumber(
              data.potential?.potentialMeanRelative ? data.potential.potentialMeanRelative * 100 : undefined,
              ConditionalFormattingType.PotentialInternetPriceAveragePercent,
              scopedConditionalFormattings,
            ),
          ];
        case CarTableColumn.PotentialMinRelative:
          return [
            ...conditionalFormattingNumber(
              data.potential?.potentialMinRelative ? data.potential.potentialMinRelative * 100 : undefined,
              ConditionalFormattingType.PotentialInternetPriceMinPercent,
              scopedConditionalFormattings,
            ),
          ];
        case CarTableColumn.PotentialMinMeanAbsolute:
          return [
            ...conditionalFormattingNumber(
              data.potential?.potentialMinMeanAbsolute?.amount,
              ConditionalFormattingType.PotentialInternetPriceMinAverageAbsolute,
              scopedConditionalFormattings,
            ),
          ];
        case CarTableColumn.PotentialMinMeanRelative:
          return [
            ...conditionalFormattingNumber(
              data.potential?.potentialMinMeanRelative ? data.potential.potentialMinMeanRelative * 100 : undefined,
              ConditionalFormattingType.PotentialInternetPriceMinAveragePercent,
              scopedConditionalFormattings,
            ),
          ];

        default:
          return [];
      }
    };

    return columnBgReducer();
  };

  const getCarsTableColumns = (
    tableData: BidsListCarDTO[],
    conditionalFormattings: ConditionalFormattingDTO[],
    bids?: BidDTO[],
    bidTableType?: BidTableType,
    filterSettings?: CarsFilterSettings,
    refetch?: () => Promise<void>,
  ) => {
    const {
      priceDamage: [priceDamageMin, priceDamageMax] = [],
      mileage: [mileageMin, mileageMax] = [],
      registrationDate: [registrationDateMin, registrationDateMax] = [],
      purchasePriceGross: [purchasePriceGrossMin, purchasePriceGrossMax] = [],
    } = filterSettings || {};

    const createTaxCol = (
      colType: CarTableColumn,
      valuationCountry: ValuationCountryCode = DEFAULT_VALUATION_COUNTRY,
    ) => ({
      ...getColumnDeclaration(colType),
      options: {
        customHeadLabelRender: ({ label, name }: CustomHeadLabelRenderOptions) => (
          <CarsTableAnnotatedHeadLabelCell
            label={label || name}
            conditionalFormattings={[
              ConditionalFormattingType.Nova,
              ConditionalFormattingType.Vat,
              ConditionalFormattingType.Transcription,
              ConditionalFormattingType.RegistrationAbsolute,
              ConditionalFormattingType.RegistrationRelative,
              ConditionalFormattingType.Eco,
              ConditionalFormattingType.Ecomalus,
              ConditionalFormattingType.PropertyAcquisition,
              ConditionalFormattingType.Excise,
              ConditionalFormattingType.Civil,
              ConditionalFormattingType.Authentication,
            ]
              .filter((taxType) => relevantTaxes[valuationCountry]?.includes(taxType))
              .map((taxType) => findConditionalFormattingDetail(taxType, conditionalFormattings))}
          />
        ),
        customBodyRenderLite: (dataIndex: number) => {
          const { taxes, tax, potential } = tableData[dataIndex];
          const baseValuationCountry = potential?.country;
          const taxTarget = valuationCountry ? taxes?.[valuationCountry] : tax;
          const taxList =
            (taxTarget &&
              createTaxList(taxTarget, valuationCountry || baseValuationCountry, {
                percent: (v) => formatUnitValue(v, '%'),
                currency: (v) => format(convert(v, {}), {}),
              })) ||
            [];

          return <CarsTaxList taxes={taxList} />;
        },
        setCellProps: (_: string, rowIdx: number) => {
          const { taxes } = tableData[rowIdx];
          const taxTarget = taxes?.[valuationCountry];
          const taxList = (taxTarget && createTaxList(taxTarget, valuationCountry)) || [];

          const mapTaxTypeToConditionalFormattingType = (taxType: string): ConditionalFormattingType => {
            const isRegistration = taxType === 'REGISTRATION';

            if (isRegistration && relevantTaxes[valuationCountry]?.includes('REGISTRATION_RELATIVE')) {
              return ConditionalFormattingType.RegistrationRelative;
            }

            if (isRegistration && relevantTaxes[valuationCountry]?.includes('REGISTRATION_ABSOLUTE')) {
              return ConditionalFormattingType.RegistrationAbsolute;
            }

            return taxType as ConditionalFormattingType;
          };

          return {
            className: classes.numberCell,
            style: {
              background: conditionalFormattingCreateBackground(
                taxList.flatMap(({ value, key }) =>
                  conditionalFormattingNumber(
                    parseInt(value as string, 10),
                    mapTaxTypeToConditionalFormattingType(key),
                    conditionalFormattings,
                  ),
                ),
              ),
            },
          };
        },
        sort: false,
        display: isUndefined(valuationCountry),
        viewColumns: isUndefined(valuationCountry) || hasRole(UserRole.ADMIN),
      },
    });

    const createRecommendedRetailPriceColumn = (type?: 'Absolute' | 'Relative') => {
      const accessPropertyName: keyof ListCarDTO = type
        ? `recommendedRetailPriceDeduction${type}`
        : 'recommendedRetailPrice';
      return {
        ...getColumnDeclaration(accessPropertyName as CarTableColumn),
        options: {
          sortDescFirst: !!type,
          customHeadLabelRender: ({ label, name }: CustomHeadLabelRenderOptions) => (
            <CarsTableAnnotatedHeadLabelCell label={label || name} />
          ),
          customBodyRenderLite(dataIndex: number) {
            const car = tableData[dataIndex];
            const price: MonetaryAmount | number = car[accessPropertyName] as MonetaryAmount;

            return (
              <UnitValueCell
                value={type === 'Relative' ? (price as number) : price?.amount}
                unit={type === 'Relative' ? '%' : 'EUR'}
              />
            );
          },
          display: false,
        },
      };
    };

    const carsColumns: MUIDataTableColumn[] = [
      {
        ...getColumnDeclaration(CarTableColumn.ImageThumbnailLink),
        options: {
          customBodyRenderLite: (dataIndex) => (
            <ImageLabel
              size="small"
              text={t('bids.expired')}
              disable={!!bids || tableData[dataIndex].validity !== ValidityType.Expired}
            >
              <CarsTableImageCell
                imageLinks={
                  tableData[dataIndex].imageThumbnailLink
                    ? [tableData[dataIndex].imageThumbnailLink as string]
                    : undefined
                }
                bidStatus={tableData[dataIndex].bidStateInfo}
              />
            </ImageLabel>
          ),
          sort: false,
        },
      },
      {
        ...getColumnDeclaration(CarTableColumn.UserInfo),
        options: {
          customBodyRenderLite: (dataIndex) => (
            <CarsTableUserInformationCell car={tableData[dataIndex]} refetch={refetch} />
          ),
        },
      },
      {
        ...getColumnDeclaration(CarTableColumn.startDateAndId),
        options: {
          sortDescFirst: true,
          customBodyRenderLite: (dataIndex) => {
            const { source, dateStart = '', packageId, carId } = tableData[dataIndex];
            return <CarsTableStartDateAndId isoDate={dateStart} packageId={packageId} source={source} carId={carId} />;
          },
        },
      },
      {
        ...getColumnDeclaration(CarTableColumn.OriginSource),
        options: {
          display: false,
          viewColumns: hasRole(UserRole.ADMIN),
          customBodyRenderLite: (dataIndex) => {
            const { originSource } = tableData[dataIndex];
            return <p>{originSource ? getLabel(sourceOriginFilter, originSource) : '-'}</p>;
          },
        },
      },
      {
        ...getColumnDeclaration(CarTableColumn.DateEnd),
        options: {
          customBodyRender: (dateEnd, { rowIndex }) => {
            const { carId } = tableData[rowIndex];

            return (
              <>
                <div style={{ whiteSpace: 'nowrap' }}>{dateEnd ? formatDate(dateEnd) : 'N/A'}</div>
                {dateEnd && (
                  <CarsTableRemainingTime
                    key={carId}
                    dateEnd={dateEnd}
                    showExpiredLabel={isUndefined(bidTableType) || bidTableType === 'PendingBidsTable'}
                  />
                )}
              </>
            );
          },
        },
      },
      {
        ...getColumnDeclaration(CarTableColumn.Model),
        options: {
          customHeadLabelRender: ({ label, name }: CustomHeadLabelRenderOptions) => (
            <CarsTableAnnotatedHeadLabelCell
              label={label || name}
              conditionalFormattings={[
                findConditionalFormattingDetail(ConditionalFormattingType.Model, conditionalFormattings),
              ]}
              width="medium"
            />
          ),
          setCellProps: (_, rowIdx) => ({
            style: {
              background: conditionalFormattingCreateBackground(
                getColumnBgColors(rowIdx, CarTableColumn.Model, tableData, conditionalFormattings),
              ),
            },
          }),
        },
      },
      {
        ...getColumnDeclaration(CarTableColumn.NormalizedModel),
        options: {
          display: false,
          customBodyRenderLite: (dataIndex) => (
            <CarsTableNormalizedModelCell normalizedModel={tableData[dataIndex]?.normalized} />
          ),
          customHeadLabelRender: ({ label, name }: CustomHeadLabelRenderOptions) => (
            <CarsTableAnnotatedHeadLabelCell
              label={label || name}
              hint={t('carsTable.hints.modelTechdata')}
              conditionalFormattings={[
                findConditionalFormattingDetail(
                  ConditionalFormattingType.NormalizedFullModelName,
                  conditionalFormattings,
                ),
              ]}
            />
          ),
          setCellProps: (_, rowIdx) => ({
            style: {
              background: conditionalFormattingCreateBackground(
                getColumnBgColors(rowIdx, CarTableColumn.NormalizedModel, tableData, conditionalFormattings),
              ),
            },
          }),
        },
      },
      {
        ...getColumnDeclaration(CarTableColumn.Highlights),
        options: {
          customBodyRenderLite: (dataIndex) => <Highlights car={tableData[dataIndex]} />,
          sort: false,
        },
      },
      {
        ...getColumnDeclaration(CarTableColumn.FirstRegistration),
        options: {
          customBodyRender: (value) => formatDate(value, REGISTRATION_DATE_FORMAT),
          customHeadLabelRender: ({ label, name }: CustomHeadLabelRenderOptions) => (
            <CarsTableAnnotatedHeadLabelCell
              label={label || name}
              min={registrationDateMin ? formatDate(registrationDateMin, REGISTRATION_DATE_FORMAT) : undefined}
              max={registrationDateMax ? formatDate(registrationDateMax, REGISTRATION_DATE_FORMAT) : undefined}
              formatHint={t('car.firstRegistration_lng')}
              conditionalFormattings={[
                findConditionalFormattingDetail(ConditionalFormattingType.FirstRegistration, conditionalFormattings),
              ]}
            />
          ),
          setCellProps: (_, rowIdx) => ({
            style: {
              background: conditionalFormattingCreateBackground(
                getColumnBgColors(rowIdx, CarTableColumn.FirstRegistration, tableData, conditionalFormattings),
              ),
            },
          }),
        },
      },
      {
        ...getColumnDeclaration(CarTableColumn.Mileage),
        options: {
          customBodyRender: (value) => <UnitValueCell value={value} unit="km" />,
          customHeadLabelRender: ({ label, name }: CustomHeadLabelRenderOptions) => (
            <CarsTableAnnotatedHeadLabelCell
              label={label || name}
              min={mileageMin ? `${formatUnitValue(mileageMin)} km` : undefined}
              max={mileageMax ? `${formatUnitValue(mileageMax)} km` : undefined}
              conditionalFormattings={[
                findConditionalFormattingDetail(ConditionalFormattingType.Mileage, conditionalFormattings),
              ]}
            />
          ),
          setCellProps: (_, rowIdx) => ({
            className: classes.numberCell,
            style: {
              background: conditionalFormattingCreateBackground(
                getColumnBgColors(rowIdx, CarTableColumn.Mileage, tableData, conditionalFormattings),
              ),
            },
          }),
        },
      },
      {
        ...getColumnDeclaration(CarTableColumn.promotions),
        options: {
          display: false,
          customBodyRenderLite: (dataIndex) => <Promotions car={tableData[dataIndex]} />,
          sort: false,
          customHeadLabelRender: ({ label, name }: CustomHeadLabelRenderOptions) => (
            <CarsTableAnnotatedHeadLabelCell
              label={label || name}
              conditionalFormattings={[
                findConditionalFormattingDetail(ConditionalFormattingType.Promotions, conditionalFormattings),
              ]}
            />
          ),
          setCellProps: (_, rowIdx) => ({
            style: {
              background: conditionalFormattingCreateBackground(
                getColumnBgColors(rowIdx, CarTableColumn.promotions, tableData, conditionalFormattings),
              ),
            },
          }),
        },
      },
      {
        ...getColumnDeclaration(CarTableColumn.Damage),
        options: {
          customBodyRenderLite: (dataIndex) => {
            const { damageType, priceDamage } = tableData[dataIndex];
            return (
              <CarsTableDamageCell
                car={tableData[dataIndex]}
                damageType={damageType}
                priceDamage={makeMonetaryAmount(priceDamage?.value ?? 0, priceDamage?.unit as SupportedCurrencyCode)}
              />
            );
          },
          customHeadLabelRender: ({ label, name }: CustomHeadLabelRenderOptions) => (
            <CarsTableAnnotatedHeadLabelCell
              label={label || name}
              hint={t('carsTable.hints.priceDamage')}
              min={priceDamageMin && format(priceDamageMin, {})}
              max={priceDamageMax && format(priceDamageMax, {})}
              conditionalFormattings={[
                findConditionalFormattingDetail(ConditionalFormattingType.PriceDamage, conditionalFormattings),
                findConditionalFormattingDetail(ConditionalFormattingType.DamageType, conditionalFormattings),
              ]}
            />
          ),
          setCellProps: (_, rowIdx) => ({
            className: classes.numberCell,
            style: {
              background: conditionalFormattingCreateBackground(
                getColumnBgColors(rowIdx, CarTableColumn.Damage, tableData, conditionalFormattings),
              ),
            },
          }),
        },
      },
      {
        ...getColumnDeclaration(CarTableColumn.Co2),
        options: {
          customHeadLabelRender: ({ label, name }: CustomHeadLabelRenderOptions) => (
            <CarsTableAnnotatedHeadLabelCell
              label={label || name}
              hint={t('carsTable.hints.nova')}
              conditionalFormattings={[
                findConditionalFormattingDetail(ConditionalFormattingType.Co2, conditionalFormattings),
              ]}
            />
          ),
          customBodyRenderLite: (dataIndex) => {
            const { engineCo2, co2ApproximationMethod, engineCo2Nedc, engineCo2Wltp } = tableData[dataIndex];
            return (
              <CarsTableCO2Cell
                approxCo2={co2ApproximationMethod}
                engineCo2={engineCo2}
                engineCo2Nedc={engineCo2Nedc}
                engineCo2Wltp={engineCo2Wltp}
              />
            );
          },
          setCellProps: (_, rowIdx) => ({
            className: classes.numberCell,
            style: {
              background: conditionalFormattingCreateBackground(
                getColumnBgColors(rowIdx, CarTableColumn.Co2, tableData, conditionalFormattings),
              ),
            },
          }),
        },
      },
      createTaxCol(CarTableColumn.Taxes, globalValuationCountry || undefined),
      createTaxCol(CarTableColumn.TaxesAtLot, ValuationCountryCode.AtLot),
      createTaxCol(CarTableColumn.TaxesAt, ValuationCountryCode.At),
      createTaxCol(CarTableColumn.TaxesDe, ValuationCountryCode.De),
      createTaxCol(CarTableColumn.TaxesFr, ValuationCountryCode.Fr),
      createTaxCol(CarTableColumn.TaxesIt, ValuationCountryCode.It),
      createTaxCol(CarTableColumn.TaxesEs, ValuationCountryCode.Es),
      createTaxCol(CarTableColumn.TaxesCz, ValuationCountryCode.Cz),
      createTaxCol(CarTableColumn.TaxesPl, ValuationCountryCode.Pl),
      createTaxCol(CarTableColumn.TaxesRo, ValuationCountryCode.Ro),
      createTaxCol(CarTableColumn.TaxesHu, ValuationCountryCode.Hu),
      createTaxCol(CarTableColumn.TaxesSi, ValuationCountryCode.Si),
      createTaxCol(CarTableColumn.TaxesPt, ValuationCountryCode.Pt),
      createTaxCol(CarTableColumn.TaxesHr, ValuationCountryCode.Hr),
      createTaxCol(CarTableColumn.TaxesBg, ValuationCountryCode.Bg),
      createTaxCol(CarTableColumn.TaxesSe, ValuationCountryCode.Se),
      createTaxCol(CarTableColumn.TaxesSk, ValuationCountryCode.Sk),
      {
        ...getColumnDeclaration(CarTableColumn.DateOnlineTimeInMinutes),
        options: {
          customHeadLabelRender: ({ label, name }: CustomHeadLabelRenderOptions) => (
            <CarsTableAnnotatedHeadLabelCell
              label={label || name}
              formatHint={t('carsTable.hints.onlineTime')}
              conditionalFormattings={[
                findConditionalFormattingDetail(
                  ConditionalFormattingType.DateOnlineTimeInMinutes,
                  conditionalFormattings,
                ),
              ]}
            />
          ),
          customBodyRenderLite: (dataIndex) => {
            const { onlineTimeInMinutes } = tableData[dataIndex];
            return <CarsTableOnlineTimeCell onlineTimeInMinutes={onlineTimeInMinutes} />;
          },
          setCellProps: (_, rowIdx) => ({
            style: {
              background: conditionalFormattingCreateBackground(
                getColumnBgColors(rowIdx, CarTableColumn.DateOnlineTimeInMinutes, tableData, conditionalFormattings),
              ),
            },
          }),
          viewColumns: hasRole(UserRole.ADMIN),
          display: false,
        },
      },
      {
        ...getColumnDeclaration(CarTableColumn.NumAuctions),
        options: {
          sortDescFirst: true,
          customHeadLabelRender: ({ label }: CustomHeadLabelRenderOptions) => (
            <CarsTableAnnotatedHeadLabelCell
              label={<Gavel />}
              hint={label}
              conditionalFormattings={[
                findConditionalFormattingDetail(ConditionalFormattingType.NumAuctions, conditionalFormattings),
              ]}
            />
          ),
          customBodyRenderLite: (dataIndex) => {
            const car = tableData[dataIndex];
            const prevAuctions = car?.previousAuctions?.length;
            const statusVariant =
              car.bidStateInfo && objectToken?.user_id
                ? getBidStatus(car.bidStateInfo, objectToken?.user_id)
                : undefined;

            return (
              <>
                <Grid container direction="row" justifyContent="center" alignItems="center" wrap="nowrap" spacing={1}>
                  <Grid item>
                    <UnitValueCell value={prevAuctions || car.numAuctions} />
                  </Grid>
                  {!isUndefined(prevAuctions) && prevAuctions > 0 && (
                    <Grid item>
                      <AuctionsHintHover car={tableData[dataIndex]} />
                    </Grid>
                  )}
                </Grid>
                {statusVariant && (
                  <div className={classes.auctionStatus}>
                    <Chip
                      style={{
                        background: statusVariant.background,
                        color: statusVariant.color,
                      }}
                      label={t(statusVariant.label as any)}
                      size="small"
                    />
                  </div>
                )}
              </>
            );
          },
          setCellProps: (_, rowIdx) => ({
            style: {
              background: conditionalFormattingCreateBackground(
                getColumnBgColors(rowIdx, CarTableColumn.NumAuctions, tableData, conditionalFormattings),
              ),
            },
          }),
        },
      },
      {
        ...getColumnDeclaration(CarTableColumn.BaseFixedOrientationPrice),
        ...(bidTableType === 'BoughtBidsTable' ? { label: t('car.prices.purchasePrice') } : {}),
        options: {
          customHeadLabelRender: ({ label, name }: CustomHeadLabelRenderOptions) => (
            <CarsTableAnnotatedHeadLabelCell
              label={label || name}
              hint={
                <>
                  {`${t('car.prices.priceOrientation_abbr')}: ${t('car.prices.priceOrientation')}`}
                  <br />
                  {`${t('car.prices.fixedPrice_abbr')}: ${t('car.prices.fixedPrice')}`}
                  <br />
                  {`${t('car.prices.startingPrice_abbr')}: ${t('car.prices.startingPrice')}`}
                </>
              }
              conditionalFormattings={[
                findConditionalFormattingDetail(ConditionalFormattingType.PotentialBase, conditionalFormattings),
                findConditionalFormattingDetail(ConditionalFormattingType.TaxType, conditionalFormattings),
              ]}
              potentialPriceType={PriceType.OfferPrice}
            />
          ),
          customBodyRenderLite: (dataIndex) => (
            <BaseFixedOrientationPriceCell car={tableData[dataIndex]} bidTableType={bidTableType} />
          ),
          setCellProps: (_, rowIdx) => ({
            className: clsx(classes.numberCell, classes.cellBoldValues),
            style: {
              background: conditionalFormattingCreateBackground(
                getColumnBgColors(rowIdx, CarTableColumn.BaseFixedOrientationPrice, tableData, conditionalFormattings),
              ),
            },
          }),
        },
      },
      {
        ...getColumnDeclaration(CarTableColumn.PurchaseNetWholesaleUnrepaired),
        options: {
          display: false,
          customBodyRenderLite: (dataIndex) => (
            <CarsPotentialValues
              car={tableData[dataIndex]}
              fixedVariable={PriceType.PurchaseNetWholesaleUnrepaired}
              targetKey="purchaseNetWholesaleUnrepaired"
            />
          ),
          setCellProps: (_, rowIdx) => ({
            className: classes.numberCell,
            style: {
              background: conditionalFormattingCreateBackground(
                getColumnBgColors(
                  rowIdx,
                  CarTableColumn.PurchaseNetWholesaleUnrepaired,
                  tableData,
                  conditionalFormattings,
                ),
              ),
            },
          }),
          customHeadLabelRender: ({ label, name }: CustomHeadLabelRenderOptions) => (
            <CarsTableAnnotatedHeadLabelCell
              label={label || name}
              hint={t('carsTable.hints.purchaseNetUnrepaired')}
              potentialPriceType={PriceType.PurchaseNetWholesaleUnrepaired}
              conditionalFormattings={[
                findConditionalFormattingDetail(
                  ConditionalFormattingType.PurchaseNetUnrepaired,
                  conditionalFormattings,
                ),
              ]}
            />
          ),
        },
      },
      {
        ...getColumnDeclaration(CarTableColumn.PurchaseNetRetailRepaired),
        options: {
          display: false,
          customHeadLabelRender: ({ label, name }: CustomHeadLabelRenderOptions) => (
            <CarsTableAnnotatedHeadLabelCell
              label={label || name}
              hint={
                <>
                  {`${t('configuration.repairQuote_abbr')}: ${t('configuration.repairQuote')}`}
                  <br />
                  {`${t('configuration.fixedCosts_abbr')}: ${t('configuration.fixedCosts')}`}
                  <br />
                  {`${t('configuration.contribMargin_abbr')}: ${t('configuration.contribMargin')}`}
                  <br />
                  <br />
                  {t('configuration.basedOnInternalConfiguration')}
                </>
              }
              conditionalFormattings={[
                findConditionalFormattingDetail(ConditionalFormattingType.PurchaseNetRepaired, conditionalFormattings),
              ]}
              potentialPriceType={PriceType.PurchaseNetRetailRepaired}
            />
          ),
          customBodyRenderLite: (dataIndex) => (
            <CarsPotentialValues
              car={tableData[dataIndex]}
              fixedVariable={PriceType.PurchaseNetRetailRepaired}
              targetKey="purchaseNetRetailRepaired"
            />
          ),
          setCellProps: (_, rowIdx) => ({
            className: classes.numberCell,
            style: {
              background: conditionalFormattingCreateBackground(
                getColumnBgColors(rowIdx, CarTableColumn.PurchaseNetRetailRepaired, tableData, conditionalFormattings),
              ),
            },
          }),
        },
      },
      {
        ...getColumnDeclaration(CarTableColumn.PurchaseGross),
        options: {
          customBodyRenderLite: (dataIndex) =>
            tableData[dataIndex].potential?.purchaseGross ? (
              <CarsPotentialValues
                car={tableData[dataIndex]}
                fixedVariable={PriceType.PurchaseGross}
                targetKey="purchaseGross"
              />
            ) : (
              <PurchaseGrossFallback />
            ),
          customHeadLabelRender: ({ label, name }: CustomHeadLabelRenderOptions) => (
            <CarsTableAnnotatedHeadLabelCell
              label={label || name}
              min={purchasePriceGrossMin && format(purchasePriceGrossMin, {})}
              max={purchasePriceGrossMax && format(purchasePriceGrossMax, {})}
              hint={<PurchaseGrossHint />}
              singleLine
              conditionalFormattings={[
                findConditionalFormattingDetail(ConditionalFormattingType.PurchaseGross, conditionalFormattings),
              ]}
              potentialPriceType={PriceType.PurchaseGross}
            />
          ),
          setCellProps: (_, rowIdx) => ({
            className: clsx(classes.cellBoldValues, classes.numberCell),
            style: {
              background: conditionalFormattingCreateBackground(
                getColumnBgColors(rowIdx, CarTableColumn.PurchaseGross, tableData, conditionalFormattings),
              ),
            },
          }),
        },
      },
      createRecommendedRetailPriceColumn(),
      createRecommendedRetailPriceColumn('Absolute'),
      createRecommendedRetailPriceColumn('Relative'),
      {
        ...getColumnDeclaration(CarTableColumn.CarCulatorLink),
        options: {
          draggable: false,
          customHeadLabelRender: () => <CarCulatorHeaderCell label={t('carsTable.carCulatorLink')} />,
          customBodyRenderLite: (dataIndex) => <CarsTableCarCulatorLinkCell car={tableData[dataIndex]} />,
          setCellHeaderProps: () => ({
            className: clsx(classes.cellDivider, `${classes.cellHeader}--small`, classes.cellHighlightTop),
          }),
          setCellProps: () => ({ className: classes.cellDivider }),
          sort: false,
        },
      },
      {
        ...getColumnDeclaration(CarTableColumn.MarketPrice),
        options: {
          customHeadLabelRender: ({ label, name }: CustomHeadLabelRenderOptions) => (
            <CarsTableAnnotatedHeadLabelCell
              label={label || name}
              hint={t('carsTable.hints.marketPrice')}
              conditionalFormattings={[
                findConditionalFormattingDetail(ConditionalFormattingType.ValuationMarketPrice, conditionalFormattings),
              ]}
            />
          ),
          customBodyRenderLite: (dataIndex) => (
            <CarsTableMarketPrice
              value={tableData[dataIndex].valuation?.price?.mean}
              valuationCountry={tableData[dataIndex]?.potential?.country}
            />
          ),
          setCellHeaderProps: () => ({
            className: classes.cellHighlightTop,
          }),
          setCellProps: (_, rowIdx) => ({
            className: classes.numberCell,
            style: {
              background: conditionalFormattingCreateBackground(
                getColumnBgColors(rowIdx, CarTableColumn.MarketPrice, tableData, conditionalFormattings),
              ),
            },
          }),
        },
      },
      {
        ...getColumnDeclaration(CarTableColumn.PotentialMeanAbsolute),
        options: {
          sortDescFirst: true,
          customHeadLabelRender: ({ label, name }: CustomHeadLabelRenderOptions) => (
            <CarsTableAnnotatedHeadLabelCell
              label={label || name}
              hint={t('carsTable.hints.potentialInternetPriceAverageAbsolute')}
              conditionalFormattings={[
                findConditionalFormattingDetail(
                  ConditionalFormattingType.PotentialInternetPriceAverageAbsolute,
                  conditionalFormattings,
                ),
              ]}
              potentialPriceType={PriceType.PotentialMeanAbsolute}
            />
          ),
          customBodyRenderLite: (dataIndex) => (
            <CarsPotentialValues
              car={tableData[dataIndex]}
              fixedVariable={PriceType.PotentialMeanAbsolute}
              targetKey="potentialMeanAbsolute"
            />
          ),
          setCellHeaderProps: () => ({
            className: classes.cellHighlightTop,
          }),
          setCellProps: (_, rowIdx) => ({
            className: classes.numberCell,
            style: {
              background: conditionalFormattingCreateBackground(
                getColumnBgColors(rowIdx, CarTableColumn.PotentialMeanAbsolute, tableData, conditionalFormattings),
              ),
            },
          }),
        },
      },
      {
        ...getColumnDeclaration(CarTableColumn.PotentialMeanRelative),
        options: {
          sortDescFirst: true,
          customHeadLabelRender: ({ label, name }: CustomHeadLabelRenderOptions) => (
            <CarsTableAnnotatedHeadLabelCell
              label={label || name}
              hint={t('carsTable.hints.potentialInternetPriceAveragePercent')}
              conditionalFormattings={[
                findConditionalFormattingDetail(
                  ConditionalFormattingType.PotentialInternetPriceAveragePercent,
                  conditionalFormattings,
                ),
              ]}
              potentialPriceType={PriceType.PotentialMeanRelative}
            />
          ),
          customBodyRenderLite: (dataIndex) => (
            <CarsPotentialValues
              car={tableData[dataIndex]}
              fixedVariable={PriceType.PotentialMeanRelative}
              targetKey="potentialMeanRelative"
              unit="%"
            />
          ),
          setCellHeaderProps: () => ({
            className: classes.cellHighlightTop,
          }),
          setCellProps: (_, rowIdx) => ({
            className: classes.numberCell,
            style: {
              background: conditionalFormattingCreateBackground(
                getColumnBgColors(rowIdx, CarTableColumn.PotentialMeanRelative, tableData, conditionalFormattings),
              ),
            },
          }),
        },
      },
      {
        ...getColumnDeclaration(CarTableColumn.PotentialMinAbsolute),
        options: {
          sortDescFirst: true,
          customHeadLabelRender: ({ label, name }: CustomHeadLabelRenderOptions) => (
            <CarsTableAnnotatedHeadLabelCell
              label={label || name}
              hint={t('carsTable.hints.potentialInternetPriceMinAbsolute')}
              conditionalFormattings={[
                findConditionalFormattingDetail(
                  ConditionalFormattingType.PotentialInternetPriceMinAbsolute,
                  conditionalFormattings,
                ),
              ]}
              potentialPriceType={PriceType.PotentialMinAbsolute}
            />
          ),
          customBodyRenderLite: (dataIndex) => (
            <CarsPotentialValues
              car={tableData[dataIndex]}
              fixedVariable={PriceType.PotentialMinAbsolute}
              targetKey="potentialMinAbsolute"
            />
          ),
          setCellHeaderProps: () => ({
            className: classes.cellHighlightTop,
          }),
          setCellProps: (_, rowIdx) => ({
            className: classes.numberCell,
            style: {
              background: conditionalFormattingCreateBackground(
                getColumnBgColors(rowIdx, CarTableColumn.PotentialMinAbsolute, tableData, conditionalFormattings),
              ),
            },
          }),
        },
      },
      {
        ...getColumnDeclaration(CarTableColumn.PotentialMinRelative),
        options: {
          sortDescFirst: true,
          customHeadLabelRender: ({ label, name }: CustomHeadLabelRenderOptions) => (
            <CarsTableAnnotatedHeadLabelCell
              label={label || name}
              hint={t('carsTable.hints.potentialInternetPriceMinPercent')}
              conditionalFormattings={[
                findConditionalFormattingDetail(
                  ConditionalFormattingType.PotentialInternetPriceMinPercent,
                  conditionalFormattings,
                ),
              ]}
              potentialPriceType={PriceType.PotentialMinRelative}
            />
          ),
          customBodyRenderLite: (dataIndex) => (
            <CarsPotentialValues
              car={tableData[dataIndex]}
              fixedVariable={PriceType.PotentialMinRelative}
              targetKey="potentialMinRelative"
              unit="%"
            />
          ),
          setCellHeaderProps: () => ({
            className: classes.cellHighlightTop,
          }),
          setCellProps: (_, rowIdx) => ({
            className: classes.numberCell,
            style: {
              background: conditionalFormattingCreateBackground(
                getColumnBgColors(rowIdx, CarTableColumn.PotentialMinRelative, tableData, conditionalFormattings),
              ),
            },
          }),
        },
      },
      {
        ...getColumnDeclaration(CarTableColumn.PotentialMinMeanAbsolute),
        options: {
          display: false,
          sortDescFirst: true,
          customHeadLabelRender: ({ label, name }: CustomHeadLabelRenderOptions) => (
            <CarsTableAnnotatedHeadLabelCell
              label={label || name}
              hint={t('carsTable.hints.potentialInternetPriceMinAverageAbsolute')}
              conditionalFormattings={[
                findConditionalFormattingDetail(
                  ConditionalFormattingType.PotentialInternetPriceMinAverageAbsolute,
                  conditionalFormattings,
                ),
              ]}
              potentialPriceType={PriceType.PotentialMinMeanAbsolute}
            />
          ),
          customBodyRenderLite: (dataIndex) => (
            <CarsPotentialValues
              car={tableData[dataIndex]}
              fixedVariable={PriceType.PotentialMinMeanAbsolute}
              targetKey="potentialMinMeanAbsolute"
            />
          ),
          setCellHeaderProps: () => ({
            className: classes.cellHighlightTop,
          }),
          setCellProps: (_, rowIdx) => ({
            className: classes.numberCell,
            style: {
              background: conditionalFormattingCreateBackground(
                getColumnBgColors(rowIdx, CarTableColumn.PotentialMinMeanAbsolute, tableData, conditionalFormattings),
              ),
            },
          }),
        },
      },
      {
        ...getColumnDeclaration(CarTableColumn.PotentialMinMeanRelative),
        options: {
          display: false,
          sortDescFirst: true,
          customHeadLabelRender: ({ label, name }: CustomHeadLabelRenderOptions) => (
            <CarsTableAnnotatedHeadLabelCell
              label={label || name}
              hint={t('carsTable.hints.potentialInternetPriceMinAveragePercent')}
              conditionalFormattings={[
                findConditionalFormattingDetail(
                  ConditionalFormattingType.PotentialInternetPriceMinAveragePercent,
                  conditionalFormattings,
                ),
              ]}
              potentialPriceType={PriceType.PotentialMinMeanRelative}
            />
          ),
          customBodyRenderLite: (dataIndex) => (
            <CarsPotentialValues
              car={tableData[dataIndex]}
              fixedVariable={PriceType.PotentialMinMeanRelative}
              targetKey="potentialMinMeanRelative"
              unit="%"
            />
          ),
          setCellHeaderProps: () => ({
            className: classes.cellHighlightTop,
          }),
          setCellProps: (_, rowIdx) => ({
            className: classes.numberCell,
            style: {
              background: conditionalFormattingCreateBackground(
                getColumnBgColors(rowIdx, CarTableColumn.PotentialMinMeanRelative, tableData, conditionalFormattings),
              ),
            },
          }),
        },
      },
    ];

    return carsColumns;
  };

  const getCarBidsColumns = (tableData: (ListCarDTO & BidDTO)[], bidsTableType?: BidTableType) => {
    const carBidsColumns: MUIDataTableColumn[] = [
      {
        ...getColumnDeclaration(BidTableColumnTypes.Bids),
        options: {
          customBodyRenderLite: (dataIndex) => (
            <CarsTableBidsCell bids={tableData[dataIndex]?.bids} bidInfos={tableData[dataIndex]} />
          ),
        },
      },
      {
        ...getColumnDeclaration(BidTableColumnTypes.BidsBidSuccessStatus),
        options: {
          display: bidsTableType === 'PastBidsTable' ? 'true' : 'excluded',
          customBodyRenderLite: (dataIndex) => <CarsTableBidSuccessStatus bid={tableData[dataIndex]} />,
        },
      },
      {
        ...getColumnDeclaration(BidTableColumnTypes.BidsBuyer),
        options: {
          display: bidsTableType === 'BoughtBidsTable' ? false : 'excluded',
          customBodyRenderLite: (dataIndex) => <CarsTableBidsBuyer bids={tableData[dataIndex]?.bids} />,
        },
      },
      {
        ...getColumnDeclaration(BidTableColumnTypes.BidsCreated),
        options: {
          display: false,
          customBodyRenderLite: (dataIndex) => {
            const date = tableData[dataIndex]?.created;
            return date ? formatDate(date, BID_DATE_TIME_FORMAT) : '';
          },
        },
      },
      {
        ...getColumnDeclaration(BidTableColumnTypes.BidsBiddingTime),
        options: {
          display: bidsTableType === 'PendingBidsTable',
          customBodyRenderLite: (dataIndex) =>
            sheduledOrPromt(tableData[dataIndex]?.created, tableData[dataIndex]?.biddingTime),
        },
      },
      {
        ...getColumnDeclaration(BidTableColumnTypes.BidsSentTime),
        options: {
          display: bidsTableType === 'PendingBidsTable' ? 'excluded' : false,
          customBodyRenderLite: (dataIndex) => {
            const date = tableData[dataIndex]?.sentTime;
            return date ? formatDate(date, BID_DATE_TIME_FORMAT) : '';
          },
        },
      },
      {
        ...getColumnDeclaration(BidTableColumnTypes.BidsBuyingTime),
        options: {
          display: bidsTableType === 'BoughtBidsTable' ? true : 'excluded',
          customBodyRenderLite: (dataIndex) => {
            const date = tableData[dataIndex]?.boughtTime;
            return date ? formatDate(date, DATE_TIME_FORMAT_NO_TIME) : '';
          },
        },
      },
    ];
    return carBidsColumns;
  };

  return { getCarsTableColumns, getCarBidsColumns, getColumnBgColors };
};
export default useCarsTableColumns;
