import { flagsConfig } from '../flags';

export const useFeatureFlags = (flag: string): boolean => {
  // get the environment by checking the url
  let env: 'development' | 'qa' | 'production';
  switch (true) {
    case window.location.hostname.includes('localhost'):
      env = 'development';
      break;
    case window.location.hostname.includes('dev'):
      env = 'development';
      break;
    case window.location.hostname.includes('qa'):
      env = 'qa';
      break;
    default:
      env = 'production';
      break;
  }
  const flagValue = flagsConfig[flag as keyof typeof flagsConfig][env];

  return flagValue;
};
