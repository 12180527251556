import PageContainer from '@components/PageContainer';
import TermsAndConditionsForm from 'src/containers/TermsAndConditionsForm';
import { RouteComponentProps } from '@reach/router';
import { PageMeta } from '@components/page-meta';

const TermsAndConditionsPage = (_props: RouteComponentProps) => (
  <>
    <PageMeta title="termsAndConditions.termsAndConditions" />
    <PageContainer>
      <TermsAndConditionsForm />
    </PageContainer>
  </>
);

export default TermsAndConditionsPage;
