export const currencyFormat = new Intl.NumberFormat('de-AT', {
  style: 'currency',
  currency: 'EUR',
  maximumFractionDigits: 0,
});

export const percentFormat = new Intl.NumberFormat('de-AT', {
  style: 'percent',
  maximumFractionDigits: 0,
});

export const numberFormat = new Intl.NumberFormat('de-AT', {
  style: 'decimal',
});

export const formatMileage = (value: number): string => `${numberFormat.format(Math.round(value))} km`;

export const formatCO2 = (value: number, approx?: boolean): string =>
  `${approx ? '~' : ''}${numberFormat.format(Math.round(value))} g/km`;

export const formatIntPercentage = (value: number): string => percentFormat.format(value / 100);
