import { RoutePaths } from 'src/constants';
import UserRole from '../types/UserRoles';
import {
  BidSuccessStatus,
  ConditionalFormattingType,
  CountryCode,
  ValuationCountryCode,
  WizardStep,
} from './generated/api';
import { CarTableColumn } from './table-data';
import { getAccountValuationCountry } from './valuation-country-helpers';

export const SHIPPING_COUNTRIES_COUNTRY_CODES: Array<string> = [
  'AL',
  'AT',
  'BA',
  'BE',
  'BG',
  'BY',
  'CH',
  'CY',
  'CZ',
  'DE',
  'DK',
  'EE',
  'ES',
  'FI',
  'FR',
  'GB',
  'GR',
  'HR',
  'HU',
  'IE',
  'IT',
  'LT',
  'LU',
  'LV',
  'MK',
  'MT',
  'NL',
  'NO',
  'PL',
  'PT',
  'RO',
  'SE',
  'SI',
  'SK',
  'UA',
  'XK',
];
export const COUNTRY_ORIGIN_FILTER_COUNTRIES: Array<string> = [
  'AL',
  'AT',
  'BE',
  'BG',
  'CH',
  'CZ',
  'DE',
  'DK',
  'ES',
  'FR',
  'HR',
  'HU',
  'IT',
  'LU',
  'MK',
  'NL',
  'PL',
  'PT',
  'RO',
  'SE',
  'SI',
  'SK',
  'XK',
];

export const DEFAULT_VALUATION_COUNTRY = getAccountValuationCountry() || ValuationCountryCode.AtLot;
export const DEFAULT_VALUATION_TYPE = null;

const iconPaths: Record<IconType, string> = {
  COLOR: 'colors',
  COUNTRY: 'countries',
  EQUIPMENT: 'equipments',
  DAMAGE_TYPE: 'damage-types',
  ASSESSMENT: 'assessments',
  LANGUAGE: 'languages',
  PROMOTION: 'promotions',
  EXTERNAL_PORTAL: 'external-portals',
  VIRTUAL_SOURCE: 'virtual-sources',
  SPECIAL: 'special',
};

export enum IconType {
  ASSESSMENT = 'ASSESSMENT',
  COLOR = 'COLOR',
  COUNTRY = 'COUNTRY',
  EQUIPMENT = 'EQUIPMENT',
  DAMAGE_TYPE = 'DAMAGE_TYPE',
  LANGUAGE = 'LANGUAGE',
  PROMOTION = 'PROMOTION',
  EXTERNAL_PORTAL = 'EXTERNAL_PORTAL',
  VIRTUAL_SOURCE = 'VIRTUAL_SOURCE',
  SPECIAL = 'SPECIAL',
}

export const getIcon = (type: IconType, option: string) => {
  const suffix = type === IconType.DAMAGE_TYPE || type === IconType.ASSESSMENT ? 'svg' : 'png';
  // Take same year goal icons except for JEZ22014
  const yearlyPurchaseGoal =
    type === IconType.PROMOTION && option.startsWith('JEZ') && option !== 'JEZ22014' ? 'JEZ' : undefined;

  return `/images/${iconPaths[type]}/${yearlyPurchaseGoal || option}.${suffix}`;
};

export const GlobalUnitMap: Record<string, string> = {
  [ConditionalFormattingType.Mileage]: 'km',
  [ConditionalFormattingType.PriceDamage]: 'EUR',
  [ConditionalFormattingType.Nova]: '%',
  [ConditionalFormattingType.Co2]: 'g',
  [ConditionalFormattingType.DateOnlineTimeInMinutes]: 'Min.',
  [ConditionalFormattingType.PotentialBase]: 'EUR',
  [ConditionalFormattingType.PurchaseNetUnrepaired]: 'EUR',
  [ConditionalFormattingType.PurchaseNetRepaired]: 'EUR',
  [ConditionalFormattingType.PurchaseGross]: 'EUR',
  [ConditionalFormattingType.ValuationMarketPrice]: 'EUR',
  [ConditionalFormattingType.PotentialInternetPriceMinAbsolute]: 'EUR',
  [ConditionalFormattingType.PotentialInternetPriceMinPercent]: '%',
  [ConditionalFormattingType.PotentialInternetPriceMinAverageAbsolute]: 'EUR',
  [ConditionalFormattingType.PotentialInternetPriceMinAveragePercent]: '%',
  [ConditionalFormattingType.PotentialInternetPriceAverageAbsolute]: 'EUR',
  [ConditionalFormattingType.PotentialInternetPriceAveragePercent]: '%',
};

export const VIN_PREFIXES: Array<string> = [
  '19U',
  '1B',
  '1C',
  '1F',
  '1G',
  '1G1',
  '1G3',
  '1G4',
  '1G9',
  '1GB',
  '1GC',
  '1GD',
  '1GM',
  '1HG',
  '1J',
  '1L',
  '1M',
  '1MR',
  '1N',
  '1VW',
  '1YV',
  '1ZV',
  '2DG',
  '2F',
  '2G1',
  '2G2',
  '2G9',
  '2Gx',
  '2HG',
  '2HH',
  '2HJ',
  '2HK',
  '2HM',
  '2L9',
  '2LN',
  '2M',
  '2T',
  '3F',
  '3G',
  '3HG',
  '3HM',
  '3KP',
  '3N',
  '3VW',
  '4F',
  '4J',
  '4M',
  '4S2',
  '4S3',
  '4S4',
  '4S6',
  '4T',
  '4US',
  '55',
  '5FN',
  '5FR',
  '5J6',
  '5L',
  '5N1',
  '5NM',
  '5NP',
  '5T',
  '5U',
  '5X',
  '5YJ',
  '6F',
  '6G',
  '6G1',
  '6G2',
  '6H',
  '6MM',
  '6T1',
  '6T9',
  '6U9',
  '7A1',
  '7A3',
  '7A4',
  '7A5',
  '7A8',
  '7AT',
  '7FA',
  '7JR',
  '829',
  '8A1',
  '8AC',
  '8AD',
  '8AF',
  '8AG',
  '8AJ',
  '8AN',
  '8AP',
  '8AT',
  '8AW',
  '8BB',
  '8BC',
  '8C3',
  '8G1',
  '8GD',
  '8GG',
  '8LB',
  '8LF',
  '8LG',
  '8Z1',
  '935',
  '936',
  '93H',
  '93P',
  '93V',
  '93W',
  '93X',
  '93Y',
  '93Z',
  '94D',
  '94G',
  '94M',
  '94N',
  '953',
  '95P',
  '988',
  '98M',
  '98R',
  '99A',
  '99J',
  '99L',
  '9AM',
  '9BD',
  '9BF',
  '9BG',
  '9BH',
  '9BM',
  '9BN',
  '9BR',
  '9BS',
  '9BV',
  '9BW',
  '9BY',
  '9C2',
  '9C6',
  '9CD',
  '9FB',
  '9FC',
  '9GC',
  '9UJ',
  '9UK',
  '9UW',
  'AAV',
  'ADM',
  'ADN',
  'AFA',
  'AHT',
  'BF9',
  'CL9',
  'DA1',
  'DA4',
  'DAA',
  'DAB',
  'J8B',
  'JA4',
  'JAA',
  'JAL',
  'JAM',
  'JC1',
  'JD1',
  'JDA',
  'JF1',
  'JF2',
  'JF3',
  'JF4',
  'JF5',
  'JHD',
  'JHF',
  'JHH',
  'JHL',
  'JHM',
  'JL5',
  'JM0',
  'JM1',
  'JM6',
  'JMB',
  'JN',
  'JS',
  'JT',
  'JY',
  'KL1',
  'KL2',
  'KLA',
  'KMA',
  'KMH',
  'KNA',
  'KNC',
  'KNE',
  'KNM',
  'KPT',
  'L1C',
  'L2C',
  'L6T',
  'LA6',
  'LB3',
  'LBE',
  'LBV',
  'LC0',
  'LDC',
  'LDN',
  'LE4',
  'LFA',
  'LFM',
  'LFP',
  'LFV',
  'LGB',
  'LGJ',
  'LGW',
  'LGX',
  'LH1',
  'LHG',
  'LJ1',
  'LJD',
  'LJU',
  'LLV',
  'LMG',
  'LPA',
  'LPS',
  'LRW',
  'LS5',
  'LSF',
  'LSG',
  'LSJ',
  'LSV',
  'LTV',
  'LUX',
  'LVG',
  'LVH',
  'LVR',
  'LVS',
  'LVV',
  'LWV',
  'LZW',
  'LZY',
  'MA1',
  'MA3',
  'MA6',
  'MA7',
  'MAJ',
  'MAK',
  'MAL',
  'MAT',
  'MB1',
  'MBH',
  'MBJ',
  'MBK',
  'MBU',
  'MBV',
  'MBY',
  'MC1',
  'MC2',
  'MC4',
  'MCA',
  'MCB',
  'MCL',
  'MD2',
  'MDH',
  'MEC',
  'MEE',
  'MH1',
  'MLH',
  'MM0',
  'MM8',
  'MMB',
  'MMF',
  'MMH',
  'MMK',
  'MML',
  'MMM',
  'MMR',
  'MMS',
  'MMT',
  'MNB',
  'MNK',
  'MNT',
  'MP5',
  'MPA',
  'MPB',
  'MR0',
  'MR2',
  'MRH',
  'MS0',
  'NAA',
  'NAD',
  'NAG',
  'NAP',
  'NFB',
  'NLC',
  'NM0',
  'NM1',
  'NM4',
  'NMA',
  'NMT',
  'PAB',
  'PL1',
  'PL8',
  'PLP',
  'PLZ',
  'PM1',
  'PM2',
  'PM9',
  'PMH',
  'PMK',
  'PML',
  'PMN',
  'PMV',
  'PN1',
  'PN2',
  'PN8',
  'PNA',
  'PNA',
  'PNV',
  'PP1',
  'PP3',
  'PPP',
  'PPV',
  'PR8',
  'PRA',
  'PRH',
  'RF5',
  'RFD',
  'RHA',
  'RKL',
  'RLE',
  'SAB',
  'SAJ',
  'SAL',
  'SAR',
  'SAT',
  'SB1',
  'SBM',
  'SC6',
  'SCC',
  'SCE',
  'SCF',
  'SCK',
  'SDB',
  'SFD',
  'SFE',
  'SHH',
  'SHS',
  'SJK',
  'SJN',
  'SUD',
  'SUP',
  'TCC',
  'TEB',
  'TMA',
  'TMB',
  'TRU',
  'TSM',
  'TW8',
  'U5Y',
  'UU',
  'VA0',
  'VBK',
  'VF1',
  'VF2',
  'VF3',
  'VF4',
  'VF5',
  'VF6',
  'VF7',
  'VF8',
  'VF9',
  'VFE',
  'VNK',
  'VR1',
  'VS7',
  'VSE',
  'VSK',
  'VSS',
  'VSX',
  'VV9',
  'VX1',
  'W08',
  'W09',
  'W0L',
  'W0SV',
  'WAC',
  'WAG',
  'WAP',
  'WAU',
  'WBA',
  'WBS',
  'WBX',
  'WDB',
  'WDC',
  'WDD',
  'WEB',
  'WF0',
  'WJM',
  'WJR',
  'WKK',
  'WMA',
  'WME',
  'WMW',
  'WMX',
  'WP0',
  'WP1',
  'WUA',
  'WV1',
  'WV2',
  'WVG',
  'WVW',
  'XLR',
  'XTA',
  'XTB',
  'XU1',
  'XUF',
  'XWB',
  'Y6D',
  'YK1',
  'YS2',
  'YS3',
  'YS4',
  'YT9',
  'YTN',
  'YV1',
  'YV2',
  'YV3',
  'ZA9',
  'ZAA',
  'ZAM',
  'ZAP',
  'ZAR',
  'ZCF',
  'ZFA',
  'ZFF',
  'ZGA',
  'ZHW',
  'ZLA',
];

export const EXPORT_PRESETS = [
  [CarTableColumn.startDateAndId, CarTableColumn.DateEnd, CarTableColumn.Model, CarTableColumn.Highlights],
];

export const AuthProviders = {
  grp: 'GRP',
  patnerNet: 'PARTNER_NET',
};

export const ConfigNavigationItems = [
  {
    title: 'navigation.searchagent',
    to: RoutePaths.searchAgents,
    role: [UserRole.ADMIN, UserRole.COUNTRYMANAGER, UserRole.DEALER, UserRole.DEALERADMIN],
  },
  {
    title: 'navigation.cost',
    to: RoutePaths.costs,
    role: [UserRole.ADMIN, UserRole.COUNTRYMANAGER, UserRole.DEALER, UserRole.DEALERADMIN],
    wizardKey: WizardStep.Costs,
  },
  {
    title: 'navigation.formatting',
    to: RoutePaths.formats,
    role: [UserRole.ADMIN, UserRole.COUNTRYMANAGER, UserRole.DEALER, UserRole.DEALERADMIN],
    wizardKey: WizardStep.ConditionalFormatting,
  },
  {
    title: 'navigation.delivery',
    to: RoutePaths.deliveryAddress,
    role: [UserRole.ADMIN, UserRole.COUNTRYMANAGER, UserRole.DEALER, UserRole.DEALERADMIN],
    wizardKey: WizardStep.DealerAddress,
  },
  {
    title: 'navigation.detailing',
    to: RoutePaths.detailingAddress,
    role: [UserRole.ADMIN],
  },
  {
    title: 'navigation.dealerAdministration',
    to: RoutePaths.dealers,
    role: [UserRole.ADMIN],
  },
  {
    title: 'navigation.dealerManagement',
    to: RoutePaths.credentials,
    role: [UserRole.ADMIN, UserRole.COUNTRYMANAGER, UserRole.DEALER, UserRole.DEALERADMIN],
    wizardKey: WizardStep.DealerAdministration,
    hideForCountries: [CountryCode.At, CountryCode.It, CountryCode.Pt],
    ignoreCountryBlacklist: [UserRole.ADMIN],
  },
  {
    title: 'navigation.packetService',
    to: RoutePaths.packets,
    role: [UserRole.ADMIN],
  },
  {
    title: 'navigation.experiments',
    to: RoutePaths.experiments,
    role: [UserRole.ADMIN],
  },
];

export type ExtendedBidSuccessStatus = BidSuccessStatus | 'NOT_AVAILABlE' | 'RESERVED';

export const bidSuccessStatus: {
  [key in ExtendedBidSuccessStatus]: {
    severity: 'primary' | 'secondary' | 'default' | 'error' | 'success' | 'failed';
    label: string;
  };
} = {
  [BidSuccessStatus.Bought]: {
    severity: 'success',
    label: 'bids.status.bought',
  },
  [BidSuccessStatus.Highest]: {
    severity: 'primary',
    label: 'bids.status.highest',
  },
  [BidSuccessStatus.Outbid]: {
    severity: 'secondary',
    label: 'bids.status.outbid',
  },
  [BidSuccessStatus.Error]: {
    severity: 'error',
    label: 'bids.status.error',
  },
  [BidSuccessStatus.Sent]: {
    severity: 'primary',
    label: 'bids.status.sent',
  },
  [BidSuccessStatus.NotSent]: {
    severity: 'secondary',
    label: 'bids.status.notSent',
  },
  [BidSuccessStatus.Scheduled]: {
    severity: 'primary',
    label: 'bids.status.scheduled',
  },
  [BidSuccessStatus.Cancelled]: {
    severity: 'secondary',
    label: 'bids.status.cancelled',
  },
  [BidSuccessStatus.Allocate]: {
    severity: 'secondary',
    label: 'bids.status.allocate',
  },
  RESERVED: {
    severity: 'failed',
    label: 'bids.status.reserved',
  },
  NOT_AVAILABlE: {
    severity: 'failed',
    label: 'bids.status.notAvailable',
  },
  [BidSuccessStatus.Deleted]: {
    severity: 'secondary',
    label: 'bids.status.deleted',
  },
};

export const obsoleteSearchAgentFields = ['brands', 'modelGroups', 'engineFuel'];

export const valuationCountryToTaxCol: Record<ValuationCountryCode, CarTableColumn> = {
  [ValuationCountryCode.At]: CarTableColumn.TaxesAt,
  [ValuationCountryCode.AtLot]: CarTableColumn.TaxesAtLot,
  [ValuationCountryCode.De]: CarTableColumn.TaxesDe,
  [ValuationCountryCode.Fr]: CarTableColumn.TaxesFr,
  [ValuationCountryCode.Es]: CarTableColumn.TaxesEs,
  [ValuationCountryCode.It]: CarTableColumn.TaxesIt,
  [ValuationCountryCode.Cz]: CarTableColumn.TaxesCz,
  [ValuationCountryCode.Pl]: CarTableColumn.TaxesPl,
  [ValuationCountryCode.Hu]: CarTableColumn.TaxesHu,
  [ValuationCountryCode.Ro]: CarTableColumn.TaxesRo,
  [ValuationCountryCode.Si]: CarTableColumn.TaxesSi,
  [ValuationCountryCode.Hr]: CarTableColumn.TaxesHr,
  [ValuationCountryCode.Sk]: CarTableColumn.TaxesSk,
  [ValuationCountryCode.Pt]: CarTableColumn.TaxesPt,
  [ValuationCountryCode.Se]: CarTableColumn.TaxesSe,
  [ValuationCountryCode.Bg]: CarTableColumn.TaxesBg,
  [ValuationCountryCode.Mk]: CarTableColumn.TaxesMk,
  [ValuationCountryCode.Al]: CarTableColumn.TaxesAl,
  [ValuationCountryCode.Xk]: CarTableColumn.TaxesXk,
};

// Alphabetically sorted
export const homeBrands = ['AUDI', 'PORSCHE', 'SEAT', 'Skoda', 'VW'];

export const fallbackIcon = '/images/icons/broken_image.png';
