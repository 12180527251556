import { useTranslation } from 'react-i18next';

function PurchaseGrossHint() {
  const { t } = useTranslation();
  return (
    <>
      {`${t('carsTable.hints.purchaseGross')}`}
      <br />
      <br />
      {`${t('configuration.repairQuote_abbr')}: ${t('configuration.repairQuote')}`}
      <br />
      {`${t('configuration.fixedCosts_abbr')}: ${t('configuration.fixedCosts')}`}
      <br />
      {`${t('configuration.contribMargin_abbr')}: ${t('configuration.contribMargin')}`}
      <br />
      <br />
      {t('configuration.basedOnInternalConfiguration')}
    </>
  );
}

export default PurchaseGrossHint;
