import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import { QueryKeys } from 'src/constants';
import ApiService from 'src/modules/api-service';
import { SupportedCurrencyCode } from 'src/modules/currency';

export type ExchangeRates = {
  [k in SupportedCurrencyCode]: {
    [u in SupportedCurrencyCode]: number;
  };
};

// There is a 0.1% chance for the exchange rates to be empty.
// In order to prevent chaos we fallback to legacy conversion factors.
const patchExchangeRates = (exchangeRates: ExchangeRates): ExchangeRates => {
  if (isEmpty(exchangeRates)) {
    // eslint-disable-next-line no-console
    console.warn('No exchange rates available! Fallback applied!');
    return {
      EUR: { EUR: 1.0, RON: 5.0, CZK: 24.3902, SEK: 10.8696, PLN: 4.7619, HUF: 416.6667 },
      RON: { EUR: 0.2, RON: 1.0, CZK: 4.878, SEK: 2.1739, PLN: 0.9524, HUF: 83.3333 },
      CZK: { EUR: 0.041, RON: 0.205, CZK: 1.0, SEK: 0.4457, PLN: 0.1952, HUF: 17.0833 },
      PLN: { EUR: 0.21, RON: 1.05, CZK: 5.122, SEK: 2.2826, PLN: 1.0, HUF: 87.5 },
      HUF: { EUR: 0.0024, RON: 0.012, CZK: 0.0585, SEK: 0.0261, PLN: 0.0114, HUF: 1.0 },
      SEK: { EUR: 0.0909, RON: 0.4483, CZK: 2.2353, SEK: 1.0, PLN: 0.4392, HUF: 38.1376 },
    } as ExchangeRates;
  }
  // Theres also a 0.1% chance for the exchange rates to be incomplete, then you need the default too:
  if (!exchangeRates.EUR || isEmpty(exchangeRates.EUR)) {
    // eslint-disable-next-line no-param-reassign
    exchangeRates.EUR = { EUR: 1.0, RON: 5.0, CZK: 24.3902, SEK: 10.8696, PLN: 4.7619, HUF: 416.6667 };
  }
  if (!exchangeRates.RON || isEmpty(exchangeRates.RON)) {
    // eslint-disable-next-line no-param-reassign
    exchangeRates.RON = { EUR: 0.2, RON: 1.0, CZK: 4.878, SEK: 2.1739, PLN: 0.9524, HUF: 83.3333 };
  }
  if (!exchangeRates.CZK || isEmpty(exchangeRates.CZK)) {
    // eslint-disable-next-line no-param-reassign
    exchangeRates.CZK = { EUR: 0.041, RON: 0.205, CZK: 1.0, SEK: 0.4457, PLN: 0.1952, HUF: 17.0833 };
  }
  if (!exchangeRates.PLN || isEmpty(exchangeRates.PLN)) {
    // eslint-disable-next-line no-param-reassign
    exchangeRates.PLN = { EUR: 0.21, RON: 1.05, CZK: 5.122, SEK: 2.2826, PLN: 1.0, HUF: 87.5 };
  }
  if (!exchangeRates.HUF || isEmpty(exchangeRates.HUF)) {
    // eslint-disable-next-line no-param-reassign
    exchangeRates.HUF = { EUR: 0.0024, RON: 0.012, CZK: 0.0585, SEK: 0.0261, PLN: 0.0114, HUF: 1.0 };
  }
  if (!exchangeRates.SEK || isEmpty(exchangeRates.SEK)) {
    // eslint-disable-next-line no-param-reassign
    exchangeRates.SEK = { EUR: 0.0909, RON: 0.4483, CZK: 2.2353, SEK: 1.0, PLN: 0.4392, HUF: 38.1376 };
  }

  return exchangeRates;
};

export const useExchangeRates = () =>
  useQuery(
    [QueryKeys.exchangeRates],
    () =>
      ApiService.calcPrice
        .priceCalculationControllerGetExchangeRates()
        .then((res) => patchExchangeRates(res.data as ExchangeRates)),
    {
      suspense: true,
      staleTime: 1000 * 60 * 60 * 24, // 1d - exchange rates get updated max once per day
    },
  );
