import { Button, Grid } from '@material-ui/core';
import { Backup, Clear } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import BidsMUIDataTable, { BidsMUIDataTableMode } from './BidsMUIDataTable';
import { CSVBidRequestDTO } from '../modules/generated/api';

type BidsUploadPreviewProps = {
  bids?: CSVBidRequestDTO[];
  recordsTotal?: number;
  onClear: () => void;
  onSubmit: () => void;
};

const BidsUploadPreview = ({ bids, recordsTotal, onClear, onSubmit }: BidsUploadPreviewProps) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2} justifyContent="flex-end">
      <Grid item xs={12}>
        <BidsMUIDataTable mode={BidsMUIDataTableMode.Preview} bids={bids} recordsTotal={recordsTotal} />
      </Grid>
      <Grid item>
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button endIcon={<Clear />} color="secondary" variant="contained" onClick={onClear}>
              {t('bids.discardBids')}
            </Button>
          </Grid>
          <Grid item>
            <Button endIcon={<Backup />} onClick={onSubmit} variant="contained" color="primary">
              {t('bids.submitBids')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BidsUploadPreview;
