import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Co2ApproximationMethod } from '../modules/generated/api';
import InfoTooltip from './InfoTooltip';
import UnitValue from './UnitValue';

const useStyles = makeStyles((theme) => ({
  root: {
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}));

type CarsTableCO2CellProps = {
  approxCo2?: Co2ApproximationMethod;
  engineCo2?: number;
  engineCo2Nedc?: number;
  engineCo2Wltp?: number;
};

const CarsTableCO2Cell = ({ approxCo2, engineCo2, engineCo2Nedc, engineCo2Wltp }: CarsTableCO2CellProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const prefix = approxCo2 && '~';

  return (
    <div className={classes.root}>
      <div>
        {engineCo2Wltp !== undefined && (
          <div>
            {t('carsTable.co2TestingMethod.wltp')}: <UnitValue value={engineCo2Wltp} unit="g/km" prefix={prefix} />
          </div>
        )}
        {engineCo2Nedc !== undefined && (
          <div>
            {t('carsTable.co2TestingMethod.nedc')}: <UnitValue value={engineCo2Nedc} unit="g/km" prefix={prefix} />
          </div>
        )}
        {engineCo2 !== undefined && (
          <div>
            ???: <UnitValue value={engineCo2} unit="g/km" prefix={prefix} />
          </div>
        )}
      </div>
      {approxCo2 !== undefined && <InfoTooltip hint={t('carsTable.hints.approxCo2')} xsIcon />}
    </div>
  );
};

export default CarsTableCO2Cell;
