import countries from 'i18n-iso-countries';
import i18next, { StringMap, TOptions, TOptionsBase } from 'i18next';
import { isObject } from 'lodash';

export const SUPPORTED_LANGUAGES: { [key: string]: string } = {
  en: 'English',
  de: 'Deutsch',
  fr: 'Français',
  es: 'Español',
  it: 'Italiano',
};

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
countries.registerLocale(require('i18n-iso-countries/langs/de.json'));
countries.registerLocale(require('i18n-iso-countries/langs/fr.json'));
countries.registerLocale(require('i18n-iso-countries/langs/es.json'));
countries.registerLocale(require('i18n-iso-countries/langs/it.json'));

// TODO: add proper options typings
export type Option<T = string> = { value: T; label: string; options?: Record<string, any> };

export type TOptionsType = TOptions<TOptionsBase & StringMap> | string;

export const tr = (key: string, options?: TOptionsType) => i18next.t(key, options);

export const isOptionObj = (x: string | number | Option<string | number>): x is Option<number | string> =>
  isObject(x) && 'value' in x;
