import { useRecoilState } from 'recoil';
import { useRef } from 'react';
import { AxiosResponse } from 'axios';
import {
  CurrencyCode,
  PriceType,
  SourceRegisterPotentialDTO,
  SourceType,
  ValuationCountryCode,
} from '../modules/generated/api';
import ApiService from '../modules/api-service';
import { MonetaryAmountStrict } from '../modules/currency';
import { bidPotentialCalculationsState } from '../stores/state';

export type FetchCalculationParams = {
  fixedVariable: PriceType;
  value?: MonetaryAmountStrict;
  valuationCountry?: ValuationCountryCode;
  bidNumber: 1 | 2 | 3;
};

export const useCalculation = (source: SourceType, carId: string) => {
  const [calculations, setCalculations] = useRecoilState(bidPotentialCalculationsState);
  // Handle race conditions
  const lastPromise = useRef<{
    1: Promise<AxiosResponse<SourceRegisterPotentialDTO>> | undefined;
    2: Promise<AxiosResponse<SourceRegisterPotentialDTO>> | undefined;
    3: Promise<AxiosResponse<SourceRegisterPotentialDTO>> | undefined;
  }>({ 1: undefined, 2: undefined, 3: undefined });

  const fetchCalculation = async ({ fixedVariable, value, valuationCountry, bidNumber }: FetchCalculationParams) => {
    const promise = value
      ? ApiService.calcPrice.priceCalculationControllerCalculatePriceV2(
          source,
          carId,
          fixedVariable,
          value.amount,
          value.currency as CurrencyCode,
          valuationCountry,
        )
      : null;
    // Here we persist null in order to track race conditions when clearing the bid input (aka value is undefined)
    lastPromise.current = { ...lastPromise.current, [bidNumber]: promise };
    if (promise === null) return undefined;
    const response = await promise;
    if (lastPromise.current[bidNumber] !== promise) return null; // Outdated request

    setCalculations((prev) => ({ ...prev, [bidNumber]: response.data }));
    return response.data;
  };
  return { calculations, fetchCalculation };
};
