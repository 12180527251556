import { makeStyles } from '@material-ui/core';
import { ReactNode } from 'react';
import type { Merge } from 'type-fest';
import { diffToNow, getIsValidDate, msToMinutes } from '../../../modules/date-helpers';
import { CellProgress } from '../CellProgress';
import { TextCell, TextCellProps } from './TextCell';

export type LeadIconCellProps = {
  icon: ReactNode;
  startDate?: string | number | Date | undefined;
  endDate?: string | number | Date | undefined;
  progressHighlightOffset?: number;
};

const useStyles = makeStyles({
  iconAdornment: {
    display: 'inline-flex',
    float: 'left',
    marginInlineEnd: 12,
  },
});

const minutesToPercent = (offset: number, minutes: number): number => Math.abs((minutes / offset) * 100);

/**
 * Provides an clear entry point for the following row data.
 */
export const LeadIconCell = ({
  isLead = true,
  icon,
  startDate,
  endDate,
  progressHighlightOffset = 30,
  ...rest
}: Merge<TextCellProps, LeadIconCellProps>): JSX.Element => {
  const classes = useStyles();
  const currentUrl = window.location.pathname;
  const isCars = currentUrl.includes('cars'); // only the cars page should show the progress bar

  const dateIsValid = getIsValidDate(startDate) && getIsValidDate(endDate);
  const minutesSinceStart = dateIsValid && Math.abs(msToMinutes(diffToNow(new Date(startDate))));
  const minutesTillEnd = dateIsValid && Math.abs(msToMinutes(diffToNow(new Date(endDate))));
  const showProgress =
    isCars && (minutesSinceStart <= progressHighlightOffset || minutesTillEnd <= progressHighlightOffset);
  const hasEnded = minutesTillEnd < 0;

  const progress =
    minutesTillEnd &&
    minutesSinceStart &&
    (minutesSinceStart <= progressHighlightOffset
      ? 100 - minutesToPercent(progressHighlightOffset, minutesSinceStart) / 3
      : minutesToPercent(progressHighlightOffset, minutesTillEnd) / 3);

  return (
    <>
      <TextCell
        startAdornment={icon ? <div className={classes.iconAdornment}>{icon}</div> : null}
        isLead={isLead}
        {...rest}
      />
      {showProgress && progress && !hasEnded ? (
        <CellProgress
          progress={progress}
          steps={[0, 33, 66, 100]}
          colors={['#f44336', '#ff9800', '#66bb6a', '#43a047']}
        />
      ) : null}
    </>
  );
};
