import { getActiveCount } from '@components/cars-table-next/filter/lib';
import { DrawerContent, DrawerFooter, DrawerHeader, DrawerStatic, Stack } from '@components/ui';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  Grid,
  Typography,
  withStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import UndoIcon from '@material-ui/icons/Undo';
import { ChangeEvent, useMemo, useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CacheKeys } from 'src/constants';
import { useLocalStorage } from 'src/hooks/useLocalStorage';
import useRole from 'src/hooks/useRole';
import useDeepCompareEffect from 'use-deep-compare-effect';
import SearchAgentSave from '../legacy/SearchAgentSave';
import { getFilter } from './filter';
import { CarsTableNextFilterData } from './types';
import { useFilter } from './useFilter';

export type FilterInstance = UseFormReturn<CarsTableNextFilterData>;

type CountState = Record<string, number>;

export const FilterGroup = withStyles({
  root: {
    borderRadius: 4,

    '&.Mui-expanded': {
      margin: 0,
    },

    '&::before': {
      content: 'none',
    },

    '& [data-subgroup-id="other"] + [data-subgroup-id="other"]': {
      marginBlockStart: -8,
    },
    '& [data-subgroup-id="offerDetails"] + [data-subgroup-id="offerDetails"]': {
      marginBlockStart: -8,
    },
    '& [data-subgroup-id="date"] + [data-subgroup-id="date"]': {
      marginBlockStart: -8,
    },
  },
})(Accordion);

export const CarsTableNextFilter = (): JSX.Element => {
  const { t } = useTranslation();

  const { hasRole } = useRole();
  const { reset, control, watch, defaultFilter, onFieldChange } = useFilter();
  const [count, setCount] = useState<CountState | null>(null);
  const filterState = watch();
  const filter = useMemo(() => getFilter(), []);

  const [expanded, setExpanded] = useLocalStorage<string[]>(CacheKeys.carsTableNextFilterExpanded, []);

  const handleExpand = (panel: string) => (event: ChangeEvent<{}>, newExpanded: boolean) => {
    if (newExpanded) {
      setExpanded((prev) => [...prev, panel]);
    } else {
      setExpanded((prev) => prev.filter((p) => p !== panel));
    }
  };

  useDeepCompareEffect(() => {
    setCount(
      filter.reduce<CountState>(
        (result, filterGroup) => ({
          ...result,
          [filterGroup.groupKey]: getActiveCount(filterGroup.filter, filterState, defaultFilter),
        }),
        {},
      ),
    );
  }, [defaultFilter, filterState]);

  return (
    <DrawerStatic>
      <DrawerHeader>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography
            style={{
              fontWeight: 'bold',
            }}
          >
            {t('carsTableNext.carsTableNextFilter.headline')}
          </Typography>
          <SearchAgentSave control={control} />
        </Grid>
      </DrawerHeader>
      <DrawerContent>
        <Stack spacing={3}>
          {filter.map((filterGroup) => (
            <FilterGroup
              key={filterGroup.groupKey}
              expanded={expanded.includes(filterGroup.groupKey)}
              onChange={handleExpand(filterGroup.groupKey)}
            >
              <AccordionSummary aria-controls="test-content" id="test-header" expandIcon={<ExpandMoreIcon />}>
                <Typography>
                  <span
                    style={{
                      fontWeight: 'bold',
                    }}
                  >
                    {t(filterGroup.label as any)}
                  </span>
                  {count?.[filterGroup.groupKey] ? (
                    <Chip
                      size="small"
                      label={count[filterGroup.groupKey]}
                      color="primary"
                      component="span"
                      style={{
                        marginInlineStart: 8,
                      }}
                    />
                  ) : null}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ display: 'block' }}>
                <Stack spacing={3}>
                  {filterGroup.filter
                    .filter(({ roles }) => roles === undefined || roles.every((role) => hasRole(role)))
                    .map(({ filter: Filter, label, name, subgroupId }) => (
                      <div key={name} data-subgroup-id={subgroupId}>
                        <Controller
                          control={control}
                          name={name as any}
                          render={({ field: { ref: _ref, ...field } }) => (
                            <Filter
                              control={control}
                              field={{
                                ...field,
                                label,
                                ref: () => {},
                                onChange: (newValue) => onFieldChange({ ...field, ref: _ref }, newValue),
                              }}
                            />
                          )}
                        />
                      </div>
                    ))}
                </Stack>
              </AccordionDetails>
            </FilterGroup>
          ))}
        </Stack>
      </DrawerContent>
      <DrawerFooter>
        <Button startIcon={<UndoIcon />} fullWidth size="small" color="secondary" variant="contained" onClick={reset}>
          {t('carsTableNext.carsTableNextFilter.buttonReset.label')}
        </Button>
      </DrawerFooter>
    </DrawerStatic>
  );
};
