import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { TaxEntry } from '../modules/tax';
import i18n from '../setup/i18n';

type CarsTaxListType = {
  taxes: TaxEntry[];
  className?: string;
};

const useStyles = makeStyles({
  list: {
    padding: 0,
    margin: 0,
    listStyle: 'none',
    whiteSpace: 'nowrap',
  },
});

export const CarsTaxList = ({ taxes, className }: CarsTaxListType): ReactElement => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <ul className={clsx(classes.list, className)}>
      {taxes.map(({ key, value }) => (
        <li key={key}>
          {i18n.exists(`taxTypes.${key.toLocaleLowerCase()}`)
            ? t(`taxTypes.${key.toLocaleLowerCase()}` as 'taxTypes.nova')
            : key}
          : {value}
        </li>
      ))}
    </ul>
  );
};
