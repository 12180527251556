import { useWatch } from 'react-hook-form';

export const useWatchSummary = () => ({
  normalizedBrand: useWatch({ name: 'normalizedBrand' }) ?? [],
  normalizedModel: useWatch({ name: 'normalizedModel' }) ?? [],
  normalizedEquipmentLine: useWatch({ name: 'normalizedEquipmentLine' }) ?? [],
  normalizedVersion: useWatch({ name: 'normalizedVersion' }) ?? [],
  equipmentCodes: useWatch({ name: 'equipmentCodes' }) ?? [],
  promotions: useWatch({ name: 'promotions' }) ?? [],
  normalizedFuelDetail: useWatch({ name: 'normalizedFuelDetail' }) ?? [],
});

export const useBidsWatchSummary = () => ({
  normalizedBrand: useWatch({ name: 'carsRequest.normalizedBrand' }) ?? [],
  normalizedModel: useWatch({ name: 'carsRequest.normalizedModel' }) ?? [],
  normalizedEquipmentLine: useWatch({ name: 'carsRequest.normalizedEquipmentLine' }) ?? [],
  normalizedVersion: useWatch({ name: 'carsRequest.normalizedVersion' }) ?? [],
  equipmentCodes: useWatch({ name: 'carsRequest.equipmentCodes' }) ?? [],
  promotions: useWatch({ name: 'carsRequest.promotions' }) ?? [],
  normalizedFuelDetail: useWatch({ name: 'carsRequest.normalizedFuelDetail' }) ?? [],
  bidCreatorUserId: useWatch({ name: 'bidCreatorUserId' }) ?? [],
  bidCreatorUserCountry: useWatch({ name: 'bidCreatorUserCountry' }) ?? [],
  bidCreatorDealerId: useWatch({ name: 'bidCreatorDealerId' }) ?? [],
});
