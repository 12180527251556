import { navigate } from '@reach/router';

export const navigator = {
  navigate({ itemUrl }: { itemUrl: string }) {
    navigate(itemUrl);
  },
  navigateNewTab({ itemUrl }: { itemUrl: string }) {
    const windowReference = window.open(itemUrl, '_blank', 'noopener');

    if (windowReference) {
      windowReference.focus();
    }
  },
  navigateNewWindow({ itemUrl }: { itemUrl: string }) {
    window.open(itemUrl, '_blank', 'noopener');
  },
};
