import { Grid, makeStyles, Typography } from '@material-ui/core';
import { Apps } from '@material-ui/icons';
import { memo, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { ConditionalFormattingDTO, PriceType } from '../modules/generated/api';
import { useCarsTableStore } from '../stores/CarsTable';
import ColumnHeaderFromTo from './ColumnHeaderFromTo';
import ConditionalFormattingColumnInfo from './ConditionalFormattingColumnInfo';
import InfoTooltip from './InfoTooltip';
import UnitValue from './UnitValue';

const useStyles = makeStyles((theme) => ({
  width: {
    '&--medium': {
      width: theme.spacing(15),
    },
  },

  pricePackage: {
    position: 'absolute',
    top: theme.spacing(10),
  },
  pricePackageInners: {
    verticalAlign: 'middle',
    display: 'inline-flex',
  },
}));

type CarsTableAnnotatedHeadLabelCellType = {
  label: ReactNode;
  hint?: ReactNode;
  min?: string;
  max?: string;
  singleLine?: boolean;
  conditionalFormattings?: (ConditionalFormattingDTO | undefined)[];
  width?: 'medium';
  formatHint?: ReactNode;
  potentialPriceType?: PriceType;
};

const CarsTableAnnotatedHeadLabelCell = ({
  label,
  min,
  max,
  singleLine,
  hint,
  conditionalFormattings,
  width,
  formatHint,
  potentialPriceType,
}: CarsTableAnnotatedHeadLabelCellType) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { packageMode, cars } = useCarsTableStore();

  const pricePackage = cars?.[0]?.pricePackage;

  const showConditionalFormattings =
    conditionalFormattings && conditionalFormattings.some((formatting) => formatting !== undefined);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div role="button" tabIndex={0}>
      <div className={width && `${classes.width}--${width}`}>
        <Grid container direction="column" justifyContent="center" alignItems="center" wrap="nowrap">
          <Grid item>{label}</Grid>

          {(hint || showConditionalFormattings) && (
            <Grid item>
              <InfoTooltip
                title={hint ? t('common.information') : undefined}
                hint={hint}
                divider={!!(hint && conditionalFormattings?.some((cF) => cF !== undefined))} // extra check if cF is really filled with data
                moreInfo={
                  showConditionalFormattings && (
                    <>
                      <Typography variant="caption">
                        <strong>{t('conditionalFormatting.conditionalFormatting')}</strong>
                        <br />
                        {formatHint}
                      </Typography>
                      {conditionalFormattings?.map((conditionalFormatting) =>
                        conditionalFormatting?.conditions?.map((condition) => (
                          <ConditionalFormattingColumnInfo
                            key={`${condition.backgroundColor},${condition.gt},${condition.lt},${condition.gte}, ${condition.lte}, ${condition.eq}, ${condition.contains}`}
                            fieldCondition={condition}
                            field={conditionalFormatting.field}
                          />
                        )),
                      )}
                    </>
                  )
                }
              />
            </Grid>
          )}
        </Grid>
        {min !== undefined && max !== undefined && <ColumnHeaderFromTo singleLine={singleLine} min={min} max={max} />}
      </div>
      {packageMode && pricePackage !== undefined && potentialPriceType === PriceType.OfferPrice && (
        <div className={classes.pricePackage}>
          <Typography className={classes.pricePackageInners}>
            <Apps fontSize="small" />
            <UnitValue
              /* @ts-ignore eslint-disable-next-line */
              value={pricePackage.value}
              unit={pricePackage.unit}
            />
          </Typography>
        </div>
      )}
    </div>
  );
};

export default memo(CarsTableAnnotatedHeadLabelCell);
