import {
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';
import BlockIcon from '@material-ui/icons/Block';
import clsx from 'clsx';
import { capitalize, isEmpty } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useRole from 'src/hooks/useRole';
import UserRole from 'src/types/UserRoles';
import useApi from 'src/hooks/useApi';
import { useUser } from 'src/hooks/useUser';
import useCustomSnackbarGlobal from 'src/hooks/useSnackbarGlobal';
import ApiService from 'src/modules/api-service';
import {
  BidAutomationStatusResponseDTO,
  BidSuccessStatus,
  IndividualBidStateInfoDTO,
  SourceType,
} from '../modules/generated/api';
import BidTableCells from './BidTableCells';
import CarDetailHeadline from './CarDetailHeadline';
import CarDetailTableHeadCell from './CarDetailTableHeadCell';
import ConfirmDeclineBidsDialog from './ConfirmDeclineBidsDialog';
import InfoTooltip from './InfoTooltip';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  disableElevation: {
    borderRadius: 0,
  },
  headingBox: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& h2': {
      paddingRight: '5px',
    },
  },
  layoutFixed: {
    tableLayout: 'fixed',
  },
  declineButton: {
    color: theme.palette.error.main,
    marginLeft: theme.spacing(2),
  },
  noRightPadding: {
    paddingRight: 0,
  },
}));

type BidInfoTableProps = {
  title: string;
  layoutFixed?: boolean;
  disableElevation?: boolean;
  className?: string;
  bids: IndividualBidStateInfoDTO;
  source?: SourceType;
  refresh: () => void;
  dateEnd?: string;
  bidId?: string;
  carId?: string;
  bidAutomationStatus?: BidAutomationStatusResponseDTO;
  bidAutomationRefresh?: () => void;
};

const BidInfoTable = ({
  title,
  layoutFixed,
  disableElevation = false,
  className,
  bids,
  source,
  refresh,
  dateEnd,
  bidId,
  carId,
  bidAutomationStatus,
  bidAutomationRefresh,
}: BidInfoTableProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { fetch } = useApi<BidAutomationStatusResponseDTO>();

  const snackbar = useCustomSnackbarGlobal();
  const [declineDialogOpen, setDeclineDialogOpen] = useState(false);
  const { data: user } = useUser();
  const { hasRole } = useRole();
  const isAdmin = hasRole(UserRole.ADMIN);
  const auctionHasEnded = dateEnd && new Date(dateEnd) < new Date();
  const hasAllocateBid =
    bids.ownCurrentBids?.some((bid) => bid.status === BidSuccessStatus.Allocate) ||
    bids.otherCurrentBids?.some((bid) => bid.status === BidSuccessStatus.Allocate) ||
    bids.allHistoricBids?.some((bid) => bid.status === BidSuccessStatus.Allocate);

  const hasSentBid =
    bids.ownCurrentBids?.some((bid) => bid.status === BidSuccessStatus.Sent) ||
    bids.otherCurrentBids?.some((bid) => bid.status === BidSuccessStatus.Sent) ||
    bids.allHistoricBids?.some((bid) => bid.status === BidSuccessStatus.Sent);

  const isBought =
    bids.ownCurrentBids?.some((bid) => bid.status === BidSuccessStatus.Bought) ||
    bids.otherCurrentBids?.some((bid) => bid.status === BidSuccessStatus.Bought) ||
    bids.allHistoricBids?.some((bid) => bid.status === BidSuccessStatus.Bought);

  const bidAutomationActive = bidAutomationStatus?.enabled || false;
  const ownHistoricBids = bids?.allHistoricBids?.filter((bid) => bid.user?.id === user?.user?.id);

  const handleSwitchChange = async () => {
    if (!source || !carId) return;
    const response = await fetch(
      ApiService.newBids.bidNewControllerUpdateAutoBidOnSubsequentOffers({
        source,
        carId,
        enabled: !bidAutomationActive,
      }),
    );
    if (response && response.status === 200) {
      if (response?.data?.enabled === true) {
        snackbar.showSuccess(t('snackBar.success.updateAutoBidActive'));
      } else if (response?.data?.enabled === false) {
        snackbar.showSuccess(t('snackBar.success.updateAutoBidInactive'));
      }
      if (bidAutomationRefresh) {
        bidAutomationRefresh();
      }
    } else {
      snackbar.showError(t('snackBar.error.updateAutoBid'));
    }
  };

  return (
    <TableContainer
      component={Paper}
      elevation={disableElevation ? 0 : 3}
      className={clsx(classes.root, disableElevation && classes.disableElevation, className)}
    >
      <div className={classes.headingBox}>
        <CarDetailHeadline title={title} />
        {auctionHasEnded && !isBought && (!isEmpty(ownHistoricBids) || !isEmpty(bids.ownCurrentBids)) && (
          <div className={classes.headingBox}>
            <Typography variant="body2">{t('carsTableNext.filter.bidsWithAutomation')}</Typography>
            <Tooltip
              title={!bidAutomationStatus?.bidRequest ? t('bids.bidAutomation-disabled') : ''}
              arrow
              placement="left"
            >
              <Switch
                checked={bidAutomationActive}
                onChange={handleSwitchChange}
                color="primary"
                disabled={!bidAutomationStatus?.bidRequest}
              />
            </Tooltip>
            <InfoTooltip
              title={t('bids.bidAutomation-info')}
              hint={t('bids.activateBidsAutomationHint')}
              placement="left"
              divider={!bidAutomationStatus?.bidRequest}
              moreInfo={
                !bidAutomationStatus?.bidRequest && (
                  <Typography variant="caption">{t('bids.bidAutomation-disabled')}</Typography>
                )
              }
            />
          </div>
        )}

        {(hasAllocateBid || hasSentBid) && auctionHasEnded && isAdmin && source === SourceType.SpotdealsAllmobil && (
          <Tooltip title={t('bids.declineBid')} arrow placement="left">
            <IconButton size="small" className={classes.declineButton} onClick={() => setDeclineDialogOpen(true)}>
              <BlockIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
      {declineDialogOpen && (
        <ConfirmDeclineBidsDialog
          open={declineDialogOpen}
          handleClose={setDeclineDialogOpen}
          refresh={refresh}
          source={source}
          carId={carId}
        />
      )}
      <Table size="small" className={layoutFixed ? classes.layoutFixed : ''}>
        <TableBody>
          <TableRow>
            <CarDetailTableHeadCell />
            <CarDetailTableHeadCell>{t('common.user')}</CarDetailTableHeadCell>
            <CarDetailTableHeadCell />
            <CarDetailTableHeadCell>KVPS</CarDetailTableHeadCell>
            <CarDetailTableHeadCell>{t('common.bid')}</CarDetailTableHeadCell>
            <CarDetailTableHeadCell>{t('bids.status.sent')}</CarDetailTableHeadCell>
            <CarDetailTableHeadCell>{t('common.status')}</CarDetailTableHeadCell>
          </TableRow>
          {!isEmpty(bids.ownCurrentBids) &&
            bids.ownCurrentBids?.map((bid, idx) => (
              <TableRow key={`(${bid.created}-${bid.bid?.value}-${bid.dealer?.id}) `}>
                <CarDetailTableHeadCell>{idx === 0 ? t('bids.bidHammers.ownCurr') : ''}</CarDetailTableHeadCell>
                <BidTableCells bid={bid} source={source} refresh={refresh} dateEnd={dateEnd} bidId={bidId} />
              </TableRow>
            ))}

          {!isEmpty(bids.otherCurrentBids) &&
            bids.otherCurrentBids?.map((bid, idx) => (
              <TableRow key={`(${bid.created}-${bid.bid?.value}-${bid.dealer?.id}) `}>
                <CarDetailTableHeadCell>{idx === 0 ? t('bids.bidHammers.otherCurr') : ''}</CarDetailTableHeadCell>
                <BidTableCells
                  bid={bid}
                  key={bid.biddingTime}
                  source={source}
                  refresh={refresh}
                  dateEnd={dateEnd}
                  bidId={bidId}
                />
              </TableRow>
            ))}
          {!isEmpty(bids.allHistoricBids) &&
            bids.allHistoricBids?.map((bid, idx) => (
              <TableRow key={`(${bid.created}-${bid.bid?.value}-${bid.dealer?.id}) `}>
                <CarDetailTableHeadCell>{idx === 0 ? capitalize(t('bids.expired')) : ''}</CarDetailTableHeadCell>
                <BidTableCells
                  bid={bid}
                  key={bid.biddingTime}
                  source={source}
                  refresh={refresh}
                  dateEnd={dateEnd}
                  bidId={bidId}
                />
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BidInfoTable;
