import { isArray, isFunction, mergeWith } from 'lodash';
import React, { createContext, useCallback, useContext, useEffect, useMemo } from 'react';
import { ControllerRenderProps, FormProvider, useForm, useFormContext } from 'react-hook-form';
import { CacheKeys } from 'src/constants';
import useRole from 'src/hooks/useRole';
import { useSources } from 'src/hooks/useSources';
import { useUser } from 'src/hooks/useUser';
import { getPersistedValue, persistValue } from 'src/lib/localStorage';
import UserRole from 'src/types/UserRoles';
import { BidTypes } from '../types';
import { getDefaultBidsFilter } from './lib';
import { BidsTableNextFilterData } from './types';

type FilterProviderProps = {
  children: React.ReactNode;
  bidsTableType: 'PastBidsTable' | 'BoughtBidsTable' | 'PendingBidsTable';
};

type FilterContext = {
  defaultFilter: BidsTableNextFilterData;
};

const bidsFilterContext = createContext({} as FilterContext);

export const useBidsFilter = () => {
  const filter = useContext(bidsFilterContext);
  const { defaultFilter } = filter;
  const form = useFormContext<BidsTableNextFilterData>();
  const { reset: formReset, getValues, setValue } = form;

  /**
   * Set the filter and additionally merges default values
   */
  const setFilterSafe = (newValue: BidsTableNextFilterData) => {
    const prevFilter = getValues();
    formReset(
      mergeWith(
        {},
        defaultFilter,
        isFunction(newValue) ? newValue(prevFilter) : newValue,
        // Customizer: overwrite with newValue if default Value is an Array.
        // Default behaviour: Concatenation if length of source1 < length of source2
        (objValue, srcValue) => {
          if (isArray(srcValue)) {
            return srcValue;
          }
          return undefined;
        },
      ),
    );
  };

  const setFilter = useCallback(
    (newValue: BidsTableNextFilterData | null) => {
      if (newValue) {
        formReset(newValue);
      }
    },
    [formReset],
  );

  const onFieldChange = useCallback(
    (field: ControllerRenderProps<BidsTableNextFilterData>, newValue: any) => {
      if (
        newValue &&
        typeof newValue === 'object' &&
        'nativeEvent' in newValue &&
        newValue.nativeEvent instanceof Event
      ) {
        // eslint-disable-next-line no-console
        console.error('Filter onChange should not be called with event');
        return;
      }
      setValue(field.name, newValue);
    },
    [setValue],
  );

  /**
   * Reset filter state to the default filter value
   */
  const reset = useCallback(() => {
    formReset(defaultFilter);
  }, [defaultFilter, formReset]);

  return {
    ...filter,
    ...form,
    setFilter,
    setFilterSafe,
    reset,
    onFieldChange,
  };
};

export const BidsFilterProvider = ({ children, bidsTableType }: FilterProviderProps) => {
  const { hasRole } = useRole();
  const isAdmin = hasRole(UserRole.ADMIN);
  const { data: sources } = useSources();
  const { data: user } = useUser();
  const bidType =
    // eslint-disable-next-line no-nested-ternary
    bidsTableType === 'PastBidsTable'
      ? BidTypes.Past
      : bidsTableType === 'BoughtBidsTable'
        ? BidTypes.Orders
        : BidTypes.Pending;
  const defaultFilter = useMemo(
    () => getDefaultBidsFilter({ admin: isAdmin, sources, user, bidType }),
    [isAdmin, bidType, user, sources],
  );
  function getCacheKey() {
    switch (bidsTableType) {
      case 'PastBidsTable':
        return CacheKeys.bidsPastTableNextFilter;
      case 'BoughtBidsTable':
        return CacheKeys.bidsOrderedTableNextFilter;
      case 'PendingBidsTable':
        return CacheKeys.bidsPendingTableNextFilter;
      default:
        return '';
    }
  }
  const cacheKey = getCacheKey();

  const form = useForm({
    defaultValues: getPersistedValue(cacheKey, {
      initialValue: defaultFilter,
    }),
  });
  const { watch } = form;

  const filterContextValue = useMemo(() => ({ defaultFilter }), [defaultFilter]);

  // Persist filter to local storage
  useEffect(() => {
    const subscription = watch((filter) => {
      persistValue(cacheKey, filter);
    });
    return () => subscription.unsubscribe();
  });

  return (
    <bidsFilterContext.Provider value={filterContextValue}>
      <FormProvider {...form}>{children}</FormProvider>
    </bidsFilterContext.Provider>
  );
};
