import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { damageTypes } from '../modules/labels';
import FilterAutocomplete from './FilterAutocomplete';
import { IconType } from '../modules/data';

const DamageTypeSelect = () => {
  const { t } = useTranslation();

  return (
    <FilterAutocomplete
      name="damageType"
      label={t('filterSearch.allowedDamages')}
      multiple
      options={damageTypes}
      iconType={IconType.DAMAGE_TYPE}
    />
  );
};

export default memo(DamageTypeSelect);
