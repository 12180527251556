import {
  ComponentType,
  createContext,
  EventHandler,
  FocusEvent,
  KeyboardEvent,
  KeyboardEventHandler,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { DrawerContent, DrawerHeader, DrawerStatic, Menu, MenuAction, useMenu } from '@components/ui';
import { IconButton, styled, Tooltip, Typography, Link as MuiLink, Grid } from '@material-ui/core';
import { grey, orange } from '@material-ui/core/colors';
import CheckIcon from '@material-ui/icons/Check';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import StarIcon from '@material-ui/icons/Star';
import StarOutlineIcon from '@material-ui/icons/StarOutline';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import clsx from 'clsx';
import { DropResult } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import type { Merge } from 'type-fest';
import { isNil, set } from 'lodash';
import { Link } from '@reach/router';
import { RoutePaths } from 'src/constants';
import { SearchAgentDrawer, SearchAgentFormData } from '@components/SearchAgentDrawer';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import { useSearchAgentUpdate } from 'src/hooks/useSearchAgentUpdate';
import { useSearchAgent } from 'src/hooks/useSearchAgent';
import { DraggableList, DraggableListItem, DraggableListItemContentProps, DragHandle } from '../DraggableList';

type TableViewsProps = {
  views: TableView[];
  onViewChange: (view: TableView) => void;
  onReorder: (id: string, index: number) => void;
  onDeleteView: (view: TableView) => void;
  onDuplicateView: (view: TableView) => void;
  onActiveChange: (view: TableView) => void;
};

export type TableView = {
  id: string;
  displayName: string;
  icon?: ComponentType;
  favorite?: boolean;
  active?: boolean;
  notification?: boolean;
  meta?: any;
};

enum TableViewItemAction {
  toggleFavorite,
  toggleNotification,
  rename,
  delete,
  duplicate,
  edit,
}

type TableViewListProps = {
  views: TableView[];
};

type DisplaySettingsCtxValue = Pick<
  TableViewsProps,
  'onReorder' | 'onViewChange' | 'onDeleteView' | 'onDuplicateView' | 'onActiveChange'
>;

const TableViewsCtx = createContext<DisplaySettingsCtxValue>(undefined!);
TableViewsCtx.displayName = 'TableViewCtx';

const SCTableViewItem = styled('div')({
  paddingInline: 12,
  paddingBlock: 8,
  borderRadius: 8,
  display: 'flex',
  minHeight: 42, // prevent content shifts from icon buttons

  '& :where(.table_view_item__ctx_menu, .table_view_item__drag-handle, .table_view_item__check)': {
    display: 'none',
  },

  '&.active': {
    background: orange[50],
  },

  '&.active .table_view_item__check': {
    display: 'inline-flex',
  },

  '&:hover, &.hover': {
    background: grey[100],
  },

  [`&:hover .table_view_item__check,
    &.hover .table_view_item__check`]: {
    display: 'none',
  },

  [`&:hover :where(.table_view_item__ctx_menu, .table_view_item__drag-handle),
    &.hover :where(.table_view_item__ctx_menu, .table_view_item__drag-handle)`]: {
    display: 'inline-flex',
  },
});

const TableViewItem = ({
  view,
  dragHandleProps,
  isDragging,
}: Merge<DraggableListItemContentProps, { view: TableView }>): JSX.Element => {
  const { active, displayName, icon: Icon, favorite, id, notification } = view;
  const { t } = useTranslation();
  const { onViewChange, onDeleteView, onDuplicateView, onActiveChange } = useContext(TableViewsCtx);
  const { isOpen: isMenuOpen, open: openMenu, close: closeMenu } = useMenu();
  const [isEditing, setEdit] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const { mutate: updateSearchAgent } = useSearchAgentUpdate();
  const searchAgent = useSearchAgent(id);

  const viewActions: MenuAction[] = [
    favorite
      ? {
          id: TableViewItemAction.toggleFavorite,
          title: t('carsTableNext.views.actions.deleteFavorite'),
          icon: StarOutlineIcon,
        }
      : {
          id: TableViewItemAction.toggleFavorite,
          title: t('carsTableNext.views.actions.addFavorite'),
          icon: StarIcon,
        },
    notification
      ? {
          id: TableViewItemAction.toggleNotification,
          title: t('carsTableNext.views.actions.disableNotification'),
          icon: NotificationsOffIcon,
        }
      : {
          id: TableViewItemAction.toggleNotification,
          title: t('carsTableNext.views.actions.enableNotification'),
          icon: NotificationsActiveIcon,
        },
    {
      id: TableViewItemAction.rename,
      title: t('carsTableNext.views.actions.rename'),
      icon: TextFieldsIcon,
    },
    {
      id: TableViewItemAction.delete,
      title: t('carsTableNext.views.actions.delete'),
      icon: DeleteIcon,
    },
    {
      id: TableViewItemAction.duplicate,
      title: t('carsTableNext.views.actions.duplicate'),
      icon: AddToPhotosIcon,
    },
    {
      id: TableViewItemAction.edit,
      title: t('carsTableNext.views.actions.edit'),
      icon: EditIcon,
    },
  ];

  const menuReducer = (action: MenuAction) => {
    switch (action.id) {
      case TableViewItemAction.rename:
        setEdit((prev) => !prev);
        break;
      case TableViewItemAction.delete:
        onDeleteView(view);
        break;
      case TableViewItemAction.duplicate:
        onDuplicateView(view);
        break;
      case TableViewItemAction.toggleFavorite:
        onViewChange(set(view, 'favorite', !favorite));
        break;
      case TableViewItemAction.edit:
        setDrawerOpen(true);
        break;
      case TableViewItemAction.toggleNotification:
        if (searchAgent) {
          updateSearchAgent({ ...searchAgent, active: !notification });
        }
        break;
      // no default
    }
  };

  const handleSubmitName: EventHandler<KeyboardEvent<HTMLInputElement> | FocusEvent<HTMLInputElement>> = (event) => {
    const { value } = event.target as HTMLInputElement;
    onViewChange({ ...view, displayName: value });
    setEdit(false);
  };

  const handleSubmitDrawer = ({ searchAgentDisplayName, notifications, display }: SearchAgentFormData) => {
    if (searchAgent) {
      updateSearchAgent({
        ...searchAgent,
        searchAgentDisplayName,
        active: Object.values(notifications).some(Boolean),
        favorite: display.favorites,
      });
    }

    setDrawerOpen(false);
  };

  const handleKeyUp: KeyboardEventHandler<HTMLInputElement> = (event) => {
    const { key } = event;

    switch (key) {
      case 'Enter':
        handleSubmitName(event);
        break;
      case 'Escape':
        setEdit(false);
        break;
      // no default
    }
  };

  useEffect(() => {
    if (isEditing) {
      inputRef.current?.focus();
    }
  }, [isEditing]);

  return (
    <SCTableViewItem
      className={clsx({
        active,
        hover: isMenuOpen || isDragging,
      })}
    >
      {/* eslint-disable-next-line */}
      <div
        onClick={() => onActiveChange(view)}
        style={{
          flexGrow: 1,
          display: 'flex',
          gap: 8,
          marginBlock: -8,
          paddingBlock: 8,
          marginInlineStart: -8,
          paddingInline: 8,
          cursor: 'pointer',
          minWidth: 0,
        }}
      >
        {Icon ? <Icon /> : null}
        <div
          style={{
            whiteSpace: 'nowrap',
            minWidth: 0,
            flexGrow: 1,
            display: 'flex',
            alignItems: 'baseline',
          }}
        >
          <Typography
            style={{
              color: grey[900],
              fontWeight: 'bold',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              flexGrow: isEditing ? 1 : 0,
            }}
          >
            {isEditing ? (
              <input
                ref={inputRef}
                style={{
                  height: 'calc(1em * 1.5)',
                  fontSize: 'inherit',
                  width: '100%',
                }}
                onBlur={handleSubmitName}
                onKeyUp={handleKeyUp}
                onClick={(event) => event.stopPropagation()}
                defaultValue={displayName}
              />
            ) : (
              <span style={{ marginInlineEnd: 4 }}>{displayName}</span>
            )}
          </Typography>
          {!isEditing && favorite ? (
            <Tooltip title={t('searchAgentTable.pinned')} placement="right" arrow>
              <StarIcon
                style={{
                  fontSize: 14,
                }}
              />
            </Tooltip>
          ) : null}
          {!isEditing && notification ? (
            <Tooltip title={t('searchAgentTable.active')} placement="right" arrow>
              <NotificationsActiveIcon
                style={{
                  fontSize: 14,
                }}
              />
            </Tooltip>
          ) : null}
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          alignSelf: 'flex-start',
          marginInlineStart: 'auto',
        }}
      >
        <CheckIcon className="table_view_item__check" fontSize="small" />
        <Menu open={isMenuOpen} onOpen={openMenu} onClose={closeMenu} actions={viewActions} onAction={menuReducer}>
          {(triggerProps) => (
            <IconButton
              {...triggerProps}
              onClick={(event) => {
                event.stopPropagation();
                triggerProps.onClick();
              }}
              size="small"
              className="table_view_item__ctx_menu"
            >
              <MoreVertIcon fontSize="small" />
            </IconButton>
          )}
        </Menu>
        <DragHandle className="table_view_item__drag-handle" style={{ display: undefined }} {...dragHandleProps} />
      </div>

      <SearchAgentDrawer
        onClose={() => setDrawerOpen(false)}
        onSubmit={handleSubmitDrawer}
        open={isDrawerOpen}
        searchAgent={{
          id,
          searchAgentDisplayName: displayName,
          active: notification,
          favorite,
        }}
      />
    </SCTableViewItem>
  );
};

const TableViewList = ({ views }: TableViewListProps): JSX.Element => {
  const { onReorder } = useContext(TableViewsCtx);
  const draggableItems = useMemo<DraggableListItem[]>(
    () =>
      views.map((view) => ({
        id: view.id,
        content: (props) => <TableViewItem {...props} view={view} />,
      })),
    [views],
  );

  const handleDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (isNil(result.destination)) {
      return;
    }

    onReorder(views[result.source.index].id, result.destination.index);
  };

  return <DraggableList onDragEnd={handleDragEnd} droppableId="cars-table-columns" items={draggableItems} />;
};

/**
 * Manage saved Table Views. (currently SearchAgents)
 */
export const TableViews = ({
  views,
  onViewChange,
  onReorder,
  onDeleteView,
  onDuplicateView,
  onActiveChange,
}: TableViewsProps): JSX.Element => {
  const { t } = useTranslation();

  const ctxValue = useMemo(
    () => ({
      onViewChange,
      onReorder,
      onDeleteView,
      onDuplicateView,
      onActiveChange,
    }),
    [onViewChange, onReorder, onDeleteView, onDuplicateView, onActiveChange],
  );

  return (
    <DrawerStatic>
      <DrawerHeader>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography
            style={{
              fontWeight: 'bold',
            }}
          >
            {t('carsTableNext.views.title')}
          </Typography>
          <MuiLink component={Link} to={RoutePaths.searchAgents}>
            {t('carsTableNext.views.manageSearchAgent')}
          </MuiLink>
        </Grid>
      </DrawerHeader>
      <DrawerContent>
        <TableViewsCtx.Provider value={ctxValue}>
          <TableViewList views={views} />
        </TableViewsCtx.Provider>
      </DrawerContent>
    </DrawerStatic>
  );
};
