import { createContext, createElement, ReactNode, useCallback, useContext, useState } from 'react';

type ExperimentProviderProps = {
  children?: ReactNode;
};

export type ExperimentId = 'CAR_TABLE_LEGACY';

export type Experiment = {
  id: ExperimentId;
  title: string;
  description: string;
  enabled: boolean;
};

type ExperimentCtx = {
  experiments: Experiment[];
  enableExperiment: (id: ExperimentId) => void;
  disableExperiment: (id: ExperimentId) => void;
  getExperimentStatus: (id: ExperimentId) => boolean | undefined;
};

const getCacheKey = (id: ExperimentId) => `gwscout/EXPERIMENTS/${id}`;

const getPersistedExperimentStatus = (id: ExperimentId) =>
  !!JSON.parse(localStorage.getItem(getCacheKey(id)) || 'false');

const makeExperiment = (id: ExperimentId, title: string, description: string): Experiment => {
  const enabled = getPersistedExperimentStatus(id);

  return {
    id,
    title,
    description,
    enabled,
  };
};

const availableExperiments: Experiment[] = [
  makeExperiment('CAR_TABLE_LEGACY', 'experiments.carTableLegacy.title', 'experiments.carTableLegacy.description'),
];

const experimentCtx = createContext<ExperimentCtx>(undefined!);

export const ExperimentsProvider = ({ children }: ExperimentProviderProps) => {
  const [experiments, setExperiments] = useState(availableExperiments);

  const update = (id: ExperimentId, enabled: boolean) => {
    localStorage.setItem(getCacheKey(id), String(enabled));
    setExperiments((prev) =>
      prev.map((experiment) => (experiment.id === id ? { ...experiment, enabled } : experiment)),
    );
  };

  const enableExperiment = (id: ExperimentId) => {
    update(id, true);
  };

  const disableExperiment = (id: ExperimentId) => {
    update(id, false);
  };

  const getExperimentStatus = useCallback(
    (id: ExperimentId): boolean | undefined => {
      const experiment = experiments.find((exp) => exp.id === id);
      return experiment?.enabled;
    },
    [experiments],
  );

  const value = {
    experiments,
    enableExperiment,
    disableExperiment,
    getExperimentStatus,
  };

  return createElement(experimentCtx.Provider, { value }, children);
};

export const useExperiments = () => useContext(experimentCtx);
