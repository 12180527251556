import WithChildren from '../../types/WithChildren';

type SidebarContainerProps = {
  sidebarIsOpen: boolean;
  numColumns?: 2 | 3;
};

const SidebarContainer = ({ sidebarIsOpen, numColumns = 2, children }: WithChildren<SidebarContainerProps>) => (
  <>
    <style>{`
        html,
        body,
        #root,
        #aria-container {
          height: 100%;
        }

        /** space header + page */
        #aria-container {
          display: grid;
          grid-template-rows: auto minmax(0, 1fr);
          grid-template-columns: minmax(0, 1fr);
        }
      `}</style>
    <div
      style={{
        height: '100%',
        display: 'grid',
        gridTemplateColumns: `${numColumns === 3 ? 'auto' : ''} ${sidebarIsOpen ? '420px' : 0} minmax(0, 1fr)`,
        gridTemplateRows: 'minmax(0, 1fr)',
        position: 'relative',
      }}
    >
      {children}
    </div>
  </>
);

export default SidebarContainer;
