import { DrawerWrapper } from '@components/ui';
import { navigate, Router } from '@reach/router';
import { find, isNil } from 'lodash';
import { nanoid } from 'nanoid';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import SpotdealsAllmobilUpload from 'src/containers/SpotdealsAllmobilUpload';
import { useCreateSearchAgent } from 'src/hooks/useCreateSearchAgent';
import { useSearchAgentDelete } from 'src/hooks/useSearchAgentDelete';
import { useSearchAgents } from 'src/hooks/useSearchAgents';
import { useSearchAgentUpdate } from 'src/hooks/useSearchAgentUpdate';
import { AutoRefresh, AutoRefreshData } from './AutoRefresh';
import { SidebarRoutePaths } from './constants';
import { CarsTableNextFilter } from './filter';
import { transformForForm } from './filter/lib';
import { useFilter } from './filter/useFilter';
import { getAllExportableColumns } from './lib';
import { refetchIntervalState } from './state';
import { ExportColumn, TableColumnDisplaySettings, TableExport, TableView, TableViews } from './table';
import { CarsTableNextInstance } from './types';
import { useCarsExport } from './useCarsExport';

type SidebarProps = {
  table: CarsTableNextInstance;
  refetch: () => void;
};

export const CarsTableSidebar = ({ table, refetch }: SidebarProps): JSX.Element => {
  const { data: searchAgents, reorder } = useSearchAgents();
  const { mutate: updateSearchAgent } = useSearchAgentUpdate();
  const { mutate: deleteSearchAgent } = useSearchAgentDelete();
  const { mutate: createSearchAgent } = useCreateSearchAgent();
  const { mutate: exportCars } = useCarsExport();
  const [refetchInterval, setRefetchInterval] = useRecoilState(refetchIntervalState);
  const { setFilterSafe, watch } = useFilter();
  const filterState = watch();
  const results = table.getPageCount();

  const handleSubmitExport = (exportColumns: ExportColumn[]) => {
    const { sorting } = table.getState();
    exportCars({
      filter: filterState,
      sorting,
      exportColumns,
      results,
    });
    navigate(SidebarRoutePaths.root);
  };

  const handleViewChange = useCallback(
    (updatedView: TableView) => {
      const target = find(searchAgents, { id: updatedView.id });

      if (target) {
        updateSearchAgent({
          ...target,
          searchAgentDisplayName: updatedView.displayName,
          favorite: updatedView.favorite,
        });
      }
    },
    [searchAgents, updateSearchAgent],
  );

  const handleDeleteView = useCallback(
    (targetView: TableView) => {
      deleteSearchAgent(targetView.id);
    },
    [deleteSearchAgent],
  );

  const handleDuplicateView = useCallback(
    (targetView: TableView) => {
      const target = find(searchAgents, { id: targetView.id });

      if (target) {
        createSearchAgent({
          ...target,
          // @ts-expect-error
          id: nanoid(),
          searchAgentDisplayName: `${target.searchAgentDisplayName} (Kopie)`,
        });
      }
    },
    [createSearchAgent, searchAgents],
  );

  const handleChangeRefetchInterval = (data: AutoRefreshData) => {
    // only update refetch interval if active
    if (!isNil(refetchInterval)) {
      setRefetchInterval(data.intervalMs);
    }
  };

  return (
    <DrawerWrapper>
      <Router basepath="/" style={{ height: '100%' }}>
        <CarsTableNextFilter
          // @ts-expect-error
          path={SidebarRoutePaths.filter}
        />
        <TableColumnDisplaySettings
          table={table}
          columns={table.getAllLeafColumns()}
          // @ts-expect-error
          path={SidebarRoutePaths.columnSettings}
        />
        <TableViews
          views={searchAgents.map((searchAgent) => ({
            id: searchAgent.id,
            displayName: searchAgent.searchAgentDisplayName,
            favorite: searchAgent.favorite,
            active: filterState?.id === searchAgent.id,
            notification: searchAgent.active,
            meta: {
              searchAgent,
            },
          }))}
          onReorder={reorder}
          onViewChange={handleViewChange}
          onDeleteView={handleDeleteView}
          onDuplicateView={handleDuplicateView}
          onActiveChange={(view) => setFilterSafe(transformForForm(view.meta.searchAgent))}
          // @ts-expect-error
          path={SidebarRoutePaths.views}
        />
        <AutoRefresh
          onSubmit={(data) => setRefetchInterval(data.intervalMs)}
          onPause={() => setRefetchInterval(null)}
          intervalMs={refetchInterval}
          isActive={!isNil(refetchInterval)}
          onChange={handleChangeRefetchInterval}
          // @ts-expect-error
          path={SidebarRoutePaths.autoRefresh}
        />
        <TableExport
          columns={getAllExportableColumns(table.getAllLeafColumns())}
          onSubmit={handleSubmitExport}
          // @ts-expect-error
          path={SidebarRoutePaths.export}
        />
        <SpotdealsAllmobilUpload
          refetchCars={refetch}
          // @ts-expect-error
          path={SidebarRoutePaths.upload}
        />
      </Router>
    </DrawerWrapper>
  );
};
