import humanizeDuration from 'humanize-duration';

export const humanizeDict = {
  shortEn: {
    y: () => 'y',
    mo: () => 'mo',
    w: () => 'w',
    d: () => 'd',
    h: () => 'h',
    m: () => 'm',
    s: () => 's',
    ms: () => 'ms',
  },
};

const humanizeDurationInstance = humanizeDuration.humanizer({
  language: 'shortEn',
  languages: humanizeDict,
  delimiter: ' ',
  spacer: '',
  units: ['d', 'h', 'm', 's'],
  round: true,
});

export default humanizeDurationInstance;
