import { Grid, makeStyles } from '@material-ui/core';
import { ListCarDTO } from '../modules/generated/api';
import CarsTableCellMyUserInformation from './CarsTableCellMyUserInformation';
import CarsTableCellExternalUserInformation from './CarsTableCellExternalUserInformation';

type CarsTableUserInformationCellProps = {
  car: ListCarDTO;
  refetch?: () => Promise<void>;
};

const useStyles = makeStyles((theme) => ({
  container: {
    minWidth: theme.spacing(20),
  },
}));

const CarsTableUserInformationCell = ({ car, refetch }: CarsTableUserInformationCellProps) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <Grid item xs={10}>
        <CarsTableCellMyUserInformation car={car} refetch={refetch} />
      </Grid>
      <Grid item xs={2}>
        <CarsTableCellExternalUserInformation individualizations={car.individualizations} />
      </Grid>
    </Grid>
  );
};

export default CarsTableUserInformationCell;
