import { Spinner } from '@components/spinner';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.grey[300],
    zIndex: theme.zIndex.modal,
  },
}));

export const AppSpinner = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Spinner isLoading size="large" />
    </div>
  );
};
