import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-block',
    width: (props: { size?: number }) => (props.size ? props.size : theme.spacing(2)),
    height: (props: { size?: number }) => (props.size ? props.size : theme.spacing(2)),
    marginRight: theme.spacing(1),
    border: '1px solid #4c5356',
  },
}));

type ConditionalFormattingColorProps = {
  size?: number;
  value?: string;
};

const ConditionalFormattingColor = ({ value, size = 16 }: ConditionalFormattingColorProps) => {
  const classes = useStyles({ size });

  return <div style={{ backgroundColor: value }} className={classes.root} />;
};

export default ConditionalFormattingColor;
