import { InternalDocSearchHit } from '@components/faq-search/types';
import { Results } from '@components/faq-search/Results';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import type { ScreenStateProps } from './ScreenState';

type ResultsScreenProps = Omit<ScreenStateProps<InternalDocSearchHit>, 'translations'>;

export const ResultsScreen = (props: ResultsScreenProps) => {
  const { state, getPanelProps } = props;

  return (
    <div {...getPanelProps()}>
      {state.collections.map((collection) => {
        if (collection.items.length === 0) {
          return null;
        }

        return (
          <Results
            {...props}
            key={collection.source.sourceId}
            title={collection.source.sourceId}
            collection={collection}
            renderIcon={() => <DescriptionOutlinedIcon />}
          />
        );
      })}
    </div>
  );
};
