import { memo } from 'react';
import { IconButton } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import ActionTooltip from './ActionTooltip';
import { SourceType } from '../modules/generated/api';

type ActionCarDetailLinkProps = {
  source?: SourceType;
  carId?: string;
  color?: 'primary' | undefined;
  tooltipPosition?: 'bottom' | 'left' | undefined;
};

const ActionCarDetailLink = ({ source, carId, color, tooltipPosition }: ActionCarDetailLinkProps) => {
  const { t } = useTranslation();
  return (
    <ActionTooltip title={t('navigation.detailPage')} disabled={!source && !carId} position={tooltipPosition}>
      <IconButton
        disabled={!source && !carId}
        size="small"
        href={source && carId ? `/cars/${source}/${carId}` : ''}
        target="_blank"
        color={color}
      >
        <Search />
      </IconButton>
    </ActionTooltip>
  );
};

export default memo(ActionCarDetailLink);
