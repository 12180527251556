import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { QueryKeys } from 'src/constants';
import ApiService from 'src/modules/api-service';
import { DeliveryAddressDTO } from 'src/modules/generated/api';
import { removeItemAtIndex, replaceItemAtIndex, setToArray } from '../modules/util';
import useCustomSnackbarGlobal from './useSnackbarGlobal';

export const useCreateDeliveryAddress = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (newDeliveryAddress: DeliveryAddressDTO) =>
      ApiService.dealer.dealerControllerSaveDeliveryAddress(newDeliveryAddress),
    {
      onMutate: async (newDeliveryAddress) => {
        await queryClient.cancelQueries([QueryKeys.deliveryAddresses]);

        const snapshot = queryClient.getQueryData<DeliveryAddressDTO[]>([QueryKeys.deliveryAddresses]);

        queryClient.setQueryData<DeliveryAddressDTO[]>([QueryKeys.deliveryAddresses], (prev = []) => {
          if (newDeliveryAddress.id !== undefined)
            return replaceItemAtIndex(
              [...prev],
              prev.findIndex((deliveryAddress) => deliveryAddress.id === newDeliveryAddress.id),
              newDeliveryAddress,
            );
          return [...prev, newDeliveryAddress];
        });

        return snapshot;
      },
      onError: (_error, _newSearchAgent, snapshot) => {
        queryClient.setQueryData<DeliveryAddressDTO[] | undefined>([QueryKeys.deliveryAddresses], snapshot);
      },
      onSettled: () => {
        queryClient.invalidateQueries([QueryKeys.deliveryAddresses]);
      },
    },
  );

  return mutation;
};

export const useDeleteDeliveryAddress = () => {
  const queryClient = useQueryClient();
  const deleteDeliveryAddress = useMutation(
    (deliverAddressId: string) => ApiService.dealer.dealerControllerDeleteDeliveryAddress(deliverAddressId),
    {
      onMutate: async (deliverAddressId) => {
        await queryClient.cancelQueries([QueryKeys.deliveryAddresses]);

        const snapshot = queryClient.getQueryData<DeliveryAddressDTO[]>([QueryKeys.deliveryAddresses]);

        queryClient.setQueryData<DeliveryAddressDTO[]>([QueryKeys.deliveryAddresses], (prev = []) =>
          removeItemAtIndex(
            prev,
            prev.findIndex((deliveryAddress) => deliveryAddress.id === deliverAddressId),
          ),
        );
        return snapshot;
      },
      onSettled: () => {
        queryClient.invalidateQueries([QueryKeys.deliveryAddresses]);
      },
    },
  );
  return deleteDeliveryAddress;
};

export const useGetDeliveryAddresses = () =>
  useQuery([QueryKeys.deliveryAddresses], () =>
    ApiService.dealer.dealerControllerGetDeliveryAddresses().then((res) => setToArray(res.data)),
  );

export const useSetDefaultAddress = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (defaultId: string) => ApiService.dealer.dealerControllerUpdateDefaultDeliveryAddress(defaultId),
    {
      onMutate: async (newDefaultId) => {
        await queryClient.cancelQueries([QueryKeys.deliveryAddresses]);

        const snapshot = queryClient.getQueryData<DeliveryAddressDTO[]>([QueryKeys.deliveryAddresses]);

        queryClient.setQueryData<DeliveryAddressDTO[]>([QueryKeys.deliveryAddresses], (prev = []) => {
          const idx = prev.findIndex((deliveryAddress) => deliveryAddress.id === newDefaultId);

          return replaceItemAtIndex([...prev], idx, { ...prev[idx], global: true });
        });

        return snapshot;
      },
      onError: (_error, _newSearchAgent, snapshot) => {
        queryClient.setQueryData<DeliveryAddressDTO[] | undefined>([QueryKeys.deliveryAddresses], snapshot);
      },
      onSettled: () => {
        queryClient.invalidateQueries([QueryKeys.deliveryAddresses]);
      },
    },
  );

  return mutation;
};

export const useBidDeliveryAddress = (bidId: string) => {
  const { t } = useTranslation();
  const { showSuccess, showError } = useCustomSnackbarGlobal();
  const queryClient = useQueryClient();

  return useMutation(
    (deliveryAddressId: string) =>
      ApiService.newBids.bidNewControllerSaveDeliveryAddress(bidId, deliveryAddressId).then((res) => res.data),
    {
      onSuccess: () => showSuccess(t('alerts.successSaved')),
      onError: () => showError(t('alerts.errorRaised')),
      onSettled: () => {
        queryClient.invalidateQueries([QueryKeys.carDetail]);
      },
    },
  );
};
