import { tr } from 'src/modules/i18n-helpers';
import { useFilter } from './useFilter';
import FilterChip from './FilterChip';

type PotentialChipProps = {
  value: any;
  name: string;
};

const PotentialRelativeFilterChip = ({ value, name }: PotentialChipProps) => {
  const { setFilter, watch } = useFilter();
  const filterState = watch();

  const min = filterState?.potentialFilterRelativeMin;
  const max = filterState?.potentialFilterRelativeMax;

  const getTooltip = (): string => {
    if (min && max) {
      return tr(`carsTableNext.filter.potential.${value}`);
    }
    const range = min ? 'Min' : 'Max';
    return `${tr(`carsTableNext.filter.potential.${value}`)} ${range}`;
  };

  if (min || max)
    return (
      <FilterChip
        name={name}
        key={`${name}-${value}-relative`}
        tooltip={getTooltip()}
        label={min && max ? `${min} % - ${max} %` : `${min || max} %`}
        onDelete={() =>
          filterState &&
          setFilter({
            ...filterState,
            potentialFilterRelativeMin: undefined,
            potentialFilterRelativeMax: undefined,
          })
        }
      />
    );
  return null;
};

export default PotentialRelativeFilterChip;
