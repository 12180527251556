import { useEffect } from 'react';
import {
  Button,
  DrawerProps as MuiDrawerProps,
  FormControl,
  FormHelperText,
  Grid,
  makeStyles,
  OutlinedInput,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import type { Merge } from 'type-fest';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Drawer, DrawerHeader, DrawerContent, DrawerFooter, InputLabel, Stack, Select } from '@components/ui';
import { Autocomplete } from '@components/cars-table-next/filter/common/Autocomplete';
import { COUNTRY_ORIGIN_FILTER_COUNTRIES, getIcon, IconType } from 'src/modules/data';
import { countryCodeToOptions } from 'src/modules/labels';
import countries from 'i18n-iso-countries';
import { CountryCode, SourceType } from 'src/modules/generated/api';
import AddIcon from '@material-ui/icons/Add';
import clsx from 'clsx';
import { getSourceOptions, useSources } from 'src/hooks/useSources';
import { nanoid } from 'nanoid';
import { ServicePacket } from './types';
import { ServicePositionItem } from './ServicePositionItem';
import { ServicePositionTotal } from './ServicePositionTotal';

type ServicePacketDrawerProps = {
  title?: string;
  onClose: () => void;
  onSubmit: (data: ServicePacket) => void;
  servicePacket?: ServicePacket;
};

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDrawer-paper': {
      width: 600,
    },
  },
  positions: {
    paddingInlineEnd: theme.spacing(3), // prevent feedback-button overlap
  },
  positionsTotal: {
    marginBlockStart: theme.spacing(2),
  },
  source: {
    '& .MuiInputBase-root': {
      background: 'transparent',
    },

    '& .MuiOutlinedInput-notchedOutline legend': {
      width: 0,
    },
  },
}));

/**
 * Collect or adjust service packet data.
 */
export const ServicePacketDrawer = ({
  onClose,
  onSubmit: onSubmitExternal,
  servicePacket: originalServicePacket,
  className,
  ...drawerProps
}: Merge<MuiDrawerProps, ServicePacketDrawerProps>) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const {
    control,
    handleSubmit,
    formState: { errors, touchedFields, isDirty },
    reset,
    watch,
    setValue,
  } = useForm<ServicePacket>({
    defaultValues: {
      name: originalServicePacket?.name ?? '',
      country: originalServicePacket?.country ?? ('' as CountryCode),
      positions: originalServicePacket?.positions ?? [],
      source: originalServicePacket?.source ?? ('' as SourceType),
      description: originalServicePacket?.description ?? '',
    },
  });

  const onSubmit = handleSubmit((packetData) => {
    onSubmitExternal({
      ...packetData,
      clientId: originalServicePacket?.clientId ?? nanoid(),
      positions: packetData.positions ?? [], // defaultValue from `useForm` somehow has no effect
    });
  });

  const {
    fields: positionFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'positions',
  });

  const positions = watch('positions');

  const { data: sources = [] } = useSources();
  const sourceOptions = getSourceOptions(sources);

  const handleClose = () => {
    onClose();
    reset(originalServicePacket);
  };

  useEffect(() => {
    if (!Object.keys(touchedFields).length && !isDirty) {
      reset(originalServicePacket);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originalServicePacket, reset]);

  return (
    <Drawer anchor="right" onClose={handleClose} className={clsx(classes.root, className)} {...drawerProps}>
      <DrawerHeader>
        <Typography>
          {originalServicePacket !== undefined
            ? t('servicePacketDrawer.title.edit')
            : t('servicePacketDrawer.title.create')}
        </Typography>
      </DrawerHeader>
      <DrawerContent>
        <form id="service-packet-edit-form" noValidate autoComplete="off" onSubmit={onSubmit}>
          <Stack spacing={3}>
            <FormControl size="small" fullWidth variant="outlined">
              <InputLabel required>{t('common.name')}</InputLabel>
              <Controller
                name="name"
                control={control}
                rules={{ required: { value: true, message: t('validation.required') } }}
                render={({ field: { ref, ...field } }) => (
                  <OutlinedInput error={!!errors.name} inputRef={ref} {...field} />
                )}
              />
              {errors.name?.message ? <FormHelperText error>{errors.name.message}</FormHelperText> : null}
            </FormControl>

            <FormControl size="small" fullWidth variant="outlined">
              <InputLabel>{t('common.description')}</InputLabel>
              <Controller
                name="description"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <OutlinedInput multiline minRows={3} inputRef={ref} {...field} />
                )}
              />
              {errors.description ? <FormHelperText error>{errors.description.type}</FormHelperText> : null}
            </FormControl>

            <Stack direction="row" spacing={3}>
              <FormControl size="small" fullWidth variant="outlined">
                <InputLabel required>
                  {t('servicePacketDrawer.form.country.label')}
                  {originalServicePacket?.country ? ` (${t('common.readonly')})` : null}
                </InputLabel>
                <Controller
                  name="country"
                  control={control}
                  rules={{ required: { value: true, message: t('validation.required') } }}
                  render={({ field: { ref, ...field } }) => (
                    <Autocomplete
                      {...field}
                      inputRef={ref}
                      label=""
                      disabled={Boolean(originalServicePacket?.country)}
                      optionIcon={(option) => getIcon(IconType.COUNTRY, option)}
                      error={!!errors.country}
                      helperText={errors.country && errors.country.message}
                      options={countryCodeToOptions(COUNTRY_ORIGIN_FILTER_COUNTRIES, i18n.language).sort((a, b) =>
                        a.label.localeCompare(b.label, i18n.language),
                      )}
                      getOptionLabel={(option: string) =>
                        (countries.getName(option, i18n.language) as string) || option
                      }
                      disableCloseOnSelect={false}
                      disableClearable
                    />
                  )}
                />
              </FormControl>

              <FormControl size="small" fullWidth variant="outlined">
                <InputLabel required>
                  {t('common.source')}
                  {originalServicePacket?.source ? ` (${t('common.readonly')})` : null}
                </InputLabel>
                <Controller
                  control={control}
                  name="source"
                  rules={{ required: { value: true, message: t('validation.required') } }}
                  render={({ field: { ref, ...field } }) => (
                    <Select
                      className={classes.source}
                      disabled={Boolean(originalServicePacket?.source)}
                      label={null}
                      options={sourceOptions}
                      inputRef={ref}
                      {...field}
                      value={field.value}
                      error={errors.source}
                    />
                  )}
                />
              </FormControl>
            </Stack>

            <FormControl size="small" fullWidth variant="outlined" className={classes.positions}>
              <InputLabel>
                <Stack direction="row" spacing={2} justifyContent="space-between">
                  {t('servicePacketDrawer.form.positions.label')}{' '}
                  {positionFields.length > 0 && (
                    <ServicePositionTotal positions={positions} className={classes.positionsTotal} />
                  )}
                </Stack>
              </InputLabel>
              <Stack spacing={2}>
                {positionFields.length > 0 && ( // prevent uneven spacing
                  <Stack spacing={1}>
                    {positionFields.map((field, index) => (
                      <ServicePositionItem
                        key={field.id}
                        name={positions[index].name ?? ''}
                        description={positions[index].description ?? ''}
                        price={positions[index].price}
                        onChange={(newPosition) => setValue(`positions.${index}`, newPosition)}
                        onClickDelete={() => remove(index)}
                      />
                    ))}
                  </Stack>
                )}

                <Button
                  onClick={() => append({ price: { currency: 'EUR' }, description: '' })}
                  startIcon={<AddIcon />}
                  size="small"
                  color="secondary"
                  variant="contained"
                >
                  {t('servicePacketDrawer.actions.addPosition')}
                </Button>
              </Stack>
            </FormControl>
          </Stack>
        </form>
      </DrawerContent>
      <DrawerFooter>
        <Grid container justifyContent="space-between">
          <Button size="small" variant="outlined" color="secondary" onClick={handleClose}>
            {t('common.cancel')}
          </Button>
          <Button type="submit" form="service-packet-edit-form" size="small" variant="contained" color="primary">
            {t('common.save')}
          </Button>
        </Grid>
      </DrawerFooter>
    </Drawer>
  );
};
