import { memo } from 'react';
import { damageTypes, getLabel } from '../modules/labels';
import { DamageType } from '../modules/generated/api';
import HighlightIcon from './HighlightIcon';
import { IconType } from '../modules/data';

type DamageTypeHighlightsProps = {
  damageType?: DamageType[];
};

const DamageTypeHighlights = ({ damageType = [] }: DamageTypeHighlightsProps) => (
  <>
    {damageType.map((d) => (
      <HighlightIcon key={d} type={IconType.DAMAGE_TYPE} label={getLabel(damageTypes, d)} value={d} />
    ))}
  </>
);

export default memo(DamageTypeHighlights);
