import { Typography, makeStyles } from '@material-ui/core';
import { DeliveryAddressDTO } from '../modules/generated/api/api';

const useStyles = makeStyles(() => ({
  tableCell: {
    paddingLeft: '10px',
    '& *': {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
}));

type CarsTableDeliveryAddressProps = {
  address?: DeliveryAddressDTO;
};

const CarsTableDeliveryAddress = ({ address }: CarsTableDeliveryAddressProps) => {
  const classes = useStyles();

  return (
    <div className={classes.tableCell}>
      {address && (
        <Typography variant="body2">
          <div key={address.id}>
            <Typography variant="body2">{address.companyName}</Typography>
            <Typography variant="body2">{address.street}</Typography>
            <Typography variant="body2">
              {address.zip} {address.city}
            </Typography>
          </div>
        </Typography>
      )}
    </div>
  );
};

export default CarsTableDeliveryAddress;
