import { isArray, isBoolean, isEmpty, isEqual, isNil, isNumber, isObject, omit, omitBy } from 'lodash';
import { DateTime } from 'luxon';
import { CacheKeys } from 'src/constants';
import { persistValue } from 'src/lib/localStorage';
import { DEFAULT_CURRENCY, makeMonetaryAmount } from 'src/modules/currency';
import {
  BidFilterStatus,
  BidSuccessStatus,
  BidVisibility,
  BuyableStatus,
  CountryCode,
  DateFilter,
  ExtendedUserDTO,
  MonetaryAmount,
  NormalizedTransmission,
  OfferType,
  OnlineCarsRequestDTO,
  SourceType,
  TaxType,
  ValidityType,
} from 'src/modules/generated/api';
import { PotentialFilterType, bidSuccessStatusFilterable, potentialFilters } from 'src/modules/labels';
import { CarsFilterSettings } from 'src/types/CarsFilterSettings';
import { ApiCarsFilterSettings } from 'src/types/FilterSettings';
import { isDefined } from '../lib';
import { BidTypes } from '../types';
import { BidsTableNextFilterData, CarsTableNextFilterData, FilterDef } from './types';

// TODO: Simplify transformer conditionals

const potentialInternetPriceKeys = potentialFilters.flatMap(({ value: base }) => [
  { base, key: `${base}AbsoluteMin` as keyof ApiCarsFilterSettings },
  { base, key: `${base}AbsoluteMax` as keyof ApiCarsFilterSettings },
  { base, key: `${base}RelativeMin` as keyof ApiCarsFilterSettings },
  { base, key: `${base}RelativeMax` as keyof ApiCarsFilterSettings },
]);

const omitEmpty = (data: Record<string, any>) =>
  omitBy(data, (value) => isNil(value) || value === '' || (Array.isArray(value) && value.length === 0));

/**
 * CarsTableNext dropped support for "ALL" enum, as it's supported via Nil values now
 */
const omitLegacyAllSafe = <TValue extends string>(value: TValue | undefined): TValue | undefined =>
  value === 'ALL' ? undefined : value;

export const transformForSubmit = (data: CarsTableNextFilterData): OnlineCarsRequestDTO => {
  const { potentialFilter, startDateFilter, endDateFilter } = data;

  // omit range slider fields and potential fields
  const primitiveFilters = omit(
    data,
    // range-slider
    'powerKw',
    'engineCo2Nedc',
    'engineCo2Wltp',
    'mileage',
    'priceDamage',
    'purchasePrice',
    'registrationDate',
    'numAuctions',
    'purchasePriceGross',
    'potential',
    // potential fields
    'potentialFilterAbsoluteMin',
    'potentialFilterAbsoluteMax',
    'potentialFilterRelativeMin',
    'potentialFilterRelativeMax',
    // legacy fields
    'damageType',
    // special fields
    'originSource',
  );

  const [powerKwMin, powerKwMax] = data.powerKw ?? [];
  const [mileageMin, mileageMax] = data.mileage ?? [];
  const [numAuctionsMin, numAuctionsMax] = data.numAuctions ?? [];
  const [engineCo2NedcMin, engineCo2NedcMax] = data.engineCo2Nedc ?? [];
  const [engineCo2WltpMin, engineCo2WltpMax] = data.engineCo2Wltp ?? [];

  const splittedOriginSource = data.originSource?.map((item) => item.split(','))?.flat() ?? []; // every array of originSource item should be splitted by "," and transformed into one array

  let transformed: OnlineCarsRequestDTO = {
    ...primitiveFilters,
    startDateFrom: startDateFilter === DateFilter.Individual ? data.startDateFrom : undefined,
    startDateTo: startDateFilter === DateFilter.Individual ? data.startDateTo : undefined,
    endDateFrom: endDateFilter === DateFilter.Individual ? data.endDateFrom : undefined,
    endDateTo: endDateFilter === DateFilter.Individual ? data.endDateTo : undefined,
    search: data?.search && {
      regex: data.search.regex,
      value: data?.search?.value.trim(),
    },
    carIds: data.search.vins,
    normalizedTransmission: data.normalizedTransmission && [data.normalizedTransmission],
    searchExact: data.search?.regex,
    // Transform empty string to undefined or zero fo min values
    powerKwMin: powerKwMin || 0,
    powerKwMax: powerKwMax || undefined,
    mileageMin: mileageMin || 0,
    mileageMax: mileageMax || undefined,
    priceDamageMin: data.priceDamage?.at(0),
    priceDamageMax: data.priceDamage?.at(1),
    purchasePriceGrossMin: data.purchasePriceGross?.at(0),
    purchasePriceGrossMax: data.purchasePriceGross?.at(1),
    numAuctionsMin: numAuctionsMin || 0,
    numAuctionsMax: numAuctionsMax || undefined,
    registrationDateMin: data.registrationDate?.[0],
    registrationDateMax: data.registrationDate?.[1],
    engineCo2NedcMin: engineCo2NedcMin || 0, // zero needs to be sent to the backend to show all cars
    engineCo2NedcMax: engineCo2NedcMax || undefined,
    engineCo2WltpMin: engineCo2WltpMin || 0,
    engineCo2WltpMax: engineCo2WltpMax || undefined,
    originSource: splittedOriginSource,
  };

  if (potentialFilter) {
    transformed = {
      ...transformed,
      [`${potentialFilter}AbsoluteMin`]: data.potentialFilterAbsoluteMin,
      [`${potentialFilter}AbsoluteMax`]: data.potentialFilterAbsoluteMax,
      [`${potentialFilter}RelativeMin`]: data.potentialFilterRelativeMin,
      [`${potentialFilter}RelativeMax`]: data.potentialFilterRelativeMax,
    };
  }

  return omitEmpty(transformed);
};

export const transformForForm = (data: OnlineCarsRequestDTO & { id?: string }): CarsTableNextFilterData => {
  let potentialFilter: Partial<
    Pick<
      CarsFilterSettings,
      | 'potentialFilter'
      | 'potentialFilterAbsoluteMin'
      | 'potentialFilterAbsoluteMax'
      | 'potentialFilterRelativeMin'
      | 'potentialFilterRelativeMax'
    >
  > = {};

  // eslint-disable-next-line no-restricted-syntax
  for (const potentialInternetPriceKey of potentialInternetPriceKeys) {
    if (data[potentialInternetPriceKey.key] !== undefined) {
      const potentialFilterAbsoluteMin = data[
        `${potentialInternetPriceKey.base}AbsoluteMin` as keyof typeof data
      ] as MonetaryAmount;
      const potentialFilterAbsoluteMax = data[
        `${potentialInternetPriceKey.base}AbsoluteMax` as keyof typeof data
      ] as MonetaryAmount;

      potentialFilter = {
        potentialFilter: potentialInternetPriceKey.base as PotentialFilterType,
        potentialFilterAbsoluteMin:
          potentialFilterAbsoluteMin !== undefined ? makeMonetaryAmount(potentialFilterAbsoluteMin) : undefined,
        potentialFilterAbsoluteMax:
          potentialFilterAbsoluteMax !== undefined ? makeMonetaryAmount(potentialFilterAbsoluteMax) : undefined,
        potentialFilterRelativeMin:
          data[`${potentialInternetPriceKey.base}RelativeMin` as 'potentialInternetPriceAverageRelativeMin'],
        potentialFilterRelativeMax:
          data[`${potentialInternetPriceKey.base}RelativeMax` as 'potentialInternetPriceAverageRelativeMax'],
      };

      break;
    }
  }

  // This code groups the originSource array elements to a string if the first word is the same
  // and connect them with ",". Because in the Form you select all keys of one group all at once
  const groupedOriginSource = // group the array elements to a string if the first word is the same and connect them with ","
    data.originSource
      ?.reduce((acc, item) => {
        const [firstWord] = item.split(' ');
        const lastItem = acc[acc.length - 1];

        if (lastItem && lastItem[0].startsWith(firstWord)) {
          lastItem.push(item);
        } else {
          acc.push([item]);
        }

        return acc;
      }, [] as string[][])
      .map((item) => item.join(','));

  // TODO: merge with default values instead
  const transformed: CarsTableNextFilterData = {
    id: data.id,
    search: {
      value: data.search?.value ?? '',
      regex: data.searchExact ?? data.search?.regex ?? false,
      vins: data.carIds ?? [],
    },
    startDateFilter: omitLegacyAllSafe(data.startDateFilter) ?? ('' as DateFilter),
    startDateFrom: data.startDateFrom,
    startDateTo: data.startDateTo,
    endDateFilter: omitLegacyAllSafe(data.endDateFilter) ?? ('' as DateFilter),
    endDateFrom: data.endDateFrom,
    endDateTo: data.endDateTo,
    source: data.source ?? [],
    originSource: groupedOriginSource ?? [],
    marketingChannel: data.marketingChannel ?? [],
    promotions: data.promotions ?? [],
    countryOrigin: data.countryOrigin ?? [],
    normalizedBrand: data.normalizedBrand ?? [],
    normalizedModel: data.normalizedModel ?? [],
    normalizedFuelDetail: data.normalizedFuelDetail ?? [],
    normalizedEquipmentLine: data.normalizedEquipmentLine ?? [],
    normalizedVersion: data.normalizedVersion ?? [],
    equipments: data.equipments ?? [],
    equipmentCodes: data.equipmentCodes ?? [],
    colors: data.colors ?? [],
    taxType: data.taxType ?? [],
    validity: omitLegacyAllSafe(data.validity) ?? ('' as ValidityType),
    offerType: omitLegacyAllSafe(data.offerType) ?? ('' as OfferType),
    buyableStatus: omitLegacyAllSafe(data.buyableStatus) ?? ('' as BuyableStatus),
    ratingMin: data.ratingMin ?? 0,
    hasUserComment: data.hasUserComment ?? false,
    anyOtherUserRatingMin: data.anyOtherUserRatingMin ?? 0,
    hasAnyOtherUserComment: data.hasAnyOtherUserComment ?? false,
    currentBidState: omitLegacyAllSafe(data.currentBidState) ?? ('' as BidFilterStatus),
    expiredBidState: omitLegacyAllSafe(data.expiredBidState) ?? ('' as BidFilterStatus),
    normalizedTransmission: omitLegacyAllSafe(data.normalizedTransmission?.[0]) ?? ('' as NormalizedTransmission),
    powerKw:
      data.powerKwMin !== undefined && data.powerKwMax !== undefined ? [data.powerKwMin, data.powerKwMax] : undefined,
    engineCo2Nedc:
      // eslint-disable-next-line no-nested-ternary
      data.engineCo2Min !== undefined && data.engineCo2Max !== undefined
        ? [data.engineCo2Min, data.engineCo2Max]
        : data.engineCo2NedcMax !== undefined
          ? [data.engineCo2NedcMin || 0, data.engineCo2NedcMax]
          : undefined,
    engineCo2Wltp:
      // eslint-disable-next-line no-nested-ternary
      data.engineCo2Min !== undefined && data.engineCo2Max !== undefined
        ? [data.engineCo2Min, data.engineCo2Max]
        : data.engineCo2WltpMax !== undefined
          ? [data.engineCo2WltpMin || 0, data.engineCo2WltpMax]
          : undefined,
    mileage: data.mileageMax !== undefined ? [data.mileageMin || 0, data.mileageMax] : undefined,
    numAuctions: data.numAuctionsMax !== undefined ? [data.numAuctionsMin || 0, data.numAuctionsMax] : undefined,
    registrationDate: [
      data.registrationDateMin ?? DateTime.fromISO('1990-01-01').toISODate(),
      data.registrationDateMax ?? DateTime.now().plus({ month: 1 }).endOf('month').toISODate(),
    ],
    priceDamage:
      data.priceDamageMin !== undefined && data.priceDamageMax !== undefined
        ? [makeMonetaryAmount(data.priceDamageMin), makeMonetaryAmount(data.priceDamageMax)]
        : [makeMonetaryAmount(0, DEFAULT_CURRENCY), makeMonetaryAmount(25_000, DEFAULT_CURRENCY)],
    purchasePriceGross:
      data.purchasePriceGrossMin !== undefined && data.purchasePriceGrossMax !== undefined
        ? [makeMonetaryAmount(data.purchasePriceGrossMin), makeMonetaryAmount(data.purchasePriceGrossMax)]
        : [makeMonetaryAmount(0, DEFAULT_CURRENCY), makeMonetaryAmount(200_000, DEFAULT_CURRENCY)],
    potentialFilter: potentialFilter.potentialFilter ?? null,
    ...potentialFilter,
  };

  return transformed;
};

export const getActiveCount = (filterDef: FilterDef[], values: Record<string, unknown>, defaults: Object): number => {
  let counter = 0;
  // count all filters besides sliders that are on default
  filterDef.forEach((def) => {
    const value = values[def.name as keyof typeof values];
    const defaultValue = defaults?.[def.name as keyof typeof defaults];

    const isSimpleCurrencySlider = isArray(value) && isObject(value[0]);
    const isMinMaxCurrencySlider = isObject(value) && !isArray(value);

    if (isSimpleCurrencySlider || isMinMaxCurrencySlider || isBoolean(value)) {
      // sliders and true/false (comment) count only if they are not default
      if (!isEqual(defaultValue, value)) {
        counter += 1;
      }
    } else if (isNumber(value)) {
      // for ratings
      if (value > 0) counter += 1;
    } else if (!isEmpty(value)) counter += 1;
  });

  return counter;
};

const firstCallDefaultCountries = [
  CountryCode.At,
  CountryCode.It,
  CountryCode.Es,
  CountryCode.Pt,
  CountryCode.Sk,
  CountryCode.Bg,
  CountryCode.De,
];

export const getDefaultSource = ({
  sources,
  country,
  allowed,
  isAdmin,
}: {
  sources?: SourceType[];
  country?: CountryCode;
  allowed?: SourceType[];
  isAdmin: boolean;
}): SourceType[] => {
  if (isAdmin) {
    return [
      sources !== undefined && sources.includes(SourceType.Vwfs) && SourceType.Vwfs,
      sources !== undefined &&
        sources.includes(SourceType.RealFirstCallOther) &&
        country !== undefined &&
        firstCallDefaultCountries.includes(country) &&
        SourceType.RealFirstCallOther,
      sources !== undefined &&
        sources.includes(SourceType.RealFirstCallOwn) &&
        country !== undefined &&
        country === CountryCode.De &&
        SourceType.RealFirstCallOwn,
      sources !== undefined && sources.includes(SourceType.SpotdealsAllmobil) && SourceType.SpotdealsAllmobil,
      sources !== undefined && sources.includes(SourceType.VwfsDkVirt) && SourceType.VwfsDkVirt,
      sources !== undefined && sources.includes(SourceType.VwfsItVirt) && SourceType.VwfsItVirt,
      sources !== undefined && sources.includes(SourceType.VwfsSkVirt) && SourceType.VwfsSkVirt,
    ].filter(isDefined);
  }
  return allowed?.filter((source) => sources?.includes(source)) ?? [];
};

export const patchRegistrationDate = (date: number | string) => {
  // Previous `registrationDate` filter values might only contain the year as INT.
  // In order to provide a smooth upgrade experience we upsert the prev state to
  // the current type.
  // 1) Year (number) --> ISO Date (string)
  if (typeof date === 'number') {
    return DateTime.now().set({ year: date }).toISODate();
  }
  return date;
};

export const transformPersistedFilter = (prev: CarsTableNextFilterData) => {
  if (!prev) {
    return prev;
  }

  const { registrationDate: [minRegistrationDate, maxRegistrationDate] = [] } = prev;

  return {
    ...prev,
    registrationDate:
      minRegistrationDate !== undefined && maxRegistrationDate !== undefined
        ? ([patchRegistrationDate(minRegistrationDate), patchRegistrationDate(maxRegistrationDate)] as [string, string])
        : undefined,
  };
};

export const getDefaultFilter = ({
  sources,
  user,
  admin = false,
}: {
  sources?: SourceType[];
  user?: ExtendedUserDTO;
  admin?: boolean;
} = {}): CarsTableNextFilterData => ({
  search: {
    value: '',
    regex: false,
    vins: [],
  },
  source: getDefaultSource({ sources, country: user?.dealer?.country, allowed: user?.allowedSources, isAdmin: admin }),
  promotions: [],
  marketingChannel: [],
  registrationDate: ['1990-01-01', DateTime.now().plus({ month: 1 }).endOf('month').toFormat('yyyy-MM-dd')],
  mileage: [0, 250000],
  engineCo2Wltp: [0, 400],
  engineCo2Nedc: [0, 400],
  numAuctions: [0, 50],
  powerKw: [0, 500],
  priceDamage: [makeMonetaryAmount(0, DEFAULT_CURRENCY), makeMonetaryAmount(25_000, DEFAULT_CURRENCY)],
  purchasePriceGross: [makeMonetaryAmount(0, DEFAULT_CURRENCY), makeMonetaryAmount(1000000, DEFAULT_CURRENCY)],
  countryOrigin: [],
  normalizedBrand: [],
  normalizedModel: [],
  normalizedFuelDetail: [],
  normalizedTransmission: '' as NormalizedTransmission,
  normalizedEquipmentLine: [],
  normalizedVersion: [],
  equipments: [],
  equipmentCodes: [],
  colors: [],
  taxType: [TaxType.Regelbesteuert],
  validity: ValidityType.Current,
  offerType: '' as OfferType,
  currentBidState: '' as BidFilterStatus,
  expiredBidState: '' as BidFilterStatus,
  ratingMin: 0,
  anyOtherUserRatingMin: 0,
  buyableStatus: '' as BuyableStatus,
  hasUserComment: false,
  hasAnyOtherUserComment: false,
  originSource: [],
  potentialFilter: null,
  startDateFilter: '' as DateFilter,
  startDateFrom: DateTime.now().startOf('day').toISO(),
  startDateTo: DateTime.now().plus({ hour: 1 }).startOf('hour').toISO(),
  endDateFilter: '' as DateFilter,
  endDateFrom: DateTime.now().startOf('day').toISO(),
  endDateTo: DateTime.now().endOf('day').toISO(),
});

export const getDefaultBidsFilter = ({
  sources,
  user,
  bidType,
  admin = false,
}: {
  sources?: SourceType[];
  user?: ExtendedUserDTO;
  admin?: boolean;
  bidType?: BidTypes;
} = {}): BidsTableNextFilterData => ({
  carsRequest: {
    search: {
      value: '',
      regex: false,
      vins: [],
    },
    source: getDefaultSource({
      sources,
      country: user?.dealer?.country,
      allowed: user?.allowedSources,
      isAdmin: admin,
    }),
    promotions: [],
    marketingChannel: [],
    registrationDate: ['1990-01-01', DateTime.now().plus({ month: 1 }).endOf('month').toFormat('yyyy-MM-dd')],
    mileage: [0, 250000],
    engineCo2Wltp: [0, 400],
    engineCo2Nedc: [0, 400],
    numAuctions: [0, 50],
    powerKw: [0, 500],
    priceDamage: [makeMonetaryAmount(0, DEFAULT_CURRENCY), makeMonetaryAmount(25_000, DEFAULT_CURRENCY)],
    purchasePriceGross: [makeMonetaryAmount(0, DEFAULT_CURRENCY), makeMonetaryAmount(1000000, DEFAULT_CURRENCY)],
    countryOrigin: [],
    normalizedBrand: [],
    normalizedModel: [],
    normalizedFuelDetail: [],
    normalizedTransmission: '' as NormalizedTransmission,
    normalizedEquipmentLine: [],
    normalizedVersion: [],
    equipments: [],
    equipmentCodes: [],
    colors: [],
    taxType: [],
    validity: '' as ValidityType,
    offerType: '' as OfferType,
    currentBidState: '' as BidFilterStatus,
    expiredBidState: '' as BidFilterStatus,
    ratingMin: 0,
    anyOtherUserRatingMin: 0,
    buyableStatus: BuyableStatus.All,
    hasUserComment: false,
    hasAnyOtherUserComment: false,
    originSource: [],
    potentialFilter: null,
  },
  bidSuccessStates:
    // eslint-disable-next-line no-nested-ternary
    bidType === BidTypes.Past
      ? [...bidSuccessStatusFilterable]
      : bidType === BidTypes.Pending
        ? [BidSuccessStatus.Scheduled]
        : [BidSuccessStatus.Bought],
  bidVisibility: admin ? BidVisibility.All : BidVisibility.User,
  sentTimeFilter: {
    timeFrom: DateTime.now().startOf('year').toISO(),
    timeTo: DateTime.now().toISO(),
    timeType: DateFilter.All,
  },
  biddingTimeFilter: {
    timeFrom: DateTime.now().startOf('year').toISO(),
    timeTo: DateTime.now().toISO(),
    timeType: DateFilter.All,
  },
  boughtTimeFilter: {
    timeFrom: DateTime.now().startOf('year').toISO(),
    timeTo: DateTime.now().toISO(),
    timeType: DateFilter.All,
  },
  bidCreatorDealerCountry: [],
  bidCreatorDealerId: [],
  bidCreatorUserId: [],
});

export function persistFilter(filter: CarsTableNextFilterData) {
  persistValue(CacheKeys.carsTableNextFilter, filter);
}

export function persistBidsPendingFilter(filter: BidsTableNextFilterData) {
  persistValue(CacheKeys.bidsPendingTableNextFilter, filter);
}

export function persistBidsPastFilter(filter: BidsTableNextFilterData) {
  persistValue(CacheKeys.bidsPastTableNextFilter, filter);
}

export function persistBidsOrderedFilter(filter: BidsTableNextFilterData) {
  persistValue(CacheKeys.bidsOrderedTableNextFilter, filter);
}
