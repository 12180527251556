import { Address } from '@components/ui/address/types';
import { Button, Card, CardActions, CardContent, Chip, makeStyles, Typography } from '@material-ui/core';
import { isFunction, isNil } from 'lodash';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddressDrawer } from './AddressDrawer';

type AddressCardProps = Address & {
  title: string;
  onEdit?: (newAddress: Address) => void;
  onDelete?: () => void;
  onSetDefault?: () => void;
  isGlobal?: boolean;
  /**
   * Additional content, rendered below the address.
   */
  content?: ReactNode;
};

const useStyles = makeStyles((theme) => ({
  root: {
    // Forcing cards with same height leads to action bar not sticking to the bottom
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  name: {
    fontWeight: 'bold',
  },
  actions: {
    flexWrap: 'wrap',
    gap: theme.spacing(1),
  },
  action: {
    // fix precedence
    '&&': {
      margin: 0, // handle spacing via gap
    },
  },
}));

export const AddressCard = ({
  title,
  name,
  companyName,
  street,
  city,
  zip,
  country,
  countryCode,
  email,
  phoneNumber,
  faxNumber,
  isGlobal,
  content,
  onEdit,
  onDelete,
  onSetDefault,
}: AddressCardProps): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleSubmitDrawer = (newAddress: Address) => {
    onEdit?.(newAddress);
    setDrawerOpen(false);
  };

  return (
    <>
      <Card className={classes.root}>
        <CardContent>
          <Typography className={classes.name} gutterBottom>
            {name} {isGlobal && <Chip label={t('common.default')} size="small" color="primary" />}
          </Typography>
          <Typography variant="body2" gutterBottom>
            {companyName}
          </Typography>
          <Typography variant="body2" gutterBottom>
            {street}
          </Typography>
          <Typography variant="body2" gutterBottom>
            {city && zip && `${city},${zip}`}
          </Typography>
          <Typography variant="body2" gutterBottom>
            {[country, countryCode].filter((c) => c !== undefined).join('•')}
          </Typography>
          <Typography variant="body2" gutterBottom>
            {email && `${t('contact.E-Mail')}:`} {email}
          </Typography>
          <Typography variant="body2" gutterBottom={!isNil(faxNumber)}>
            {email && `${t('contact.phone')}:`} {phoneNumber}
          </Typography>
          {!isNil(faxNumber) && (
            <Typography variant="body2">
              {t('contact.fax')}: {faxNumber || '-'}
            </Typography>
          )}
          {content}
        </CardContent>
        <CardActions className={classes.actions}>
          {isFunction(onEdit) && (
            <Button
              className={classes.action}
              variant="outlined"
              color="secondary"
              size="small"
              onClick={() => setDrawerOpen(true)}
            >
              {t('action.edit')}
            </Button>
          )}
          {isFunction(onDelete) && (
            <Button className={classes.action} variant="outlined" color="secondary" size="small" onClick={onDelete}>
              {t('action.delete')}
            </Button>
          )}
          {!isGlobal && (
            <Button className={classes.action} variant="outlined" color="secondary" size="small" onClick={onSetDefault}>
              {t('addressCard.actions.onSetDefault')}
            </Button>
          )}
        </CardActions>
      </Card>

      {isFunction(onEdit) && (
        <AddressDrawer
          title={title}
          open={drawerOpen}
          onSubmit={handleSubmitDrawer}
          onClose={() => setDrawerOpen(false)}
          address={{
            name,
            companyName,
            street,
            city,
            zip,
            country,
            countryCode,
            email,
            phoneNumber,
            faxNumber,
          }}
        />
      )}
    </>
  );
};
