import { Divider, makeStyles, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { isNumber } from 'lodash';
import clsx from 'clsx';
import { green, red } from '@material-ui/core/colors';
import { BidTableType } from '../setup/bids-filter-settings';
import { BidsListCarDTO } from '../modules/table-data';
import { getChannel } from '../modules/unit-helpers';
import UnitValue from './UnitValue';
import CarsPotentialValues from './CarsPotentialValues';
import { PriceType } from '../modules/generated/api';
import { ListCarDtoSafe, useTableCalculator } from '../hooks/useTableCalculator';

const useStyles = makeStyles(() => ({
  root: { textAlign: 'right' },
  iconValue: {
    display: 'flex',
    gap: '5px',
    alignItems: 'center',
    justifyContent: 'flex-end',
    fontWeight: 'normal',
    marginTop: '-25px',
    '& svg': {
      filter: ' invert(46%) sepia(0%) saturate(15%) hue-rotate(249deg) brightness(98%) contrast(97%);',
      width: '15px',
    },
  },
  crossText: {
    textDecoration: 'line-through',
  },
  diff: {
    fontWeight: 'normal',
    '&-plus': {
      color: green[600],
    },
    '&-minus': {
      color: red[600],
    },
  },
}));

type BaseFixedOrientationPriceCellProps = {
  bidTableType: BidTableType | undefined;
  car: BidsListCarDTO;
};

export const BaseFixedOrientationPriceCell = ({ bidTableType, car }: BaseFixedOrientationPriceCellProps) => {
  const classes = useStyles();
  const { isEdit, toCalcCar } = useTableCalculator();
  const { t } = useTranslation();
  const { marketingChannel = [], oldBasePrice } = car;
  const channel = getChannel(marketingChannel);
  const basePrice = car.potential?.base;
  const showPurchasePrice = bidTableType === 'BoughtBidsTable' && oldBasePrice?.amount !== basePrice?.amount;
  const diff =
    isNumber(oldBasePrice?.amount) &&
    isNumber(basePrice?.amount) &&
    basePrice?.currency === oldBasePrice?.currency &&
    // @ts-ignore
    basePrice.amount - oldBasePrice.amount;

  return (
    <div className={classes.root}>
      {showPurchasePrice && (
        <span className={classes.crossText}>
          {' '}
          <UnitValue value={oldBasePrice?.amount} unit={oldBasePrice?.currency} />
        </span>
      )}

      <CarsPotentialValues
        car={car}
        fixedVariable={PriceType.OfferPrice}
        targetKey="base"
        prefixes={[channel ? `(${channel})` : '', '', '', '']}
      />

      {diff !== undefined && diff !== false && !isEdit(toCalcCar(car as ListCarDtoSafe)) && (
        <>
          <Divider />
          <Tooltip title={t('bids.hintDiffBasePriceBuyingPrice')} placement="bottom" arrow>
            <div className={clsx(classes.diff, diff && classes.diff + (diff < 0 ? '-plus' : '-minus'))}>
              {/* eslint-disable-next-line no-nested-ternary */}
              {diff === 0 ? '' : diff > 0 ? '+' : ''} <UnitValue value={diff} unit={oldBasePrice?.currency} />
            </div>
          </Tooltip>
        </>
      )}
    </div>
  );
};
