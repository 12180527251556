import { ContextType, Dispatch, Reducer, useContext, useMemo, useReducer, createContext } from 'react';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { authenticationStorageKey } from 'src/modules/global-vars';
import WithChildren from '../types/WithChildren';
import UserRole from '../types/UserRoles';
import { AuthenticationProvider, ValuationCountryCode } from '../modules/generated/api';

export type GwscoutJwtPayload = JwtPayload & {
  email: string;
  first_name: string;
  last_name: string;
  dealer_id: string[];
  user_id: number;
  scope: UserRole[];
  authentication_provider: string;
  valuation_country: ValuationCountryCode;
  is_allowed_to_login: boolean;
  country: string;
};

type AuthenticationState = {
  hasAccess: boolean;
  simulateDealer: boolean;
  simulateDealerAdmin: boolean;
  simulateCountryManager: boolean;
  accessToken?: string;
  objectToken?: GwscoutJwtPayload;
};

type AuthenticationAction =
  | { type: 'authenticationStore/SIGN_IN'; payload: string }
  | { type: 'authenticationStore/SIGN_OUT' }
  | { type: 'authenticationStore/TOGGLE_SIMULATION_DEALER' }
  | { type: 'authenticationStore/TOGGLE_SIMULATION_COUNTRYMANAGER' }
  | { type: 'authenticationStore/TOGGLE_SIMULATION_DEALERADMIN' }
  | { type: 'authenticationStore/TOGGLE_SIMULATION_OFF' };

export const AuthenticationProviders = {
  grp: 'GRP',
  partnerNet: 'PARTNER_NET',
};

export const getAccessTokenFromStorage = () =>
  process.env.REACT_APP_ACCESS_TOKEN || localStorage.getItem(authenticationStorageKey) || undefined;

export const getAuthenticationUrl = (portal: AuthenticationProvider) => {
  if (portal === AuthenticationProvider.Grp) return `${process.env.REACT_APP_API_URL || ''}/oauth2/authorization/grp`;
  if (portal === AuthenticationProvider.PartnerNet)
    return `${process.env.REACT_APP_API_URL || ''}/oauth2/authorization/partner-net`;
  return `${process.env.REACT_APP_API_URL || ''}/login`;
};

export const redirectToAuthentication = () => {
  if (window.location.pathname !== '/login') {
    window.location.replace(`${process.env.REACT_APP_API_URL || ''}/login`);
  }
};

const initialAccessToken =
  process.env.REACT_APP_ACCESS_TOKEN || localStorage.getItem(authenticationStorageKey) || undefined;

const initialState: AuthenticationState = {
  hasAccess: !!initialAccessToken,
  simulateDealer: false,
  simulateCountryManager: false,
  simulateDealerAdmin: false,
  accessToken: initialAccessToken,
  objectToken: initialAccessToken ? jwtDecode<GwscoutJwtPayload>(initialAccessToken) : undefined,
};

const initialMapContext: { state: typeof initialState; dispatch: Dispatch<AuthenticationAction> } = {
  state: initialState,
  dispatch: () => {},
};
const Store = createContext(initialMapContext);

export const reducer: Reducer<typeof initialState, AuthenticationAction> = (state, action) => {
  switch (action.type) {
    case 'authenticationStore/SIGN_IN': {
      const { payload: accessToken } = action;

      // Reset Valuation on every login
      localStorage.removeItem('gwscout/CarsTable.valuationCountry');
      localStorage.removeItem('gwscout/CarsTable.valuationType');

      localStorage.setItem(authenticationStorageKey, accessToken);

      return {
        ...state,
        hasAccess: true,
        accessToken,
        objectToken: jwtDecode<GwscoutJwtPayload>(accessToken),
      };
    }
    case 'authenticationStore/SIGN_OUT': {
      localStorage.setItem(authenticationStorageKey, '');

      return { ...state, hasAccess: false, accessToken: undefined, objectToken: undefined };
    }
    case 'authenticationStore/TOGGLE_SIMULATION_DEALER': {
      return { ...state, simulateDealer: true, simulateCountryManager: false, simulateDealerAdmin: false };
    }
    case 'authenticationStore/TOGGLE_SIMULATION_DEALERADMIN': {
      return { ...state, simulateDealer: false, simulateCountryManager: false, simulateDealerAdmin: true };
    }
    case 'authenticationStore/TOGGLE_SIMULATION_COUNTRYMANAGER': {
      return { ...state, simulateDealer: false, simulateCountryManager: true, simulateDealerAdmin: false };
    }
    case 'authenticationStore/TOGGLE_SIMULATION_OFF': {
      return { ...state, simulateDealer: false, simulateCountryManager: false, simulateDealerAdmin: false };
    }
    default:
      return state;
  }
};

const Provider = ({ children }: WithChildren) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(
    () => ({
      state,
      dispatch,
    }),
    [state],
  );

  return <Store.Provider value={value}>{children}</Store.Provider>;
};

export const useAuthenticationStore = (): ContextType<typeof Store> => useContext(Store);

export default Provider;
