import BiddingTimeRadios from '@components/bid-buy/BiddingTimeRadios';
import { Button, InputAdornment, makeStyles, TextField } from '@material-ui/core';
import { FolderOpen, Visibility } from '@material-ui/icons';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  textFieldInput: {
    '& .MuiInputBase-input': {
      color: theme.palette.text.primary,
    },

    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.error.main,
    },
  },
}));

type BidsUploadFormProps = {
  onSubmit: () => void;
};

const BidsUploadForm = ({ onSubmit }: BidsUploadFormProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    register,
    control,
    formState: { errors },
    watch,
  } = useFormContext();
  const file = watch('file');
  const fileName = file && file[0] ? file[0].name.replace('C:\\fakepath\\', '') : '';

  return (
    <form onSubmit={onSubmit}>
      <input id="file" accept="text/csv" type="file" {...register('file', { required: true })} hidden />
      <TextField
        fullWidth
        label={t('bids.csvFile')}
        margin="dense"
        variant="outlined"
        value={fileName}
        disabled
        error={!!errors.file}
        InputLabelProps={{
          disabled: false,
        }}
        InputProps={{
          className: classes.textFieldInput,
          endAdornment: (
            <InputAdornment position="end">
              <Button component="label" htmlFor="file" endIcon={<FolderOpen />}>
                {t('common.chooseFile')}
              </Button>
            </InputAdornment>
          ),
        }}
      />
      <BiddingTimeRadios name="secondsBeforeEnd" control={control} activateForm />

      <Button endIcon={<Visibility />} type="submit" variant="contained" color="primary">
        {t('bids.showPreview')}
      </Button>
    </form>
  );
};

export default BidsUploadForm;
