import { BoughtCarDeliveryAddressForm } from '@components/BoughtCarDeliveryAddressTransportation';
import UnitValue from '@components/UnitValue';
import { CircularProgress, makeStyles, MenuItem, Select } from '@material-ui/core';
import { ReactElement } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BidBuyFormType } from 'src/hooks/useBidBuyForm';
import { getLabel, transportOptionProvider } from 'src/modules/labels';
import { transportOptionLocalStorage } from 'src/modules/global-vars';
import { SourceType, TransportOptionDTO } from '../../modules/generated/api';

const useStyles = makeStyles((theme) => ({
  loadingIcon: {
    position: 'absolute',
    right: theme.spacing(3),
  },
}));

type TransportSelectProps = {
  options?: TransportOptionDTO[];
  className?: string;
  disabled?: boolean;
  source?: SourceType;
  dataIsMissing?: boolean;
  loading?: boolean;
  fullWidth?: boolean;
};

export const TransportSelect = ({
  options = [],
  className,
  disabled,
  source,
  dataIsMissing,
  loading = false,
  fullWidth = false,
}: TransportSelectProps): ReactElement | null => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { control } = useFormContext<BidBuyFormType>();

  return (
    <Controller
      name="selectedTransportOption"
      control={control}
      rules={{ required: source === SourceType.Vwfs }}
      render={({ field }) => (
        <Select
          {...field}
          disabled={disabled || dataIsMissing || loading}
          endAdornment={
            loading && !dataIsMissing ? (
              <CircularProgress className={classes.loadingIcon} size={14} color="secondary" />
            ) : (
              ''
            )
          }
          variant="standard"
          className={className}
          value={field.value || 'empty'}
          fullWidth={fullWidth}
        >
          <MenuItem value="empty" disabled>
            {t('bids.transportOptionSelect')}
          </MenuItem>
          {options?.map((option) => (
            <MenuItem
              key={option.id}
              value={option.id}
              onClick={() => option.provider && localStorage.setItem(transportOptionLocalStorage, option.provider)}
            >
              <div style={{ display: 'flex', width: '100%', gap: 5, alignItems: 'center' }}>
                {getLabel(transportOptionProvider, option.provider || '')}
                {option.cost && (
                  <span>
                    (
                    <UnitValue
                      minFractionDigits={2}
                      maxFractionDigits={2}
                      value={option.cost.amount}
                      unit={option.cost.currency}
                    />
                    )
                  </span>
                )}
              </div>
            </MenuItem>
          ))}
        </Select>
      )}
    />
  );
};

type TransportSelectBoughtCarProps = {
  options?: TransportOptionDTO[];
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  fullWidth?: boolean;
  preSelectedId?: string;
};

export const TransportSelectBoughtCar = ({
  options = [],
  className,
  disabled,
  loading = false,
  fullWidth = false,
  preSelectedId,
}: TransportSelectBoughtCarProps): ReactElement | null => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { control } = useFormContext<BoughtCarDeliveryAddressForm>();
  return (
    <Controller
      name="transportOptionId"
      control={control}
      rules={{ required: true }}
      defaultValue={preSelectedId}
      render={({ field }) => (
        <Select
          {...field}
          disabled={disabled || loading}
          endAdornment={loading ? <CircularProgress className={classes.loadingIcon} size={14} color="secondary" /> : ''}
          variant="standard"
          className={className}
          value={field.value || 'empty'}
          fullWidth={fullWidth}
        >
          <MenuItem value="empty" disabled>
            {t('bids.transportOptionSelect')}
          </MenuItem>
          {options?.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              <div style={{ display: 'flex', width: '100%', gap: 5, alignItems: 'center' }}>
                {getLabel(transportOptionProvider, option.provider || '')}
                {option.cost && (
                  <span>
                    (
                    <UnitValue
                      minFractionDigits={2}
                      maxFractionDigits={2}
                      value={option.cost.amount}
                      unit={option.cost.currency}
                    />
                    )
                  </span>
                )}
              </div>
            </MenuItem>
          ))}
        </Select>
      )}
    />
  );
};
