import { Avatar, Button } from '@material-ui/core';
import { Callout, CalloutAdornment, CalloutBody, CalloutContent, CalloutText, CalloutTitle } from '@components/ui';
import { useTranslation } from 'react-i18next';

export const SupportCallout = () => {
  const { t } = useTranslation();

  return (
    <Callout>
      <CalloutContent>
        <CalloutAdornment>
          <Avatar />
        </CalloutAdornment>
        <CalloutBody>
          <CalloutTitle>{t('helpCenter.callout.title')}</CalloutTitle>
          <CalloutText>{t('helpCenter.callout.bodyText')}</CalloutText>
        </CalloutBody>
        <Button
          variant="contained"
          color="secondary"
          component="a"
          href="mailto:irisscout@porsche.co.at"
          target="_blank"
        >
          {t('helpCenter.callout.contactButton.label')}
        </Button>
      </CalloutContent>
    </Callout>
  );
};
