import { Divider, Grid, IconButton, makeStyles, TextField } from '@material-ui/core';
import { Check, Edit } from '@material-ui/icons';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useLocation } from '@reach/router';
import clsx from 'clsx';
import { Dispatch, memo, SetStateAction, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TagManager from 'react-gtm-module';
import UserRating from '../components/UserRating';
import ApiService from '../modules/api-service';
import { DetailCarDTO, SourceType, ValuationCountryCode } from '../modules/generated/api';
import { useCarsTableStore } from '../stores/CarsTable';
import CarsTableCellExternalUserInformation from '../components/CarsTableCellExternalUserInformation';
import useCustomSnackbarGlobal from '../hooks/useSnackbarGlobal';
import useApi from '../hooks/useApi';
import { getCarDetailsTM } from '../modules/tag-manager-helpers';
import UserRole from '../types/UserRoles';
import { DEFAULT_VALUATION_COUNTRY } from '../modules/data';
import useRole from '../hooks/useRole';
import { useValuationSettings } from '../hooks/useValuationSettings';

const useStyles = makeStyles((theme) => ({
  alert: {
    width: '100%',
    '& .MuiAlert-message': {
      width: '100%',
      position: 'relative',
    },
  },
  alertTitle: {
    marginBottom: 0,
  },
  button: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  textField: {
    backgroundColor: theme.palette.common.white,
  },
  userComment: {
    fontStyle: 'italic',
    whiteSpace: 'pre-line',
  },
  printHide: {
    '@media print': {
      display: 'none',
    },
  },
  printPadding: {
    '@media print': {
      width: '100% !important',
    },
  },
  externalInfoIcons: {
    marginLeft: theme.spacing(-1),
    marginTop: theme.spacing(-1),
  },
}));

type CarDetailUserInformationProps = {
  car: DetailCarDTO;
  carId: string;
  source: SourceType;
  userComment?: string;
  userRatingNamePrefix: string;
  refresh?: () => Promise<void>;
  editDefault?: boolean;
  setEdit?: Dispatch<SetStateAction<boolean>>;
  className?: string;
};

const CarDetailUserInformation = ({
  car,
  carId,
  source,
  userComment,
  userRatingNamePrefix,
  refresh,
  editDefault = !userComment,
  setEdit,
  className,
}: CarDetailUserInformationProps) => {
  const { valuationCountry } = useValuationSettings();
  const classes = useStyles();
  const [editing, setEditing] = useState(editDefault);
  const { fetch } = useApi<DetailCarDTO>();
  const { hasRole } = useRole();
  const location = useLocation();
  const { register, handleSubmit } = useForm<{ userComment: string }>();
  const carsTableCtx = useCarsTableStore(true);

  const { ref, ...field } = register('userComment');
  const snackbar = useCustomSnackbarGlobal();
  const { t } = useTranslation();

  const onSubmit = async ({ userComment: newUserComment }: { userComment: string }) => {
    const requestValuationCountry: ValuationCountryCode = hasRole(UserRole.ADMIN)
      ? valuationCountry || DEFAULT_VALUATION_COUNTRY
      : DEFAULT_VALUATION_COUNTRY;
    const response = await fetch(
      ApiService.listCars.listCarsControllerUpdateCar(
        source,
        carId,
        {
          userComment: newUserComment,
        },
        2,
        requestValuationCountry,
      ),
    );
    if (!response?.data || response.status !== 200) {
      snackbar.showError(t('alerts.errorRaised'));
      setEditing(false);
      if (setEdit) setEdit(false);
      return;
    }
    const { data: updatedCar } = response;
    if (carsTableCtx) {
      carsTableCtx.updateCar(updatedCar);
    }
    if (refresh) await refresh();
    snackbar.showSuccess(t('alerts.successSaved'));
    setEditing(false);
    if (setEdit) setEdit(false);
    TagManager.dataLayer({
      dataLayer: {
        event: 'car-comment',
        ...getCarDetailsTM(car).dataLayer,
      },
    });
  };

  return (
    <form
      className={clsx(location.pathname === '/cars' && classes.printPadding, className)}
      onSubmit={handleSubmit(({ userComment: newUserComment }) => onSubmit({ userComment: newUserComment }))}
    >
      <Alert
        severity="info"
        elevation={3}
        className={clsx(classes.alert, !userComment && !car.rating && classes.printHide)}
        icon={
          <CarsTableCellExternalUserInformation
            individualizations={car.individualizations}
            className={classes.externalInfoIcons}
            iconSpacing={4.8}
          />
        }
      >
        <Grid container direction="column" spacing={2}>
          <Grid item className={clsx(!car.rating && classes.printHide)}>
            <Grid container alignItems="center" spacing={4}>
              <Grid item>
                <AlertTitle className={classes.alertTitle}>{t('carsTable.userRating')}</AlertTitle>
              </Grid>
              <Grid item>
                <UserRating car={car} namePrefix={userRatingNamePrefix} refresh={refresh} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={clsx(!userComment || (!car.rating && classes.printHide))}>
            <Divider />
          </Grid>
          <Grid item className={clsx(!userComment && classes.printHide)}>
            <Grid container alignItems="center" justifyContent="space-between" spacing={4}>
              <Grid item>
                <AlertTitle className={classes.alertTitle}>{t('carsTable.userComment')}</AlertTitle>
              </Grid>
              <Grid item>
                {editing ? (
                  <IconButton key="submit" size="small" type="submit">
                    <Check />
                  </IconButton>
                ) : (
                  <IconButton
                    key="edit"
                    size="small"
                    onClick={() => setEditing(true)}
                    type="button"
                    className="editBtn"
                  >
                    <Edit />
                  </IconButton>
                )}
              </Grid>
            </Grid>

            {editing ? (
              <TextField
                className={classes.textField}
                variant="outlined"
                margin="dense"
                fullWidth
                multiline
                defaultValue={userComment}
                inputRef={ref}
                autoFocus
                {...field}
              />
            ) : (
              <div className={classes.userComment}>{userComment}</div>
            )}
          </Grid>
        </Grid>
      </Alert>
    </form>
  );
};

export default memo(CarDetailUserInformation);
