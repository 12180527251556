import ContentLoader, { IContentLoaderProps } from 'react-content-loader';
import type { Merge } from 'type-fest';

export type TextFallbackProps = {
  width: string | number;
};

export const TextFallback = ({
  width = '100%',
  ...rest
}: Merge<IContentLoaderProps, TextFallbackProps>): JSX.Element => (
  <ContentLoader height="1em" width={width} {...rest}>
    <rect x="0" y="0" width="100%" rx={2} height="100%" />
  </ContentLoader>
);
