import { Link, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { imprintMail } from 'src/modules/global-vars';
import { useAuthenticationStore } from 'src/stores/Authentication';

const useStyles = makeStyles(() => ({
  box: {
    padding: '20px 0 0 0',
  },
  break: {
    paddingBottom: '25px',
  },
}));

const ImprintContent = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    state: { hasAccess },
  } = useAuthenticationStore();

  return (
    <>
      <Typography variant="h1">{t('imprint.imprintAndContact')}</Typography>
      <br />
      <Typography variant="h3">{t('imprint.operator')}</Typography>
      <Typography variant="body1">
        Allmobil GmbH
        <br />
        Louise-Piech-Straße 2<br />
        5020 Salzburg
        <br />
        <br />
        {t('contact.E-Mail')}: <Link href={`mailto:${imprintMail}`}>{imprintMail}</Link>
      </Typography>
      <Typography variant="body1">
        <div className={classes.box}>
          <strong>{t('imprint.regNumb')}</strong>
          <br />
          464567m
        </div>
        <div className={classes.box}>
          <strong>{t('imprint.regCourt')}</strong>
          <br />
          Landesgericht Salzburg
        </div>
        <div className={classes.box}>
          <strong>{t('imprint.vatNumber')}</strong>
          <br />
          ATU 71944738 (AT) | DE314550013 (DE)
        </div>
        <div className={clsx(classes.box, classes.break)}>
          <strong>{t('imprint.datahandlingRegisterNumb')}</strong>
          <br />
          4017975
        </div>
      </Typography>

      <Typography variant="body1">{t('imprint.company')}</Typography>
      <Typography variant="body1">
        {t('imprint.web')}:{' '}
        <Link target="_blank" href="https://www.porsche-holding.com">
          www.porsche-holding.com
        </Link>
      </Typography>
      <br />
      <Typography variant="body1">
        {t('imprint.our')}{' '}
        <Link target="_blank" href="https://www.porsche-holding.com/de/lieferantenportal">
          Code of Conduct
        </Link>{' '}
        {t('imprint.codeOfConduct')}
      </Typography>

      <br />
      <br />
      {hasAccess ? (
        <Typography variant="h3">
          {t('common.iris')} {t('imprint.producedBy')}
        </Typography>
      ) : (
        <Typography variant="h3">{t('imprint.kindSupport')}</Typography>
      )}
      <Typography variant="body1">
        cognify GmbH
        <br />
        Hubert-Sattler-Gasse 1
        <br />
        5020 Salzburg
        <br />
        <Link target="_blank" href="https://www.cognify.ai">
          www.cognify.ai
        </Link>
      </Typography>
    </>
  );
};

export default ImprintContent;
