import { ReactNode } from 'react';
import { SvgIconComponent } from '@material-ui/icons';
import { makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import WithChildren from '../types/WithChildren';
import InfoTooltip from './InfoTooltip';

const useStyles = makeStyles<Theme, { small: boolean }>((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    fontSize: ({ small }) => (small ? '1.5rem' : '2.5rem'),
    marginRight: theme.spacing(1),
  },
  label: {
    fontWeight: 'bold',
    color: theme.palette.text.secondary,
  },
  value: {
    fontWeight: 'bold',
    fontSize: ({ small }) => (small ? '1rem' : '1.5rem'),
  },
  padding: {
    padding: '6px',
  },
}));

type IconValueProps = {
  hint?: string | ReactNode;
  icon: SvgIconComponent;
  label: string;
  small?: boolean;
  color?: string;
  padding?: boolean;
};

const IconValue = ({
  hint,
  icon: Icon,
  label,
  children,
  small = false,
  color,
  padding = false,
}: WithChildren<IconValueProps>) => {
  const classes = useStyles({ small });

  return (
    <div className={clsx(classes.root, padding && classes.padding)}>
      <Icon className={classes.icon} style={color ? { color } : {}} />
      <div>
        <div className={classes.label}>
          {label}
          {hint && <InfoTooltip hint={hint} xsIcon />}
        </div>
        <div className={classes.value}>{children}</div>
      </div>
    </div>
  );
};

export default IconValue;
