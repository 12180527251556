const mail = 'irisscout@porsche.co.at';
export default mail;

export const imprintMail = 'alluc@porsche.co.at'; // yes, the imprint mail is different

export const MAX_NUMBER_CHECKABLE = 3;

export const LANGUAGES_WITH_INSTRUCTIONS = ['de', 'en', 'fr'];

export const kvpsLocalStorageKey = 'gwscout/PREFERRED_KVPS';
export const transportOptionLocalStorage = 'gwscout/PREFERRED_TRANSPORT_OPTION';
export const authenticationStorageKey = 'gwscout/accessToken';

export const DELIVERY_ADDRESS_VALIDATION = {
  companyName: { maxLength: 40 },
  name: { maxLength: 30 },
  email: { maxLength: 60 },
  phoneNumber: { maxLength: 16 },
  street: { maxLength: 71 },
  zip: { maxLength: 10 },
  city: { maxLength: 40 },
};
