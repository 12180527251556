import { useTranslation } from 'react-i18next';
import { sourceOriginFilter } from 'src/modules/labels';
import FilterAutocomplete from './FilterAutocomplete';

const OriginSourceSelect = () => {
  const { t } = useTranslation();
  return (
    <FilterAutocomplete
      name="originSource"
      label={t('carsTableNext.filter.originSource')}
      multiple
      options={sourceOriginFilter}
    />
  );
};

export default OriginSourceSelect;
