import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { engineFuelDetails } from '../modules/labels';
import FilterAutocomplete from './FilterAutocomplete';

const EngineFuelSelect = () => {
  const { t } = useTranslation();
  return <FilterAutocomplete name="normalizedFuelDetail" label={t('car.fuel')} multiple options={engineFuelDetails} />;
};

export default memo(EngineFuelSelect);
