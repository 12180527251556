import PageContainer from '@components/PageContainer';
import {
  ServicePacketGroup,
  ServicePacketItem,
  ServicePacketList,
  usePacketDelete,
  usePackets,
} from '@components/packet-service';
import { PageHeader } from '@components/page-header';
import { PageMeta } from '@components/page-meta';
import { Stack } from '@components/ui';
import { Button, makeStyles } from '@material-ui/core';
import { Link, RouteComponentProps, navigate } from '@reach/router';
import countries from 'i18n-iso-countries';
import { groupBy, sortBy } from 'lodash';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { RoutePaths } from 'src/constants';
import { IconType, getIcon } from 'src/modules/data';
import type { Merge } from 'type-fest';

type PacketsPageProps = {
  children?: ReactNode;
};

const useStyles = makeStyles((theme) => ({
  pageHeader: {
    marginBlockEnd: theme.spacing(4.5),
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBlockEnd: theme.spacing(1),
  },
}));

const PacketsPage = ({ children }: Merge<RouteComponentProps, PacketsPageProps>) => {
  const { t, i18n } = useTranslation();
  const { data: packets = [] } = usePackets();
  const classes = useStyles();
  const packetsByCountry = groupBy(
    sortBy(packets, [(packet) => countries.getName(packet.country, i18n.language), 'name']),
    'country',
  );
  const { mutate: deletePacket } = usePacketDelete();

  const handleClickEdit = (id: string) => {
    navigate(`${RoutePaths.packets}/${id}`);
  };

  const handleClickDelete = (id: string) => {
    deletePacket(id);
  };

  return (
    <>
      <PageMeta title={t('packetServicePage.title')} />

      <PageContainer>
        <PageHeader
          className={classes.pageHeader}
          headline={t('packetServicePage.headline')}
          body={t('packetServicePage.subHeadline')}
        />

        <div className={classes.actions}>
          <Button variant="contained" color="primary" component={Link} to={`${RoutePaths.packets}/create`}>
            {t('packetServicePage.actions.createPacket')}
          </Button>
        </div>

        <ServicePacketList>
          {Object.keys(packetsByCountry).map((country) => (
            <ServicePacketGroup
              key={country}
              label={
                <Stack spacing={1} direction="row" alignItems="center">
                  <img src={getIcon(IconType.COUNTRY, country)} width={16} height={16} alt={country} />
                  {countries.getName(country, i18n.language)}
                </Stack>
              }
            >
              {packetsByCountry[country].map((packet) => (
                <ServicePacketItem
                  key={packet.clientId}
                  packet={packet}
                  onClickEdit={() => handleClickEdit(packet.id)}
                  onClickDelete={() => handleClickDelete(packet.id)}
                />
              ))}
            </ServicePacketGroup>
          ))}

          {/* PacketDetailPage */}
          {children}
        </ServicePacketList>
      </PageContainer>
    </>
  );
};

export default PacketsPage;
