import { useMutation } from '@tanstack/react-query';
import { SortingState } from '@tanstack/react-table';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { useCurrency } from 'src/hooks/useCurrency';
import ApiService from 'src/modules/api-service';
import { FILE_DATE_TIME_FORMAT } from 'src/modules/date-helpers';
import useSnackbarGlobal from '../../hooks/useSnackbarGlobal';
import { useValuationSettings } from '../../hooks/useValuationSettings';
import { SupportedCurrencyCode } from '../../modules/currency';
import { ExportCarProperty, ExportProperty, PriceType, ValuationCountryCode } from '../../modules/generated/api/api';
import { ExportType } from './constants';
import { transformForSubmit } from './filter/lib';
import { BidsTableNextFilterData } from './filter/types';
import { ExportColumn } from './table';

type ExportCarsOptions = {
  filter: BidsTableNextFilterData | null;
  sorting: SortingState;
  exportColumns: ExportColumn[];
  results: number;
  valuationCountry?: ValuationCountryCode;
  valuationType?: PriceType;
  currency?: SupportedCurrencyCode;
};

const downloadFile = (file: File) => {
  const link = document.createElement('a');
  link.style.display = 'none';
  link.href = URL.createObjectURL(file);
  link.download = file.name;
  document.body.appendChild(link);
  link.click();

  window.queueMicrotask(() => {
    URL.revokeObjectURL(link.href);
    link.parentNode?.removeChild(link);
  });
};

const exportBids = ({ filter, sorting, exportColumns, valuationCountry, currency }: ExportCarsOptions) =>
  // ATTENTION: This BE endpoint does not support the required types!
  ApiService.export
    .exportControllerListBids(
      {
        // @ts-ignore
        exportProperties: exportColumns
          .filter((col) => col.checked)
          .reduce<ExportProperty[]>((exportProperties, col) => {
            const exportIcon =
              col.exportType !== undefined
                ? col.exportType === ExportType.iconsOnly || col.exportType === ExportType.textAndIcons
                : undefined;
            const exportText =
              col.exportType !== undefined
                ? col.exportType === ExportType.textOnly || col.exportType === ExportType.textAndIcons
                : undefined;

            const exportProperty = {
              name: col.name as ExportCarProperty,
              label: col.label,
              valueLabels: col.column.columnDef.meta?.export?.valueLabels,
              exportIcon,
              exportText,
            };

            if (col.exports) {
              return [
                ...exportProperties,
                ...col.exports
                  .filter((subExport) => subExport.checked)
                  .map<ExportProperty>((subExport) => ({
                    name: subExport.name as ExportCarProperty,
                    label: subExport.label,
                    exportIcon,
                    exportText,
                    valueLabels: subExport.subExportDef.valueLabels,
                  })),
              ];
            }

            return [...exportProperties, exportProperty];
          }, []),
        payload: {
          ...filter,
          carsRequest: { ...transformForSubmit(filter!.carsRequest), valuationCountry, targetCurrency: currency },
          length: -1,
        },
        order: sorting.map((columnSort, index) => ({ column: index, dir: columnSort.desc ? 'desc' : 'asc' })),
        columns: sorting.map((columnSort) => ({ data: columnSort.id })) as any, // Only send minimal information for simple directional sorting,
      },
      2,
      { responseType: 'blob' },
    )
    .then((res) => res.data);

export const useBidsExport = () => {
  const { showError, showInfo } = useSnackbarGlobal();
  const { t } = useTranslation();
  const { valuationCountry } = useValuationSettings();
  const { currency } = useCurrency();

  return useMutation(
    (options: ExportCarsOptions) => {
      showInfo(t('alerts.exportInProcess', { carCount: options.results }));
      return exportBids({ ...options, valuationCountry, currency } as ExportCarsOptions);
    },
    {
      onSuccess: (data) => {
        downloadFile(new File([data], `export_ALLUC_${DateTime.now().toFormat(FILE_DATE_TIME_FORMAT)}.xlsx`));
      },
      onError: () => {
        showError(t('alerts.exportFailed'));
      },
    },
  );
};
