import { getExternalColumns, getInternalColumns, isDefined } from '@components/cars-table-next/lib';
import { BidTypes, CarsTableNextInstance } from '@components/cars-table-next/types';
import { DrawerContent, DrawerFooter, DrawerHeader, DrawerStatic, Stack } from '@components/ui';
import { Button, Checkbox, Grid, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import UndoIcon from '@material-ui/icons/Undo';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { Column } from '@tanstack/react-table';
import { mapValues } from 'lodash';
import { DropResult } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { useResetRecoilState } from 'recoil';
import carCulatorLogoImage from 'src/assets/images/carculator.png';
import i18n from 'src/setup/i18n';
import type { Merge } from 'type-fest';
import { DragHandle, DraggableList, DraggableListItem, DraggableListItemContentProps, reorder } from '../DraggableList';
import {
  bidOrderedColumnOrderState,
  bidPastColumnOrderState,
  bidPendingColumnOrderState,
  columnCarsVisibilityState,
  columnOrderState,
  columnOrderedBidsVisibilityState,
  columnPastBidsVisibilityState,
  columnPendingBidsVisibilityState,
} from '../state';

export type TableColumnDisplaySettingsProps = {
  columns: Column<any, any>[];
  table: CarsTableNextInstance;
  bidType?: BidTypes;
};

type ColumnListItemProps = {
  column: Column<any, any>;
  disabled?: boolean;
};

type ColumnListProps = {
  columns: Column<any, unknown>[];
  droppableId: string;
  table: CarsTableNextInstance;
};

const ColumnListItem = ({
  column,
  dragHandleProps,
  disabled,
}: Merge<DraggableListItemContentProps, ColumnListItemProps>): JSX.Element => {
  const { t } = useTranslation();
  const title = t(`carsTableNext.columns.${column.id}` as any);
  const supportText =
    !!i18n.exists(`carsTableNext.columns.${column.id}-supportText` as any) &&
    ` ${t(`carsTableNext.columns.${column.id}-supportText` as any)}`;
  const supportLabel = !!column.columnDef.meta?.supportLabel && t(column.columnDef.meta.supportLabel as any);
  const finalTitle = [title, supportText, supportLabel].filter(isDefined).join(' ');

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        paddingInline: 8,
        paddingBlock: 4,
        border: '1px solid',
        borderColor: grey[400],
        background: '#fff',
        borderRadius: 4,
        gap: 8,
      }}
    >
      <Checkbox
        size="small"
        style={{
          margin: -9,
        }}
        checked={column.getIsVisible()}
        onChange={column.getToggleVisibilityHandler()}
        disabled={disabled}
        color={column.columnDef.meta?.isExternalColumn ? 'primary' : 'secondary'}
      />
      <Typography
        style={{
          flex: '1 0',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
        title={finalTitle}
      >
        {finalTitle}
      </Typography>
      <DragHandle disabled={disabled} {...dragHandleProps} />
    </div>
  );
};

const ColumnList = ({ columns, droppableId, table }: ColumnListProps): JSX.Element => {
  const draggableItems = columns.map<DraggableListItem>((column) => ({
    id: column.id,
    content: (props) => <ColumnListItem {...props} column={column} />,
  }));

  const handleDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    // `prevOrder` entries might differ from `columns` as the available
    // columns could change due to role simulations. (e.g. Admin -> Dealer)
    // Therefore we have to resolve indices based on the actual order state
    // instead of relying on the drag&drop source.
    table.setColumnOrder((prevOrder) =>
      reorder(
        prevOrder,
        prevOrder.indexOf(columns[result.source.index].id),
        prevOrder.indexOf(columns[result.destination!.index].id),
      ),
    );
  };

  return <DraggableList onDragEnd={handleDragEnd} droppableId={droppableId} items={draggableItems} />;
};

/**
 * Manage table column display settings. View, hide and re-order columns.
 */
export const TableColumnDisplaySettings = ({
  columns,
  table,
  bidType,
}: TableColumnDisplaySettingsProps): JSX.Element => {
  const { t } = useTranslation();
  // separate internal and external columns
  const internalColumns = getInternalColumns(columns);
  // Primary column re-ordering is currently not possible due to pinning limitations
  const [primaryColumn, ...internalOrderableColumns] = internalColumns;
  const externalColumns = getExternalColumns(columns);

  const toggleAllColumnsVisible = (visible: boolean) => {
    table.setColumnVisibility((prev) => ({
      ...mapValues(prev, () => visible),
      [primaryColumn.id]: true,
    }));
  };

  function getColumnVisibilityState() {
    if (bidType === BidTypes.Pending) {
      return columnPendingBidsVisibilityState;
    }
    if (bidType === BidTypes.Past) {
      return columnPastBidsVisibilityState;
    }
    if (bidType === BidTypes.Orders) {
      return columnOrderedBidsVisibilityState;
    }
    return columnCarsVisibilityState;
  }

  function getOrderState() {
    if (bidType === BidTypes.Pending) {
      return bidPendingColumnOrderState;
    }
    if (bidType === BidTypes.Past) {
      return bidPastColumnOrderState;
    }
    if (bidType === BidTypes.Orders) {
      return bidOrderedColumnOrderState;
    }
    return columnOrderState;
  }

  const resetVisibility = useResetRecoilState(getColumnVisibilityState());
  const resetOrder = useResetRecoilState(getOrderState());

  return (
    <DrawerStatic>
      <DrawerHeader>
        <Typography
          style={{
            fontWeight: 'bold',
          }}
        >
          {t('carsTableNext.columnDisplaySettings.headline')}
        </Typography>
      </DrawerHeader>
      <DrawerContent>
        <div
          style={{
            marginBlockEnd: 24,
          }}
        >
          <Typography
            style={{
              fontWeight: 'bold',
              marginBlockEnd: 8,
            }}
            variant="body2"
          >
            {t('carsTableNext.columnDisplaySettings.primaryColumn')} (
            {t('carsTableNext.columnDisplaySettings.readonly')})
          </Typography>
          <ColumnListItem column={primaryColumn} disabled />
        </div>

        <Grid container justifyContent="space-between" style={{ marginBlockEnd: 8 }}>
          <Button
            size="small"
            variant="text"
            color="primary"
            startIcon={<VisibilityIcon />}
            onClick={() => toggleAllColumnsVisible(true)}
          >
            {t('carsTableNext.columnDisplaySettings.showAll')}
          </Button>
          <Button
            size="small"
            variant="text"
            color="primary"
            startIcon={<VisibilityOffIcon />}
            onClick={() => toggleAllColumnsVisible(false)}
          >
            {t('carsTableNext.columnDisplaySettings.hideAll')}
          </Button>
        </Grid>

        <Stack spacing={3}>
          <ColumnList columns={internalOrderableColumns} droppableId="cars-table-columns-internal" table={table} />
          <div>
            <Typography
              style={{
                marginBlockEnd: 8,
              }}
            >
              powered by <img src={carCulatorLogoImage} alt="CARculator logo" width={100} />
            </Typography>
            <ColumnList columns={externalColumns} droppableId="cars-table-columns-external" table={table} />
          </div>
        </Stack>
      </DrawerContent>
      <DrawerFooter>
        <Button
          fullWidth
          size="small"
          variant="contained"
          color="secondary"
          startIcon={<UndoIcon />}
          onClick={() => {
            resetVisibility();
            resetOrder();
          }}
        >
          {t('carsTableNext.columnDisplaySettings.showDefault')}
        </Button>
      </DrawerFooter>
    </DrawerStatic>
  );
};
