import React, { ComponentProps, ReactNode } from 'react';
import { makeStyles, Tooltip } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import clsx from 'clsx';
import type { Merge } from 'type-fest';
import ContentLoader from 'react-content-loader';
import { TextFallback } from '@components/ui';
import { isString } from 'lodash';
import { useTranslation } from 'react-i18next';
import { ContentCell } from './ContentCell';
import { SupportText } from './common/SupportText';

export type TextCellProps = {
  text: ReactNode;
  supportText?: ReactNode;
  isLead?: boolean;
  startAdornment?: ReactNode;
  textProps?: ComponentProps<'div'>;
  supportTextProps?: ComponentProps<'div'>;
  helperText?: ReactNode;
  style?: React.CSSProperties;
};

export type TextCellFallbackProps = {
  textWidth: number | string;
  supportTextWidth?: number | string;
  withStartAdornment?: boolean;
};

const useStyles = makeStyles(
  {
    text: {
      color: `var(--text_cell__text_color___text, ${grey[900]})`,
    },
    text_solo: {
      color: `var(--text_cell__text_color___text_solo, ${grey[600]})`,
    },
    text_lead: {
      fontWeight: 'bold',
      color: grey[900],
    },
    text_fallback: {
      verticalAlign: 'middle',
    },
    adornment_fallback: {
      float: 'left',
      marginInlineEnd: 12,
      aspectRatio: '4/3',
    },
    text___with_helper: {
      textDecoration: 'underline',
      textDecorationStyle: 'dotted',
      cursor: 'help',
    },
    truncate: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },
  {
    name: 'TextCell',
  },
);

export const TextCell = ({
  text,
  supportText,
  isLead,
  textProps,
  supportTextProps,
  startAdornment,
  helperText,
  ...rest
}: Merge<ComponentProps<'div'>, TextCellProps>): JSX.Element => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <ContentCell {...rest}>
      {startAdornment}
      <div
        {...textProps}
        className={clsx(classes.text, classes.truncate, textProps?.className, {
          [classes.text_lead]: isLead,
          [classes.text_solo]: !supportText,
          [classes.text___with_helper]: helperText,
        })}
        title={isString(text) ? text : undefined}
      >
        <Tooltip title={isString(helperText) ? t(helperText as any) : helperText || ''} arrow>
          <span>{text}</span>
        </Tooltip>
      </div>
      {supportText ? <SupportText {...supportTextProps}>{supportText}</SupportText> : null}
    </ContentCell>
  );
};

export const TextCellFallback = ({
  textWidth,
  supportTextWidth,
  withStartAdornment,
  ...rest
}: Merge<ComponentProps<'div'>, TextCellFallbackProps>): JSX.Element => {
  const classes = useStyles();

  return (
    <ContentCell {...rest}>
      {withStartAdornment ? (
        <ContentLoader height={40} className={classes.adornment_fallback}>
          <rect x={0} width="100%" height="100%" rx={4} />
        </ContentLoader>
      ) : null}
      <div
        className={clsx(classes.text, classes.truncate, {
          [classes.text_solo]: !supportTextWidth,
        })}
      >
        <TextFallback width={textWidth} className={classes.text_fallback} />
      </div>
      {supportTextWidth ? (
        <SupportText>
          <TextFallback width={supportTextWidth} className={classes.text_fallback} />
        </SupportText>
      ) : null}
    </ContentCell>
  );
};
