import { useState } from 'react';
import { Button } from '@material-ui/core';
import { Save } from '@material-ui/icons';
import TagManager from 'react-gtm-module';
import ApiService from 'src/modules/api-service';
import useApi from 'src/hooks/useApi';
import { useSearchAgents } from 'src/hooks/useSearchAgents';
import { useTranslation } from 'react-i18next';
import SearchAgentSaveDialog from './SearchAgentSaveDialog';

// Ported from `src/components/SearchAgentSave.tsx`

const SearchAgentSave = ({ control }: any) => {
  const { t } = useTranslation();
  const { data: searchAgents, refetch } = useSearchAgents();
  const [open, setOpen] = useState(false);
  const { fetch: fetchCreate } = useApi();
  const { fetch: fetchUpdate } = useApi();

  return (
    <>
      <Button size="small" color="primary" onClick={() => setOpen(true)} endIcon={<Save />}>
        {t('carsTableNext.carsTableNextFilter.buttonSaveSearchAgent.label')}
      </Button>
      <SearchAgentSaveDialog
        open={open}
        filterControl={control}
        searchAgents={searchAgents}
        onClose={() => setOpen(false)}
        onCreate={async (searchAgent) => {
          TagManager.dataLayer({ dataLayer: { event: 'searchagent-save' } });
          await fetchCreate(
            ApiService.searchAgent.searchAgentControllerCreateSearchAgent({
              ...searchAgent,
              version: '2',
            }),
          );
        }}
        onUpdate={async (id, searchAgent) => {
          await fetchUpdate(ApiService.searchAgent.searchAgentControllerUpdateSearchAgent(id, searchAgent));
        }}
        refetch={refetch}
      />
    </>
  );
};

export default SearchAgentSave;
