import { ReactElement } from 'react';
import { Tooltip } from '@material-ui/core';
import isString from 'lodash/isString';

type ActionTooltipProps = {
  ariaLabel?: string;
  title: string | JSX.Element;
  disabled?: boolean;
  children: ReactElement;
  position?:
    | 'right'
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'top-end'
    | 'top-start'
    | 'top'
    | undefined;
  className?: string;
  classes?: any; // No type because dependency needed
  arrow?: boolean;
};

const ActionTooltip = ({
  ariaLabel,
  title,
  disabled,
  children,
  position,
  className,
  classes,
  arrow,
}: ActionTooltipProps) =>
  disabled ? (
    (children as ReactElement)
  ) : (
    <Tooltip
      title={title}
      aria-label={isString(title) ? title : ariaLabel}
      placement={position || 'right'}
      className={className}
      classes={classes}
      arrow={arrow}
    >
      {children as ReactElement}
    </Tooltip>
  );

export default ActionTooltip;
