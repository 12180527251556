import { client } from '@iris/content/lib';
import { useQuery } from '@tanstack/react-query';
import groq from 'groq';
import { useTranslation } from 'react-i18next';
import { QueryKeys } from 'src/constants';

type UseFaqProps = {
  slug: string;
};

export type FaqBySlug = {
  _id: string;
  _updatedAt: string;
  title: string;
  subTitle: string | null;
  description: any | null;
};

const faqBySlug = groq`*[
  _type == 'faq' && 
  __i18n_lang == $lang &&
  slug.current == $slug
][0] {
  _id,
  _updatedAt,
  title,
  subTitle,
  description,
}`;

export const fetchFaqBySlug = (variables: { slug: string; lang: string }) =>
  client.fetch<FaqBySlug>(faqBySlug, variables);

export const useFaq = ({ slug }: UseFaqProps) => {
  const { i18n } = useTranslation();
  const variables = {
    slug,
    lang: i18n.language,
  };

  return useQuery([QueryKeys.faq, variables], () => fetchFaqBySlug(variables), {
    staleTime: Infinity,
    suspense: true,
    useErrorBoundary: true,
  });
};
