import { makeStyles } from '@material-ui/core';
import { UnitEntry } from 'src/modules/generated/api';
import { LocalGasStation } from '@material-ui/icons';
import UnitValue from './UnitValue';
import IconValue from './IconValue';

type CarDetailTileEngineProps = {
  kW?: UnitEntry;
  hp?: UnitEntry;
  fuelType?: string;
};

const useStyles = makeStyles((theme) => ({
  root: {
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  engine: {
    ...theme.typography.body1,
    fontWeight: theme.typography.fontWeightBold as number,
  },
}));

const CarDetailTileEngine = ({ kW, hp, fuelType }: CarDetailTileEngineProps) => {
  const classes = useStyles();

  return (
    <IconValue icon={LocalGasStation} label={fuelType || ''} padding>
      <div className={classes.engine}>
        <div>
          {kW !== undefined && (
            <div>
              <UnitValue value={kW.value} unit={kW.unit} />
            </div>
          )}
          {hp !== undefined && (
            <div>
              <UnitValue value={hp.value} unit={hp.unit} />
            </div>
          )}
        </div>
      </div>
    </IconValue>
  );
};

export default CarDetailTileEngine;
