import { Grid } from '@material-ui/core';
import { Fragment, useMemo } from 'react';
import useRole from 'src/hooks/useRole';
import { isDefaultFilter } from 'src/modules/table-helpers';
import { getFilter } from './filter';

type ActiveFilterChipsProps = {
  defaultFilter: any;
  watch: any;
  setFilter: any;
  prefix?: string;
};

const ActiveFilterChips = ({ defaultFilter, watch, setFilter, prefix = '' }: ActiveFilterChipsProps) => {
  const filterState = prefix ? watch('carsRequest') : watch();
  const { hasRole } = useRole();
  const filter = useMemo(() => getFilter(prefix), [prefix]);
  const active = new Set(
    Object.keys(filterState).filter((name) => isDefaultFilter(defaultFilter, name as any, filterState)),
  );
  if (prefix) active.delete('buyableStatus');

  const getValue = (name: string) => {
    if (prefix) {
      // remove prefix from name
      const pureName = name.replace(prefix, '');
      return filterState[pureName];
    }
    return filterState[name];
  };

  return (
    <Grid container>
      {filter.map((filterGroup) => (
        <Fragment key={filterGroup.groupKey}>
          {filterGroup.filter
            .filter((filterObject) => active.has(filterObject.name.replace(prefix, '')))
            .filter(({ roles }) => roles === undefined || roles.every((role) => hasRole(role))) // default filters shouldn't be displayed if the user can't set this filter
            .map(({ label, name, renderFilterChip: Chip }) => (
              <Chip key={name} field={{ name: name as any, label, value: getValue(name) }} onDelete={setFilter} />
            ))}
        </Fragment>
      ))}
    </Grid>
  );
};

export default ActiveFilterChips;
