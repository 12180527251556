import { makeStyles } from '@material-ui/core';
import carCulatorLogoImage from '../assets/images/carculator.png';

const useStyles = makeStyles(({ spacing }) => ({
  box: {
    minWidth: spacing(13),
  },
  image: {
    display: 'block',
    position: 'absolute',
    top: spacing(1.5),
    width: spacing(13),
    height: 'auto',
  },
}));

type CarCulatorHeaderCellProps = {
  label: string;
};

const CarCulatorHeaderCell = ({ label }: CarCulatorHeaderCellProps) => {
  const classes = useStyles();

  return (
    <div className={classes.box}>
      <img className={classes.image} src={carCulatorLogoImage} alt="CARculator" />
      {label}
    </div>
  );
};

export default CarCulatorHeaderCell;
