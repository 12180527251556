import { ReactElement } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

type ConditionalFormattingResetDialogProps = {
  handleClose: (confirmed: boolean) => void;
  open: boolean;
};

export const ConditionalFormattingResetDialog = ({
  handleClose,
  open,
}: ConditionalFormattingResetDialogProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={() => handleClose(false)}>
      <DialogTitle>{t('conditionalFormatting.dialog.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('conditionalFormatting.dialog.body')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)} color="primary" size="small">
          {t('common.cancel')}
        </Button>
        <Button onClick={() => handleClose(true)} autoFocus variant="contained" color="primary" size="small">
          {t('common.continue')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
