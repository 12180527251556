import { FaqSearch } from '@components/faq-search';
import { isSupportedLang as isSupportedContentLang } from '@iris/content';
import { makeStyles } from '@material-ui/core';
import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { CategoryList, CategoryListFallback } from './CategoryList';
import { ErrorFallback } from './ErrorFallback';

type HelpCenterHeaderProps = {
  minimized?: boolean;
};

const useStyles = makeStyles((theme) => ({
  headline: {
    paddingTop: ({ minimized }: HelpCenterHeaderProps) => (minimized ? '5%' : '10%'),
    fontWeight: 'bold',
    fontSize: theme.typography.h1.fontSize,
    textAlign: 'center',
    marginBlockEnd: theme.spacing(4),
    transition: 'all 0.3s ease-out', // Don't try this at home!
  },
  searchBar: {
    display: 'flex',
    maxWidth: 400,
    marginInline: 'auto',
    marginBlockEnd: theme.spacing(10),
  },
}));

export const HelpCenterHeader = ({ minimized }: HelpCenterHeaderProps): JSX.Element => {
  const { i18n, t } = useTranslation();
  const classes = useStyles({ minimized });

  return (
    <div>
      <div className={classes.headline}>{t('helpCenter.header.headline')}</div>

      {isSupportedContentLang(i18n.language) && (
        <FaqSearch
          indexName={`irisscout_help_${i18n.language}`}
          textFieldProps={{
            fullWidth: true,
            className: classes.searchBar,
          }}
          getMissingResultsUrl={() => '/help/404'}
          placeholder={t('helpCenter.search.placeholder')}
        />
      )}
      {!minimized && (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Suspense fallback={<CategoryListFallback />}>
            <CategoryList />
          </Suspense>
        </ErrorBoundary>
      )}
    </div>
  );
};
