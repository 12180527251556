import { Divider, makeStyles } from '@material-ui/core';
import { memo } from 'react';

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    marginRight: 0,
    marginLeft: 0,
    background: theme.palette.common.white,
  },
}));

type HorizontalDividerProps = {
  variant?: 'fullWidth' | 'middle' | 'inset';
  light?: boolean;
};

const HorizontalDivider = ({ variant = 'fullWidth', light = false }: HorizontalDividerProps) => {
  const classes = useStyles();
  return <Divider variant={variant} light={light} classes={{ root: classes.divider }} />;
};

export default memo(HorizontalDivider);
