import { useMemo } from 'react';
import { SourceType } from 'src/modules/generated/api';
import { Option } from 'src/types';
import { sources as sourceOptions } from 'src/modules/labels';
import { useUser } from './useUser';

export const getSourceOptions = (sources: SourceType[]): Option<SourceType>[] =>
  sourceOptions.filter((option) => sources.includes(option.value));

export const useSources = () => {
  const { data: user, ...query } = useUser();
  const data = useMemo(
    () =>
      user &&
      sourceOptions.filter((option) => user?.allowedSources?.includes(option.value)).map((option) => option.value),
    [user],
  );

  return {
    data,
    ...query,
  };
};
