import { RouteComponentProps } from '@reach/router';
import { Button, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import FullPageCenterContainer from '@components/FullPageCenterContainer';
import { PageMeta } from '@components/page-meta';

const useStyles = makeStyles(({ spacing }) => ({
  heading: {
    marginBottom: spacing(4),
  },
}));

const LogoutPage = (_props: RouteComponentProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <PageMeta title="Logout" />
      <FullPageCenterContainer>
        <Typography variant="h1" className={classes.heading}>
          {t('account.loggedOut')}
        </Typography>
        <Button color="primary" variant="contained" href="login">
          {t('account.loginPage')}
        </Button>
      </FullPageCenterContainer>
    </>
  );
};

export default LogoutPage;
