import { Grid } from '@material-ui/core';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { potentialFilters } from 'src/modules/labels';
import FilterAutocomplete from '@components/FilterAutocomplete';
import PotentialFilterInput from '@components/PotentialFilterInput';
import CurrencyInput from '@components/CurrencyInput';
import { useFilter } from '../filter/useFilter';

const PotentialFilter = () => {
  const { control } = useFilter();
  const potentialFilter = useWatch({ control, name: 'potentialFilter' });
  const { t } = useTranslation();
  const from = t('common.from');
  const to = t('common.to');

  return (
    <>
      <FilterAutocomplete
        name="potentialFilter"
        label={t('filterSearch.basisOfCalc')}
        options={potentialFilters}
        highlightTextField
      />

      {potentialFilter && (
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <CurrencyInput name="potentialFilterAbsoluteMin" label={from} />
          </Grid>
          <Grid item xs={6}>
            <CurrencyInput name="potentialFilterAbsoluteMax" label={to} />
          </Grid>
          <Grid item xs={6}>
            <PotentialFilterInput name="potentialFilterRelativeMin" unit="%" label={from} />
          </Grid>
          <Grid item xs={6}>
            <PotentialFilterInput name="potentialFilterRelativeMax" unit="%" label={to} />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default PotentialFilter;
