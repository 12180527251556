import { isFunction } from 'lodash';
import { AtomEffect } from 'recoil';

type LocalStorageEffectSettings<TValue> = {
  transform?: (value: TValue) => TValue;
};

export const localStorageEffect =
  <TValue>(key: string, { transform }: LocalStorageEffectSettings<TValue> = {}): AtomEffect<TValue> =>
  ({ setSelf, onSet, resetSelf }) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue != null) {
      try {
        const parsedValue = JSON.parse(savedValue);

        setSelf(isFunction(transform) ? transform(parsedValue) : parsedValue);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.warn(error);
        resetSelf();
      }
    }

    onSet((newValue, _, isReset) => {
      if (isReset) {
        localStorage.removeItem(key);
      } else {
        localStorage.setItem(key, JSON.stringify(newValue));
      }
    });
  };
