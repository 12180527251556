import { tr } from './i18n-helpers';
import { MarketingChannel } from './generated/api';

/**
 * @deprecated Use the `useCurrency` hook instead.
 */
export const retrieveLocaleStringOptions = (unit?: string) => {
  switch (unit) {
    case '€':
      return { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 };
    case 'EUR':
      return { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 };
    case '%':
      return { style: 'percent' };
    default:
      return { style: 'decimal' };
  }
};

export const formatUnitValueUndefined = (value?: number, unit?: string) => {
  if (typeof value === 'number') {
    const localeStringOptions = retrieveLocaleStringOptions(unit);
    return value.toLocaleString('de-DE', localeStringOptions);
  }

  return undefined;
};

export const formatUnitValue = (value?: number, unit?: string) => formatUnitValueUndefined(value, unit) || '';

export const getChannel = (marketingChannel: MarketingChannel[]) =>
  marketingChannel
    .map((m) => {
      switch (m) {
        case MarketingChannel.Bid:
          return tr('car.prices.priceOrientation_abbr');
        case MarketingChannel.Fixed:
          return tr('car.prices.fixedPrice_abbr');
        case MarketingChannel.Auction:
          return tr('car.prices.startingPrice_abbr');
        default:
          return '';
      }
    })
    .join(',\u00A0');
