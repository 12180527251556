import { DrawerWrapper } from '@components/ui';
import { Router, navigate } from '@reach/router';
import { SidebarRoutePaths } from './constants';
import { BidsTableNextFilter } from './filter/BidsTableNextFilter';
import { useBidsFilter } from './filter/useBidsFilter';
import { getAllExportableColumns } from './lib';
import { ExportColumn, TableColumnDisplaySettings, TableExport } from './table';
import { BidTypes, BidsTableNextInstance } from './types';
import { useBidsExport } from './useBidsExport';

type SidebarProps = {
  table: BidsTableNextInstance;
};

export const BidsOrderedTableSidebar = ({ table }: SidebarProps): JSX.Element => {
  const { mutate: exportBids } = useBidsExport();
  const { watch } = useBidsFilter();
  const filterState = watch();
  const results = table.getPageCount();

  const handleSubmitExport = (exportColumns: ExportColumn[]) => {
    const { sorting } = table.getState();
    exportBids({
      filter: filterState,
      sorting,
      exportColumns,
      results,
    });
    navigate(SidebarRoutePaths.ordersFilter);
  };

  return (
    <DrawerWrapper>
      <Router basepath="/" style={{ height: '100%' }}>
        <BidsTableNextFilter
          bidType={BidTypes.Orders}
          // @ts-expect-error
          path={SidebarRoutePaths.ordersFilter}
        />
        <TableColumnDisplaySettings
          table={table}
          columns={table.getAllLeafColumns()}
          bidType={BidTypes.Orders}
          // @ts-expect-error
          path={SidebarRoutePaths.ordersColumnSettings}
        />

        <TableExport
          columns={getAllExportableColumns(table.getAllLeafColumns())}
          onSubmit={handleSubmitExport}
          // @ts-expect-error
          path={SidebarRoutePaths.ordersExport}
        />
      </Router>
    </DrawerWrapper>
  );
};
