import { Alert, AlertTitle } from '@material-ui/lab';
import ActionTooltip from '@components/ActionTooltip';
import { IconButton, makeStyles } from '@material-ui/core';
import { Close, Delete } from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit';
import { useTranslation } from 'react-i18next';
import { getBidAlertTitle } from '../../modules/bid-buy-helpers';
import { BidSuccessStatus } from '../../modules/generated/api';

const useStyles = makeStyles((theme) => ({
  statusInfoAlert: {
    height: theme.spacing(5.5),
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    '& .MuiAlert-message': {
      width: '100%',
      position: 'relative',
    },
    '& .MuiAlertTitle-root': {
      marginTop: '2px',
      marginBottom: '0px',
    },
  },
}));
type BidBuyHeadlineProps = {
  actualBidSuccessState?: BidSuccessStatus;
  bidEditMode: boolean;
  toggleEdit: () => void;
  onDelete: () => void;
};

const BidBuyHeadline = ({ bidEditMode, actualBidSuccessState, toggleEdit, onDelete }: BidBuyHeadlineProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const bidAlertTitle = getBidAlertTitle(actualBidSuccessState, bidEditMode);

  if (!bidAlertTitle) return null;
  return (
    <Alert
      severity={bidEditMode ? 'warning' : 'success'}
      className={classes.statusInfoAlert}
      action={
        actualBidSuccessState === BidSuccessStatus.Scheduled && (
          <>
            <ActionTooltip title={t('bids.editBids')} position="bottom">
              <IconButton type="button" size="small" onClick={toggleEdit} key="edit">
                {bidEditMode ? <Close /> : <EditIcon />}
              </IconButton>
            </ActionTooltip>
            <ActionTooltip title={t('bids.delete')} position="bottom">
              <IconButton size="small" onClick={onDelete}>
                <Delete />
              </IconButton>
            </ActionTooltip>
          </>
        )
      }
    >
      <AlertTitle>{t(bidAlertTitle)}</AlertTitle>
    </Alert>
  );
};
export default BidBuyHeadline;
