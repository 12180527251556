import { FormControl, InputLabel, makeStyles, Select } from '@material-ui/core';
import { RefCallBack } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ConditionalFormattingConditionTypes } from 'src/modules/generated/api';

type ConditionSelectProps = {
  conditionRef: RefCallBack;
  conditionField: any;
  defaultValue: ConditionalFormattingConditionTypes | undefined;
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const ConditionSelect = ({ conditionRef, conditionField, defaultValue }: ConditionSelectProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <FormControl className={classes.formControl} fullWidth variant="outlined">
      <InputLabel shrink id="condition-select">
        {t('conditionalFormatting.condition')}
      </InputLabel>
      <Select
        native
        fullWidth
        variant="outlined"
        inputRef={conditionRef}
        {...conditionField}
        defaultValue={defaultValue || ConditionalFormattingConditionTypes.Contains}
        margin="dense"
        labelId="condition-select"
        labelWidth={85}
        label={t('conditionalFormatting.condition')}
        notched
      >
        <option value={ConditionalFormattingConditionTypes.Contains}>
          {t('conditionalFormatting.contains_condition')}
        </option>
        <option value={ConditionalFormattingConditionTypes.ContainsNot}>
          {t('conditionalFormatting.containsNot_condition')}
        </option>
      </Select>
    </FormControl>
  );
};

export default ConditionSelect;
