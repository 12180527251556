import { BidsTableNext } from '@components/cars-table-next/BidsTableNext';
import { BidsFilterProvider } from '@components/cars-table-next/filter/useBidsFilter';
import { BidTypes } from '@components/cars-table-next/types';
import { PageMeta } from '@components/page-meta';
import { RouteComponentProps } from '@reach/router';

const BidsPastPage = (_props: RouteComponentProps) => (
  <>
    <PageMeta title="bids.sentBids_plural" />

    <BidsFilterProvider bidsTableType="PastBidsTable">
      <BidsTableNext bidType={BidTypes.Past} />
    </BidsFilterProvider>
  </>
);

export default BidsPastPage;
