import { ExternalSearchAgentTable } from '@components/ExternalSearchAgentTable';
import { BadgeGroup } from '@components/ui/BadgeGroup';
import { makeStyles, Theme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PageContainer from 'src/components/PageContainer';
import { SearchAgentTable } from 'src/components/SearchAgentTable';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { SourceType } from 'src/modules/generated/api';
import { useSources } from 'src/hooks/useSources';
import { RouteComponentProps } from '@reach/router';
import { PageHeader } from '@components/page-header';

const useStyles = makeStyles((theme: Theme) => ({
  pageContainer: {
    paddingBlockEnd: theme.spacing(6),
  },
  announcements: {
    marginBlockEnd: theme.spacing(4),
  },
  endAdornment: {
    marginInlineStart: theme.spacing(1),
    verticalAlign: 'middle',
  },
  pageHeader: {
    marginBlockEnd: theme.spacing(4.5),
  },
  searchAgentTable: {
    marginBlockEnd: theme.spacing(8),
  },
}));

const SearchAgentsPage = (_props: RouteComponentProps): JSX.Element => {
  const { data: sources } = useSources();
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <PageContainer className={classes.pageContainer}>
      {sources?.includes(SourceType.AudiGws) && (
        <div className={classes.announcements}>
          <BadgeGroup
            component="a"
            // @ts-expect-error
            href="#audi-gws"
            primary={t('searchAgentsPage.announcements.externalSearchAgents.category')}
            label={
              <>
                {t('searchAgentsPage.announcements.externalSearchAgents.text')}
                <ArrowForwardIcon fontSize="small" className={classes.endAdornment} />
              </>
            }
          />
        </div>
      )}

      <PageHeader
        className={classes.pageHeader}
        headline={t('searchAgentsPage.title')}
        body={t('searchAgentsPage.subheader')}
      />

      <SearchAgentTable className={classes.searchAgentTable} />

      {sources?.includes(SourceType.AudiGws) && <ExternalSearchAgentTable id="audi-gws" />}
    </PageContainer>
  );
};

export default SearchAgentsPage;
