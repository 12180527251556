import { ComponentProps } from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import { grey, orange } from '@material-ui/core/colors';
import type { Merge } from 'type-fest';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import clsx from 'clsx';
import { Menu, MenuAction, useMenu } from '@components/ui';

type TableHeaderCellProps = {
  align?: 'left' | 'center' | 'right';
  menuReducer: (action: MenuAction) => void;
  actions: MenuAction[];
  resizeHandleProps?: ComponentProps<'div'>;
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid',
    borderBottomColor: grey[200],
    borderLeft: '1px solid',
    borderTop: '1px solid',
    borderColor: grey[200],
    background: grey[50],

    '&:hover :where(.context_menu, .button_sort)': {
      display: 'block',
    },
  },
  root___reversed: {
    flexDirection: 'row-reverse',
  },
  children: {
    flex: '1 1',
    // truncate overflowing content
    minWidth: 0,
  },
  context_menu: {
    display: 'none',
    flex: '0 0',
    paddingInline: 12,
  },
  context_menu___is_open: {
    display: 'block',
  },
  resize_handle: {
    position: 'absolute',
    right: -14,
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    paddingInline: 12,
    zIndex: 1,
    cursor: 'ew-resize',
    opacity: 0,

    '&:hover': {
      opacity: 1,
    },
  },
  resize_handle__icon: {
    width: 4,
    height: 56,
    background: orange[800],
    borderRadius: 2,
  },
});

/**
 * TableCell is the root of the header cell and vertically centers the content.
 */
export const TableHeaderCell = ({
  children,
  className,
  align = 'left',
  menuReducer,
  actions,
  resizeHandleProps,
  ...rest
}: Merge<ComponentProps<'div'>, TableHeaderCellProps>): JSX.Element => {
  const classes = useStyles();
  const { isOpen: isMenuOpen, open: openMenu, close: closeMenu } = useMenu();

  return (
    <div
      className={clsx(classes.root, className, {
        [classes.root___reversed]: align === 'right',
      })}
      {...rest}
    >
      <div className={classes.children}>{children}</div>

      <div
        className={clsx('context_menu', classes.context_menu, {
          [classes.context_menu___is_open]: isMenuOpen,
        })}
      >
        <Menu actions={actions} open={isMenuOpen} onAction={menuReducer} onOpen={openMenu} onClose={closeMenu}>
          {(triggerProps) => (
            <IconButton {...triggerProps} size="small">
              <MoreVertIcon fontSize="inherit" />
            </IconButton>
          )}
        </Menu>
      </div>

      <div
        {...resizeHandleProps}
        className={clsx('resize_handle', classes.resize_handle, resizeHandleProps?.className)}
      >
        <div className={classes.resize_handle__icon} />
      </div>
    </div>
  );
};
