import { InputAdornment, TextField } from '@material-ui/core';
import { Controller } from 'react-hook-form';

type PotentialFilterInputProps = {
  label: string;
  name: string;
  unit: string;
};

const PotentialFilterInput = ({ label, name, unit }: PotentialFilterInputProps) => (
  <Controller
    name={name}
    render={({ field: { ref, onChange, value, ...field } }) => (
      <TextField
        fullWidth
        inputRef={ref}
        onChange={(event) => {
          const newValue = event.target.value || null;
          const amount = newValue && parseInt(newValue, 10);
          onChange(amount);
        }}
        {...field}
        margin="dense"
        variant="outlined"
        type="number"
        inputProps={{
          type: 'number',
          min: 0,
        }}
        label={label}
        InputProps={{
          endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
        }}
        InputLabelProps={{ shrink: true }}
        value={value || ''}
      />
    )}
  />
);

export default PotentialFilterInput;
