import { ListItemIcon, ListItemText, makeStyles, MenuItem, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import NestedMenuItem from 'material-ui-nested-menu-item';
import { getIcon, IconType } from '../modules/data';
import { PriceType, ValuationCountryCode } from '../modules/generated/api';
import { getPropertyLabel } from '../modules/labels';
import { uncapitalize } from '../modules/string-helpers';
import { useValuationSettings } from '../hooks/useValuationSettings';
import { CarProperty } from '../modules/table-data';

const useStyles = makeStyles((theme) => ({
  regions: {
    top: '200px',
  },
  title: {
    marginLeft: theme.spacing(2),
  },
  selected: {
    backgroundColor: theme.palette.grey[400],
    '&:hover': {
      backgroundColor: theme.palette.grey[400],
    },
  },
}));

type ValuationCountrySubmenuProps = {
  onClose: () => void;
  handleChangeValuation: (
    updatedValuationCountry: ValuationCountryCode | null,
    updatedValuationType: PriceType | null,
  ) => void;
  sourceItemClass: string;
  highlightSelectedValuation?: boolean;
};

const ValuationTypeSubmenu = ({
  onClose,
  handleChangeValuation,
  sourceItemClass,
  highlightSelectedValuation,
}: ValuationCountrySubmenuProps) => {
  const classes = useStyles();
  const { valuationType } = useValuationSettings();
  const { t } = useTranslation();

  return (
    <NestedMenuItem
      label={
        <>
          <ListItemIcon className={sourceItemClass}>
            <img src={getIcon(IconType.COUNTRY, 'AT_BE_SE_CZ')} alt="" width={18} />
          </ListItemIcon>
          <ListItemText>{t('car.prices.bestValuationCountry')}</ListItemText>
        </>
      }
      title={t('car.prices.parameter')}
      parentMenuOpen
      className={highlightSelectedValuation && valuationType ? classes.selected : undefined}
    >
      <Typography variant="subtitle2" className={classes.title}>
        <strong>{t('car.prices.parameter')}</strong>
      </Typography>
      {(Object.keys(PriceType) as (keyof typeof PriceType)[])
        .filter((priceType) => priceType)
        .map((priceType) => (
          <div key={priceType}>
            {![PriceType.PurchaseNetRetailUnrepaired, PriceType.OfferPrice].includes(PriceType[priceType]) && (
              <MenuItem
                onClick={() => {
                  handleChangeValuation(null, PriceType[priceType]);
                  onClose();
                }}
                key={priceType}
                className={
                  highlightSelectedValuation && valuationType === PriceType[priceType] ? classes.selected : undefined
                }
              >
                <ListItemText>
                  {
                    // @ts-ignore
                    getPropertyLabel(uncapitalize(priceType) as CarProperty)
                  }
                </ListItemText>
              </MenuItem>
            )}
          </div>
        ))}
    </NestedMenuItem>
  );
};

export default ValuationTypeSubmenu;
