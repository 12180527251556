import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from 'src/constants';
import ApiService from 'src/modules/api-service';
import { SummaryRequestDTO } from 'src/modules/generated/api';

type UseFilterSummaryProps = {
  summaryState: SummaryRequestDTO;
};

export const useFilterSummary = ({ summaryState }: UseFilterSummaryProps) =>
  useQuery(
    [QueryKeys.filterSummary, summaryState],
    () => ApiService.listCars.listCarsControllerSummaryPost(summaryState, 2).then((res) => res.data),
    {
      keepPreviousData: true,
      staleTime: Infinity,
    },
  );

export const useBidsFilterSummary = ({ summaryState }: UseFilterSummaryProps) =>
  useQuery(
    [QueryKeys.bidsFilterSummary, summaryState],

    () => ApiService.newBids.bidNewControllerSummaryPost(summaryState).then((res) => res.data),
    {
      keepPreviousData: true,
      staleTime: Infinity,
    },
  );
