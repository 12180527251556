import { makeStyles, TextField, InputAdornment, TextFieldProps, IconButton } from '@material-ui/core';
import React, { useEffect } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import { AutocompleteApi, AutocompleteState } from '@algolia/autocomplete-core';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { MAX_QUERY_SIZE } from './constants';
import { InternalDocSearchHit } from './types';

export type SearchBarProps = AutocompleteApi<
  InternalDocSearchHit,
  React.FormEvent,
  React.MouseEvent,
  React.KeyboardEvent
> & {
  state: AutocompleteState<InternalDocSearchHit>;
  autoFocus: boolean;
  inputRef: React.MutableRefObject<HTMLInputElement | null>;
  formRef: React.MutableRefObject<HTMLFormElement | null>;
  textFieldProps?: Omit<Partial<TextFieldProps>, 'inputRef'>;
  className?: string;
};

const useStyles = makeStyles({
  input: {
    background: '#fff',
  },
  nativeInput: {
    // reset default search input 'clear'-button
    [`&::-webkit-search-decoration, 
      &::-webkit-search-cancel-button,
      &::-webkit-search-results-button, 
      &::-webkit-search-results-decoration`]: {
      appearance: 'none',
    },
  },
});

export const SearchBar = ({
  textFieldProps,
  autoFocus,
  getInputProps,
  getFormProps,
  inputRef,
  state,
  className,
  formRef,
}: SearchBarProps): JSX.Element => {
  const { t } = useTranslation();
  const classes = useStyles();

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [autoFocus, inputRef]);

  return (
    <form
      className={className}
      ref={formRef}
      {...getFormProps({
        inputElement: inputRef.current,
      })}
    >
      <TextField
        inputRef={inputRef}
        {...textFieldProps}
        variant="outlined"
        inputProps={{
          ...textFieldProps?.inputProps,
          ...getInputProps({
            inputElement: inputRef.current,
            autoFocus,
            maxLength: MAX_QUERY_SIZE,
          }),
          className: clsx(classes.nativeInput, textFieldProps?.inputProps?.className),
        }}
        InputProps={{
          ...textFieldProps?.InputProps,
          className: clsx(classes.input, textFieldProps?.InputProps?.className),
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon color="primary" />
            </InputAdornment>
          ),
          endAdornment: (
            <IconButton
              type="reset"
              title={t('helpCenter.search.searchBar.resetButton.label')}
              aria-label={t('helpCenter.search.searchBar.resetButton.label')}
              hidden={!state.query}
              style={{
                visibility: state.query ? 'visible' : 'hidden',
              }}
              size="small"
            >
              <ClearIcon />
            </IconButton>
          ),
        }}
      />
    </form>
  );
};
