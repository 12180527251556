import { InputAdornment, TextField } from '@material-ui/core';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { isEqual, isNumber } from 'lodash';
import { useCurrency } from '../hooks/useCurrency';
import { makeMonetaryAmount, MonetaryAmountStrict, SUPPORTED_CURRENCIES } from '../modules/currency';

type CurrencyInputProps = {
  label: string;
  name: string;
  error?: boolean;
};

const CurrencyInput = ({ label, name, error }: CurrencyInputProps) => {
  const { setValue, getValues, control } = useFormContext();
  const { currency, convert } = useCurrency();
  const formValue = useWatch({ control, name });

  useDeepCompareEffect(() => {
    const prev = getValues(name) as MonetaryAmountStrict;

    if (prev) {
      const newValue = makeMonetaryAmount(Math.round(convert(prev, {}).amount), currency);

      if (!isEqual(newValue, prev)) {
        setValue(name, newValue);
      }
    }
  }, [currency, convert, getValues, name, setValue, formValue]);

  return (
    <Controller
      name={name}
      render={({ field: { onChange, value, name: fieldName, ref } }) => (
        <TextField
          name={fieldName}
          fullWidth
          inputRef={ref}
          margin="dense"
          variant="outlined"
          type="number"
          inputProps={{
            type: 'number',
            min: 0,
          }}
          value={value?.amount ?? ''}
          label={label}
          onChange={(event) => {
            const newValue = event.target.value || null;
            const amount = newValue && parseInt(newValue, 10);

            onChange(isNumber(amount) ? makeMonetaryAmount(amount, currency) : amount);
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">{SUPPORTED_CURRENCIES[currency].symbol}</InputAdornment>,
          }}
          InputLabelProps={{ shrink: true }}
          error={error}
        />
      )}
    />
  );
};

export default CurrencyInput;
