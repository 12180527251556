import { Container, ContainerProps, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    marginTop: spacing(4),
    marginBottom: spacing(4),
  },
}));

const PageContainer = ({ children, className, ...rest }: ContainerProps) => {
  const classes = useStyles();

  return (
    <Container className={clsx(classes.root, className)} {...rest}>
      {children}
    </Container>
  );
};

export default PageContainer;
