import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from '@reach/router';
import { useTitle } from 'src/hooks/useTitle';
import DeliveryAddressContainer from '../../containers/DeliveryAddressContainer';

const DeliveryAddressPage = (_props: RouteComponentProps) => {
  const { t } = useTranslation();

  useTitle(`${t('navigation.delivery')} ${t('navigation.configuration')}`);

  return <DeliveryAddressContainer />;
};

export default DeliveryAddressPage;
