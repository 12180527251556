import NumberFormat, { InputAttributes, NumberFormatProps, NumberFormatValues } from 'react-number-format';

const amountInputValidator = (values: NumberFormatValues) => {
  const { floatValue } = values;
  if (floatValue === undefined) return false;
  return floatValue >= 0;
};

type AmountInputProps = {
  emptyInputAllowed?: boolean;
};

const AmountInput = (({ emptyInputAllowed, ...props }: NumberFormatProps & AmountInputProps) => (
  <NumberFormat
    allowEmptyFormatting
    decimalSeparator=","
    thousandSeparator="."
    isAllowed={emptyInputAllowed ? undefined : amountInputValidator}
    {...props}
  />
)) as <T = InputAttributes>(props: NumberFormatProps<T> & AmountInputProps) => JSX.Element;

export default AmountInput;
