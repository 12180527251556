import { AutocompleteApi, AutocompleteState, BaseItem } from '@algolia/autocomplete-core';
import { FaqSearchProps } from '@components/faq-search/FaqSearch';
import { InternalDocSearchHit } from '@components/faq-search/types';
import { Stack } from '@components/ui';
import { styled, Typography } from '@material-ui/core';
import { orange } from '@material-ui/core/colors';
import React from 'react';

export type ResultsProps<TItem extends BaseItem> = AutocompleteApi<
  TItem,
  React.FormEvent,
  React.MouseEvent,
  React.KeyboardEvent
> & {
  title: string;
  collection: AutocompleteState<TItem>['collections'][0];
  renderIcon: (props: { item: TItem; index: number }) => React.ReactNode;
  onItemClick: (item: TItem) => void;
  hitComponent: FaqSearchProps['hitComponent'];
};

type ResultProps<TItem extends BaseItem> = ResultsProps<TItem> & {
  item: TItem;
  index: number;
};

const StyledHit = styled('li')(({ theme }) => ({
  listStyle: 'none',
  borderRadius: theme.shape.borderRadius,
  paddingInline: theme.spacing(1),
  cursor: 'pointer',

  '&:hover, &[aria-selected="true"]': {
    background: orange[50],
  },
}));

const Result = <TItem extends InternalDocSearchHit>({
  item,
  index,
  renderIcon,
  getItemProps,
  onItemClick,
  collection,
}: ResultProps<TItem>) => (
  <StyledHit
    {...getItemProps({
      item,
      source: collection.source,
      onClick() {
        onItemClick(item);
      },
    })}
  >
    <Stack spacing={1} direction="row" alignItems="center">
      {renderIcon({ item, index })}

      {/* eslint-disable-next-line no-underscore-dangle */}
      {item._type === 'faq' && (
        <div>
          <Typography>{item.title}</Typography>
          {item.categories.length > 0 && (
            <Typography variant="caption" color="textSecondary">
              {item.categories.at(0)?.title}
            </Typography>
          )}
        </div>
      )}
    </Stack>
  </StyledHit>
);

export const Results = <TItem extends InternalDocSearchHit>(props: ResultsProps<TItem>) => {
  const { collection, title, getListProps } = props;

  if (!collection || collection.items.length === 0) {
    return null;
  }

  return (
    <section>
      <Typography gutterBottom variant="overline">
        {title}
      </Typography>

      <Stack
        spacing={1}
        {...getListProps()}
        style={{
          padding: 0,
          margin: 0,
        }}
        component="ul"
      >
        {collection.items.map((item, index) => (
          <Result key={[title, item.objectID].join(':')} item={item} index={index} {...props} />
        ))}
      </Stack>
    </section>
  );
};
