import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { QueryKeys } from 'src/constants';
import useCustomSnackbarGlobal from 'src/hooks/useSnackbarGlobal';
import ApiService from 'src/modules/api-service';
import { ServicePacket } from './types';

const deletePacket = (packetId: string) =>
  ApiService.packet.packetControllerDeletePacket(packetId).then((res) => res.data);

export const usePacketDelete = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { showInfo, showError } = useCustomSnackbarGlobal();

  const mutation = useMutation(deletePacket, {
    onMutate: async (packetId: string) => {
      await queryClient.cancelQueries([QueryKeys.servicePackets]);
      const snapshot = queryClient.getQueryData<ServicePacket[]>([QueryKeys.servicePackets]);

      queryClient.setQueryData<ServicePacket[]>([QueryKeys.servicePackets], (prev = []) =>
        prev.filter((packet) => packet.id !== packetId),
      );

      return snapshot;
    },
    onError: (_error, _targetId, snapshot) => {
      queryClient.setQueryData<ServicePacket[] | undefined>([QueryKeys.servicePackets], snapshot);
      showError(t('servicePacketItem.actions.delete.error'));
    },
    onSuccess: (packet) => {
      showInfo(
        t('servicePacketItem.actions.delete.success', {
          packetName: packet.name,
        }),
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries([QueryKeys.servicePackets]);
    },
  });

  return mutation;
};
