import { Card, makeStyles, Grid, Typography } from '@material-ui/core';
import { grey, red } from '@material-ui/core/colors';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { DurationObject, DurationObjectUnits } from 'luxon';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { dateTillNow, nowTillDate } from '../modules/date-helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'flex-start',
    margin: '5px',
    width: '50%',
  },
  card: {
    height: theme.spacing(5),
    width: theme.spacing(5),
    backgroundColor: grey[200],
    borderColor: theme.palette.primary.main,
    borderStyle: 'outset',
    borderWidth: '2px',
    margin: '0 5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 'large',
  },
  container: {
    width: 'fit-content',
  },
  red: {
    borderColor: red[400],
    backgroundColor: red[100],
  },
}));

type CountdownTimerProps = {
  countType: string;
  date: string;
  refresh: () => void;
};

const durations: (keyof DurationObjectUnits)[] = ['days', 'hours', 'minutes', 'seconds'];

const units = ['xday', 'xhour', 'xminute', 'xsecond'];

const formatNumber = (num: number) => String(Math.round(num)).padStart(2, '0');

const CountdownTimer = ({ countType, date, refresh }: CountdownTimerProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const startTime = countType === 'down' ? nowTillDate(date, durations) : dateTillNow(date, durations);
  const [timeNow, setTimeNow] = useState<DurationObject | false>(startTime);
  const refreshRef = useRef<typeof refresh>(refresh);
  refreshRef.current = refresh;
  const isCriticalTime: boolean =
    !!timeNow && !timeNow.days && !timeNow.hours && timeNow.minutes !== undefined && timeNow.minutes < 3;

  useEffect(() => {
    if (isEmpty(timeNow)) refreshRef.current();
  }, [timeNow]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (countType === 'down' || countType === 'until') {
        setTimeNow(nowTillDate(date, durations));
      } else {
        setTimeNow(dateTillNow(date, durations));
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [countType, date]);

  return (
    <div className={classes.root}>
      {Object.values(timeNow).map((time: number, index) => (
        <Fragment key={units[index]}>
          <Grid
            alignItems="center"
            className={classes.container}
            container
            direction="column"
            justifyContent="flex-start"
          >
            <Grid item>
              <Card className={clsx(classes.card, isCriticalTime && classes.red)} variant="outlined">
                {formatNumber(time)}
              </Card>
            </Grid>
            <Grid item>
              <Typography variant="caption">
                {t('common.timeUnits', { context: units[index], count: Math.round(time) })}
              </Typography>
            </Grid>
          </Grid>
          {index < units.length - 1 && ':'}
        </Fragment>
      ))}
    </div>
  );
};

export default CountdownTimer;
