import { hashQueryKey, useQuery, useQueryClient } from '@tanstack/react-query';
import { PaginationState, SortingState } from '@tanstack/react-table';
import { AxiosRequestConfig } from 'axios';
import { differenceBy } from 'lodash';
import { useEffect } from 'react';
import { QueryKeys } from 'src/constants';
import { useCurrency } from 'src/hooks/useCurrency';
import { useValuationSettings } from 'src/hooks/useValuationSettings';
import ApiService from 'src/modules/api-service';
import {
  Column,
  CurrencyCode,
  DataTablesOutputListCarDTO,
  ListCarDTO,
  PriceType,
  ValuationCountryCode,
} from 'src/modules/generated/api';
import { columns } from './columns';
import { transformForSubmit } from './filter/lib';
import { CarsTableNextFilterData } from './filter/types';
import { getColumnSortKey } from './lib';

type UseCarsProps = {
  filter: CarsTableNextFilterData;
  pagination: PaginationState;
  sorting: SortingState;
  refetchInterval?: number;
  enabled?: boolean;
};

export type DataTablesOutputListCarDTOExtended = DataTablesOutputListCarDTO & {
  data?: ListCarDTO &
    {
      isNew?: boolean;
    }[];
};

export const fetchCars = (
  filter: CarsTableNextFilterData,
  pagination: PaginationState,
  sorting: SortingState,
  settings?: {
    valuationCountry?: ValuationCountryCode;
    valuationType?: PriceType;
    targetCurrency?: CurrencyCode;
  },
  config?: AxiosRequestConfig,
) =>
  ApiService.listCars
    .listCarsControllerList(
      {
        ...transformForSubmit(filter),
        length: pagination.pageSize,
        start: pagination.pageIndex * pagination.pageSize,
        order: sorting.map((columnSort, index) => ({
          column: index,
          dir: columnSort.desc ? 'desc' : 'asc',
        })),
        columns: sorting.map(
          (columnSort) => ({ data: getColumnSortKey(columnSort.id, columns()) }) as Column, // Only send minimal information for simple directional sorting
        ),
        ...settings,
      },
      2,
      config,
    )
    .then((res) => res.data);

const flagNewCars = (next: ListCarDTO[], prev: ListCarDTO[]): ListCarDTO[] => {
  const newCarIds = differenceBy(next, prev, 'carId').map((car) => car.carId);

  return next.map((car) => ({
    ...car,
    isNew: newCarIds.includes(car.carId),
  }));
};

export const useCars = ({ filter, pagination, sorting, refetchInterval, enabled }: UseCarsProps) => {
  const queryClient = useQueryClient();
  const { valuationCountry, valuationType } = useValuationSettings();
  const { currency } = useCurrency();
  const settings = {
    valuationCountry: valuationCountry || undefined,
    valuationType: valuationType || undefined,
    targetCurrency: currency,
  };
  const options = [QueryKeys.carsList, { filter, pagination, sorting, settings }] as const;
  const query = useQuery<DataTablesOutputListCarDTOExtended>(
    options,
    () => fetchCars(filter, pagination, sorting, settings),
    {
      keepPreviousData: true,
      enabled,
      // refetchInterval --> check custom `refetch` fn
    },
  );
  const queryKeyHash = hashQueryKey(options);

  // Custom re-fetcher, in order to flag
  // new cars for highlighting
  useEffect(() => {
    if (!refetchInterval) {
      return undefined;
    }

    const controller = new AbortController();
    let t: number | undefined;

    const refetch = async (): Promise<void> => {
      try {
        const res = await fetchCars(options[1].filter, options[1].pagination, options[1].sorting, options[1].settings, {
          signal: controller.signal,
        });
        queryClient.setQueryData<DataTablesOutputListCarDTOExtended>(options, (prev) => ({
          ...res,
          data: res.data && prev?.data && flagNewCars(res.data, prev.data),
        }));
      } catch {
        if (controller.signal.aborted) {
          // Mission aborted!
          return;
        }
      }

      t = window.setTimeout(refetch, refetchInterval);
    };

    t = window.setTimeout(refetch, refetchInterval);

    return () => {
      window.clearTimeout(t);
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    refetchInterval,
    queryKeyHash, // account for `options`
    queryClient,
  ]);

  return query;
};
