import { useTranslation } from 'react-i18next';
import { Divider, makeStyles } from '@material-ui/core';
import { IndividualizedCarDTO } from '../modules/generated/api';
import { DATE_TIME_FORMAT, formatDate } from '../modules/date-helpers';

type ExternalUserCommentsProps = {
  comments?: IndividualizedCarDTO[];
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(0.3),
  },
  row: {
    marginTop: theme.spacing(0.7),
    minWidth: theme.spacing(40),
  },
  userName: {
    width: '100%',
  },
  comment: {
    marginLeft: theme.spacing(0.3),
  },
  divider: {
    width: '90%',
  },
  commentDate: {
    float: 'right',
  },
}));

const ExternalUserComments = ({ comments }: ExternalUserCommentsProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <b>{t('carsTable.userComment_plural')}</b>
      <Divider className={classes.divider} />
      {comments?.map((comment) => (
        <div className={classes.row} key={`${comment.user?.id}:${comment.userComment}`}>
          <b className={classes.userName}>
            <i>{comment.user?.name ? `${comment.user.name} (${comment.user.id})` : `User-ID: ${comment.user?.id}`}</i>
          </b>
          <div className={classes.commentDate}>
            <i>{comment.created && formatDate(comment.created, DATE_TIME_FORMAT)}</i>
          </div>
          <div className={classes.comment}>
            <i>{comment.userComment}</i>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ExternalUserComments;
