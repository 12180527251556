import { EffectCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import TagManager from 'react-gtm-module';
import { useExperiments } from 'src/hooks/useExperiments';
import { RouteComponentProps } from '@reach/router';
import { PageMeta } from '@components/page-meta';
import CarsFilter from 'src/containers/CarsFilter';
import CarsTableProvider, { useCarsTableStore } from 'src/stores/CarsTable';
import CarsMUIDataTableContainer from 'src/containers/CarsMUIDataTableContainer';
import { getGlobalFilterAndCarDataGTM, getPageViewTM, mergeDL } from 'src/modules/tag-manager-helpers';
import { CarsFilterProvider, useCarsFilter } from 'src/hooks/useCarsFilter';
import { CarsTableNext } from '@components/cars-table-next';
import { FilterProvider } from '@components/cars-table-next/filter/useFilter';

const useRunOnce = (cb: EffectCallback, condition: boolean) => {
  const run = useRef(false);
  const cbRef = useRef(cb);
  cbRef.current = cb;

  useEffect(() => {
    if (condition && !run.current) {
      run.current = true;
      return cbRef.current();
    }

    return undefined;
  }, [condition]);
};

const CarsPageComponent = () => {
  const {
    i18n: { language },
  } = useTranslation();
  const { page, rowsPerPage, sortOn, cars, loading } = useCarsTableStore();
  const { form } = useCarsFilter();

  // delay tracking, after initial car list request is done
  useRunOnce(() => {
    TagManager.dataLayer(
      mergeDL(
        getPageViewTM(language, 'Car list'),
        getGlobalFilterAndCarDataGTM('pageview', cars, page, rowsPerPage, sortOn, form.getValues()),
      ),
    );
  }, loading === false);

  return (
    <>
      <PageMeta title="common.car_plural" />
      <CarsFilter />
      <CarsMUIDataTableContainer />
    </>
  );
};

const CarsPage = (_props: RouteComponentProps) => {
  const { getExperimentStatus } = useExperiments();

  return getExperimentStatus('CAR_TABLE_LEGACY') ? (
    <CarsFilterProvider>
      <CarsTableProvider>
        <CarsPageComponent />
      </CarsTableProvider>
    </CarsFilterProvider>
  ) : (
    <>
      <PageMeta title="common.car_plural" />
      <FilterProvider>
        <CarsTableNext />
      </FilterProvider>
    </>
  );
};

export default CarsPage;
