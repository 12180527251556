import _ from 'lodash';

export const isSameArray = <T>(array1: T[], array2: T[]) => _.isEmpty(_.xor(array1, array2));

export const intersect = <T>(smallerArr: Array<T>, biggerArr: Iterable<T>) => {
  const setB = new Set(biggerArr);
  return smallerArr.filter((x) => setB.has(x));
};

export const discountBid = (base: number, discount: number) => Math.ceil((base * (1 - discount)) / 10) * 10 + 1; // calculates base minus discount in percent

export const formatNumber = (numb?: number, unit?: string) => {
  if (numb !== undefined) {
    if (unit) return `${numb.toLocaleString()} ${unit}`;

    return numb.toLocaleString();
  }
  return undefined;
};
