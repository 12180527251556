import { ChangeEvent } from 'react';
import { TextCell, TextCellProps } from '@components/ui/TextCell';
import { Switch } from '@material-ui/core';

export type LeadToggleCellProps = {
  checked: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
};

export const LeadToggleCell = ({
  onChange,
  checked,
  disabled,
  ...rest
}: LeadToggleCellProps & TextCellProps): JSX.Element => (
  <TextCell
    startAdornment={<Switch checked={checked} onChange={onChange} color="primary" disabled={disabled} />}
    {...rest}
  />
);
