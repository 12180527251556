import jwtDecode from 'jwt-decode';
import { RoutePaths } from 'src/constants';
import {
  AuthControllerApi,
  BidNewControllerApi,
  CarDetailControllerApi,
  ConfigControllerApi,
  Configuration,
  DealerControllerApi,
  ExportControllerApi,
  ExportImagesControllerApi,
  ListCarsControllerApi,
  PacketControllerApi,
  PriceCalculationControllerApi,
  SearchAgentControllerApi,
} from './generated/api';

import { GwscoutJwtPayload, getAccessTokenFromStorage, redirectToAuthentication } from '../stores/Authentication';
import { ManualImportControllerApi, TransportationControllerApi } from './generated/api/api';

const ApiConfiguration = new Configuration({
  basePath: process.env.REACT_APP_API_URL || window.location.origin,
  accessToken: () => {
    const accessToken = getAccessTokenFromStorage();
    const isPublicService = window.location.pathname.startsWith(
      // Omit path param
      RoutePaths.imageExport.split('/').slice(0, -1).join('/'),
    );

    if (isPublicService) {
      if (accessToken) {
        const token = jwtDecode<GwscoutJwtPayload>(accessToken ?? '');
        if (token.exp && token.exp * 1000 < Date.now()) {
          redirectToAuthentication(); // Redirect to login if token is there but expired
          return '';
        }
      }
      return accessToken ?? ''; // Public services don't need to redirect, but if there's a token, it should still be used
    }

    if (!accessToken) {
      redirectToAuthentication();
      return '';
    }

    return accessToken ?? '';
  },
});

const ApiService = {
  newBids: new BidNewControllerApi(ApiConfiguration),
  calcPrice: new PriceCalculationControllerApi(ApiConfiguration),
  export: new ExportControllerApi(ApiConfiguration),
  listCars: new ListCarsControllerApi(ApiConfiguration),
  searchAgent: new SearchAgentControllerApi(ApiConfiguration),
  config: new ConfigControllerApi(ApiConfiguration),
  dealer: new DealerControllerApi(ApiConfiguration),
  auth: new AuthControllerApi(ApiConfiguration),
  packet: new PacketControllerApi(ApiConfiguration),
  imageExport: new ExportImagesControllerApi(ApiConfiguration),
  detail: new CarDetailControllerApi(ApiConfiguration),
  importController: new ManualImportControllerApi(ApiConfiguration),
  transportationController: new TransportationControllerApi(ApiConfiguration),
};

export default ApiService;
