import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import { ListCarDTO, SourceRegisterPotentialDTO, ValuationCountryCode } from '../modules/generated/api';
import { refCarDialogState } from '../stores/state';

const useStyles = makeStyles({
  icon: {
    width: '3.5rem',
    height: 'auto',
  },
});

type ReferenceVehicleButtonProps = {
  refCar: ListCarDTO;
  potential?: SourceRegisterPotentialDTO;
  offerId?: string;
  valuationCountry?: ValuationCountryCode;
};

const ReferenceVehicleButton = ({ refCar, potential, offerId, valuationCountry }: ReferenceVehicleButtonProps) => {
  const classes = useStyles();
  const [, setReferenceVehicleState] = useRecoilState(refCarDialogState);
  const { t } = useTranslation();

  return (
    <IconButton
      className={classes.icon}
      onClick={(e) => {
        e.stopPropagation();
        if (refCar.carId)
          setReferenceVehicleState({ carId: refCar.carId, refCar, potential, offerId, valuationCountry });
      }}
    >
      <Tooltip title={t('referencePopup.showRefCars')}>
        <img src="/images/icons/cars_icon.png" alt="Icon of multiple cars" />
      </Tooltip>
    </IconButton>
  );
};

export default memo(ReferenceVehicleButton);
