import jwtDecode from 'jwt-decode';
import { isNumber, min, round } from 'lodash';
import { DateTime } from 'luxon';
import { GwscoutJwtPayload, useAuthenticationStore } from 'src/stores/Authentication';
import { ConvertOverload } from '../hooks/useCurrency';
import UserRole from '../types/UserRoles';
import { isUcs } from './car-source-helper';
import { makeMonetaryAmount, SupportedCurrencyCode } from './currency';
import {
  BidDTO,
  BidSuccessStatus,
  BidType,
  CountryCode,
  CurrencyCode,
  DealerId,
  DetailCarDTO,
  DetailCarPreviousAuctionDTO,
  MonetaryAmount,
  OfferType,
  SourceType,
  TransportationProvider,
  TransportOptionDTO,
} from './generated/api';
import { transportOptionLocalStorage } from './global-vars';
import { discountBid } from './math-helpers';

export const getMinOfPrevAuctions = (previousAuctions: DetailCarPreviousAuctionDTO[], packageId?: string) => {
  if (packageId) {
    return min(
      previousAuctions
        .filter(
          (auction) =>
            auction.offerType === OfferType.Package &&
            (auction.priceOrientation !== undefined || auction.priceFixed !== undefined),
        )
        .map((auction) => auction.priceOrientation?.amount || auction.priceFixed?.amount || 0),
    );
  }
  return min(
    previousAuctions
      .filter(
        (auction) =>
          auction.offerType === OfferType.Individual &&
          (auction.priceOrientation !== undefined || auction.priceFixed !== undefined),
      )
      .map((auction) => auction.priceOrientation?.amount || auction.priceFixed?.amount || 0),
  );
};
export const bidTimeInPast = (biddingTime: string | undefined) => {
  if (biddingTime) {
    return new Date(biddingTime).getTime() < Date.now();
  }
  return true;
};

export const getBidAlertTitle = (successStatus: BidSuccessStatus | undefined, bidEditMode: boolean) => {
  switch (successStatus) {
    case BidSuccessStatus.Sent:
      return 'bids.hintSent';
    case BidSuccessStatus.Scheduled:
      return bidEditMode ? 'bids.hintOverwrite' : 'bids.hintScheduled';
    case BidSuccessStatus.Bought:
      return 'bids.hintBought';
    default:
      return '';
  }
};

export const getTimerValue = (dateEnd: string | undefined, dateBuyOpening: string | undefined) => {
  const parsedDateEnd = dateEnd && new Date(dateEnd);
  const parsedDateBuyOpening = dateBuyOpening && new Date(dateBuyOpening);
  const currentDate = new Date();
  const isOpen =
    parsedDateBuyOpening &&
    parsedDateBuyOpening.getTime() &&
    parsedDateBuyOpening.getTime() - currentDate.getTime() <= 0;
  const isEndingSoon =
    parsedDateEnd && parsedDateEnd.getTime() && parsedDateEnd.getTime() - currentDate.getTime() <= 1000 * 60 * 60 * 12;

  switch (true) {
    case isOpen === false:
      return [dateBuyOpening!, 'until']; // Auction starts in...
    case isEndingSoon === true:
      return [dateEnd!, 'down']; // Auction ends in...
    case isOpen === true:
    case isEndingSoon === false:
      return [dateBuyOpening!, 'up']; // Auction is running for...
    default:
      return false;
  }
};
export const SOURCES_BID_ALLOWED: SourceType[] = [SourceType.Vwfs, SourceType.Ucs, SourceType.SpotdealsAllmobil];

export const getBiddingStatus = (bid?: BidDTO) => {
  const isAlreadyBought = bid?.bids?.[0].bidType === BidType.Fixed;

  switch (true) {
    case isAlreadyBought:
      return BidSuccessStatus.Bought;
    case bid?.successStatus === BidSuccessStatus.Scheduled && !isAlreadyBought:
      return bidTimeInPast(bid?.biddingTime) ? BidSuccessStatus.Sent : BidSuccessStatus.Scheduled;
    default:
      return bid?.successStatus;
  }
};

export const getAccountDealerId = (): string[] | undefined => {
  const {
    state: { accessToken },
  } = useAuthenticationStore();
  if (accessToken) {
    const token = jwtDecode<GwscoutJwtPayload>(accessToken);
    return token.dealer_id;
  }
  return undefined;
};

export const wasAuctionAlreadyVisible = (visibleForKvps: string[] | undefined) => !!visibleForKvps?.length;

type BidDefaultProps = {
  car: DetailCarDTO;
  convert: ConvertOverload;
  hasRole: (role: UserRole) => boolean;
};

export const getBidDefaults = ({
  car: { previousAuctions, packageId, bid, bidStateInfo, source },
  hasRole,
  convert,
}: BidDefaultProps) => {
  const actualBidSuccessState = getBiddingStatus(bid);

  const colleagueBidActive = bidStateInfo?.highestHistoricBid?.amount;
  const base = getMinOfPrevAuctions(previousAuctions || [], packageId) || 0;

  const bids = bid?.bids;
  type BidsType = {
    bid1: Required<MonetaryAmount> | undefined;
    bid2: Required<MonetaryAmount> | undefined;
    bid3: Required<MonetaryAmount> | undefined;
  };

  const overbidColleague: BidsType = {
    bid1: colleagueBidActive
      ? { amount: colleagueBidActive + 100, currency: bidStateInfo.highestHistoricBid?.currency || 'EUR' }
      : undefined,
    bid2: colleagueBidActive
      ? { amount: colleagueBidActive + 200, currency: bidStateInfo.highestHistoricBid?.currency || 'EUR' }
      : undefined,
    bid3: colleagueBidActive
      ? { amount: colleagueBidActive + 300, currency: bidStateInfo.highestHistoricBid?.currency || 'EUR' }
      : undefined,
  };

  const prevBid: BidsType = {
    bid1: isNumber(bids?.[0]?.bid?.value)
      ? convert(makeMonetaryAmount(bids![0]!.bid!.value, bids![0]!.bid!.unit as SupportedCurrencyCode), {})
      : undefined,
    bid2: isNumber(bids?.[1]?.bid?.value)
      ? convert(makeMonetaryAmount(bids![1]!.bid!.value, bids![1]!.bid!.unit as SupportedCurrencyCode), {})
      : undefined,
    bid3: isNumber(bids?.[2]?.bid?.value)
      ? convert(makeMonetaryAmount(bids![2]!.bid!.value, bids![2]!.bid!.unit as SupportedCurrencyCode), {})
      : undefined,
  };
  const baseBid: BidsType = {
    // base minus 5/4/3 percent
    bid1: convert(makeMonetaryAmount(discountBid(base, 0.05), CurrencyCode.Eur), {}),
    bid2: convert(makeMonetaryAmount(discountBid(base, 0.04), CurrencyCode.Eur), {}),
    bid3: convert(makeMonetaryAmount(discountBid(base, 0.03), CurrencyCode.Eur), {}),
  };

  const defaultBiddingTime =
    actualBidSuccessState === undefined || actualBidSuccessState === BidSuccessStatus.Scheduled ? '60' : '0';

  return {
    biddingTime: defaultBiddingTime,
    ...(hasRole(UserRole.DEALER) && prevBid),
    ...(hasRole(UserRole.ADMIN) &&
      !bids && {
        bid1: overbidColleague.bid1 || prevBid.bid1 || baseBid.bid1,
        bid2: source && !isUcs(source) ? overbidColleague.bid2 || prevBid.bid2 || baseBid.bid2 : undefined,
        bid3: source && !isUcs(source) ? overbidColleague.bid3 || prevBid.bid3 || baseBid.bid3 : undefined,
      }),
  };
};
export type BidBuyErrors = {
  dealerAddressNotSet?: boolean;
  colleagueBidActive?: boolean;
  credentialsMissing?: boolean;
  illegalAccountValuationCountry?: boolean;
};

export const isBuyingAllowedForUCS = (dateStart?: string, dateBuyOpening?: string): boolean => {
  // For UCS buying not allowed if the start time > 720 hours away
  const startDate = DateTime.fromISO(dateBuyOpening || dateStart!);
  const today = DateTime.now();

  const hoursTillStart = round(startDate.diff(today, 'hours').hours, 0);
  if (hoursTillStart <= 720) return true;
  return false;
};

export const transformDealerIdToDealerIdDTO = (dealerId?: string): DealerId | undefined => {
  if (dealerId && dealerId.length > 0) {
    return { id: dealerId, country: dealerId.substring(0, 2) as CountryCode };
  }
  return undefined;
};

export const selectDefaultTransportOption = (transportOptions: TransportOptionDTO[]): string | undefined => {
  if (transportOptions.length === 1) {
    return transportOptions[0].id;
  }

  const transportLocalStorage = localStorage.getItem(transportOptionLocalStorage) || '';
  if (!transportLocalStorage) return '';

  switch (transportLocalStorage) {
    case TransportationProvider.PhsTransshipment:
      return transportOptions.find((option) => option.provider === TransportationProvider.PhsTransshipment)?.id;
    case TransportationProvider.PlatformDefault:
      return transportOptions.find((option) => option.provider === TransportationProvider.PlatformDefault)?.id;
    case TransportationProvider.VwfsFirstCallPickup:
    case TransportationProvider.VwfsPickup:
      return transportOptions.find(
        (option) =>
          option.provider === TransportationProvider.VwfsPickup ||
          option.provider === TransportationProvider.VwfsFirstCallPickup,
      )?.id;
    case TransportationProvider.VwfsTransportingWheelsTransport:
    case TransportationProvider.VwfsTransport:
      return transportOptions
        .filter((option) =>
          [
            TransportationProvider.VwfsTransportingWheelsTransport,
            TransportationProvider.VwfsTransport,
            TransportationProvider.PhsTransshipment,
          ].includes(option.provider as TransportationProvider),
        )
        .reduce(
          (prev, current) => ((prev.cost?.amount ?? Infinity) < (current.cost?.amount ?? Infinity) ? prev : current),
          {
            id: '',
            cost: { amount: Infinity },
          },
        ).id;
    default:
      return '';
  }
};
