import { Button, ButtonGroup, IconButton, Tooltip } from '@material-ui/core';
import MUIDataTable, {
  MUIDataTableColumn,
  MUIDataTableOptions,
  MUIDataTableProps,
  MUIDataTableState,
} from 'mui-datatables';
import { Component, memo, ReactNode, useEffect, useRef, useState } from 'react';
import { Apps, NavigateBefore, NavigateNext } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { range } from 'lodash';
import useRole from '../hooks/useRole';
import {
  BidDTO,
  ConditionalFormattingDTO,
  ConditionalFormattingScope,
  ExportProperty,
  ListCarDTO,
  Order,
  PriceType,
} from '../modules/generated/api';
import { PRICE_STRATEGY_LABEL, PriceStrategyType } from '../modules/labels';
import getMUIDataTableDefaultOptions from '../setup/MUIDataTableOptions';
import UserRole from '../types/UserRoles';
import ExportPrompt from './ExportPrompt';
import { checkColumnOrderAllowed, mergeBidCars } from '../modules/table-helpers';
import { BidTableType } from '../setup/bids-filter-settings';
import useCarsTableColumns from '../hooks/useCarsTableColumns';
import { CarsFilterSettings } from '../types/CarsFilterSettings';
import { ListCarDtoSafe, useTableCalculator } from '../hooks/useTableCalculator';
import TableHeadCustom from './TableHeadCustom';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { AutoRefresh } from './auto-refresh';
import ProgressBar from './ProgressBar';
import { CarTableColumn, BidsListCarDTO } from '../modules/table-data';
import { conditionalFormattingCreateBackground } from '../modules/conditional-formatting';

const retrieveViewColumnFromLocalStorage = (name: string, isAdmin: boolean, localStoragePrefix: string) => {
  const localStorageViewColumns = localStorage.getItem(`gwscout/${localStoragePrefix}.viewColumns`);
  if (!localStorageViewColumns) return null;
  const localStorageViewColumnsAsJson: Record<string, 'false' | 'true'> = JSON.parse(localStorageViewColumns);
  if (
    !isAdmin &&
    [
      CarTableColumn.DateOnlineTimeInMinutes,
      CarTableColumn.TaxesAt,
      CarTableColumn.TaxesAtLot,
      CarTableColumn.TaxesFr,
      CarTableColumn.TaxesDe,
      CarTableColumn.TaxesIt,
      CarTableColumn.TaxesEs,
      CarTableColumn.TaxesCz,
      CarTableColumn.TaxesPl,
      CarTableColumn.TaxesHu,
      CarTableColumn.TaxesRo,
    ].includes(name as CarTableColumn)
  ) {
    return false;
  }
  return localStorageViewColumnsAsJson[name];
};

type CarsMUIDataTableProps = {
  cars: ListCarDTO[];
  bids?: BidDTO[];
  bidsTableType?: BidTableType;
  tableOptions?: MUIDataTableOptions;
  totalCarsCount: number;
  localStoragePrefix: string;
  page: number;
  filterSettings?: CarsFilterSettings;
  conditionalFormattings?: ConditionalFormattingDTO[];
  onChangePage: (page: number) => void;
  onChangeRowsPerPage: (rowsPerPage: number, page: number) => void;
  onSort?: (name: string, direction: string) => void;
  onDownload?: (exportProperties: ExportProperty[]) => Promise<void>;
  title?: ReactNode;
  packageMode?: boolean;
  handleTogglePackageMode?: () => void;
  autoRefresh?: boolean;
  refetchList?: () => Promise<void>;
};

export type CarsTableState = {
  count: number;
  order: Order[];
  rowsPerPage: number;
};

const isEqualLength = (...arrays: unknown[][]): boolean => {
  const lengths = arrays.map((arr) => arr.length);
  const allEqual = new Set(lengths).size === 1;

  if (!allEqual) {
    throw Error(`Input did not pass equality test for length:\n${lengths.join(';')}`);
  }

  return allEqual;
};

const CarsMUIDataTable = ({
  cars,
  bids,
  bidsTableType,
  tableOptions = {},
  totalCarsCount,
  localStoragePrefix,
  page,
  filterSettings,
  conditionalFormattings = [],
  onChangePage,
  onChangeRowsPerPage,
  onSort,
  onDownload,
  title,
  packageMode,
  handleTogglePackageMode,
  autoRefresh,
  refetchList,
}: CarsMUIDataTableProps) => {
  const { getCarsTableColumns, getCarBidsColumns, getColumnBgColors } = useCarsTableColumns();
  const { loading: packageModeLoading } = useTableCalculator();

  const { hasRole } = useRole();
  const [openExportDialog, setOpenExportDialog] = useState(false);
  const tableRef = useRef<Component<MUIDataTableProps, MUIDataTableState, any>>();
  const { t } = useTranslation();
  const { resetEditCars, handleOnEditMultiple, reComputeEveryEditCar, toCalcCar } = useTableCalculator();

  const tableData: BidsListCarDTO[] = bids ? mergeBidCars(cars, bids, bidsTableType === 'BoughtBidsTable') : cars;

  const carsColumns: MUIDataTableColumn[] = getCarsTableColumns(
    tableData,
    conditionalFormattings,
    bids,
    bidsTableType,
    filterSettings,
    refetchList,
  );
  const bidsColumns: MUIDataTableColumn[] = getCarBidsColumns(tableData, bidsTableType);

  const insertAfterColumnIdx = carsColumns.findIndex(
    (carsColumn: MUIDataTableColumn) => carsColumn.name === CarTableColumn.NumAuctions,
  );

  const columns = bids
    ? [
        ...carsColumns.slice(0, insertAfterColumnIdx + 1),
        ...bidsColumns,
        ...carsColumns.slice(insertAfterColumnIdx + 1),
      ]
    : carsColumns;

  const [columnOrder, setColumnOrder] = useLocalStorage(
    `gwscout/${localStoragePrefix}.columnOrder`,
    range(columns.length),
    (retrievedColumns: number[]) => isEqualLength(retrievedColumns, columns),
  );

  columns.forEach((column) => {
    if (column.options?.display === 'excluded') return;
    if (!column.options) {
      // eslint-disable-next-line no-param-reassign
      column.options = {};
    }
    // eslint-disable-next-line no-param-reassign
    column.options.display =
      retrieveViewColumnFromLocalStorage(column.name, hasRole(UserRole.ADMIN), localStoragePrefix) ??
      column.options.display ??
      'true';
  });

  useEffect(() => {
    if (!packageMode) return;
    const editAll = cars
      .filter((car) => car.carId !== undefined && car.source !== undefined)
      .map((car) => toCalcCar(car as ListCarDtoSafe));
    handleOnEditMultiple(editAll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packageMode]);

  useEffect(() => {
    if (!packageMode) resetEditCars();
  }, [packageMode, resetEditCars, cars]);

  const options: MUIDataTableOptions = {
    ...getMUIDataTableDefaultOptions(),
    rowsPerPageOptions: packageMode ? [cars.length] : getMUIDataTableDefaultOptions().rowsPerPageOptions,
    tableBodyMaxHeight: 'calc(100vh - 300px)',
    count: totalCarsCount,
    sort: !!onSort,
    onDownload: () => {
      setOpenExportDialog(true);
      return false;
    },
    customToolbar: () => (
      <>
        {packageMode && (
          <span>
            <ButtonGroup>
              {[
                PriceType.PotentialMeanRelative,
                PriceType.PotentialMinMeanRelative,
                PriceType.PotentialMinRelative,
              ].map((priceType) => (
                <Button key={priceType} onClick={() => reComputeEveryEditCar(null, priceType)}>
                  {t(PRICE_STRATEGY_LABEL[priceType as PriceStrategyType] as any)}
                </Button>
              ))}
            </ButtonGroup>
          </span>
        )}
        {handleTogglePackageMode && hasRole(UserRole.ADMIN) && (
          <span>
            <Tooltip title={t('carsTable.packageMode')}>
              <IconButton onClick={handleTogglePackageMode}>
                <Apps color={packageMode ? 'primary' : 'inherit'} />
              </IconButton>
            </Tooltip>
          </span>
        )}
        <span>
          <IconButton disabled={page === 0} onClick={() => onChangePage(page - 1)}>
            <NavigateBefore />
          </IconButton>
          <IconButton
            disabled={(tableOptions.rowsPerPage as number) * (page + 1) >= totalCarsCount}
            onClick={() => onChangePage(page + 1)}
          >
            <NavigateNext />
          </IconButton>
          {autoRefresh && <AutoRefresh />}
        </span>
      </>
    ),
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          onChangePage(tableState.page);
          if (tableRef.current) {
            (tableRef.current as any).tableRef.parentElement.scrollTop = 0;
          }
          break;
        case 'changeRowsPerPage': {
          const { rowsPerPage, page: currentPage } = tableState;
          onChangeRowsPerPage(rowsPerPage, currentPage);
          break;
        }
        case 'sort': {
          const { name, direction } = tableState.sortOrder;
          if (onSort) onSort(name, direction);
          break;
        }
        case 'viewColumnsChange': {
          const viewColumns = tableState.columns.reduce(
            (acc, column) => {
              if (column.display === 'true' || column.display === 'false' || typeof column.display === 'boolean')
                acc[column.name] = String(column.display) as 'true' | 'false';
              return acc;
            },
            {} as Record<string, 'false' | 'true'>,
          );
          localStorage.setItem(`gwscout/${localStoragePrefix}.viewColumns`, JSON.stringify(viewColumns));
          break;
        }
        // no default
      }
    },
    onColumnOrderChange: (newColumnOrder, columnIndex, newPosition) => {
      const oldPosition = columnOrder.indexOf(columnIndex);
      if (!checkColumnOrderAllowed(oldPosition, newPosition, columns)) {
        // Enforce rerender in a hacky way
        setColumnOrder([...columnOrder]);
        return;
      }
      setColumnOrder(newColumnOrder);

      localStorage.setItem(`gwscout/${localStoragePrefix}.columnOrder`, JSON.stringify(newColumnOrder));
    },
    page,
    serverSide: true,
    columnOrder,
    setRowProps: (_row, _dataIndex, rowIdx) => {
      const bgColors = columns.flatMap((col) =>
        getColumnBgColors(
          rowIdx,
          col.name as CarTableColumn,
          tableData,
          conditionalFormattings,
          ConditionalFormattingScope.Row,
        ),
      );

      return { style: { background: conditionalFormattingCreateBackground(bgColors) } };
    },
    ...tableOptions,
  };

  return (
    <>
      <MUIDataTable
        title={title}
        data={tableData}
        columns={columns}
        options={options}
        innerRef={tableRef}
        // @ts-ignore
        components={{ TableHead: TableHeadCustom }}
      />
      {openExportDialog && onDownload && (
        <ExportPrompt
          columns={columns}
          columnOrder={columnOrder}
          open={openExportDialog}
          onDownload={onDownload}
          onClose={() => setOpenExportDialog(false)}
        />
      )}
      {packageModeLoading && <ProgressBar />}
    </>
  );
};

export default memo(CarsMUIDataTable);
