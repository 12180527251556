import { Divider, Grid, makeStyles } from '@material-ui/core';
import { StarRounded } from '@material-ui/icons';
import { Rating } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { IndividualizedCarDTO } from '../modules/generated/api';

type ExternalRatingProps = {
  individualizations?: IndividualizedCarDTO[];
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(0.3),
  },
  star: {
    fontSize: '1.1rem',
  },
  rating: {
    color: theme.palette.grey[200],
  },
  row: {
    marginTop: theme.spacing(0.7),
    minWidth: theme.spacing(40),
  },
  contentWrapper: {
    textAlign: 'right',
    justifyContent: 'right',
    alignItems: 'center',
    display: 'flex',
    marginLeft: 'auto',
  },
  divider: {
    width: '90%',
  },
}));

const ExternalUserRating = ({ individualizations }: ExternalRatingProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.root}>
      <b>{t('carsTable.userRating_plural')}</b>
      <Divider className={classes.divider} />
      {individualizations?.map((individualization) => (
        // Extra div due to CSS bug introducing too large top margin when applying class to <Grid>
        <div className={classes.row} key={`${individualization.user?.id}:${individualization.rating}`}>
          <Grid container spacing={3} wrap="nowrap">
            <Grid item>
              {individualization.user?.name
                ? `${individualization.user?.name} (${individualization.user?.id})`
                : `User-ID: ${individualization.user?.id}`}
            </Grid>
            <Grid item className={classes.contentWrapper}>
              <Rating
                classes={{ iconFilled: classes.rating }}
                size="small"
                name="read-only"
                value={individualization.rating}
                readOnly
                icon={<StarRounded className={classes.star} />}
              />
            </Grid>
          </Grid>
        </div>
      ))}
    </div>
  );
};

export default ExternalUserRating;
