import { ButtonBase, styled, SvgIconProps, Typography } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import clsx from 'clsx';

export const TeaserContent = styled('div')({
  flexGrow: 1,
  minWidth: 0,
});

export const TeaserLinkEndAdornment = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.grey[500],
  alignSelf: 'stretch',
  paddingLeft: theme.spacing(2),
  borderLeft: '1px solid',
  borderColor: theme.palette.grey[300],
}));

export const TeaserLinkChevron = styled(({ className, ...props }: SvgIconProps) => (
  <ChevronRightIcon className={clsx(className, 'teaser___chevron')} {...props} />
))({
  transform: 'translateX(0)',
  transition: 'transform 0.2s ease-out',
});

export const TeaserTitle = styled(Typography)({});
TeaserTitle.defaultProps = {
  variant: 'h6',
};

export const TeaserBody = styled(Typography)({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});
TeaserBody.defaultProps = {
  variant: 'body2',
  color: 'textSecondary',
};

export const Teaser = styled(ButtonBase)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  border: '1px solid',
  borderColor: theme.palette.grey[300],
  borderRadius: theme.shape.borderRadius,

  // link reset
  color: theme.palette.text.primary,
  textDecoration: 'none',
  textAlign: 'left',

  '& .MuiTouchRipple-child': {
    backgroundColor: theme.palette.primary.light,
  },

  '&:hover': {
    borderColor: theme.palette.primary.main,
  },

  '&:hover .teaser___chevron': {
    color: theme.palette.primary.main,
    transform: 'translateX(4px)',
  },
}));
