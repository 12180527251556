import { ReactNode } from 'react';
import { styled, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { useTranslation } from 'react-i18next';

export type HeadCellProps = {
  text: ReactNode;
};

const Text = styled(Typography)({
  textAlign: 'left',
  fontWeight: 'bold',
  color: grey[700],
});

export const HeadCell = ({ text }: HeadCellProps) => {
  const { t } = useTranslation();

  return (
    <Text variant="body2">
      {/* @ts-expect-error */}
      {t(text)}
    </Text>
  );
};
