import { memo, ReactElement } from 'react';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: theme.spacing(1),
  },
}));

type FilterHeadingProps = {
  children: ReactElement | string;
};

const FilterHeading = ({ children }: FilterHeadingProps) => {
  const classes = useStyles();

  return (
    <Typography variant="h3" component="h2" className={classes.heading}>
      {children}
    </Typography>
  );
};

export default memo(FilterHeading);
