import { CacheKeys } from 'src/constants';
import {
  DEFAULT_CURRENCY,
  isSupportedCurrencyCode,
  MonetaryAmountStrict,
  SupportedCurrencyCode,
} from 'src/modules/currency';
import { CurrencyCode, MonetaryAmount } from 'src/modules/generated/api';
import { isNil, isNumber } from 'lodash';
import { isMonetaryAmount } from '@components/cars-table-next/lib';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { atom, useRecoilState } from 'recoil';
import { localStorageEffect } from 'src/lib/recoil';
import { useExchangeRates } from './useExchangeRates';

export type ConvertOverload = {
  (value: number, options: { from: string; to?: string }): number;
  (value: MonetaryAmount | MonetaryAmountStrict, options: { to?: string }): MonetaryAmountStrict;
};

type FormatOverload = {
  (
    value: number,
    options: { to?: string; locale?: string; minimumFractionDigits?: number; maximumFractionDigits?: number },
  ): string;
  (
    value: MonetaryAmount | MonetaryAmountStrict,
    options: { to?: string; locale?: string; minimumFractionDigits?: number; maximumFractionDigits?: number },
  ): string;
};

const currencyState = atom<SupportedCurrencyCode>({
  key: 'i18n/activeCurrency',
  default: DEFAULT_CURRENCY.code,
  effects: [localStorageEffect(CacheKeys.activeCurrency)],
});

export const useCurrency = () => {
  const { i18n } = useTranslation();
  const { data: exchangeRates } = useExchangeRates();

  const [currency, setCurrency] = useRecoilState(currencyState);

  const convert: ConvertOverload = useCallback(
    (value, options) => {
      const to = options.to ?? currency;

      const invalidRate = (from: string): never => {
        throw Error(`Invalid conversion!\n${JSON.stringify({ from, to })}`);
      };

      if (!isSupportedCurrencyCode(to)) {
        throw Error(`Invalid to-currency: "${to}"`);
      }

      if (isNumber(value) && 'from' in options) {
        const rate = exchangeRates?.[options.from as SupportedCurrencyCode]?.[to as SupportedCurrencyCode];

        if (isNil(rate)) {
          return invalidRate(options.from);
        }

        return (value * rate) as any;
      }

      if (isMonetaryAmount(value) && isNumber(value.amount) && isSupportedCurrencyCode(value.currency)) {
        const rate = exchangeRates?.[value.currency as SupportedCurrencyCode]?.[to as SupportedCurrencyCode];

        if (isNil(rate)) {
          return invalidRate(value.currency);
        }

        return {
          amount: value.amount * rate,
          currency: to as CurrencyCode,
        } as any;
      }

      throw Error(`Invalid exchange arguments!\n${JSON.stringify({ value, options })}`);
    },
    [currency, exchangeRates],
  );

  const format: FormatOverload = (
    value,
    { to = currency, locale = i18n.language, maximumFractionDigits = 0, minimumFractionDigits = 0 } = {},
  ) => {
    const finalValue = isNumber(value) ? value : value.amount;
    const finalCurrency = isNumber(value) ? to : value.currency;

    if (isNil(finalValue)) {
      throw Error(`Invalid value: "${JSON.stringify(value)}"`);
    }

    const currencyFormat = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: finalCurrency,
      minimumFractionDigits,
      maximumFractionDigits,
    });

    return currencyFormat.format(finalValue);
  };

  return {
    convert,
    format,
    currency,
    setCurrency,
  };
};
