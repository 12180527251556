import { AppBar, Badge, Container, Icon, IconButton, makeStyles, Toolbar, Tooltip } from '@material-ui/core';
import { ExitToApp, InfoOutlined, Settings, ShoppingCart } from '@material-ui/icons';
import GavelIcon from '@material-ui/icons/Gavel';
import { Link, useLocation, useMatch, useNavigate } from '@reach/router';
import clsx from 'clsx';
import { isUndefined } from 'lodash';
import { DateTime } from 'luxon';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { RoutePaths } from 'src/constants';
import ImprintPopup from 'src/containers/ImprintPopup';
import { getSourceOptions, useSources } from 'src/hooks/useSources';
import AllucLogoImage from '../assets/images/Allmobil_Logo.jpg';
import logoImage from '../assets/images/logo.png';
import SupportPopup from '../containers/SupportPopup';
import useRole from '../hooks/useRole';
import { useWizard } from '../hooks/useWizard';
import { ConfigNavigationItems } from '../modules/data';
import { CountryCode, SourceType, WizardStep } from '../modules/generated/api';
import { useAuthenticationStore } from '../stores/Authentication';
import GavelClockIcon from './GavelClockIcon';
import LanguageCountrySwitcher from './LanguageCountrySwitcher';
import LanguageSwitcher from './LanguageSwitcher';
import NavigationLink, { useStyles as useStylesButton } from './NavigationLink';
import NavigationLinkDropdown from './NavigationLinkDropdown';
import RoleSimulationChip from './RoleSimulationChip';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    zIndex: 1001,
    position: 'sticky',
    top: 0,
  },
  toolBar: {
    padding: 0,
  },
  title: {
    display: 'flex',
    alignItems: 'flex-end',
    color: 'inherit',
    textDecoration: 'none',
    marginTop: -6,
  },
  logo: {
    display: 'inline-block',
    width: 240,
    height: 'auto',
    marginRight: theme.spacing(2),
  },
  logo2: {
    display: 'inline-block',
    width: 200,
    height: 'auto',
    marginRight: theme.spacing(2),
  },
  titleTypography: {
    lineHeight: 1,
    fontWeight: 'bold',
    fontSize: '2.5rem',
    fontVariant: 'small-caps',
    padding: `0px ${theme.spacing(2)}px`,
    textDecorationLine: 'underline',
    textDecorationColor: theme.palette.primary.main,
    marginBottom: -6,
  },
  links: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2),
  },
  printHide: {
    '@media print': {
      display: 'none',
    },
  },
  singleIcon: {
    width: '3em',
    height: 'auto',
    fontWeight: 'bold',
  },
  pendingBidNav: {
    paddingRight: theme.spacing(0.8),
    paddingTop: theme.spacing(0.8),
    paddingBottom: theme.spacing(0.8),
  },
  pastBidNav: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(0.8),
    paddingBottom: theme.spacing(0.8),
  },
  boughtBidNav: {
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(0.8),
    paddingBottom: theme.spacing(0.8),
  },
  gavelIconClock: {
    fontSize: '1.8rem',
  },
  imprint: {
    padding: theme.spacing(0, 2, 0, 1),
  },
}));

const ConfigurationNavigation = () => {
  const { t } = useTranslation();
  const { getRoles, hasRole } = useRole();
  const roles = getRoles();
  const { data: source = [] } = useSources();
  const sourceOptions = getSourceOptions(source);
  const sourceValues = sourceOptions.map(({ value }) => value);
  const { state: authState } = useAuthenticationStore();
  const dealerCountry = authState.objectToken?.country;
  const { hasWizardStepsToShow } = useWizard();

  const allowedConfNavItems = ConfigNavigationItems.filter((navItem) => {
    const roleAllowed = roles.some((role) => navItem.role.includes(role));
    const countryAllowed =
      isUndefined(navItem.hideForCountries) ||
      (dealerCountry !== undefined && !navItem.hideForCountries?.includes(dealerCountry as CountryCode)) ||
      navItem.ignoreCountryBlacklist?.some((role) => hasRole(role)) ||
      // IT dealers are allowed to see & add credentials only if their allowed Sources are more than only VWFS
      (dealerCountry === CountryCode.It && sourceValues.length > 1 && sourceValues.includes(SourceType.Vwfs));

    return roleAllowed && countryAllowed;
  });

  return (
    <NavigationLinkDropdown
      title={t('navigation.configuration')}
      icon={
        <Badge
          color="primary"
          variant="dot"
          overlap="circular"
          invisible={!hasWizardStepsToShow([WizardStep.TermsConditions])}
        >
          <Settings />
        </Badge>
      }
      items={allowedConfNavItems}
    />
  );
};

const Navigation = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const buttonClasses = useStylesButton();
  const {
    dispatch,
    state: { hasAccess, objectToken },
  } = useAuthenticationStore();
  const location = useLocation();
  const servicePage = location.pathname.includes('services');
  const navigate = useNavigate();
  const { showWizardBadge } = useWizard();
  const matchCarDetail = useMatch(RoutePaths.car);
  const hasReallyAccess =
    hasAccess &&
    objectToken?.is_allowed_to_login &&
    objectToken.exp &&
    objectToken?.exp > DateTime.now().plus({ minute: 1 }).toSeconds();

  return (
    <AppBar position="static" color="primary" className={classes.root}>
      <Container maxWidth={false}>
        <Toolbar className={classes.toolBar}>
          <Link to="/" className={classes.title}>
            {!hasReallyAccess && servicePage ? (
              <img alt={t('common.alluc')} src={AllucLogoImage} className={classes.logo2} />
            ) : (
              <img alt={t('common.gwscout')} src={logoImage} className={classes.logo} />
            )}
          </Link>

          {hasReallyAccess ? (
            <div className={classes.links}>
              <LanguageCountrySwitcher />
              <RoleSimulationChip />
              <NavigationLink to={RoutePaths.cars} text={t('common.car_plural')}>
                <Icon className={classes.singleIcon}>
                  <img
                    src={
                      location.pathname.startsWith(RoutePaths.cars) && !matchCarDetail
                        ? '/images/icons/multiple_cars_highlight.svg'
                        : '/images/icons/multiple_cars_icon.svg'
                    }
                    alt={t('common.car_plural')}
                  />
                </Icon>
              </NavigationLink>

              <NavigationLink
                to={RoutePaths.bidsPending}
                text={t('bids.pendingBids_plural')}
                className={classes.pendingBidNav}
              >
                <GavelClockIcon className={classes.gavelIconClock} />
              </NavigationLink>

              <NavigationLink to={RoutePaths.bidsPast} text={t('bids.sentBids_plural')} className={classes.pastBidNav}>
                <GavelIcon />
              </NavigationLink>

              <NavigationLink to={RoutePaths.orders} text={t('bids.boughtCars')} className={classes.boughtBidNav}>
                <ShoppingCart />
              </NavigationLink>

              <ConfigurationNavigation />

              <NavigationLinkDropdown
                title={t('navigation.information')}
                icon={
                  <Badge
                    color="primary"
                    variant="dot"
                    overlap="circular"
                    invisible={!showWizardBadge(WizardStep.TermsConditions)}
                  >
                    <InfoOutlined />
                  </Badge>
                }
                items={[
                  {
                    title: t('navigation.help'),
                    to: '/help',
                  },
                  {
                    title: t('termsAndConditions.termsAndConditions_short'),
                    to: RoutePaths.termsAndConditions,
                    wizardKey: WizardStep.TermsConditions,
                  },
                  {
                    title: t('navigation.imprint'),
                    to: RoutePaths.imprint,
                  },
                ]}
              />

              <Tooltip
                title={`${t('account.logout')}:\n${objectToken?.first_name} ${objectToken?.last_name}\n${
                  objectToken?.email
                }`}
              >
                <IconButton
                  color="inherit"
                  className={buttonClasses.root}
                  onClick={async () => {
                    await navigate(RoutePaths.logout);
                    dispatch({ type: 'authenticationStore/SIGN_OUT' });
                  }}
                >
                  <ExitToApp />
                </IconButton>
              </Tooltip>
            </div>
          ) : (
            <div className={clsx(classes.links, classes.printHide)}>
              <SupportPopup className={buttonClasses.root} />
              <ImprintPopup className={classes.imprint} />
              <LanguageSwitcher />
            </div>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default memo(Navigation);
