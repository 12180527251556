import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from 'src/constants';
import ApiService from 'src/modules/api-service';
import { createStrictPacket } from './lib';
import { ServicePacket } from './types';

const fetchPackets = () => ApiService.packet.packetControllerGetPackets().then((res) => res.data);

export const usePackets = () =>
  useQuery([QueryKeys.servicePackets], () =>
    fetchPackets().then((packets) =>
      packets.map((packet) => createStrictPacket(packet)).filter((packet): packet is ServicePacket => packet !== null),
    ),
  );
