import React from 'react';
import { AutocompleteApi, AutocompleteState, BaseItem } from '@algolia/autocomplete-core';
import { ErrorScreen } from './ErrorScreen';
import { InternalDocSearchHit } from './types';
import { ResultsScreen } from './ResultsScreen';
import type { FaqSearchProps } from './FaqSearch';

export type ScreenStateProps<TItem extends BaseItem> = AutocompleteApi<
  TItem,
  React.FormEvent,
  React.MouseEvent,
  React.KeyboardEvent
> & {
  state: AutocompleteState<TItem>;
  onItemClick: (item: InternalDocSearchHit) => void;
  inputRef: React.MutableRefObject<HTMLInputElement | null>;
  hitComponent: FaqSearchProps['hitComponent'];
  indexName: FaqSearchProps['indexName'];
  resultsFooterComponent: FaqSearchProps['resultsFooterComponent'];
  getMissingResultsUrl?: FaqSearchProps['getMissingResultsUrl'];
};

export const ScreenState = (props: ScreenStateProps<InternalDocSearchHit>) => {
  const { state } = props;

  if (state.status === 'error') {
    return <ErrorScreen />;
  }

  return <ResultsScreen {...props} />;
};
