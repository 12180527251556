import { createStyles, TableCell as MuiTableCell, Theme, withStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

export const TableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      paddingBlock: theme.spacing(1.5),
      paddingInline: theme.spacing(2),
      backgroundColor: grey[50],
    },
    body: {},
  }),
)(MuiTableCell);
