import { makeStyles, TableRow } from '@material-ui/core';
import clsx from 'clsx';
import { omit } from 'lodash';
import { memo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import TableCellWithoutBorder from 'src/components/TableCellWithoutBorder';
import UnitValue from 'src/components/UnitValue';
import { BidBuyFormType } from 'src/hooks/useBidBuyForm';
import { useCurrency } from 'src/hooks/useCurrency';
import { IndividualBidDTO, PriceType, SourceRegisterPotentialDTO, SourceType } from 'src/modules/generated/api';
import { PotentialFilterType } from 'src/modules/labels';
import { BidInputNameType } from 'src/types/BidInputNameType';
import { FetchCalculationParams, useCalculation } from '../../hooks/useCalculation';
import { chosenValuationCountryState } from '../../stores/state';
import TableNumberInput from './TableNumberInput';

const carculatorTypeMapAbs: Record<
  PotentialFilterType,
  keyof Pick<
    SourceRegisterPotentialDTO,
    'potentialMinAbsolute' | 'potentialMeanAbsolute' | 'potentialMinMeanAbsolute'
    // 'carculatorMinPotential' | 'carculatorAveragePotential' | 'carculatorBetweeenMinAndAveragePotential'
  >
> = {
  [PotentialFilterType.PotentialInternetPriceMin]: 'potentialMinAbsolute',
  [PotentialFilterType.PotentialInternetPriceAverage]: 'potentialMeanAbsolute',
  [PotentialFilterType.PotentialInternetPriceMinAverage]: 'potentialMinMeanAbsolute',
};

const carculatorTypeMapRel: Record<
  PotentialFilterType,
  keyof Pick<
    SourceRegisterPotentialDTO,
    'potentialMinRelative' | 'potentialMeanRelative' | 'potentialMinMeanRelative'
    // 'carculatorMinPotential' | 'carculatorAveragePotential' | 'carculatorBetweeenMinAndAveragePotential'
  >
> = {
  [PotentialFilterType.PotentialInternetPriceMin]: 'potentialMinRelative',
  [PotentialFilterType.PotentialInternetPriceAverage]: 'potentialMeanRelative',
  [PotentialFilterType.PotentialInternetPriceMinAverage]: 'potentialMinMeanRelative',
};

const useStyles = makeStyles((theme) => ({
  textField: {
    width: theme.spacing(20),
    '&--grey-out': {
      '& .MuiInputBase-input': {
        color: theme.palette.grey[600],
      },
    },
  },
  calculation: {
    fontSize: '1rem',
    textAlign: 'right',
  },
  cell: {
    verticalAlign: 'top',
  },
}));

type BidInputProps = {
  bids?: IndividualBidDTO[];
  bidNumber: 1 | 2 | 3;
  source: SourceType;
  carId: string;
  activateForm: boolean;
  bidEditMode: boolean;
  comparisonPotential: PotentialFilterType;
  minBid?: number;
  // In order to handle race conditions
  fetchCalculation: (params: FetchCalculationParams) => Promise<undefined | null | SourceRegisterPotentialDTO>;
};

const BidInput = ({
  bidNumber,
  source,
  carId,
  activateForm,
  bidEditMode,
  comparisonPotential,
  minBid,
  fetchCalculation,
}: BidInputProps) => {
  const classes = useStyles();
  const { calculations } = useCalculation(source, carId);
  const { t } = useTranslation();
  const { setValue, getValues, trigger: triggerValidation, reset } = useFormContext<BidBuyFormType>();
  const { currency, convert } = useCurrency();
  const [chosenValuationCountry] = useRecoilState(chosenValuationCountryState);

  const baseBid: BidInputNameType = `bid.bid${bidNumber}`;
  const watchBaseBid = useWatch({ name: baseBid });
  const grossBid: BidInputNameType = `bid.bid-gross-${bidNumber}`;
  const watchGrossBid = useWatch({ name: grossBid });

  const mappedComparisonValueAbs = calculations?.[bidNumber]?.[carculatorTypeMapAbs[comparisonPotential]];
  const mappedComparisonValueRel = calculations?.[bidNumber]?.[carculatorTypeMapRel[comparisonPotential]];

  const fetchAndSetComputation = async (amount: number | undefined, otherInputName: BidInputNameType) => {
    const calculation = await fetchCalculation({
      fixedVariable: otherInputName === grossBid ? PriceType.OfferPrice : PriceType.PurchaseGross,
      value: amount !== undefined ? { amount, currency } : undefined,
      valuationCountry: chosenValuationCountry,
      bidNumber,
    });
    if (calculation === null) {
      return; // outdated request
    }
    if (!calculation) {
      const formData = omit(getValues(), [baseBid, grossBid]);
      reset(formData);
      triggerValidation();
      return;
    }

    if (otherInputName === grossBid)
      setValue(grossBid, calculation.purchaseGross && convert(calculation.purchaseGross, {}));
    else setValue(baseBid, calculation.base && convert(calculation.base, {}));
    triggerValidation();
  };

  return (
    <TableRow>
      <TableCellWithoutBorder className={classes.cell}>
        <TableNumberInput
          type="base"
          activateForm={activateForm}
          bidEditMode={bidEditMode}
          bidNumber={bidNumber}
          onValueChange={(value) => fetchAndSetComputation(value, grossBid)}
          source={source}
          minBid={minBid}
        />
      </TableCellWithoutBorder>
      <TableCellWithoutBorder className={clsx(classes.cell)}>
        <TableNumberInput
          type="gross"
          activateForm={activateForm}
          bidEditMode={bidEditMode}
          bidNumber={bidNumber}
          onValueChange={(value) => fetchAndSetComputation(value, baseBid)}
          source={source}
          minBid={minBid}
        />
      </TableCellWithoutBorder>
      <TableCellWithoutBorder className={classes.calculation}>
        <UnitValue
          alt={`${t('navigation.notAvailable')}`}
          value={watchBaseBid && watchGrossBid && mappedComparisonValueAbs?.amount}
          unit={mappedComparisonValueAbs?.currency}
        />
        {watchBaseBid && watchGrossBid && mappedComparisonValueRel !== undefined && (
          <>
            {' '}
            (<UnitValue alt="/" value={mappedComparisonValueRel} unit="%" />)
          </>
        )}
      </TableCellWithoutBorder>
    </TableRow>
  );
};

export default memo(BidInput);
