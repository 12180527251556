import { useState } from 'react';
import { Button, ButtonGroup, Grid, makeStyles } from '@material-ui/core';
import { NotificationsOffOutlined, NotificationsOutlined } from '@material-ui/icons';
import { SearchAgentDTO } from '../modules/generated/api';
import { HotButton } from './HotButton';

type SearchAgentFavoritesProps = {
  searchAgents: SearchAgentDTO[];
  viewSearchAgent: (searchAgent: SearchAgentDTO) => void;
  updateSearchAgent: (searchAgent: SearchAgentDTO) => Promise<void>;
};

const useStyles = makeStyles((theme) => ({
  notificationIconActive: {
    backgroundColor: theme.palette.grey[300],
    '&:hover, &.Mui-focusVisible': {
      backgroundColor: theme.palette.error.light,
    },
  },
  notificationIconDisabled: {
    backgroundColor: theme.palette.grey[300],
    '&:hover, &.Mui-focusVisible': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  notificationIcon: {
    fontSize: '1.2rem',
  },
}));

const SearchAgentFavorites = ({ searchAgents, viewSearchAgent, updateSearchAgent }: SearchAgentFavoritesProps) => {
  const classes = useStyles();
  const [hoverId, setHoverId] = useState<string | null>(null);

  return (
    <Grid container spacing={1}>
      <Grid item style={{ paddingInlineEnd: '0.5em' }}>
        <HotButton />
      </Grid>

      {searchAgents.map((searchAgent) => (
        <Grid item key={searchAgent.id}>
          <ButtonGroup variant="contained" size="small">
            <Button onClick={() => viewSearchAgent(searchAgent)}>{searchAgent.searchAgentDisplayName}</Button>
            <Button
              onClick={() =>
                updateSearchAgent({
                  ...searchAgent,
                  active: !searchAgent.active,
                })
              }
              onMouseOver={() => setHoverId(searchAgent.id as string)}
              onMouseLeave={() => setHoverId(null)}
              className={searchAgent.active ? classes.notificationIconActive : classes.notificationIconDisabled}
            >
              {searchAgent.active &&
                (hoverId === searchAgent.id ? (
                  <NotificationsOffOutlined className={classes.notificationIcon} />
                ) : (
                  <NotificationsOutlined className={classes.notificationIcon} />
                ))}
              {!searchAgent.active &&
                (hoverId !== searchAgent.id ? (
                  <NotificationsOffOutlined className={classes.notificationIcon} />
                ) : (
                  <NotificationsOutlined className={classes.notificationIcon} />
                ))}
            </Button>
          </ButtonGroup>
        </Grid>
      ))}
    </Grid>
  );
};

export default SearchAgentFavorites;
