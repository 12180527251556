import { DamageType, DetailCarDTO } from '../modules/generated/api';
import Highlights from './Highlights';
import DamageTypeHighlights from './DamageTypeHighlights';

type CarDetailTileHighlightsProps = {
  car: DetailCarDTO;
  damageType?: Array<DamageType>;
};

const CarDetailTileHighlights = ({ car, damageType }: CarDetailTileHighlightsProps) => (
  <Highlights car={car}>
    <DamageTypeHighlights damageType={damageType} />
  </Highlights>
);

export default CarDetailTileHighlights;
