import { Button, Grid, IconButton, InputAdornment, makeStyles, TextField, Tooltip } from '@material-ui/core';
import ClearSharpIcon from '@material-ui/icons/ClearSharp';
import SearchIcon from '@material-ui/icons/Search';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { green } from '@material-ui/core/colors';
import { isEmpty } from 'lodash';
import theme from '../../../setup/theme';

export const splitOnDelimiter = (s: string) => s.split(/[, \n]+/);

type SearchVinAdderProps = {
  value: string[];
  onSubmit: (value: string[]) => void;
};

const useStyles = makeStyles(() => ({
  alert: {
    width: theme.spacing(48),

    '& .MuiAlert-message': {
      flex: '1',
    },
  },
  alertTitle: {
    margin: 0,
  },
  header: {
    marginBlockEnd: theme.spacing(0.5),
  },
  textarea: {
    backgroundColor: theme.palette.common.white,
  },
  check: {
    color: green[400],
  },
  submitButton: {
    margin: -3,
  },
  clearButtonWrapper: {
    alignSelf: 'flex-start',
    marginBlockStart: theme.spacing(1.25),
  },
}));

const SearchVinAdder = ({ value: initialValue, onSubmit }: SearchVinAdderProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [value, setValue] = useState(initialValue);

  return (
    <Alert severity="info" iconMapping={{ info: <SearchIcon /> }} elevation={3} className={classes.alert}>
      <Grid container justifyContent="space-between" spacing={2} className={classes.header}>
        <Grid item>
          <AlertTitle className={classes.alertTitle}>{t('filterSearch.searchForVins')}</AlertTitle>
        </Grid>
        <Grid item>
          <Button
            size="small"
            variant="contained"
            color="secondary"
            className={classes.submitButton}
            onClick={() => onSubmit(value.filter((vin) => !isEmpty(vin)))}
          >
            {t('action.apply')}
          </Button>
        </Grid>
      </Grid>

      <TextField
        onChange={(event) => setValue(splitOnDelimiter(event.target.value))}
        variant="outlined"
        fullWidth
        multiline
        autoFocus
        className={classes.textarea}
        minRows={4}
        maxRows={20}
        value={value.join('\n')}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" className={classes.clearButtonWrapper}>
              <IconButton size="small" onClick={() => setValue([])}>
                <Tooltip title={t('filterSearch.clearSearch')}>
                  <ClearSharpIcon color="action" />
                </Tooltip>
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Alert>
  );
};

export default SearchVinAdder;
