import { ColumnDef } from '@tanstack/react-table';
import { Trans } from 'react-i18next';
import i18n from 'src/setup/i18n';
import { HeaderCell, TextCell, TextCellFallback } from '../cells';
import { ColumnSize } from '../constants';
import { CarsTableNextItem } from '../types';

export const defaultColumn: Partial<ColumnDef<CarsTableNextItem>> = {
  header: ({ header }) => (
    <HeaderCell
      text={
        <Trans
          i18nKey={
            i18n.exists(`carsTableNext.columns.${header.id}-abbr`)
              ? `carsTableNext.columns.${header.id}-abbr`
              : (`carsTableNext.columns.${header.id}` as any)
          }
        />
      }
      supportText={
        i18n.exists(`carsTableNext.columns.${header.id}-supportText`) && (
          <Trans i18nKey={`carsTableNext.columns.${header.id}-supportText` as any} />
        )
      }
      helperText={
        i18n.exists(`carsTableNext.columns.${header.id}-helperText`) && (
          <Trans i18nKey={`carsTableNext.columns.${header.id}-helperText` as any} />
        )
      }
      hint={i18n.exists(`carsTable.hints.${header.id}`) && <Trans i18nKey={`carsTable.hints.${header.id}` as any} />}
      align={header.column.columnDef.meta?.align ?? (header.column.columnDef.meta?.isNumeric ? 'right' : undefined)}
      enableSorting={header.column.getCanSort()}
      conditionalFormatDef={header.column.columnDef.meta?.conditionalFormat}
      sortDir={header.column.getIsSorted()}
      onSortClick={header.column.getToggleSortingHandler()}
      filterValueName={header.column.columnDef.meta?.filter}
      id={header.column.columnDef.id}
      {...header.column.columnDef.meta?.headerCellProps}
    />
  ),
  cell: (props) => <TextCell text={props.getValue<any>() ?? '-'} />,
  size: ColumnSize.sm,
  minSize: ColumnSize.xs,
  enableSorting: true,
  // NOTE: meta defaults won't get deep merged!
  meta: {
    columnVisible: true,
    fallback: () => <TextCellFallback textWidth="80%" />,
  },
};
