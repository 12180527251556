import { useCallback } from 'react';
import { useAuthenticationStore } from '../stores/Authentication';
import UserRole from '../types/UserRoles';

const useRole = () => {
  const {
    state: { simulateDealer, simulateCountryManager, simulateDealerAdmin, objectToken },
  } = useAuthenticationStore();

  const hasRole = useCallback(
    (role: UserRole, bypassSimulation = false) => {
      if (!bypassSimulation && simulateDealer) return role === UserRole.DEALER;
      if (!bypassSimulation && simulateCountryManager) return role === UserRole.COUNTRYMANAGER;
      if (!bypassSimulation && simulateDealerAdmin) return role === UserRole.DEALERADMIN;
      return objectToken ? objectToken.scope.includes(role) : false;
    },
    [objectToken, simulateCountryManager, simulateDealer, simulateDealerAdmin],
  );

  const getRoles = () => {
    if (simulateDealer) return [UserRole.DEALER];
    if (simulateCountryManager) return [UserRole.COUNTRYMANAGER];
    if (simulateDealerAdmin) return [UserRole.DEALERADMIN];
    return objectToken?.scope || [];
  };

  return { hasRole, getRoles };
};

export default useRole;
