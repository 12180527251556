import { useEffect } from 'react';
import type { AutocompleteApi } from '@algolia/autocomplete-core';

type UseTouchEventsProps = {
  getEnvironmentProps: AutocompleteApi<any>['getEnvironmentProps'];
  panelElement: HTMLDivElement | null;
  formElement: HTMLDivElement | null;
  inputElement: HTMLInputElement | null;
};

/**
 * Close the virtual keyboard when the user starts scrolling.
 * This makes the results more discoverable
 * without the user having to manually close the keyboard.
 */
export function useTouchEvents({ getEnvironmentProps, panelElement, formElement, inputElement }: UseTouchEventsProps) {
  useEffect(() => {
    if (!(panelElement && formElement && inputElement)) {
      return undefined;
    }

    const { onTouchStart, onTouchMove } = getEnvironmentProps({
      panelElement,
      formElement,
      inputElement,
    });

    window.addEventListener('touchstart', onTouchStart);
    window.addEventListener('touchmove', onTouchMove);

    return () => {
      window.removeEventListener('touchstart', onTouchStart);
      window.removeEventListener('touchmove', onTouchMove);
    };
  }, [getEnvironmentProps, panelElement, formElement, inputElement]);
}
