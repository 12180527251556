import { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { DoubleArrow, Save } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { useAuthenticationStore } from 'src/stores/Authentication';
import useApi from '../hooks/useApi';
import { TermsConditionsDTO, TermsConditionsType, WizardStep } from '../modules/generated/api';
import ApiService from '../modules/api-service';
import { useWizard } from '../hooks/useWizard';
import TermsAndConditionsPdf from '../components/TermsAndConditionsPDF';
import { replaceItemAtIndex } from '../modules/util';
import { getLabel, TERMS_AND_CONDITIONS_LABELS } from '../modules/labels';

const useStyles = makeStyles((theme) => ({
  submit: {
    marginBottom: theme.spacing(2),
  },
  pdf: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
  root: {
    marginTop: theme.spacing(5),
  },
  h2: {
    marginBottom: theme.spacing(1),
  },
  accordionSummary: {
    margin: 0,
    minHeight: 0,
    padding: 0,
    display: 'block',
  },
  flipExpaned: {
    color: theme.palette.grey[600],
    transform: 'rotate(270deg)',
  },
  expandedIcon: { color: theme.palette.grey[600], transform: 'rotate(90deg)' },
  accordionDetails: {
    paddingTop: 0,
  },
}));

type TermsAndConditionsFormProps = {
  showInWizard?: boolean;
};

const TermsAndConditionsForm = ({ showInWizard }: TermsAndConditionsFormProps) => {
  const { state: authState } = useAuthenticationStore();
  const { accessToken } = authState;
  const classes = useStyles();
  const [expanded, setExpanded] = useState([false, false, false]);
  const { t } = useTranslation();
  const { fetch, loading, data, mutate } = useApi<TermsConditionsDTO[]>();
  const { fetch: acceptFetch } = useApi<TermsConditionsDTO>();
  const { onConfirmCallback } = useWizard();

  useEffect(() => {
    fetch(ApiService.config.configControllerGetLatestTermsAndConditions());
  }, [fetch]);

  function handleAccept(termsAndConditions: TermsConditionsDTO) {
    return async () => {
      if (termsAndConditions?.version !== undefined) {
        await acceptFetch(
          ApiService.config.configControllerAcceptTermsAndConditions({
            termsType: termsAndConditions.termsType,
            version: termsAndConditions.version,
          }),
        ).then((response) => {
          mutate((prevState) => {
            if (!prevState) return prevState;
            const idx = prevState.findIndex(
              (stateTermsAndConditions) => stateTermsAndConditions.termsType === termsAndConditions.termsType,
            );
            const item = prevState[idx];
            item.accepted = response?.data.accepted;

            return replaceItemAtIndex(prevState, idx, item);
          });
        });

        if (data?.every((toc) => toc.accepted)) {
          onConfirmCallback(WizardStep.TermsConditions, showInWizard);
        }
      }
    };
  }

  const handleChange = (panelNumber: number) => () => {
    setExpanded((prevState) => {
      const newState = [...prevState];
      newState[panelNumber] = !prevState[panelNumber];
      return newState;
    });
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!loading &&
        data?.map((termsAndConditions, idx) => {
          const isPrivacy = termsAndConditions.termsType === TermsConditionsType.VwfsPrivacy;
          return (
            <div key={termsAndConditions.termsType} className={classes.root}>
              <Typography variant="h1" className={classes.h2}>
                {getLabel(TERMS_AND_CONDITIONS_LABELS, termsAndConditions.termsType)}
              </Typography>
              <Accordion onChange={handleChange(idx)}>
                <AccordionSummary
                  classes={{
                    content: classes.accordionSummary,
                    root: classes.accordionSummary,
                    expanded: classes.accordionSummary,
                  }}
                >
                  <Alert severity={!termsAndConditions?.accepted ? 'warning' : 'info'} elevation={1}>
                    <span>
                      {!termsAndConditions?.accepted
                        ? t(`termsAndConditions.reminderText${isPrivacy ? 'DAT' : 'TOC'}`)
                        : t(`termsAndConditions.alreadyAcceptedText${isPrivacy ? 'DAT' : 'TOC'}`)}
                    </span>
                  </Alert>
                  <AccordionActions>
                    <DoubleArrow className={expanded[idx] ? classes.flipExpaned : classes.expandedIcon} />
                  </AccordionActions>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                  {termsAndConditions.link && (
                    <TermsAndConditionsPdf
                      url={termsAndConditions.link}
                      accessToken={
                        termsAndConditions.termsType === TermsConditionsType.TermsConditions ? accessToken : undefined
                      }
                    />
                  )}
                </AccordionDetails>
                {!termsAndConditions?.accepted && (
                  <>
                    <Divider />
                    <AccordionActions>
                      <Button
                        endIcon={<Save />}
                        color="primary"
                        variant="contained"
                        onClick={handleAccept(termsAndConditions)}
                      >
                        {t('common.accept')}
                      </Button>
                    </AccordionActions>
                  </>
                )}
              </Accordion>
            </div>
          );
        })}
    </>
  );
};
export default TermsAndConditionsForm;
