import { createContext, createElement, ReactNode, useContext } from 'react';
import { DEFAULT_VALUATION_COUNTRY, DEFAULT_VALUATION_TYPE } from '../modules/data';
import { PriceType, ValuationCountryCode } from '../modules/generated/api';
import UserRole from '../types/UserRoles';
import { useLocalStorage } from './useLocalStorage';
import useRole from './useRole';

type Nullable<T> = T | null;

type ValuationSettingsProviderProps = {
  children?: ReactNode;
};

type ValuationCountry = Nullable<ValuationCountryCode>;

type ValuationType = Nullable<PriceType>;

type ValuationSettingsCtx = {
  valuationCountry: ValuationCountry;
  valuationType: ValuationType;
  setValuationSettings: (valuationCountry: ValuationCountry, valuationType: ValuationType) => void;
};

const valuationSettingsCtx = createContext<ValuationSettingsCtx>(undefined!);

export const ValuationSettingsProvider = ({ children }: ValuationSettingsProviderProps) => {
  const { hasRole } = useRole();
  const [valuationCountry, setValuationCountry] = useLocalStorage<ValuationCountry>(
    'gwscout/valuationCountry',
    DEFAULT_VALUATION_COUNTRY,
  );
  const [valuationType, setValuationType] = useLocalStorage<ValuationType>(
    'gwscout/valuationType',
    DEFAULT_VALUATION_TYPE,
  );

  const setValuationSettings = (newValuationCountry: ValuationCountry, newValuationType: ValuationType) => {
    setValuationCountry(hasRole(UserRole.ADMIN) ? newValuationCountry : DEFAULT_VALUATION_COUNTRY);
    setValuationType(newValuationType);
  };

  const value = {
    valuationCountry,
    valuationType,
    setValuationSettings,
  };

  return createElement(valuationSettingsCtx.Provider, { value }, children);
};

export const useValuationSettings = () => useContext(valuationSettingsCtx);
