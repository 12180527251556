import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { MarketingChannel } from '../modules/generated/api';
import OptionCheckboxes from './OptionCheckboxes';

const MarketingChannelCheckboxes = () => {
  const { t } = useTranslation();
  return (
    <OptionCheckboxes
      name="marketingChannel"
      label={t('carsTable.marketingType')}
      options={[
        { value: MarketingChannel.Fixed, label: t('car.prices.fixedPrice') },
        { value: MarketingChannel.Bid, label: t('common.toBid') },
      ]}
    />
  );
};

export default memo(MarketingChannelCheckboxes);
