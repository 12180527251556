import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import { Link, useLocation } from '@reach/router';
import useRole from 'src/hooks/useRole';
import UserRole from 'src/types/UserRoles';
import {
  CarsTableNavItems,
  OrderBidsTableNavItems,
  PastBidsTableNavItems,
  PendingBidsTableNavItems,
} from './nav-items';
import { BidTypes, TableType } from './types';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 52,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gridRow: '1 / 3',
    paddingBlock: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[5],
    zIndex: 3,
  },
  nav: {
    // css reset
    margin: 0,
    padding: 0,
    listStyle: 'none',
  },
}));

type NavigationProps = {
  tableType: TableType;
  bidType?: BidTypes;
};

export const Navigation = ({ tableType, bidType }: NavigationProps): JSX.Element => {
  const classes = useStyles();
  const location = useLocation();
  const { hasRole } = useRole();

  const getNavigationItems = () => {
    switch (tableType) {
      case TableType.Cars:
        return CarsTableNavItems;
      case TableType.Bids:
        switch (bidType) {
          case BidTypes.Pending:
            return PendingBidsTableNavItems;
          case BidTypes.Past:
            return PastBidsTableNavItems;
          case BidTypes.Orders:
            return OrderBidsTableNavItems;
          default:
            return [];
        }
      default:
        return [];
    }
  };

  return (
    <div className={classes.root}>
      <ul className={classes.nav}>
        {getNavigationItems()
          .filter(
            ({ roles }) =>
              roles?.length === 0 || hasRole(UserRole.ADMIN) || roles?.every((role) => hasRole(role as any)),
          )
          .map(({ path, icon: Icon, title }) => (
            <li key={path}>
              <Tooltip placement="right" title={title}>
                <IconButton component={Link} to={path} color={path === location.pathname ? 'primary' : undefined}>
                  <Icon fontSize="small" />
                </IconButton>
              </Tooltip>
            </li>
          ))}
      </ul>
    </div>
  );
};
