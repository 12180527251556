import { useCallback, useMemo } from 'react';
import { isEqual } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import { atom, useRecoilState } from 'recoil';
import { CacheKeys, QueryKeys } from 'src/constants';
import { localStorageEffect } from 'src/lib/recoil';
import ApiService from 'src/modules/api-service';
import { SearchAgentDTO, SourceType } from 'src/modules/generated/api';
import { addItemAtIndexSafe, removeItemAtIndex } from 'src/modules/util';
import { useSources } from './useSources';

export type SearchAgentDTOStrict = SearchAgentDTO & {
  id: string;
  searchAgentDisplayName: string;
};

const orderState = atom<string[]>({
  key: CacheKeys.searchAgentsOrder,
  default: [],
  effects: [localStorageEffect(CacheKeys.searchAgentsOrder)],
});

const fetchSearchAgents = () =>
  ApiService.searchAgent.searchAgentControllerListSearchAgent().then((res) =>
    res.data.map((searchAgent, index) => ({
      ...searchAgent,
      id: searchAgent.id ?? String(index),
      searchAgentDisplayName: searchAgent.searchAgentDisplayName ?? 'Unknown',
    })),
  );

const syncCache = (data: SearchAgentDTOStrict[], staleCache: string[]): string[] => {
  const newOrUpdated = data.map((searchAgent) => searchAgent.id!).filter((id) => !staleCache.includes(id));
  const cleanedCache = staleCache.filter((id) => data.some((searchAgent) => searchAgent.id === id));

  const updated = [...cleanedCache, ...newOrUpdated];

  // optimize re-renders and return old ref
  if (isEqual(updated, staleCache)) {
    return staleCache;
  }

  return updated;
};

const updateCache = (id: string, index: number, staleCache: string[]): string[] => {
  const prevIndex = staleCache.indexOf(id);

  if (prevIndex === -1) {
    return [...staleCache, id];
  }

  return addItemAtIndexSafe(removeItemAtIndex(staleCache, prevIndex), index, id);
};

const sortSearchAgents = (data: SearchAgentDTOStrict[], order: string[]): SearchAgentDTOStrict[] => {
  const ordered = [...data];

  ordered.sort((a, b) => {
    const cachedIndexA = order.indexOf(a.id!);
    const cachedIndexB = order.indexOf(b.id!);
    const indexA = cachedIndexA !== -1 ? cachedIndexA : data.length;
    const indexB = cachedIndexB !== -1 ? cachedIndexB : data.length;
    return indexA - indexB;
  });

  return ordered;
};

/**
 * Prevent users with stale SearchAgents from re-hydrating sources with missing permissions.
 */
const filterAllowedSources = (
  searchAgent: SearchAgentDTOStrict,
  allowedSources: SourceType[],
): SearchAgentDTOStrict => ({
  ...searchAgent,
  source: searchAgent.source?.filter((source) => allowedSources.includes(source)),
});

export const useSearchAgents = () => {
  const [order, setOrder] = useRecoilState(orderState);
  const { data: allowedSources = [] } = useSources();
  const query = useQuery(
    [QueryKeys.searchAgents],
    () =>
      fetchSearchAgents().then((searchAgents) =>
        searchAgents.map((searchAgent) => filterAllowedSources(searchAgent, allowedSources)),
      ),
    {
      onSuccess: (data) => {
        setOrder((stale) => syncCache(data, stale));
      },
    },
  );
  const { data } = query;
  const sorted = useMemo(() => sortSearchAgents(data ?? [], order), [data, order]);

  const reorder = useCallback(
    (id: string, index: number) => {
      setOrder((prev) => updateCache(id, index, prev));
    },
    [setOrder],
  );

  return {
    ...query,
    data: sorted,
    reorder,
  };
};
