import { RouteComponentProps } from '@reach/router';
import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { PageMeta } from '@components/page-meta';
import { getAuthenticationUrl } from '../stores/Authentication';
import { AuthenticationProvider } from '../modules/generated/api';
import PageContainer from '../components/PageContainer';

const useStyles = makeStyles(({ palette, spacing }) => ({
  bottom: {
    marginBottom: spacing(8),
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '100px',
  },
  divider: {
    width: '100%',
    borderColor: palette.secondary.light,
    borderWidth: '1px',
  },
  heading: {
    marginBottom: spacing(1),
  },
  imgBtnBox: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  logo: {
    margin: spacing(2),
    height: '60px',
  },
  lineContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: spacing(5),
  },
  underHeadline: {
    marginBottom: spacing(3),
    fontWeight: 'bold',
    fontSize: '1.2rem',
  },
}));

const LoginPage = (_props: RouteComponentProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <PageMeta title="Login" />
      <PageContainer>
        <div className={classes.center}>
          <Typography className={classes.heading} variant="h1">
            {t('account.welcome')}
          </Typography>
          <div className={classes.underHeadline}>{t('account.loginToContinue')}</div>
          <Grid className={classes.lineContainer} container>
            <Grid className={classes.imgBtnBox} item>
              <img alt="Group Retail Portal Logo" className={classes.logo} src="/images/icons/grp-logo.png" />
              <Button color="primary" href={getAuthenticationUrl(AuthenticationProvider.Grp)} variant="contained">
                {t('account.loginWith', { portalName: 'Group Retail Portal' })}
              </Button>
            </Grid>
            <Grid className={classes.imgBtnBox} item>
              <img alt="Partner.net Logo" className={classes.logo} src="/images/icons/partner-net.svg" />
              <Button
                color="primary"
                href={getAuthenticationUrl(AuthenticationProvider.PartnerNet)}
                variant="contained"
              >
                {t('account.loginWith', { portalName: 'Partner.net' })}
              </Button>
            </Grid>
          </Grid>
        </div>
      </PageContainer>
    </>
  );
};

export default LoginPage;
